// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import {
  getHolidaysAsync,
  createHolidayAsync,
  getHolidayDetailsAsync,
  updateHolidayAsync,
  deleteHolidayAsync,
} from './HolidayThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import { IGetHolidaysRes, IGetHolidayDetailsRes } from 'types/holidayTypes';
import { IErrorRes } from 'types/appTypes';

interface ISliceHolidaysState {
  holidays: IGetHolidaysRes | null;
  holidayDetails: IGetHolidayDetailsRes | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: ISliceHolidaysState = {
  holidays: null,
  holidayDetails: null,
  error: null,
  isLoading: false,
};

const holidaysSlice = createSlice({
  name: 'holidays',
  initialState,
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    //* GET HOLIDAYS ASYNC THUNK
    builder.addCase(getHolidaysAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getHolidaysAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.holidays = payload;
    });
    builder.addCase(getHolidaysAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    // //* GET HOLIDAY DETAILS BY ID ASYNC THUNK
    builder.addCase(getHolidayDetailsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getHolidayDetailsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.holidayDetails = payload;
    });
    builder.addCase(getHolidayDetailsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CREATE HOLIDAY ASYNC THUNK
    builder.addCase(createHolidayAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(createHolidayAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(createHolidayAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* UPDATE HOLIDAY (one) ASYNC THUNK
    builder.addCase(updateHolidayAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(updateHolidayAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(updateHolidayAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* DELETE HOLIDAY ASYNC THUNK
    builder.addCase(deleteHolidayAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(deleteHolidayAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteHolidayAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export default holidaysSlice;
