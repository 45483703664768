// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Types
import { TQueryParams } from 'types/appTypes';
import {
  IGetEquipmentExpensesByEquipmentIdRes,
  IGetEquipmentExpensesRes,
} from 'types/equipmentExpensesTypes';

export const getEquipmentExpensesItems = (params: TQueryParams) => {
  return xhr.get<IGetEquipmentExpensesRes, IGetEquipmentExpensesRes>(
    `${ENDPOINTS.equipmentExpenses}`,
    { params },
  );
};

export const getEquipmentExpensesItemsByEquipmentId = (
  equipmentId: number,
  params: TQueryParams,
) => {
  return xhr.get<IGetEquipmentExpensesByEquipmentIdRes, IGetEquipmentExpensesByEquipmentIdRes>(
    `${ENDPOINTS.equipment}/${equipmentId}${ENDPOINTS.equipmentExpenses}`,
    {
      params,
    },
  );
};
