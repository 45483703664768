// Core
import { CSSProperties, ReactNode } from 'react';
// Packages
import { List as AntList, SpinProps } from 'antd';
import { ListGridType, ListLocale } from 'antd/lib/list';
import { PaginationConfig } from 'antd/lib/pagination';

interface IListProps<T> {
  bordered?: boolean;
  dataSource?: T[];
  dataCy?: string;
  footer?: ReactNode;
  grid?: ListGridType;
  header?: ReactNode;
  itemLayout?: 'horizontal' | 'vertical';
  loading?: boolean | SpinProps;
  loadMore?: ReactNode;
  locale?: ListLocale;
  pagination?: PaginationConfig | false;
  renderItem?: (item: T, index: number) => ReactNode;
  rowKey?: ((item: T) => string) | string;
  size?: 'small' | 'default' | 'large';
  split?: boolean;
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
}

/**
 * Simple List. A list can be used to display content related to a single subject.
 * The content can consist of multiple elements of varying type and size.
 *
 * @param bordered - Toggles rendering of the border around the list
 * @param dataSource - DataSource array for list
 * @param dataCy - Prop need for Testing purpose;
 * @param footer - List footer renderer
 * @param grid - The grid type of list. You can set grid to something like {gutter: 16, column: 4}
 * @param header - List header renderer
 * @param itemLayout - The layout of list
 * @param loading - Shows a loading indicator while the contents of the list are being fetched
 * @param loadMore - Shows a load more content
 * @param locale - The i18n text including empty text
 * @param pagination - Pagination config, hide it by setting it to false
 * @param renderItem - Customize list item when using dataSource
 * @param rowKey - Item's unique value, could be an Item's key which holds a unique value of type React.Key or function that receives Item and returns a React.Key
 * @param size - Size of list
 * @param split - Toggles rendering of the split under the list item
 * @param className - Component className
 * @param style - To customize the styles
 * @param children - Some ReactNode
 */

function List<T>({
  bordered = false,
  dataSource,
  dataCy,
  footer,
  grid,
  header,
  itemLayout = 'horizontal',
  loading = false,
  loadMore,
  locale = { emptyText: 'No Data' },
  pagination = false,
  renderItem,
  rowKey,
  size = 'default',
  split = true,
  className,
  style,
  children,
}: IListProps<T>) {
  return (
    <AntList
      bordered={bordered}
      dataSource={dataSource}
      data-cy={dataCy}
      footer={footer}
      grid={grid}
      header={header}
      itemLayout={itemLayout}
      loading={loading}
      loadMore={loadMore}
      locale={locale}
      pagination={pagination}
      renderItem={renderItem}
      rowKey={rowKey}
      size={size}
      split={split}
      className={className}
      style={style}
    >
      {children}
    </AntList>
  );
}

export default List;
