// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import {
  getCurrentUserAsync,
  updateCurrentUserAsync,
  resendVerificationEmailAsync,
} from './userThunk';
import { clearReducer } from '../auth/authThunk';
// i18
import localesList from 'i18n/localesList/localesList';
// Interfaces and types
import { IErrorRes } from 'types/appTypes';
import { IGetUserRes } from 'types/userTypes';
import { TSliceReducer, TActionType } from 'redux/store';

type TAction<T extends TActionType> =
  | typeof getCurrentUserAsync[T]
  | typeof updateCurrentUserAsync[T]
  | typeof resendVerificationEmailAsync[T];

const handleLoadingReducer: TSliceReducer<IState, TAction<'pending'>> = state => {
  state.isLoading = true;
  state.error = null;
};

const handleErrorReducer: TSliceReducer<IState, TAction<'rejected'>> = (state, action) => {
  state.isLoading = false;
  state.error = action.payload || null;
};

const handleEmptyFulfilledReducer: TSliceReducer<IState, TAction<'fulfilled'>> = state => {
  state.isLoading = false;
};

export interface IUser extends IGetUserRes {
  numberLocaleBasic: string;
}

export interface IState {
  user: IUser | null;
  error: IErrorRes | null;
  isLoading: boolean;
  isSwitchingAccount: boolean;
}

export const initialState: IState = {
  user: null,
  error: null,
  isLoading: false,
  isSwitchingAccount: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setNewSettingsAfterSwitching: (state, { payload }) => {
      state.isSwitchingAccount = payload;
    },
  },
  extraReducers: builder => {
    //* GET CURRENT USER ASYNC THUNK
    builder.addCase(getCurrentUserAsync.pending, handleLoadingReducer);
    builder.addCase(getCurrentUserAsync.fulfilled, (state, { payload }) => {
      const numberLocaleBasic =
        localesList.filter(({ localeAbb }) => localeAbb === payload?.numberLocale)[0]
          ?.localeDominantAbb || 'en-US';

      state.user = { ...payload, numberLocaleBasic };
      state.isLoading = false;
    });
    builder.addCase(getCurrentUserAsync.rejected, handleErrorReducer);

    //* UPDATE CURRENT USER ASYNC THUNK
    builder.addCase(updateCurrentUserAsync.fulfilled, (state, { payload }) => {
      if (state.user) {
        state.user.timeFormat = payload.timeFormat;
        state.user.paginationPreference = payload.paginationPreference;
      }
    });
    builder.addCase(updateCurrentUserAsync.rejected, handleErrorReducer);

    //* RESEND VERIFICATION EMAIL ASYNC THUNK
    builder.addCase(resendVerificationEmailAsync.pending, handleLoadingReducer);
    builder.addCase(resendVerificationEmailAsync.fulfilled, handleEmptyFulfilledReducer);
    builder.addCase(resendVerificationEmailAsync.rejected, handleErrorReducer);

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export const { setNewSettingsAfterSwitching } = userSlice.actions;

export default userSlice;
