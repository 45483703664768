// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { RecurringInvoices } from 'api/endpoints';
// Interfaces and types
import {
  IGetRecurringInvoicesRes,
  IGetRecurringInvoiceDetailsRes,
  IUpdateRecurringInvoiceBodyWithParam,
  ICreateRecurringInvoiceRes,
  ICreateRecurringInvoiceBody,
  IUpdateRecurringInvoiceRes,
  IDeleteRecurringInvoiceRes,
} from 'types/recurringInvoiceTypes';
import { IErrorRes, TQueryParams } from 'types/appTypes';

const getRecurringInvoicesAsync = createAsyncThunk<
  IGetRecurringInvoicesRes,
  TQueryParams,
  { rejectValue: IErrorRes }
>('recurringInvoices/getInvoices', async (params: TQueryParams, { rejectWithValue }) => {
  try {
    return await RecurringInvoices.getRecurringInvoices(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const createRecurringInvoiceAsync = createAsyncThunk<
  ICreateRecurringInvoiceRes,
  ICreateRecurringInvoiceBody,
  { rejectValue: IErrorRes }
>(
  'recurringInvoices/createInvoice',
  async (body: ICreateRecurringInvoiceBody, { rejectWithValue }) => {
    try {
      return await RecurringInvoices.createRecurringInvoiceItem(body);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const getRecurringInvoiceDetailsAsync = createAsyncThunk<
  IGetRecurringInvoiceDetailsRes,
  number,
  { rejectValue: IErrorRes }
>('recurringInvoices/getInvoiceDetails', async (id: number, { rejectWithValue }) => {
  try {
    return await RecurringInvoices.getRecurringInvoiceDetails(id);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const updateRecurringInvoiceAsync = createAsyncThunk<
  IUpdateRecurringInvoiceRes,
  IUpdateRecurringInvoiceBodyWithParam,
  { rejectValue: IErrorRes }
>(
  'recurringInvoices/updateInvoice',
  async ({ id, body }: IUpdateRecurringInvoiceBodyWithParam, { rejectWithValue }) => {
    try {
      return await RecurringInvoices.updateRecurringInvoiceItem({ id, body });
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const deleteRecurringInvoiceAsync = createAsyncThunk<
  IDeleteRecurringInvoiceRes,
  number,
  { rejectValue: IErrorRes }
>('recurringInvoices/deleteInvoice', async (id: number, { rejectWithValue }) => {
  try {
    return await RecurringInvoices.deleteRecurringInvoice(id);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

export {
  getRecurringInvoicesAsync,
  createRecurringInvoiceAsync,
  getRecurringInvoiceDetailsAsync,
  updateRecurringInvoiceAsync,
  deleteRecurringInvoiceAsync,
};
