import { TQueryParams } from 'types/appTypes';

export type TTodoType = 'employee' | 'job';

export interface ITask {
  id: number | string;
  name: string;
  isRequired: boolean;
  todoId: number;
  isDone: boolean;
  index: number;
  completedAt: Date;
}

export interface ITaskItem {
  id: ITask['id'];
  name: ITask['name'];
  isRequired: ITask['isRequired'];
}

export interface ITodo {
  id: number;
  userId: number;
  employeeName: string;
  toDoNote: string;
  tasks: ITask[];
  type: TTodoType;
  title: string;
  creatorId: number;
  sendOn: string | null;
  dueDate: string | null;
  isDone: boolean;
  jobNote: string;
  shiftId: number;
  updatedBy: string;
  updatedAt: Date;
  createdAt: Date;
  resultUpdatedAt: Date;
  resultUpdatedBy: string;
}

export interface ITodoParams {
  id: ITodo['id'];
  userId: ITodo['userId'];
}

//? ENDPOINT "/todo" METHOD "GET"
export interface ITodoItem {
  id: ITodo['id'];
  userId: ITodo['userId'];
  employeeName: ITodo['employeeName'];
  title: ITodo['title'];
  type: TTodoType;
  creatorId: ITodo['creatorId'];
  sentBy: string;
  sendOn: ITodo['sendOn'];
  dueDate: ITodo['dueDate'];
  isDone: ITodo['isDone'];
  locationName?: string;
  shiftId?: ITodo['shiftId'];
}

export interface IGetTodoRes {
  count: number;
  items: ITodoItem[];
}

//? ENDPOINT "/todo" METHOD "POST"
export interface ICreateTaskItem {
  id?: string | number;
  name: ITask['name'];
  isRequired: ITask['isRequired'];
}

export interface ICreateTodoBody {
  title: ITodo['title'];
  toDoNote: ITodo['toDoNote'];
  dueDate: ITodo['dueDate'];
  sendOn: ITodo['sendOn'];
  showInApp: boolean;
  employeeIds: number[];
  employeeGroupIds: number[];
  tasks: ICreateTaskItem[];
}

export interface ICreateTodoRes {
  message: string;
}

//? ENDPOINT "/employees/${userId}/todo" METHOD "GET"
export interface IGetEmployeeTodoBody {
  userId: ITodo['userId'];
  params: TQueryParams;
}

export interface IEmployeeTodoItem {
  id: ITodo['id'];
  userId: number;
  type: TTodoType;
  title: ITodo['title'];
  creatorId: ITodo['creatorId'];
  sentBy: string;
  sendOn: ITodo['sendOn'];
  dueDate: ITodo['dueDate'];
  isDone: ITodo['isDone'];
}

export interface IGetEmployeeTodoRes {
  count: number;
  items: IEmployeeTodoItem[];
}

//? ENDPOINT "/todo/${id}" METHOD "GET"
export interface IGetTaskItem extends ITask {
  id: number;
}

export interface IGetTodoDetailsRes {
  id: ITodo['id'];
  userId: ITodo['userId'];
  employeeName: ITodo['employeeName'];
  toDoNote: ITodo['toDoNote'];
  tasks: IGetTaskItem[];
  type: TTodoType;
  title: ITodo['title'];
  creatorId: ITodo['creatorId'];
  sendOn: ITodo['sendOn'];
  dueDate: ITodo['dueDate'];
  isDone: ITodo['isDone'];
  jobNote?: ITodo['jobNote'];
  shiftId?: ITodo['shiftId'];
  updatedBy: ITodo['updatedBy'];
  updatedAt: ITodo['updatedAt'];
  createdAt: ITodo['createdAt'];
  resultUpdatedAt: ITodo['resultUpdatedAt'];
  resultUpdatedBy: ITodo['resultUpdatedBy'];
}

//? ENDPOINT "/employees/${userId}/todo/${id}" METHOD "PATCH"
export interface IUpdateTodoParams extends ITodoParams {
  body: IUpdateTodoBody;
}

export interface IUpdateTodoTaskItem {
  id: ITask['id'];
  name: ITask['name'];
  isRequired: ITask['isRequired'];
}

export interface IUpdateTodoBody {
  title: ITodo['title'];
  toDoNote: ITodo['toDoNote'];
  dueDate: ITodo['dueDate'];
  sendOn: ITodo['sendOn'];
  showInApp: boolean;
  tasks: IUpdateTodoTaskItem[];
}

export interface IUpdateTodoRes {
  message: string;
}

//? ENDPOINT "/employees/${userId}/todo/${id}" METHOD "DELETE"
export interface IDeleteTodoRes {
  message: string;
}

//? ENDPOINT "/shifts/${shiftId}/todo/${id}" METHOD "DELETE"
export interface IShiftTodoParams {
  id: ITodo['id'];
  shiftId: ITodo['shiftId'];
}

//? ENDPOINT "/todo/${shiftId}/tasks" METHOD "PATCH"
export interface IUpdateTaskItem {
  id: ITask['id'];
  isDone: ITask['isDone'];
}

export interface IUpdateTasks {
  tasks: IUpdateTaskItem[];
}

export interface IUpdateTasksParams {
  body: IUpdateTasks;
  todoId: number;
}

export enum ETypeOfEditItem {
  TODO = 'todo',
  TODO_RESULT = 'todoResult',
}
