// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { JobExpensesApi } from 'api/endpoints';
// Interfaces and types
import {
  IGetJobExpensesRes,
  ICreateJobExpensesRes,
  ICreateJobExpensesBodyWithParams,
  IGetJobExpensesByIdParams,
  IGetJobExpensesByJobIdRes,
  IGetJobExpensesDetailsParams,
  IGetJobExpensesDetailsRes,
  IUpdateJobExpensesBodyWithParams,
  IUpdateJobExpensesRes,
  IDeleteJobExpensesRes,
  IDeleteJobExpensesParams,
  IUpdateJobExpensesStatusBodyWithParams,
  IUpdateJobExpensesStatusRes,
} from 'types/jobExpensesTypes';
import { IErrorRes, TQueryParams } from 'types/appTypes';

const getJobExpensesAsync = createAsyncThunk<
  IGetJobExpensesRes,
  TQueryParams,
  { rejectValue: IErrorRes }
>('jobExpenses/getJobExpenses', async (params: TQueryParams, { rejectWithValue }) => {
  try {
    return await JobExpensesApi.getJobExpenses(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const createJobExpensesAsync = createAsyncThunk<
  ICreateJobExpensesRes,
  ICreateJobExpensesBodyWithParams,
  { rejectValue: IErrorRes }
>(
  'jobExpenses/createJobExpenses',
  async ({ jobId, body }: ICreateJobExpensesBodyWithParams, { rejectWithValue }) => {
    try {
      return await JobExpensesApi.createJobExpenses({ jobId, body });
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const getJobExpensesByJobIdAsync = createAsyncThunk<
  IGetJobExpensesByJobIdRes,
  IGetJobExpensesByIdParams,
  { rejectValue: IErrorRes }
>(
  'jobExpenses/getJobExpensesByJobId',
  async ({ jobId, params }: IGetJobExpensesByIdParams, { rejectWithValue }) => {
    try {
      return await JobExpensesApi.getJobExpensesByJobId({ jobId, params });
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const getJobExpensesDetailsAsync = createAsyncThunk<
  IGetJobExpensesDetailsRes,
  IGetJobExpensesDetailsParams,
  { rejectValue: IErrorRes }
>(
  'jobExpenses/getJobExpensesDetails',
  async (params: IGetJobExpensesDetailsParams, { rejectWithValue }) => {
    try {
      return await JobExpensesApi.getJobExpenseDetails(params);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const updateJobExpensesAsync = createAsyncThunk<
  IUpdateJobExpensesRes,
  IUpdateJobExpensesBodyWithParams,
  { rejectValue: IErrorRes }
>(
  'jobExpenses/updateJobExpenses',
  async ({ jobId, id, body }: IUpdateJobExpensesBodyWithParams, { rejectWithValue }) => {
    try {
      return await JobExpensesApi.editJobExpensesDetails({ jobId, id, body });
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const deleteJobExpensesAsync = createAsyncThunk<
  IDeleteJobExpensesRes,
  IDeleteJobExpensesParams,
  { rejectValue: IErrorRes }
>(
  'jobExpenses/deleteJоbExpenses',
  async ({ jobId, id }: IDeleteJobExpensesParams, { rejectWithValue }) => {
    try {
      return await JobExpensesApi.deleteJobExpenses({ jobId, id });
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const updateJobExpensesStatusAsync = createAsyncThunk<
  IUpdateJobExpensesStatusRes,
  IUpdateJobExpensesStatusBodyWithParams,
  { rejectValue: IErrorRes }
>(
  'jobExpenses/updateJobExpensesStatus',
  async ({ jobId, id, body }: IUpdateJobExpensesStatusBodyWithParams, { rejectWithValue }) => {
    try {
      return await JobExpensesApi.editJobExpensesStatus({ jobId, id, body });
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

export {
  getJobExpensesAsync,
  createJobExpensesAsync,
  getJobExpensesByJobIdAsync,
  getJobExpensesDetailsAsync,
  updateJobExpensesAsync,
  deleteJobExpensesAsync,
  updateJobExpensesStatusAsync,
};
