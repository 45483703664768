import showNotification from 'helpers/showNotification';
// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Types
import {
  IGetSubscriptionRes,
  IGetSessionIdRes,
  IGetSubscriptionByAccountNameRes,
} from 'types/subscriptionTypes';
import { IResMessage } from 'types/appTypes';
import { t } from 'i18n';

export const getUserSubscription = () => {
  return xhr.get<IGetSubscriptionRes, IGetSubscriptionRes>(`${ENDPOINTS.userSubscription}`);
};

export const getSessionId = () => {
  return xhr.get<IGetSessionIdRes, IGetSessionIdRes>(`${ENDPOINTS.addSubscription}`);
};

export const deleteSubscription = () => {
  return xhr.delete<IResMessage, IResMessage>(`${ENDPOINTS.userSubscription}`);
};

export const getSubscriptionStatusByAccountName = (accountName: string) => {
  return xhr.get<IGetSubscriptionByAccountNameRes, IGetSubscriptionByAccountNameRes>(
    `${ENDPOINTS.companies}/${accountName}${ENDPOINTS.subscriptions}${ENDPOINTS.status}`,
  );
};

//In this api call we add logic of notification for reuse it in different pages and not to add notification separately in each Page or Component;
export const getSubscriptionStatusForPermissionPurpose = () => {
  return xhr
    .get<IGetSubscriptionRes, IGetSubscriptionRes>(
      `${ENDPOINTS.subscriptions}${ENDPOINTS.status}`,
    )
    .then(subscriptionInfo => {
      if (!subscriptionInfo?.isSubscribed) {
        showNotification('error', t('errors.SUBSCRIPTION_NOT_FOUND'));
      }

      return subscriptionInfo.isSubscribed;
    })
    .catch(() => {
      console.log('Some thing went wrong!');
    });
};
