// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import {
  getEquipmentLogItemsAsync,
  getEquipmentLogDetailsAsync,
  createEquipmentLogAsync,
  deleteEquipmentLogAsync,
  getEquipmentLogItemsByEquipmentIdAsync,
  updateEquipmentLogAsync,
} from './equipmentLogsThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import { IErrorRes } from 'types/appTypes';
import { IGetEquipmentLogItem, IGetEquipmentLogsRes } from 'types/equipmentLogsTypes';

interface ISliceEquipmentLogsState {
  equipmentLogItems: IGetEquipmentLogsRes | null;
  equipmentLogDetails: IGetEquipmentLogItem | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: ISliceEquipmentLogsState = {
  equipmentLogItems: null,
  equipmentLogDetails: null,
  error: null,
  isLoading: false,
};

const equipmentLogsSlice = createSlice({
  name: 'equipmentLogs',
  initialState,
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    //* GET EQUIPMENT LOGS ASYNC THUNK
    builder.addCase(getEquipmentLogItemsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getEquipmentLogItemsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.equipmentLogItems = payload;
    });
    builder.addCase(getEquipmentLogItemsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* GET EQUIPMENT_LOGS BY EQUIPMENT_ID ASYNC THUNK
    builder.addCase(getEquipmentLogItemsByEquipmentIdAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getEquipmentLogItemsByEquipmentIdAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.equipmentLogItems = payload;
    });
    builder.addCase(getEquipmentLogItemsByEquipmentIdAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* GET EQUIPMENT LOG DETAILS ASYNC THUNK
    builder.addCase(getEquipmentLogDetailsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getEquipmentLogDetailsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.equipmentLogDetails = payload;
    });
    builder.addCase(getEquipmentLogDetailsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CREATE EQUIPMENT LOG ASYNC THUNK
    builder.addCase(createEquipmentLogAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(createEquipmentLogAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(createEquipmentLogAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* UPDATE EQUIPMENT LOG (one) ASYNC THUNK
    builder.addCase(updateEquipmentLogAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(updateEquipmentLogAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(updateEquipmentLogAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* DELETE EQUIPMENT ASYNC THUNK
    builder.addCase(deleteEquipmentLogAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(deleteEquipmentLogAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteEquipmentLogAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export default equipmentLogsSlice;
