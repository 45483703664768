// Core
import { CSSProperties, ReactNode } from 'react';
// Packages
import { Col as AntCol } from 'antd';

interface IColProps {
  flex?: string | number;
  offset?: number;
  order?: number;
  pull?: number;
  push?: number;
  span?: number;
  xs?: number | object;
  sm?: number | object;
  md?: number | object;
  lg?: number | object;
  xl?: number | object;
  xxl?: number | object;
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
}

/**
 * 24 Grids System.
 *
 * @param flex - Flex layout style
 * @param offset - The number of cells to offset Col from the left
 * @param order - Raster order
 * @param pull - The number of cells that raster is moved to the left
 * @param push - The number of cells that raster is moved to the right
 * @param span - Raster number of cells to occupy, 0 corresponds to "display: none". Max = 24
 * @param xs - screen < 576px and also default setting, could be a span value or an object containing above props
 * @param sm - screen ≥ 576px, could be a span value or an object containing above props
 * @param md - screen ≥ 768px, could be a span value or an object containing above props
 * @param lg - screen ≥ 992px, could be a span value or an object containing above props
 * @param xl - screen ≥ 1200px, could be a span value or an object containing above props
 * @param xxl - screen ≥ 1600px, could be a span value or an object containing above props
 * @param className - Component className
 * @param style - To customize the styles
 * @param children - Some ReactNode
 */

const Col = ({
  flex,
  offset = 0,
  order = 0,
  pull = 0,
  push = 0,
  span,
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
  className,
  style,
  children,
}: IColProps) => (
  <AntCol
    flex={flex}
    offset={offset}
    order={order}
    pull={pull}
    push={push}
    span={span}
    xs={xs}
    sm={sm}
    md={md}
    lg={lg}
    xl={xl}
    xxl={xxl}
    className={className}
    style={style}
  >
    {children}
  </AntCol>
);

export default Col;
