// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { EquipmentApi } from 'api/endpoints';
// Interfaces and types
import {
  IEquipmentCreateBody,
  IEquipmentCreateRes,
  IEquipmentItemDetailsRes,
  IEquipmentUpdateBody,
  IEquipmentUpdateRes,
  IGetEquipmentItemsRes,
} from 'types/equipmentTypes';
import { IErrorRes, TQueryParams, IResMessage } from 'types/appTypes';

const getEquipmentItemsAsync = createAsyncThunk<
  IGetEquipmentItemsRes,
  TQueryParams,
  { rejectValue: IErrorRes }
>('equipment/getEquipment', async (params: TQueryParams, { rejectWithValue }) => {
  try {
    return await EquipmentApi.getEquipmentItems(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getEquipmentDetailsAsync = createAsyncThunk<
  IEquipmentItemDetailsRes,
  number,
  { rejectValue: IErrorRes }
>('equipment/getEquipmentDetails', async (id: number, { rejectWithValue }) => {
  try {
    return await EquipmentApi.getEquipment(id);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const createEquipmentAsync = createAsyncThunk<
  IEquipmentCreateRes,
  IEquipmentCreateBody,
  { rejectValue: IErrorRes }
>('equipment/createEquipment', async (body: IEquipmentCreateBody, { rejectWithValue }) => {
  try {
    return await EquipmentApi.createEquipment(body);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const updateEquipmentAsync = createAsyncThunk<
  IEquipmentUpdateRes,
  IEquipmentUpdateBody,
  { rejectValue: IErrorRes }
>('equipment/updateEquipment', async (cred: IEquipmentUpdateBody, { rejectWithValue }) => {
  try {
    return await EquipmentApi.updateEquipment(cred);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const deleteEquipmentAsync = createAsyncThunk<IResMessage, number, { rejectValue: IErrorRes }>(
  'equipment/deleteEquipment',
  async (id: number, { rejectWithValue }) => {
    try {
      return await EquipmentApi.deleteEquipment(id);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

export {
  getEquipmentItemsAsync,
  getEquipmentDetailsAsync,
  createEquipmentAsync,
  updateEquipmentAsync,
  deleteEquipmentAsync,
};
