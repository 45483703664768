// Core
import { CSSProperties, ReactNode } from 'react';
// Packages
import { Slider as AntSlider } from 'antd';
import { SliderMarks } from 'antd/lib/slider';
import { TooltipPlacement } from 'antd/lib/tooltip';
// Styles
import './slider.scss';

interface ISliderProps {
  autoFocus?: boolean;
  className?: string;
  defaultValue?: number;
  disabled?: boolean;
  dots?: boolean;
  getTooltipPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
  included?: boolean;
  marks?: SliderMarks;
  max?: number;
  min?: number;
  reverse?: boolean;
  step?: number | null;
  style?: CSSProperties;
  tipFormatter?: null | ((value?: number) => ReactNode);
  tooltipPlacement?: TooltipPlacement;
  tooltipVisible?: boolean;
  value?: number;
  vertical?: boolean;
  onChange?: (value: number) => void;
  trackStyle?: CSSProperties;
  handleStyle?: CSSProperties;
}

/**
 * A Slider component for displaying current value and intervals in range.
 *
 * @description To input a value in a range.
 *
 * @param autoFocus - Whether get focus when component mounted
 * @param className - The picker className
 * @param defaultValue - The default value of slider. When range is false, use number, otherwise, use [number, number]
 * @param disabled - If true, the slider will not be interactable
 * @param dots - Whether the thumb can drag over tick only
 * @param getTooltipPopupContainer - The DOM container of the Tooltip, the default behavior is to create a div element in body
 * @param included - Make effect when marks not null, true means containment and false means coordinative
 * @param marks - Tick mark of Slider, type of key must be number, and must in closed interval [min, max], each mark can declare its own style
 * @param max - The maximum value the slider can slide to
 * @param min - The minimum value the slider can slide to
 * @param reverse - Reverse the component
 * @param step - The granularity the slider can step through values. Must greater than 0, and be divided by (max - min) . When marks no null, step can be null
 * @param style - To customize the style
 * @param tipFormatter - Slider will pass its value to tipFormatter, and display its value in Tooltip, and hide Tooltip when return value is null
 * @param tooltipPlacement - Set Tooltip display position. Ref Tooltip
 * @param tooltipVisible - If true, Tooltip will show always, or it will not show anyway, even if dragging or hovering
 * @param value - The value of slider. When range is false, use number, otherwise, use [number, number]
 * @param vertical - If true, the slider will be vertical
 * @param onChange - Callback function that is fired when the user changes the slider's value
 * @param trackStyle - The style of slider track
 * @param handleStyle - The style of slider handle
 */

const Slider = ({
  autoFocus = false,
  className,
  defaultValue = 50,
  disabled = false,
  dots = false,
  getTooltipPopupContainer,
  included = true,
  marks,
  max = 500,
  min = 50,
  reverse = false,
  step = 1,
  style,
  tipFormatter,
  tooltipPlacement,
  tooltipVisible,
  value,
  vertical = false,
  onChange,
  trackStyle,
  handleStyle,
}: ISliderProps) => (
  <AntSlider
    autoFocus={autoFocus}
    className={className}
    defaultValue={defaultValue}
    disabled={disabled}
    dots={dots}
    getTooltipPopupContainer={getTooltipPopupContainer}
    included={included}
    marks={marks}
    max={max}
    min={min}
    reverse={reverse}
    step={step}
    style={style}
    tipFormatter={tipFormatter}
    tooltipPlacement={tooltipPlacement}
    tooltipVisible={tooltipVisible}
    value={value}
    vertical={vertical}
    onChange={onChange}
    trackStyle={trackStyle}
    handleStyle={handleStyle}
  />
);

export default Slider;
