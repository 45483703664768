// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import { clearReducer } from '../auth/authThunk';
import {
  getPerfLogsAsync,
  createPerfLogAsync,
  getEmployeePerfLogsAsync,
  getEmployeePerfLogDetailsAsync,
  updatePerfLogAsync,
  deletePerfLogAsync,
} from './perfLogsThunk';
// Interfaces and types
import { IErrorRes } from 'types/appTypes';
import {
  IGetPerfLogsRes,
  IGetEmployeePerfLogsRes,
  IGetEmployeePerfLogDetailsRes,
} from 'types/performanceLog';
import { TSliceReducer, TActionType } from 'redux/store';

type TAction<T extends TActionType> =
  | typeof getPerfLogsAsync[T]
  | typeof createPerfLogAsync[T]
  | typeof getEmployeePerfLogsAsync[T]
  | typeof getEmployeePerfLogDetailsAsync[T]
  | typeof updatePerfLogAsync[T]
  | typeof deletePerfLogAsync[T];

const handleLoadingReducer: TSliceReducer<IState, TAction<'pending'>> = state => {
  state.isLoading = true;
  state.error = null;
};

const handleEmptyFulfilledReducer: TSliceReducer<IState, TAction<'fulfilled'>> = state => {
  state.isLoading = false;
};

const handleErrorReducer: TSliceReducer<IState, TAction<'rejected'>> = (state, action) => {
  state.isLoading = false;
  state.error = action.payload || null;
};

interface IState {
  perfLogs: IGetPerfLogsRes | null;
  employeePerfLogs: IGetEmployeePerfLogsRes | null;
  perfLogDetails: IGetEmployeePerfLogDetailsRes | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: IState = {
  perfLogs: null,
  employeePerfLogs: null,
  perfLogDetails: null,
  error: null,
  isLoading: false,
};

const perfLogSlice = createSlice({
  name: 'perfLog',
  initialState,
  reducers: {},
  extraReducers: builder => {
    //* GET PERFORMANCE LOGS ASYNC THUNK
    builder.addCase(getPerfLogsAsync.pending, handleLoadingReducer);
    builder.addCase(getPerfLogsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.perfLogs = payload;
    });
    builder.addCase(getPerfLogsAsync.rejected, handleErrorReducer);

    //* CREATE PERFORMANCE LOG ASYNC THUNK
    builder.addCase(createPerfLogAsync.pending, handleLoadingReducer);
    builder.addCase(createPerfLogAsync.fulfilled, handleEmptyFulfilledReducer);
    builder.addCase(createPerfLogAsync.rejected, handleErrorReducer);

    //* GET EMPLOYEE PERFORMANCE LOGS ASYNC THUNK
    builder.addCase(getEmployeePerfLogsAsync.pending, handleLoadingReducer);
    builder.addCase(getEmployeePerfLogsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.employeePerfLogs = payload;
    });
    builder.addCase(getEmployeePerfLogsAsync.rejected, handleErrorReducer);

    //* GET EMPLOYEE PERFORMANCE LOG DETAILS ASYNC THUNK
    builder.addCase(getEmployeePerfLogDetailsAsync.pending, handleLoadingReducer);
    builder.addCase(getEmployeePerfLogDetailsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.perfLogDetails = payload;
    });
    builder.addCase(getEmployeePerfLogDetailsAsync.rejected, handleErrorReducer);

    //* UPDATE PERFORMANCE LOG ASYNC THUNK
    builder.addCase(updatePerfLogAsync.pending, handleLoadingReducer);
    builder.addCase(updatePerfLogAsync.fulfilled, handleEmptyFulfilledReducer);
    builder.addCase(updatePerfLogAsync.rejected, handleErrorReducer);

    //* DELETE PERFORMANCE LOG ASYNC THUNK
    builder.addCase(deletePerfLogAsync.pending, handleLoadingReducer);
    builder.addCase(deletePerfLogAsync.fulfilled, state => {
      state.isLoading = false;
      state.perfLogDetails = null;
    });
    builder.addCase(deletePerfLogAsync.rejected, handleErrorReducer);

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export default perfLogSlice;
