//Redux
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { IntegrationApi } from 'api/endpoints';
// Types
import { IErrorRes } from 'types/appTypes';

// Interfaces and types
import {
  ICreateIntegrationBody,
  IIntegrationRes,
  IGetEmployeeDetailsRes,
  IIntegrationParams,
  IGetTimegoHourseDifferenceRes,
  ISyncEmployeeRateBodyWithParam,
  IUpdateEmployeeRateBodyWithParam,
  SyncLogsRes,
  ISyncLogsParams,
  IQboAuthRes,
  IQboAuthParams,
  ISyncEmployeeVerifyRatesBody,
  IIntegrationXeroParams,
  ISyncParams,
  ICreateConfigurationBody,
  ISyncEmployeePayrollBodyWithParam,
} from 'types/integrationTypes';

const getIntegrationAsync = createAsyncThunk<
  IIntegrationRes,
  number,
  { rejectValue: IErrorRes }
>('integrations/qboIntegration', async (params: number, { rejectWithValue }) => {
  try {
    return await IntegrationApi.getIntegration(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});
const getConfigurationAsync = createAsyncThunk<
  IIntegrationRes,
  number,
  { rejectValue: IErrorRes }
>('integrations/xeroIntegration', async (params: number, { rejectWithValue }) => {
  try {
    return await IntegrationApi.getConfiguration(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});
const getEarningRatesAndPayRunCalendersAsync = createAsyncThunk<
  IIntegrationRes,
  number,
  { rejectValue: IErrorRes }
>('integrations/xeroIntegration', async (params: number, { rejectWithValue }) => {
  try {
    return await IntegrationApi.getEarningRatesAndPayRunCalenders(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});
const syncEarningRatesOrPayCalendersAsync = createAsyncThunk<
  IIntegrationRes,
  ISyncParams,
  { rejectValue: IErrorRes }
>(
  'integrations/xeroIntegration/configuration',
  async (params: ISyncParams, { rejectWithValue }) => {
    try {
      return await IntegrationApi.syncEarningRatesAndPayCalenders(params);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const getAuthURLIntegrationAsync = createAsyncThunk<
  IQboAuthRes,
  IQboAuthParams,
  { rejectValue: IErrorRes }
>('integrations/AuthURL', async (params: IQboAuthParams, { rejectWithValue }) => {
  try {
    return await IntegrationApi.getIntegrationAuthURL(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const createIntegrationAsync = createAsyncThunk<
  IIntegrationRes,
  ICreateIntegrationBody,
  { rejectValue: IErrorRes }
>(
  '/integrations/qboIntegration',
  async (body: ICreateIntegrationBody, { rejectWithValue }) => {
    try {
      return await IntegrationApi.createIntegration(body);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);
const createConfigurationAsync = createAsyncThunk<
  IIntegrationRes,
  ICreateConfigurationBody,
  { rejectValue: IErrorRes }
>(
  '/integrations/xeroIntegration',
  async ({ body, timeGoUniqueId }: ICreateConfigurationBody, { rejectWithValue }) => {
    try {
      return await IntegrationApi.createConfiguration({ body, timeGoUniqueId });
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const createXeroIntegrationAsync = createAsyncThunk<
  IIntegrationRes,
  ICreateIntegrationBody,
  { rejectValue: IErrorRes }
>(
  '/integrations/xeroIntegration/connection',
  async (body: ICreateIntegrationBody, { rejectWithValue }) => {
    try {
      return await IntegrationApi.createXeroIntegration(body);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const deleteIntegrationAsync = createAsyncThunk<
  IIntegrationRes,
  number,
  { rejectValue: IErrorRes }
>('integrations/qboIntegration/connection', async (params: number, { rejectWithValue }) => {
  try {
    return await IntegrationApi.deleteCheckpoint(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});
const deleteXeroIntegrationAsync = createAsyncThunk<
  IIntegrationRes,
  number,
  { rejectValue: IErrorRes }
>('integrations/xeroIntegration/connection', async (params: number, { rejectWithValue }) => {
  try {
    return await IntegrationApi.deleteXeroCheckpoint(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});
const getTimegoHourssAsync = createAsyncThunk<
  IGetEmployeeDetailsRes,
  IIntegrationParams,
  { rejectValue: IErrorRes }
>('/reports/time-activity', async (cred: IIntegrationParams, { rejectWithValue }) => {
  try {
    return await IntegrationApi.getTimegoHours(cred);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});
const getTimesheetHourssAsync = createAsyncThunk<
  IGetEmployeeDetailsRes,
  IIntegrationXeroParams,
  { rejectValue: IErrorRes }
>('/reports/time-activity', async (cred: IIntegrationXeroParams, { rejectWithValue }) => {
  try {
    return await IntegrationApi.getTimesheetHours(cred);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const verifyRatesAsync = createAsyncThunk<
  IGetTimegoHourseDifferenceRes,
  ISyncEmployeeVerifyRatesBody,
  { rejectValue: IErrorRes }
>(
  'qboIntegration/verifyRates',
  async ({ timeGoUniqueId, body }: ISyncEmployeeVerifyRatesBody, { rejectWithValue }) => {
    try {
      return await IntegrationApi.verifyRates({ timeGoUniqueId, body });
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const syncTimegoRatesDataAsync = createAsyncThunk<
  IIntegrationRes,
  ISyncEmployeeRateBodyWithParam,
  { rejectValue: IErrorRes }
>(
  'qboIntegration/sync',
  async (
    { timeGoUniqueId, toDate, body }: ISyncEmployeeRateBodyWithParam,
    { rejectWithValue },
  ) => {
    try {
      return await IntegrationApi.syncTimegoRatesData({ timeGoUniqueId, toDate, body });
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const syncTimegoPayrollDataAsync = createAsyncThunk<
  IIntegrationRes,
  ISyncEmployeePayrollBodyWithParam,
  { rejectValue: IErrorRes }
>(
  'xerosIntegration/sync',
  async ({ timeGoUniqueId, body }: ISyncEmployeePayrollBodyWithParam, { rejectWithValue }) => {
    try {
      return await IntegrationApi.syncTimegoPayrollData({ timeGoUniqueId, body });
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const updateTimegoRatesDataAsync = createAsyncThunk<
  IIntegrationRes,
  IUpdateEmployeeRateBodyWithParam,
  { rejectValue: IErrorRes }
>(
  'qboIntegration/rateUpdates',
  async ({ timeGoUniqueId, body }: IUpdateEmployeeRateBodyWithParam, { rejectWithValue }) => {
    try {
      return await IntegrationApi.updateTimegoRatesData({ timeGoUniqueId, body });
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);
const getSynclogsAsync = createAsyncThunk<
  SyncLogsRes,
  ISyncLogsParams,
  { rejectValue: IErrorRes }
>('integrations/qboIntegration/logs', async (params: ISyncLogsParams, { rejectWithValue }) => {
  try {
    return await IntegrationApi.getSyncLogs(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

export {
  getIntegrationAsync,
  getConfigurationAsync,
  createConfigurationAsync,
  getEarningRatesAndPayRunCalendersAsync,
  syncEarningRatesOrPayCalendersAsync,
  syncTimegoPayrollDataAsync,
  createIntegrationAsync,
  createXeroIntegrationAsync,
  getAuthURLIntegrationAsync,
  deleteIntegrationAsync,
  deleteXeroIntegrationAsync,
  getTimegoHourssAsync,
  getTimesheetHourssAsync,
  verifyRatesAsync,
  syncTimegoRatesDataAsync,
  updateTimegoRatesDataAsync,
  getSynclogsAsync,
};
