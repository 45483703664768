// Packages
import axios from 'axios';
// Modules
import setupInterceptorsTo from './interceptors';
import { API_URL } from 'api/api.config';

const axiosService = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

setupInterceptorsTo(axiosService);

export default axiosService;
