// Packages
import { FormInstance, RadioChangeEvent } from 'antd';
// Components
import {
  FormItem,
  Card,
  GridRow,
  GridCol,
  Select,
  // Switch,
  RadioGroup,
  Radio,
  // Divider,
} from 'components/lib';
import { ANCHOR_IDS } from '../SideBar';
// Data
import payrollStartOptions from 'helpers/data/signUpData/payrollStart.json';
// 2nd phase
// import overTimePerDayOptions from 'helpers/data/companySettingsData/overTimePerDayOptions';
import overTimePerWeekOptions from 'helpers/data/companySettingsData/overTimePerWeekOptions';
// Helpers
import getInputRules from 'helpers/getRules';
// Interfaces and types
import { IErrorRes } from 'types/appTypes';
// I18n
import { t } from 'i18n';
// Styles
import styles from './payrollAndOvertime.module.scss';

interface IProps {
  hasUserPermissions: boolean;
  errorInfo?: IErrorRes | null;
  form: FormInstance;
}

const PayrollAndOverTime = ({ hasUserPermissions, form, errorInfo }: IProps) => {
  const handleTrackOverTimeFlagChange = (e: RadioChangeEvent) => {
    if (e.target.value) {
      return;
    }

    form.setFieldsValue({
      payroll: {
        overtimeHoursPerWeek: 0,
        //  overtimeHoursPerDay: 0
      },
    });
  };

  return (
    <Card
      id={ANCHOR_IDS.PAYROLL_OVERTIME.slice(1)}
      title={t('settingsModal.payrollOvertime')}
      className="infoCard"
    >
      <GridRow gutter={32}>
        <GridCol span={24}>
          <GridRow justify="end" gutter={32}>
            <GridCol span={24}>
              <FormItem
                required
                name={['payroll', 'startDay']}
                label={t('settingsModal.payrollStart')}
                rules={getInputRules('payrollStartDay')}
                validateStatus={errorInfo?.errors?.['payroll.startDay'] ? 'error' : undefined}
                help={errorInfo?.errors?.['payroll.startDay']}
              >
                <Select
                  disabled={!hasUserPermissions}
                  size="large"
                  placeholder={t('settingsModal.payrollStart')}
                  dataCy="payrollStartDay"
                >
                  {payrollStartOptions.map(({ label, value }) => (
                    <Select.Option key={label} value={value}>
                      {t(`options.${[label]}`)}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
            </GridCol>
          </GridRow>
        </GridCol>
      </GridRow>

      <GridRow justify="start" gutter={32}>
        <GridCol span={12}>
          <FormItem
            name={['payroll', 'trackOvertime']}
            label={t('settingsModal.doYouTrackOvertime')}
            rules={getInputRules('payrollTrackOvertime')}
            validateStatus={errorInfo?.errors?.['payroll.trackOvertime'] ? 'error' : undefined}
            help={errorInfo?.errors?.['payroll.trackOvertime']}
            className={styles.bigLabel}
          >
            <RadioGroup
              disabled={!hasUserPermissions}
              onChange={handleTrackOverTimeFlagChange}
            >
              <Radio value={true}>{t('settingsModal.yes')}</Radio>
              <Radio value={false}>{t('settingsModal.no')}</Radio>
            </RadioGroup>
          </FormItem>
        </GridCol>
      </GridRow>

      <GridRow gutter={32}>
        <GridCol span={24}>
          <GridRow justify="end" gutter={32}>
            <GridCol span={24}>
              <FormItem shouldUpdate className={styles.itemWrap}>
                {() => (
                  <FormItem
                    name={['payroll', 'overtimeHoursPerWeek']}
                    label={t('settingsModal.overtimeHoursPerWeek')}
                    rules={getInputRules('overTimePerWeek')}
                    dependencies={['payroll', 'trackOvertime']}
                    validateStatus={
                      errorInfo?.errors?.['payroll.overtimeHoursPerWeek'] ? 'error' : undefined
                    }
                    help={errorInfo?.errors?.['payroll.overtimeHoursPerWeek']}
                    className={styles.itemWrap}
                  >
                    <Select
                      disabled={!hasUserPermissions}
                      size="large"
                      placeholder={t('settingsModal.overtimeHoursPerWeek')}
                      value={form.getFieldValue(['payroll', 'overtimeHoursPerWeek']) || 0}
                      options={overTimePerWeekOptions}
                    />
                  </FormItem>
                )}
              </FormItem>
            </GridCol>
          </GridRow>
        </GridCol>
        {/* //TODO second phase */}
        {/* DO NOT DELETE! */}
        {/* <GridCol span={12}>
          <GridRow justify="end" gutter={32}>
            <GridCol span={24}>
              <FormItem shouldUpdate className={styles.itemWrap}>
                {() => (
                  <FormItem
                    name={['payroll', 'overtimeHoursPerDay']}
                    label={t('settingsModal.overtimeHoursPerDay')}
                    rules={getInputRules('overTimePerDay')}
                    validateStatus={
                      errorInfo?.errors?.['payroll.overtimeHoursPerDay'] ? 'error' : undefined
                    }
                    help={errorInfo?.errors?.['payroll.overtimeHoursPerDay']}
                    className={styles.itemWrap}
                  >
                    <Select
                      size="large"
                      placeholder={t('settingsModal.overtimeHoursPerDay')}
                      value={form.getFieldValue(['payroll', 'overtimeHoursPerDay']) || 0}
                      options={overTimePerDayOptions}
                    />
                  </FormItem>
                )}
              </FormItem>
            </GridCol>
          </GridRow>
        </GridCol> */}
      </GridRow>

      {/* <GridRow gutter={32}>
        <GridCol span={24}>
          <Divider />
        </GridCol>
      </GridRow>

      <GridRow gutter={32}>
        <GridCol span={12}>
          <GridRow justify="end" gutter={32}>
            <GridCol span={24}>
              <FormItem
                name={['payroll', 'hasOtherRate']}
                label={t('settingsModal.doEnableOtherRate')}
                valuePropName="checked"
                initialValue={false}
                validateStatus={
                  errorInfo?.errors?.['payroll.hasOtherRate'] ? 'error' : undefined
                }
                help={errorInfo?.errors?.['payroll.hasOtherRate']}
                className={styles.switchItem}
              >
                <Switch />
              </FormItem>
            </GridCol>
          </GridRow>
        </GridCol>

        
        <GridCol span={12}>
          <GridRow justify="end" gutter={32}>
            <GridCol span={24}>
              <FormItem
                name={['payroll', 'hasHolidayRate']}
                label={t('settingsModal.doEnableHolidayRate')}
                valuePropName="checked"
                initialValue={true}
                validateStatus={
                  errorInfo?.errors?.['payroll.hasHolidayRate'] ? 'error' : undefined
                }
                help={errorInfo?.errors?.['payroll.hasHolidayRate']}
                className={styles.switchItem}
              >
                <Switch className={styles.switch} />
              </FormItem>
            </GridCol>
          </GridRow>
        </GridCol>
      </GridRow> */}
    </Card>
  );
};

export default PayrollAndOverTime;
