// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { CalendarAPI } from 'api/endpoints';
// Interfaces and types
import { IGetEventsRes } from 'types/calendarTypes';
import { TQueryParams } from 'types/appTypes';
import { IErrorRes } from 'types/appTypes';

const getEventsAsync = createAsyncThunk<
  IGetEventsRes,
  TQueryParams,
  { rejectValue: IErrorRes }
>('calendar/getEvents', async (params: TQueryParams, { rejectWithValue }) => {
  try {
    return await CalendarAPI.getEvents(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

export { getEventsAsync };
