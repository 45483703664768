// Packages
import { History } from 'history';
import queryString, { ParsedQuery, ParseOptions, StringifyOptions } from 'query-string';

// ================ getQueryParams ================
type TGetQueryStr = (query: string, options?: ParseOptions) => ParsedQuery<string>;

const getQueryParams: TGetQueryStr = (
  locationSearch,
  options = { arrayFormat: 'bracket' },
) => {
  return queryString.parse(locationSearch, options);
};

// ================ setQueryString ================
type TSetQueryStr = (object: Record<string, any>, options?: StringifyOptions) => string;

const setQueryString: TSetQueryStr = (locationSearch, options = {}) => {
  return queryString.stringify(locationSearch, options);
};

// ================ getParsedQuery ================
type TGetParsedQuery = (queryParams: { [key: string]: any }) => string;

const getParsedQuery: TGetParsedQuery = queryParams => {
  return Object.keys(queryParams)
    .filter(
      key =>
        (queryParams[key] &&
          (queryParams[key].length === undefined || queryParams[key].length > 0)) ||
        queryParams[key] === 0,
    )
    .map(
      key =>
        `${key}=${
          queryParams[key] instanceof Array
            ? queryParams[key].join(`&${key}=`)
            : queryParams[key]
        }`,
    )
    .join('&');
};

// ================ updatePageUrl ================
type TUpdateUrl = <QP>(
  prevQParams: ParsedQuery<string>,
  newQParams: QP,
  history: History,
) => void;

const updatePageUrl: TUpdateUrl = (previousQueryParams, newQueryParams, history) => {
  const queryParams = {
    ...previousQueryParams,
    ...newQueryParams,
  };

  const query = getParsedQuery(queryParams);

  history.push(`?${query}`);
};

const replacePageUrl: TUpdateUrl = (previousQueryParams, newQueryParams, history) => {
  const queryParams = {
    ...previousQueryParams,
    ...newQueryParams,
  };

  const query = getParsedQuery(queryParams);

  history.replace(`?${query}`);
};

export { getQueryParams, setQueryString, updatePageUrl, replacePageUrl };
