// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import {
  getPayrollAdjustmentAsync,
  savePayrollAdjustmentAsync,
} from './payrollAdjustmentThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import { IErrorRes } from 'types/appTypes';
import { IGetPayrollAdjustmentsRes } from 'types/payrollAdjustmentTypes';

interface ISlicePaymentsState {
  isTableTouch: boolean;
  payrollAdjustments: IGetPayrollAdjustmentsRes | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: ISlicePaymentsState = {
  isTableTouch: false,
  payrollAdjustments: null,
  error: null,
  isLoading: false,
};

const payrollAdjustmentsSlice = createSlice({
  name: 'payrollAdjustments',
  initialState,
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
    changeTableData: (state, { payload }) => {
      state.payrollAdjustments = payload;
    },
    changeIsTouchFlag: (state, { payload }) => {
      state.isTableTouch = payload;
    },
  },
  extraReducers: builder => {
    //* GET PAYROLL ADJUSTMENTS ASYNC THUNK
    builder.addCase(getPayrollAdjustmentAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getPayrollAdjustmentAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.payrollAdjustments = payload;
    });
    builder.addCase(getPayrollAdjustmentAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* SAVE PAYROLL ADJUSTMENTS ASYNC THUNK
    builder.addCase(savePayrollAdjustmentAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(savePayrollAdjustmentAsync.fulfilled, (state, { payload }) => {
      state.payrollAdjustments = payload;
      state.isLoading = false;
    });
    builder.addCase(savePayrollAdjustmentAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export default payrollAdjustmentsSlice;

export const { changeTableData, changeIsTouchFlag } = payrollAdjustmentsSlice.actions;
