// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { RatesAPI } from 'api/endpoints';
// Interfaces and types
import {
  ICreateRatesBody,
  ICreateRatesRes,
  IDeleteRatesRes,
  IGetEmployeeRatesDetailsRes,
  IGetEmployeeRatesRes,
  IGetEmployeeRatesBody,
  IGetRatesRes,
  IRatesParams,
  IUpdateRatesParams,
  IUpdateRatesRes,
} from 'types/rateTypes';
import { IErrorRes, TQueryParams } from 'types/appTypes';

const getRatesAsync = createAsyncThunk<IGetRatesRes, TQueryParams, { rejectValue: IErrorRes }>(
  'rates/getRates',
  async (params: TQueryParams, { rejectWithValue }) => {
    try {
      return await RatesAPI.getRates(params);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const createRatesAsync = createAsyncThunk<
  ICreateRatesRes,
  ICreateRatesBody,
  { rejectValue: IErrorRes }
>('rates/createRates', async (cred: ICreateRatesBody, { rejectWithValue }) => {
  try {
    return await RatesAPI.createRates(cred);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getEmployeeRatesAsync = createAsyncThunk<
  IGetEmployeeRatesRes,
  IGetEmployeeRatesBody,
  { rejectValue: IErrorRes }
>('rates/getEmployeeRates', async (cred: IGetEmployeeRatesBody, { rejectWithValue }) => {
  try {
    return await RatesAPI.getEmployeeRates(cred);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getEmployeeRatesDetailsAsync = createAsyncThunk<
  IGetEmployeeRatesDetailsRes,
  IRatesParams,
  { rejectValue: IErrorRes }
>('rates/getEmployeeRatesDetails', async (params: IRatesParams, { rejectWithValue }) => {
  try {
    return await RatesAPI.getEmployeeRatesDetails(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const updateRatesAsync = createAsyncThunk<
  IUpdateRatesRes,
  IUpdateRatesParams,
  { rejectValue: IErrorRes }
>('rates/updateRates', async (cred: IUpdateRatesParams, { rejectWithValue }) => {
  try {
    return await RatesAPI.updateRates(cred);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const deleteRatesAsync = createAsyncThunk<
  IDeleteRatesRes,
  IRatesParams,
  { rejectValue: IErrorRes }
>('rates/deleteRates', async (params: IRatesParams, { rejectWithValue }) => {
  try {
    return await RatesAPI.deleteRates(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

export {
  getRatesAsync,
  createRatesAsync,
  getEmployeeRatesAsync,
  getEmployeeRatesDetailsAsync,
  updateRatesAsync,
  deleteRatesAsync,
};
