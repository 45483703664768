// Core
import { useCallback } from 'react';
// Packages
import { generatePath } from 'react-router-dom';
// Redux
import { STORE_KEYS, useAppSelector } from 'redux/store';

const useCompanyPath = () => {
  const { accountName } = useAppSelector(STORE_KEYS.AUTH);

  const getUpdatedPath = useCallback(
    (path: string, params?: { [key: string]: string }) => {
      return params
        ? generatePath(path, params)
        : `/${accountName}/${path.split('/').slice(2).join('/')}`;
    },
    [accountName],
  );

  const getPathForReplace = useCallback((currentPathname: string, accName: string) => {
    return `/${accName}/${currentPathname.split('/').slice(2).join('/')}`;
  }, []);

  return { getUpdatedPath, getPathForReplace };
};

export default useCompanyPath;
