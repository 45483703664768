// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import { getInvoiceAgingAsync } from './invoiceAgingThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import { IGetInvoiceAgingRes } from 'types/invoiceAgingTypes';
import { IErrorRes } from 'types/appTypes';

interface ISliceInvoiceAgingState {
  invoiceAging: IGetInvoiceAgingRes | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: ISliceInvoiceAgingState = {
  invoiceAging: null,
  error: null,
  isLoading: false,
};

const invoiceAgingSlice = createSlice({
  name: 'invoiceAging',
  initialState,
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    //* GET INVOICE AGING ASYNC THUNK
    builder.addCase(getInvoiceAgingAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getInvoiceAgingAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.invoiceAging = payload;
    });
    builder.addCase(getInvoiceAgingAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export default invoiceAgingSlice;
