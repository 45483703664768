// Core
import { useEffect, useState } from 'react';
// Packages
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
// Components
import SideBar from './SideBar';
import MainSettings from './MainSettings';
import PayrollAndOvertime from './PayrollAndOverTime';
import SwitchAccount from './SwitchAccount';
import { ActionModal, Loader } from 'components';
import { Form, GridRow, GridCol } from 'components/lib';
// Assets
import { MiniLogo } from 'assets/icons/logo';
// Redux
import { changeAccountName, changeLogoImage } from 'redux/auth/authReducer';
import { STORE_KEYS, useAppDispatch, useAppSelector } from 'redux/store';
import { getCurrentUserAsync } from 'redux/user/userThunk';
import { setCanSetStartEndScheduleDates } from 'redux/schedule/scheduleReducer';
// CustomHooks
import { useFetch, useCompanyPath, useUserPermissions } from 'customHooks';
// Modules
import webStorage from 'modules/storage';
// Routes
import { ROUTES } from 'router/routes';
// API
import { CompaniesAPI } from 'api/endpoints';
// Helpers
import showNotification from 'helpers/showNotification';
import { replacePageUrl, getQueryParams } from 'helpers/queryString';
import { PERMISSION_KEYS } from 'helpers/data/constants';
// Types and interfaces
import {
  IGetSettingsRes,
  IUpdateSettingsBody,
  IUpdateSettingsRes,
  ISettingsFormUpdate,
} from 'types/companyTypes';
// I18n
import { t } from 'i18n';

interface IProps {
  isModalVisible: boolean;
  onCloseModal: () => void;
}

const SettingsModal = ({ isModalVisible, onCloseModal }: IProps) => {
  const history = useHistory();
  const { pathname, search } = useLocation();

  const { getPathForReplace } = useCompanyPath();

  const [form] = Form.useForm();

  const { user } = useAppSelector(STORE_KEYS.USER);
  const dispatch = useAppDispatch();

  const [accountName, setAccountName] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  // Page should be reloaded after change startDay of week
  const [shouldPageBeReloaded, setShouldPageBeReloaded] = useState(false);
  // Get company settings
  const [
    { response: responseGetCompanySettings },
    doFetchGetCompanySettings,
    doResetGetCompanySettings,
  ] = useFetch<IGetSettingsRes, void, typeof CompaniesAPI.getCompanySettings>(
    CompaniesAPI.getCompanySettings,
  );

  useEffect(() => {
    if (!isModalVisible) {
      return;
    }

    doFetchGetCompanySettings();

    return () => {
      doResetGetCompanySettings();
    };
  }, [isModalVisible, doFetchGetCompanySettings, doResetGetCompanySettings]);

  useEffect(() => {
    if (!responseGetCompanySettings) {
      return form.setFieldsValue({
        main: {
          twoFactorMethod: '',
        },
      });
    }

    dispatch(changeLogoImage(responseGetCompanySettings?.main?.logoImage));

    form.setFieldsValue({
      ...responseGetCompanySettings,
      main: {
        ...responseGetCompanySettings.main,
        emailNotificationsRecipients: responseGetCompanySettings?.main
          ?.emailNotificationsRecipients
          ? responseGetCompanySettings?.main?.emailNotificationsRecipients?.join(',')
          : null,
        twoFactorMethod: responseGetCompanySettings?.main?.twoFactorMethod || '',
      },
      payroll: {
        ...responseGetCompanySettings.payroll,
        startDay: Number(responseGetCompanySettings.payroll.startDay),
      },
    });

    const {
      trackOvertime,
      // overtimeHoursPerDay, //2nd phase
      overtimeHoursPerWeek,
    } = responseGetCompanySettings?.payroll;

    if (
      !trackOvertime &&
      !overtimeHoursPerWeek
      // && !overtimeHoursPerDay
    ) {
      form.setFieldsValue({
        payroll: {
          // overtimeHoursPerDay: 0,
          overtimeHoursPerWeek: 0,
        },
      });
    }
  }, [responseGetCompanySettings, form, dispatch]);

  // Change companies settings
  const [
    { response: responseChangeCompanySettings, error: errorChangeCompanySettings },
    doFetchChangeCompanySettings,
    doResetChangeCompanySettings,
  ] = useFetch<
    IUpdateSettingsRes,
    IUpdateSettingsBody,
    typeof CompaniesAPI.changeCompanySettings
  >(CompaniesAPI.changeCompanySettings);

  const handleSubmitSettings = async (values: ISettingsFormUpdate) => {
    shouldPageBeReloaded && setShouldPageBeReloaded(false);
    setAccountName(values.main.accountName);

    const body = {
      ...values,
      main: {
        ...values.main,
        emailNotificationsRecipients: values?.main?.emailNotificationsRecipients
          ? values?.main?.emailNotificationsRecipients?.split(',')
          : null,
        twoFactorMethod: values?.main?.twoFactorMethod || null,
      },
      payroll: {
        ...values.payroll,
        startDay: String(values.payroll.startDay),
      },
    } as IUpdateSettingsBody;

    delete body.main.logoImage;

    if (Number(values.payroll.startDay) !== user?.payroll?.startDay) {
      setShouldPageBeReloaded(true);
    }

    doFetchChangeCompanySettings(body as IUpdateSettingsBody);

    moment.updateLocale('en', { week: { dow: Number(body.payroll.startDay) } });
  };

  useEffect(() => {
    if (!responseChangeCompanySettings) {
      return;
    }

    // Change AUTH DATA and info in LOCAL STORAGE
    dispatch(changeAccountName(accountName));
    dispatch(getCurrentUserAsync());

    const authData = webStorage.getData();
    const updateAuthData = { ...authData, accountName };

    webStorage.setData(updateAuthData);

    showNotification('success', t('notification.saved'));
    onCloseModal();

    // Change Company name in URL
    const updatedPathname = getPathForReplace(ROUTES.SCHEDULE, accountName);
    history.replace(updatedPathname);

    !shouldPageBeReloaded && doResetChangeCompanySettings();

    if (shouldPageBeReloaded) {
      // we need it for correct updating new Start day of week
      const prevQueryParams = getQueryParams(search, {});
      delete prevQueryParams.dateFrom;
      delete prevQueryParams.dateTo;
      sessionStorage.removeItem('shiftsPeriod');
      dispatch(setCanSetStartEndScheduleDates(false));
      replacePageUrl(prevQueryParams, prevQueryParams, history);
      window.location.reload();
    }

    return () => {
      doResetChangeCompanySettings();
    };
  }, [
    shouldPageBeReloaded,
    history,
    pathname,
    accountName,
    responseChangeCompanySettings,
    dispatch,
    onCloseModal,
    getPathForReplace,
    doResetChangeCompanySettings,
    search,
  ]);

  const hasActionPermissions = useUserPermissions(PERMISSION_KEYS.SETTINGS);

  if (isLoading) {
    return <Loader isLoading={isLoading} />;
  }

  return (
    <ActionModal
      destroyOnClose
      footer={null}
      title={
        <>
          <MiniLogo />
          {t('settingsModal.title')}
        </>
      }
      visible={isModalVisible}
      onCancel={onCloseModal}
    >
      <Form
        form={form}
        scrollToFirstError
        name="settingsForm"
        layout="vertical"
        autoComplete="off"
        onFinish={handleSubmitSettings}
      >
        <GridRow gutter={30}>
          <GridCol span={5}>
            <SideBar hasActionPermissions={hasActionPermissions} onCancel={onCloseModal} />
          </GridCol>

          <GridCol span={19}>
            <div id="scroll-layout" className="modalScrollLayout">
              <MainSettings
                hasUserPermissions={hasActionPermissions}
                errorInfo={errorChangeCompanySettings}
                logoImage={responseGetCompanySettings?.main?.logoImage}
                role={user?.role}
              />

              <PayrollAndOvertime
                hasUserPermissions={hasActionPermissions}
                form={form}
                errorInfo={errorChangeCompanySettings}
              />

              <SwitchAccount
                isVerified={!!user?.emailVerified}
                id={responseGetCompanySettings?.main?.id}
                onCloseModal={onCloseModal}
                onSetLoading={setIsLoading}
              />
            </div>
          </GridCol>
        </GridRow>
      </Form>
    </ActionModal>
  );
};

export default SettingsModal;
