// Core
import { ReactNode, CSSProperties } from 'react';
// Packages
import { Checkbox as AntCheckbox } from 'antd';

export type TCheckboxValueType = string | number | boolean;

type TOption = {
  label: ReactNode;
  value: TCheckboxValueType;
  style?: CSSProperties;
  disabled?: boolean;
};

interface ICheckboxProps {
  defaultValue?: TCheckboxValueType[];
  dataCy?: string;
  disabled?: boolean;
  children?: ReactNode;
  name?: string;
  options?: string[] | TOption[];
  value?: TCheckboxValueType[];
  onChange?: (checkedValue: TCheckboxValueType[]) => void;
  className?: string;
  style?: CSSProperties;
}

/**
 * Checkbox Group component.
 *
 * @description Used for selecting multiple values from several options.
 * If you use only one checkbox, it is the same as using Switch to toggle
 * between two states. The difference is that Switch will trigger the state
 * change directly, but Checkbox just marks the state as changed and this
 * needs to be submitted.
 *
 * @param defaultValue - Default selected value
 * @param dataCy - prop for testing purpose
 * @param disabled - If disable all checkboxes
 * @param name - The name property of all input[type="checkbox"] children
 * @param options - Specifies options
 * @param value - Used for setting the currently selected value
 * @param onChange - The callback function that is triggered when the state changes
 * @param className - Component className
 * @param style - To customize the styles
 */

const CheckboxGroup = ({
  defaultValue = [],
  dataCy,
  disabled = false,
  children,
  name,
  options = [],
  value,
  onChange,
  className,
  style,
}: ICheckboxProps) => (
  <AntCheckbox.Group
    data-cy={dataCy}
    defaultValue={defaultValue}
    disabled={disabled}
    name={name}
    options={options}
    value={value}
    onChange={onChange}
    className={className}
    style={style}
  >
    {children}
  </AntCheckbox.Group>
);

export default CheckboxGroup;
