// Core
import { useState, useCallback } from 'react';
// Packages
import { CgMenuGridO } from 'react-icons/cg';
import { useLocation } from 'react-router-dom';
// Components
import { Loader, NavigationMemory } from 'components';
import { MobileMenu, AuthSideBarDesktop } from './components';
import { MainHeader, GridRow, GridCol, Text, Button, Avatar, Modal } from 'components/lib';
import SubMenu from './components/AuthMenu/SubMenu';
import SettingsModal from './components/SettingsModal';
// Custom Hooks
import { useBreakpoint } from 'customHooks';
// Routes
import { ROUTES } from 'router/routes';
// Assets
import FullLogo from 'assets/icons/logo/FullLogo';
// Redux
import { STORE_KEYS, useAppDispatch, useAppSelector } from 'redux/store';
import { resendVerificationEmailAsync } from 'redux/user/userThunk';
// Helpers
import getFullNameInitials from 'helpers/getFullNameInitials';
import { ROLES } from 'helpers/data/constants';
import showNotification from 'helpers/showNotification';
// I18n
import { t } from 'i18n';
// Styles
import styles from './header.module.scss';

const Header = () => {
  const { pathname } = useLocation();
  const { lg } = useBreakpoint();
  const { user, isLoading } = useAppSelector(STORE_KEYS.USER);
  const { navHistory, isLoading: isLoadingNav } = useAppSelector(STORE_KEYS.NAV);
  const isEmailVerifyButtonShown =
    user?.role === ROLES?.business_admin && !user?.emailVerified;

  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isSettingModalVisible, setIsSettingsModalVisible] = useState(false);

  const dispatch = useAppDispatch();

  const handleToggleSettingsModal = useCallback(
    () => setIsSettingsModalVisible(prev => !prev),
    [],
  );

  const handleToggleMenu = () => setIsMenuVisible(prevState => !prevState);

  const resendVerificationEmailToken = () => {
    Modal.confirm({
      okType: 'default',
      title: t('header.resendVerificationEmail'),
      okText: t('header.resendLetter'),
      cancelText: t('header.cancel'),
      async onOk() {
        const resultAction = await dispatch(resendVerificationEmailAsync());

        if (resendVerificationEmailAsync.fulfilled.match(resultAction)) {
          showNotification('success', t('header.resendSuccessfully'));
        }
      },
    });
  };

  const userInitials = getFullNameInitials(user);
  let headerAccountInfoText = user?.accountSubscriptionStatus;
  const isBusinessCompany = user?.role === ROLES.business_company;
  const headerTextAccountInfoClassName = !isBusinessCompany ? styles.status : '';

  const getEqualPath = (path: string) => {
    return path.split('/').slice(2).join('/');
  };
  const isPaymentConfirmedPage =
    getEqualPath(ROUTES.PAYMENT_CONFIRMED) === getEqualPath(pathname);

  if (isBusinessCompany) {
    headerAccountInfoText = user?.username;
  }

  if (isLoading) {
    return <Loader isLoading={isLoading} />;
  }

  return (
    <MainHeader className={styles.header}>
      <GridRow align="middle" className={styles.headerWrap} justify="space-between">
        <GridCol className={styles.leftSideMenuContainer}>
          {lg && !isPaymentConfirmedPage && (
            <Button
              icon={<CgMenuGridO size={24} />}
              onClick={handleToggleMenu}
              className={styles.menuBtn}
            />
          )}
          <div className={styles.logoWrap}>
            <Text className={styles.logo}>
              <FullLogo />
            </Text>
          </div>
        </GridCol>

        {lg && (
          <>
            <GridCol
              lg={{ span: !user?.emailVerified ? 9 : 11 }}
              xl={{ span: !user?.emailVerified ? 10 : 13 }}
            >
              <NavigationMemory isLoadingNav={isLoadingNav} pages={navHistory} />
            </GridCol>

            <GridCol className={styles.rightSideMenu}>
              <GridCol>
                {isEmailVerifyButtonShown && (
                  <Button type="link" onClick={resendVerificationEmailToken}>
                    {t('header.verifyEmail')}
                  </Button>
                )}
              </GridCol>

              <GridCol>
                <a href="https://help.timego.com" className={styles.helpLink}>
                  {t('mainNav.helpPage')}
                </a>
              </GridCol>

              <div className={styles.userInfoWrap}>
                <Avatar className={styles.avatar}>{userInitials}</Avatar>

                <div className={styles.fullNameAndRole}>
                  <Text fontSize={'middle'}>
                    {user?.firstName} {user?.lastName}
                  </Text>
                  <Text fontSize={'middle'} className={headerTextAccountInfoClassName}>
                    {headerAccountInfoText}
                  </Text>
                </div>
              </div>

              {!isPaymentConfirmedPage && (
                <SubMenu onSetIsSettingsModalVisible={handleToggleSettingsModal} />
              )}
            </GridCol>
          </>
        )}

        {lg ? (
          <AuthSideBarDesktop
            isMenuVisible={isMenuVisible}
            setIsMenuVisible={setIsMenuVisible}
          />
        ) : (
          !isPaymentConfirmedPage && <MobileMenu />
        )}
      </GridRow>

      <SettingsModal
        isModalVisible={isSettingModalVisible}
        onCloseModal={handleToggleSettingsModal}
      />
    </MainHeader>
  );
};

export default Header;
