// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import {
  getJoGroupsAsync,
  createJobGroupAsync,
  getJobGroupDetailsAsync,
  updateJobGroupAsync,
  deleteJobGroupAsync,
} from './jobGroupsThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import { IGetJobGroupsRes, IGetJobGroupDetailsRes } from 'types/jobGroupTypes';
import { IErrorRes } from 'types/appTypes';

interface ISliceJobsRateState {
  jobGroups: IGetJobGroupsRes | null;
  jobGroupDetails: IGetJobGroupDetailsRes | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: ISliceJobsRateState = {
  jobGroups: null,
  jobGroupDetails: null,
  error: null,
  isLoading: false,
};

const jobRatesSlice = createSlice({
  name: 'jobGroups',
  initialState,
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    //* GET JOB_GROUPS ASYNC THUNK
    builder.addCase(getJoGroupsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getJoGroupsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.jobGroups = payload;
    });
    builder.addCase(getJoGroupsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CREATE JOB_GROUPS ASYNC THUNK
    builder.addCase(createJobGroupAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(createJobGroupAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(createJobGroupAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    // //* GET JOB_RATE_GROUP ASYNC THUNK
    builder.addCase(getJobGroupDetailsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getJobGroupDetailsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.jobGroupDetails = payload;
    });
    builder.addCase(getJobGroupDetailsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* UPDATE JOB_GROUP (one) ASYNC THUNK
    builder.addCase(updateJobGroupAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(updateJobGroupAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(updateJobGroupAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* DELETE JOB_GROUP ASYNC THUNK
    builder.addCase(deleteJobGroupAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(deleteJobGroupAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteJobGroupAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export default jobRatesSlice;
