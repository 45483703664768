// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Interfaces and types
import {
  IGetJobRatesByJobIdParams,
  IGetJobRatesByJobIdRes,
  IGetJobRateDetailsParams,
  IGetJobRateDetailsRes,
  IUpdateJobRateBodyWithParams,
  IUpdateJobRateRes,
  IDeleteJobRateParams,
  IDeleteJobRateRes,
  IGetJobRatesRes,
  ICreateJobRateBodyWithParam,
  ICreateJobRateRes,
} from 'types/jobRatesTypes';
import { TQueryParams } from 'types/appTypes';

export const getJobRates = (params: TQueryParams) => {
  return xhr.get<IGetJobRatesRes, IGetJobRatesRes>(`${ENDPOINTS.jobRatesAll}`, {
    params,
  });
};

export const getJobRatesByJobId = ({ jobId, params }: IGetJobRatesByJobIdParams) => {
  return xhr.get<IGetJobRatesByJobIdRes, IGetJobRatesByJobIdRes>(
    `${ENDPOINTS.jobs}/${jobId}${ENDPOINTS.jobRates}`,
    { params },
  );
};

export const createJobRate = ({ jobId, body }: ICreateJobRateBodyWithParam) => {
  return xhr.post<ICreateJobRateRes, ICreateJobRateRes>(
    `${ENDPOINTS.jobs}/${jobId}${ENDPOINTS.jobRates}`,
    body,
  );
};

export const getJobRateDetails = ({ jobId, id }: IGetJobRateDetailsParams) => {
  return xhr.get<IGetJobRateDetailsRes, IGetJobRateDetailsRes>(
    `${ENDPOINTS.jobs}/${jobId}${ENDPOINTS.jobRates}/${id}`,
  );
};

export const updateJobRate = ({ jobId, id, body }: IUpdateJobRateBodyWithParams) => {
  return xhr.put<IUpdateJobRateRes, IUpdateJobRateRes>(
    `${ENDPOINTS.jobs}/${jobId}${ENDPOINTS.jobRates}/${id}`,
    body,
  );
};

export const deleteJobRate = ({ jobId, id }: IDeleteJobRateParams) => {
  return xhr.delete<IDeleteJobRateRes, IDeleteJobRateRes>(
    `${ENDPOINTS.jobs}/${jobId}${ENDPOINTS.jobRates}/${id}`,
  );
};
