// Packages
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// Redux
import {
  getPayrollPeriodsAsync,
  createPayrollPeriodAsync,
  getPayrollPeriodDetailsAsync,
  updatePayrollPeriodAsync,
  deletePayrollPeriodAsync,
} from './payrollPeriodsThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import { IGetPayrollPeriods, IGetPayrollPeriodDetailsRes } from 'types/payrollTypes';
import { IErrorRes } from 'types/appTypes';

interface ISlicePayrollsState {
  payrollPeriods: IGetPayrollPeriods | null;
  payrollDetails: IGetPayrollPeriodDetailsRes | null;
  error: IErrorRes | null;
  isLoading: boolean;
  shouldOptionsPayrollScheduleFilterBeUpdated: boolean;
}

const initialState: ISlicePayrollsState = {
  payrollPeriods: null,
  payrollDetails: null,
  error: null,
  isLoading: false,
  shouldOptionsPayrollScheduleFilterBeUpdated: true,
};

const payrollPeriodsSlice = createSlice({
  name: 'payrollPeriods',
  initialState,
  reducers: {
    changeStateFlagShouldOptionsPayrollScheduleFilterBeUpdated: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.shouldOptionsPayrollScheduleFilterBeUpdated = payload;
    },
    clearErrors: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    //* GET PAYROLL PERIODS ASYNC THUNK
    builder.addCase(getPayrollPeriodsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getPayrollPeriodsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.payrollPeriods = payload;
    });
    builder.addCase(getPayrollPeriodsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    // //* GET PAYROLL PERIOD DETAILS BY ID ASYNC THUNK
    builder.addCase(getPayrollPeriodDetailsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getPayrollPeriodDetailsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.payrollDetails = payload;
    });
    builder.addCase(getPayrollPeriodDetailsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CREATE PAYROLL PERIOD ASYNC THUNK
    builder.addCase(createPayrollPeriodAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(createPayrollPeriodAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(createPayrollPeriodAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* UPDATE PAYROLL PERIOD (one) ASYNC THUNK
    builder.addCase(updatePayrollPeriodAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(updatePayrollPeriodAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(updatePayrollPeriodAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //DELETE PAYROLL PERIOD ASYNC THUNK
    builder.addCase(deletePayrollPeriodAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(deletePayrollPeriodAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(deletePayrollPeriodAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export default payrollPeriodsSlice;

export const { changeStateFlagShouldOptionsPayrollScheduleFilterBeUpdated } =
  payrollPeriodsSlice.actions;
