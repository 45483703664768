// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { LocationsAPI } from 'api/endpoints';
// Interfaces and types
import {
  IGetLocationsRes,
  ICreateLocationBodyWithParam,
  ICreateLocationRes,
  IGetLocationParams,
  IGetLocationRes,
  IUpdateLocationBodyWithParams,
  IUpdateLocationRes,
  IDeleteLocationParams,
  IDeleteLocationRes,
  IGetLocationsByAccountNameParams,
} from 'types/locationTypes';
import { TQueryParams } from 'types/appTypes';
import { IErrorRes } from 'types/appTypes';

const getLocationsAsync = createAsyncThunk<
  IGetLocationsRes,
  TQueryParams,
  { rejectValue: IErrorRes }
>('locations/getLocations', async (params: TQueryParams, { rejectWithValue }) => {
  try {
    return await LocationsAPI.getLocations(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const createLocationAsync = createAsyncThunk<
  ICreateLocationRes,
  ICreateLocationBodyWithParam,
  { rejectValue: IErrorRes }
>(
  'locations/createLocation',
  async (cred: ICreateLocationBodyWithParam, { rejectWithValue }) => {
    try {
      return await LocationsAPI.createLocation(cred);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const getLocationDetailsAsync = createAsyncThunk<
  IGetLocationRes,
  IGetLocationParams,
  { rejectValue: IErrorRes }
>('locations/getLocation', async (cred: IGetLocationParams, { rejectWithValue }) => {
  try {
    return await LocationsAPI.getLocation(cred);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const updateLocationAsync = createAsyncThunk<
  IUpdateLocationRes,
  IUpdateLocationBodyWithParams,
  { rejectValue: IErrorRes }
>(
  'locations/updateLocation',
  async (cred: IUpdateLocationBodyWithParams, { rejectWithValue }) => {
    try {
      return await LocationsAPI.updateLocation(cred);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const deleteLocationAsync = createAsyncThunk<
  IDeleteLocationRes,
  IDeleteLocationParams,
  { rejectValue: IErrorRes }
>('location/deleteLocation', async (params: IDeleteLocationParams, { rejectWithValue }) => {
  try {
    return await LocationsAPI.deleteLocation(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getLocationsByAccountNameAsync = createAsyncThunk<
  IGetLocationsRes,
  IGetLocationsByAccountNameParams,
  { rejectValue: IErrorRes }
>(
  'locations/getLocationsByAccountName',
  async (params: IGetLocationsByAccountNameParams, { rejectWithValue }) => {
    try {
      return await LocationsAPI.getLocationsByAccountName(params);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

export {
  getLocationsAsync,
  createLocationAsync,
  updateLocationAsync,
  getLocationDetailsAsync,
  deleteLocationAsync,
  getLocationsByAccountNameAsync,
};
