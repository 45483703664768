// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { InvoiceAgingApi } from 'api/endpoints';
// Interfaces and types
import { IGetInvoiceAgingRes } from 'types/invoiceAgingTypes';
import { TQueryParams, IErrorRes } from 'types/appTypes';

const getInvoiceAgingAsync = createAsyncThunk<
  IGetInvoiceAgingRes,
  TQueryParams,
  { rejectValue: IErrorRes }
>('invoiceAging/getInvoiceAging', async (params: TQueryParams, { rejectWithValue }) => {
  try {
    return await InvoiceAgingApi.getInvoiceAging(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

export { getInvoiceAgingAsync };
