// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Interfaces and types
import {
  IGetEmployeesParams,
  IGetEmployeesRes,
  IGetGpsParams,
  IGetGpsRes,
} from 'types/gpsTypes';

export const getJobShiftDates = (id: number) => {
  return xhr.get<string[], string[]>(`${ENDPOINTS.jobs}/${id}/shifts-dates`);
};

export const getJobEmployees = ({ id, date }: IGetEmployeesParams) => {
  return xhr.get<IGetEmployeesRes, IGetEmployeesRes>(
    `${ENDPOINTS.jobs}/${id}/shifts-dates/${date}/employees`,
  );
};

export const getGps = ({ userId, shiftId, params }: IGetGpsParams) => {
  return xhr.get<IGetGpsRes, IGetGpsRes>(
    `${ENDPOINTS.employees}/${userId}${ENDPOINTS.schedule}/${shiftId}/gps-tracks`,
    { params },
  );
};
