// Core
import { CSSProperties, ReactNode, Key } from 'react';
// Packages
import { Form as AntForm, TooltipProps } from 'antd';
import { Rule, RuleObject } from 'antd/lib/form';
import { NamePath, Store, StoreValue } from 'antd/lib/form/interface';
import { ShouldUpdate } from 'rc-field-form/lib/Field.d';
import cx from 'classnames';
// Styles
import styles from './formItem.module.scss';

export type TRuleArray = Rule[];
export type TRuleObject = RuleObject;

interface IFormProps {
  children?: ReactNode;
  className?: string;
  colon?: boolean;
  dataCy?: string;
  dependencies?: NamePath[];
  extra?: ReactNode;
  fieldKey?: Key | Key[];
  getValueFromEvent?: (...args: any[]) => any;
  getValueProps?: (value: any) => any;
  hasFeedback?: boolean;
  help?: ReactNode;
  hidden?: boolean;
  htmlFor?: string;
  id?: string;
  initialValue?: any;
  label?: ReactNode;
  labelAlign?: 'left' | 'right';
  labelCol?: object;
  messageVariables?: Record<string, string>;
  name?: NamePath;
  normalize?: (value: StoreValue, prevValue: StoreValue, allValues: Store) => StoreValue;
  noStyle?: boolean;
  preserve?: boolean;
  required?: boolean;
  rules?: Rule[];
  shouldUpdate?: ShouldUpdate;
  style?: CSSProperties;
  tooltip?: ReactNode | (TooltipProps & { icon: ReactNode });
  trigger?: string;
  validateFirst?: boolean | 'parallel';
  validateStatus?: 'success' | 'warning' | 'error' | 'validating';
  validateTrigger?: string | string[];
  valuePropName?: string;
  wrapperCol?: object;
}

/**
 * Form field component for data bidirectional binding, validation, layout, and so on.
 *
 * @description When you need to create an instance or collect information. When you need to validate fields in certain rules
 *
 * @param children - Some ReactNode
 * @param className - The picker className
 * @param colon - Used with label, whether to display : after label text.
 * @param dependencies - Set the dependency field. See below
 * @param dataCy = need for testing
 * @param extra - The extra prompt message. It is similar to help. Usage example: to display error message and prompt message at the same time
 * @param getValueFromEvent - Specify how to get value from event or other onChange arguments
 * @param getValueProps - Additional props with sub component
 * @param hasFeedback - Used with validateStatus, this option specifies the validation status icon. Recommended to be used only with Input
 * @param help - The prompt message. If not provided, the prompt message will be generated by the validation rule.
 * @param hidden - Whether to hide Form.Item (still collect and validate value)
 * @param htmlFor - Set sub label htmlFor
 * @param initialValue - Config sub default value. Form initialValues get higher priority when conflict
 * @param label - Label text
 * @param labelAlign - The text align of label
 * @param labelCol - The layout of label. You can set span offset to something like {span: 3, offset: 12} or sm: {span: 3, offset: 12} same as with <Col>. You can set labelCol on Form which will not affect nest Item. If both exists, use Item first
 * @param messageVariables - The default validate field info
 * @param name - Field name, support array
 * @param normalize - Normalize value from component value before passing to Form instance. Do not support async
 * @param noStyle - No style for true, used as a pure field control
 * @param preserve - Keep field value even when field removed
 * @param required - Display required style. It will be generated by the validation rule
 * @param rules - Rules for field validation. Click here to see an example
 * @param shouldUpdate - Custom field update logic. See below
 * @param style - To customize the style of the input box
 * @param tooltip - Config tooltip info
 * @param trigger - When to collect the value of children node. Click here to see an example
 * @param validateFirst - Whether stop validate on first rule of error for this field. Will parallel validate when parallel cofigured
 * @param validateStatus - The validation status. If not provided, it will be generated by validation rule. options: success warning error validating
 * @param validateTrigger - When to validate the value of children node
 * @param valuePropName - Props of children node, for example, the prop of Switch is 'checked'. This prop is an encapsulation of getValueProps, which will be invalid after customizing getValueProps
 * @param wrapperCol - The layout for input controls, same as labelCol. You can set wrapperCol on Form which will not affect nest Item. If both exists, use Item first
 */
const FormItem = ({
  children,
  className,
  colon = true,
  dataCy,
  dependencies,
  extra,
  fieldKey,
  getValueFromEvent,
  getValueProps,
  hasFeedback = false,
  help,
  hidden = false,
  htmlFor,
  initialValue,
  id,
  label,
  labelAlign = 'right',
  labelCol,
  messageVariables,
  name,
  normalize,
  noStyle = false,
  preserve = true,
  required = false,
  rules,
  shouldUpdate = false,
  style,
  tooltip,
  trigger,
  validateFirst = false,
  validateStatus,
  validateTrigger,
  valuePropName,
  wrapperCol,
}: IFormProps) => (
  <AntForm.Item
    className={cx(styles.formItem, styles.formItemLabel, className)}
    colon={colon}
    data-cy={dataCy}
    dependencies={dependencies}
    extra={extra}
    fieldKey={fieldKey}
    getValueFromEvent={getValueFromEvent}
    getValueProps={getValueProps}
    hasFeedback={hasFeedback}
    help={help}
    hidden={hidden}
    htmlFor={htmlFor}
    initialValue={initialValue}
    id={id}
    label={label}
    labelAlign={labelAlign}
    labelCol={labelCol}
    messageVariables={messageVariables}
    name={name}
    normalize={normalize}
    noStyle={noStyle}
    preserve={preserve}
    required={required}
    rules={rules}
    shouldUpdate={shouldUpdate}
    style={style}
    tooltip={tooltip}
    trigger={trigger}
    validateFirst={validateFirst}
    validateStatus={validateStatus}
    validateTrigger={validateTrigger}
    valuePropName={valuePropName}
    wrapperCol={wrapperCol}
  >
    {children}
  </AntForm.Item>
);

export default FormItem;
