// Core
import { ReactNode, FocusEvent, ChangeEvent, KeyboardEvent, CSSProperties } from 'react';
// Packages
import cn from 'classnames';
import { Input as AntEmailInput } from 'antd';
// Styles
import styles from './email.module.scss';

interface IEmailInputProps {
  addonAfter?: ReactNode;
  addonBefore?: ReactNode;
  allowClear?: boolean;
  bordered?: boolean;
  defaultValue?: string;
  multiple?: boolean;
  disabled?: boolean;
  id?: string;
  maxLength?: number;
  prefix?: ReactNode;
  size?: 'small' | 'middle' | 'large';
  suffix?: ReactNode;
  value?: string;
  autoComplete?: string;
  readOnly?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onInvalid?: (e: ChangeEvent<HTMLInputElement>) => void;
  onInput?: (e: ChangeEvent<HTMLInputElement>) => void;
  onPressEnter?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement> | undefined) => void;
  placeholder?: string;
  className?: string;
  style?: CSSProperties;
  dataCy?: string;
}

/**
 * A basic widget for getting the user input is a email field.
 * Keyboard and mouse can be used for providing or changing data.
 *
 * @description A user input in a form field is needed. A search input is required.
 *
 * @param addonAfter - The label text displayed after (on the right side of) the input field
 * @param addonBefore - The label text displayed before (on the left side of) the input field
 * @param allowClear - If allow to remove input content with clear icon
 * @param bordered - Whether has border style
 * @param defaultValue - The initial input content
 * @param disabled - 	Whether the input is disabled
 * @param id - The ID for input
 * @param maxLength - The max length
 * @param prefix - The prefix icon for the Input
 * @param size - The size of the input box. Note: in the context of a form, the "large" size is used
 * @param suffix - The suffix icon for the Input
 * @param value - The input content value
 * @param onChange - Callback when user input
 * @param placeholder - Specifies a short hint that describes the expected value of an input field
 * @param className - Component className
 * @param autoComplete - Browser autocomplete
 * @param style - To customize the styles
 * @param dataCy - cypress
 */

const EmailInput = ({
  addonAfter,
  addonBefore,
  allowClear = false,
  bordered = true,
  defaultValue,
  disabled = false,
  multiple = false,
  id,
  maxLength,
  prefix,
  size = 'middle',
  suffix,
  autoComplete = 'off',
  value,
  readOnly,
  onChange,
  onBlur,
  onInput,
  onPressEnter,
  onInvalid,
  placeholder = '',
  className,
  style,
  dataCy,
}: IEmailInputProps) => {
  const classNames = cn(className, styles.formEmailInput, {
    [styles.formInputHasAffix]: prefix || suffix,
  });

  return (
    <AntEmailInput
      multiple={multiple}
      addonAfter={addonAfter}
      addonBefore={addonBefore}
      allowClear={allowClear}
      bordered={bordered}
      defaultValue={defaultValue}
      disabled={disabled}
      id={id}
      maxLength={maxLength}
      prefix={prefix}
      autoComplete={autoComplete}
      size={size}
      suffix={suffix}
      type="email"
      value={value}
      readOnly={readOnly}
      onBlur={onBlur}
      onInput={onInput}
      onChange={onChange}
      onInvalid={onInvalid}
      onPressEnter={onPressEnter}
      placeholder={placeholder}
      className={classNames}
      style={style}
      data-cy={dataCy}
    />
  );
};

export default EmailInput;
