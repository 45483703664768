// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Interfaces and types
import {
  IGetJobGroupsRes,
  ICreateJobGroupBody,
  ICreateJobGroupRes,
  IGetJobGroupDetailsRes,
  IUpdateJobGroupBodyWithParam,
  IUpdateJobGroupRes,
  IDeleteJobGroupRes,
} from 'types/jobGroupTypes';
import { TQueryParams } from 'types/appTypes';

export const getJobGroups = (params: TQueryParams) => {
  return xhr.get<IGetJobGroupsRes, IGetJobGroupsRes>(`${ENDPOINTS.jobsGroups}`, { params });
};

export const createJobGroup = (body: ICreateJobGroupBody) => {
  return xhr.post<ICreateJobGroupRes, ICreateJobGroupRes>(`${ENDPOINTS.jobsGroups}`, body);
};

export const getJobGroupDetails = (id: number) => {
  return xhr.get<IGetJobGroupDetailsRes, IGetJobGroupDetailsRes>(
    `${ENDPOINTS.jobsGroups}/${id}`,
  );
};

export const updateJobGroup = ({ id, body }: IUpdateJobGroupBodyWithParam) => {
  return xhr.put<IUpdateJobGroupRes, IUpdateJobGroupRes>(
    `${ENDPOINTS.jobsGroups}/${id}`,
    body,
  );
};

export const deleteJobGroup = (id: number) => {
  return xhr.delete<IDeleteJobGroupRes, IDeleteJobGroupRes>(`${ENDPOINTS.jobsGroups}/${id}`);
};
