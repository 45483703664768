// Core
import { CSSProperties, ReactNode } from 'react';
// Packages
import { Switch as AntSwitch } from 'antd';
import cx from 'classnames';
// Styles
import styles from './switch.module.scss';

interface ISwitchProps {
  autoFocus?: boolean;
  checked?: boolean;
  checkedChildren?: ReactNode;
  className?: string;
  dataCy?: string;
  defaultChecked?: boolean;
  disabled?: boolean;
  loading?: boolean;
  size?: 'small' | 'default';
  style?: CSSProperties;
  unCheckedChildren?: ReactNode;
  onChange?: (checked: boolean, event: MouseEvent) => void;
  onClick?: (checked: boolean, e: Event) => void;
}

/**
 * Switching Selector.
 *
 * @description If you need to represent the switching between two states or on-off state.
 * The difference between Switch and Checkbox is that Switch will trigger a state change
 * directly when you toggle it, while Checkbox is generally used for state marking,
 * which should work in conjunction with submit operation.
 *
 * @param autoFocus -	Whether get focus when component mounted
 * @param checked - Determine whether the Switch is checked
 * @param checkedChildren - The content to be shown when the state is checked
 * @param className - The additional class to Switch
 * @param dataCy - param - need for testing;
 * @param defaultChecked - Whether to set the initial state
 * @param disabled - Disable switch
 * @param loading - Loading state of switch
 * @param size - The size of the Switch, options: "default" | "small"
 * @param style - To customize the styles
 * @param unCheckedChildren - The content to be shown when the state is unchecked
 * @param onChange - Trigger when clicked
 * @param onClick - Trigger when clicked
 */

const Switch = ({
  autoFocus = false,
  checked = false,
  checkedChildren,
  className,
  dataCy,
  defaultChecked = false,
  disabled = false,
  loading = false,
  size = 'default',
  style,
  unCheckedChildren,
  onChange,
  onClick,
}: ISwitchProps) => (
  <AntSwitch
    autoFocus={autoFocus}
    checked={checked}
    checkedChildren={checkedChildren}
    className={cx(styles.switch, className)}
    data-cy={dataCy}
    defaultChecked={defaultChecked}
    disabled={disabled}
    loading={loading}
    size={size}
    style={style}
    unCheckedChildren={unCheckedChildren}
    onChange={onChange}
    onClick={onClick}
  />
);

export default Switch;
