// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Types
import { TQueryParams, IResMessage } from 'types/appTypes';
import {
  IEquipmentCreateBody,
  IEquipmentCreateRes,
  IEquipmentItemDetailsRes,
  IEquipmentUpdateBody,
  IEquipmentUpdateRes,
  IGetEquipmentItemsRes,
  TEquipmentTypesRes,
} from 'types/equipmentTypes';
import { IGetConditionRes } from '../../types/conditionTypes';

export const getEquipmentTypes = () => {
  return xhr.get<TEquipmentTypesRes, TEquipmentTypesRes>(`${ENDPOINTS.equipmentTypes}`);
};

export const getEquipmentConditions = (params: TQueryParams) => {
  return xhr.get<IGetConditionRes, IGetConditionRes>(`${ENDPOINTS.conditionItems}`, {
    params,
  });
};

export const getEquipmentItems = (params: TQueryParams) => {
  return xhr.get<IGetEquipmentItemsRes, IGetEquipmentItemsRes>(`${ENDPOINTS.equipmentItems}`, {
    params,
  });
};

export const getEquipment = (id: number) => {
  return xhr.get<IEquipmentItemDetailsRes, IEquipmentItemDetailsRes>(
    `${ENDPOINTS.equipmentItems}/${id}`,
  );
};

export const createEquipment = (body: IEquipmentCreateBody) => {
  return xhr.post<IEquipmentCreateRes, IEquipmentCreateRes>(`${ENDPOINTS.equipment}`, body);
};

export const updateEquipment = ({ id, ...body }: IEquipmentUpdateBody) => {
  return xhr.put<IEquipmentUpdateRes, IEquipmentUpdateRes>(
    `${ENDPOINTS.equipmentItems}/${id}`,
    body,
  );
};

export const deleteEquipment = (id: number) => {
  return xhr.delete<IResMessage, IResMessage>(`${ENDPOINTS.equipmentItems}/${id}`);
};

export const deleteEquipmentType = (id: number) => {
  return xhr.delete<IResMessage, IResMessage>(`${ENDPOINTS.equipmentTypes}/${id}`);
};

export const deleteEquipmentCondition = (id: number) => {
  return xhr.delete<IResMessage, IResMessage>(`${ENDPOINTS.conditionItems}/${id}`);
};
