// Core
import { CSSProperties } from 'react';
// Packages
import { Typography as AntTypography } from 'antd';
import {
  AiOutlineCloseCircle,
  AiOutlineCheckCircle,
  AiOutlineExclamationCircle,
} from 'react-icons/ai';

interface IRulesMessageProps {
  className?: string;
  message?: string;
  type?: 'secondary' | 'success' | 'warning' | 'danger';
  style?: CSSProperties;
}

const icons = {
  secondary: null,
  danger: <AiOutlineCloseCircle />,
  success: <AiOutlineCheckCircle />,
  warning: <AiOutlineExclamationCircle />,
};

type TIconsType = keyof typeof icons;

/**
 * @param className - The picker className
 * @param message - Message text
 * @param type - Content type
 * @param style - To customize the styles
 */

const RulesMessage = ({ className, message, type = 'danger', style }: IRulesMessageProps) => (
  <AntTypography.Text className={className} type={type} style={style}>
    {icons[type as TIconsType]} {message}
  </AntTypography.Text>
);

export default RulesMessage;
