interface IProps {
  width?: string;
  height?: string;
}

const Basket = ({ width = '1em', height = '1em' }: IProps) => (
  <svg width={width} height={height} viewBox="0 0 14 14" fill="none">
    <path
      d="M13.333 3a.667.667 0 01-.589.662l-.077.005h-.563l-.821 8.346a1.833 1.833 0 01-1.825 1.654H4.542a1.833 1.833 0 01-1.825-1.654l-.82-8.346h-.564a.667.667 0 010-1.334h3.334a2.333 2.333 0 014.666 0h3.334c.368 0 .666.299.666.667zM8.5 5.167a.5.5 0 00-.495.432L8 5.667v4.666l.005.068a.5.5 0 00.99 0L9 10.333V5.667l-.005-.068a.5.5 0 00-.495-.432zm-3 0a.5.5 0 00-.495.432L5 5.667v4.666l.005.068a.5.5 0 00.99 0L6 10.333V5.667l-.005-.068a.5.5 0 00-.495-.432zM7 1.333a1 1 0 00-1 1h2a1 1 0 00-1-1z"
      fill="#fff"
    />
  </svg>
);

export default Basket;
