// Core
import { useState, MouseEvent } from 'react';
// Packages
import { UploadFile } from 'antd/lib/upload/interface';
// Components
import { Upload, Text, Space, Button, FormItem } from 'components/lib';
import { TCustomRequest, TRcFile } from 'components/lib/Upload';
// Assets
import { MailInbox, DeleteIcon } from 'assets/icons/other';
// Helpers
import showNotification from 'helpers/showNotification';
// Types
import { IErrorRes } from 'types/appTypes';
import { TFormInstance } from 'components/lib/Form';
// I18n
import { t } from 'i18n';
// Styles
import styles from './fileUploader.module.scss';

type TFileEvent = { file: UploadFile; fileList: UploadFile[] };

interface IProps {
  isRequired?: boolean;
  initialFileName?: string;
  formInstance: TFormInstance;
  limit: number;
  errorInfo?: IErrorRes | null;
}

const FileUploader = ({
  isRequired = false,
  initialFileName,
  formInstance,
  limit,
  errorInfo,
}: IProps) => {
  const [fileName, setFileName] = useState<undefined | string>(initialFileName);

  const handleBeforeUpload = ({ name, size }: TRcFile) => {
    const isLimit20Mb = size / 1024 / 1024 <= limit;

    if (!isLimit20Mb) {
      showNotification('error', `${name} ${t('uploader.overLimit')}`);
    }

    return isLimit20Mb;
  };

  const handleAddFile = ({ file }: TFileEvent) => {
    if (file) {
      setFileName(file.originFileObj?.name);

      return file.originFileObj;
    }
  };

  const handleRemoveFile = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    setFileName('');

    formInstance.setFieldsValue({ file: null });
  };

  const dummyRequest: TCustomRequest = ({ onSuccess }) => {
    setTimeout(() => onSuccess && onSuccess('ok', {} as any), 0);
  };

  let isRequiredFile;

  if (isRequired) {
    isRequiredFile = <sup className={styles.required}> *</sup>;
  }

  return (
    <FormItem
      required={isRequired}
      name="file"
      valuePropName="file"
      getValueFromEvent={handleAddFile}
      validateStatus={errorInfo?.errors?.file ? 'error' : undefined}
      help={errorInfo?.errors?.file}
    >
      <Upload.Dragger
        showUploadList={false}
        customRequest={dummyRequest}
        beforeUpload={handleBeforeUpload}
        className={styles.upload}
      >
        {!fileName ? (
          <Space direction="vertical" size={0}>
            <MailInbox />
            <Text strong>{t('uploader.uploadTitle')}</Text>
            <Text>
              {t('uploader.uploadSubtitle')}
              {isRequiredFile}
            </Text>
          </Space>
        ) : (
          <>
            <Text strong className={styles.fileName}>
              {fileName}
            </Text>

            <Button
              type="text"
              size="small"
              shape="circle"
              icon={<DeleteIcon />}
              onClick={handleRemoveFile}
              className={styles.removeFileBtn}
            />
          </>
        )}
      </Upload.Dragger>
    </FormItem>
  );
};

export default FileUploader;
