// Modules
import webStorage from 'modules/storage';

interface IInitDateConfig {
  appTimeFormat: 'HH:mm' | 'hh:mm a';
  shouldUse12Hours: boolean;
  hoursTimeFormat: number;
  configureTimeFormat(format: 12 | 24): void;
}

const initDateConfig = (): IInitDateConfig => {
  const _24_HOURS = 'HH:mm';
  const _12_HOURS = 'hh:mm a';

  const configureTimeFormat = (format: 12 | 24) => {
    webStorage.setData<boolean>(format === 12, 'timeGoUse12Hours');
  };

  const getTimeFormat = () => {
    const shouldUse12Hours = !!webStorage.getData<boolean>('timeGoUse12Hours');

    return shouldUse12Hours ? _12_HOURS : _24_HOURS;
  };

  const getTimeFormatHours = () => {
    const shouldUse12Hours = !!webStorage.getData<boolean>('timeGoUse12Hours');

    return shouldUse12Hours ? 12 : 24;
  };

  const shouldUse12Hours = () => {
    return !!webStorage.getData<boolean>('timeGoUse12Hours');
  };

  return {
    configureTimeFormat,
    appTimeFormat: getTimeFormat(),
    shouldUse12Hours: shouldUse12Hours(),
    hoursTimeFormat: getTimeFormatHours(),
  };
};

export default initDateConfig();
