// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { InvoicesApi } from 'api/endpoints';
// Interfaces and types
import {
  IGetInvoiceSettingsRes,
  IUpdateInvoiceSettingsBody,
  IUpdateInvoiceSettingsRes,
} from 'types/invoiceSettingsTypes';
import { IErrorRes } from 'types/appTypes';

const getInvoiceSettingsAsync = createAsyncThunk<
  IGetInvoiceSettingsRes,
  void,
  { rejectValue: IErrorRes }
>('invoiceSettings/getInvoiceSettings', async (_: void, { rejectWithValue }) => {
  try {
    return await InvoicesApi.getInvoiceSettings();
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const updateInvoiceSettingsAsync = createAsyncThunk<
  IUpdateInvoiceSettingsRes,
  IUpdateInvoiceSettingsBody,
  { rejectValue: IErrorRes }
>(
  'invoiceSettings/updateInvoiceSettings',
  async (body: IUpdateInvoiceSettingsBody, { rejectWithValue }) => {
    try {
      return await InvoicesApi.updateInvoiceSettings(body);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

export { getInvoiceSettingsAsync, updateInvoiceSettingsAsync };
