// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Interfaces and types
import {
  ISignUpBody,
  ISignUpRes,
  ISignInBody,
  ISignInRes,
  IForgotAccountNameBody,
  IForgotAccountNameRes,
  IForgotPassBody,
  IForgotPassRes,
  IRecoverPassBody,
  IRecoverPassRes,
  IRecoverTokenRes,
  ICheckAccessTokenRes,
  ISignUpLandingBody,
  ISignUpLandingRes,
  ISignOutBody,
  ISignInResData,
  ISignInWithCodeBody,
  IResendCodeRes,
} from 'types/authTypes';
import { IResMessage } from 'types/appTypes';

export const signUpLandingForm = (body: ISignUpLandingBody) => {
  return xhr.post<ISignUpLandingRes, ISignUpLandingRes>(
    `${ENDPOINTS.signUpFormLanding}`,
    body,
  );
};

export const signUp = (body: Partial<ISignUpBody>) => {
  return xhr.post<ISignUpRes, ISignUpRes>(`${ENDPOINTS.signUp}`, body);
};

export const signIn = (body: ISignInBody) => {
  return xhr.post<ISignInRes, ISignInRes>(`${ENDPOINTS.signIn}`, body);
};

export const resendTwoFactorCode = () => {
  return xhr.post<IResendCodeRes, IResendCodeRes>(`${ENDPOINTS.twoFactorCode}`);
};

export const signInWithTwoFactorCode = (body: ISignInWithCodeBody) => {
  return xhr.post<ISignInResData, ISignInResData>(`${ENDPOINTS.signInWithTwoFactor}`, body);
};

export const signOut = (refreshToken: ISignOutBody) => {
  return xhr.post<IResMessage, IResMessage>(`${ENDPOINTS.signOut}`, refreshToken);
};

export const forgotWebAddress = (body: IForgotAccountNameBody) => {
  return xhr.post<IForgotAccountNameRes, IForgotAccountNameRes>(
    `${ENDPOINTS.forgotWebAddress}`,
    body,
  );
};

export const forgotPassword = (body: IForgotPassBody) => {
  return xhr.post<IForgotPassRes, IForgotPassRes>(`${ENDPOINTS.forgotPassword}`, body);
};

export const recoverPassword = (body: IRecoverPassBody) => {
  return xhr.post<IRecoverPassRes, IRecoverPassRes>(`${ENDPOINTS.recoverPassword}`, body);
};

export const checkRecoverToken = (token: string) => {
  return xhr.get<IRecoverTokenRes, IRecoverTokenRes>(`${ENDPOINTS.recoverToken}/${token}`);
};

export const checkIfAccessTokenIsValid = () => {
  return xhr.get<ICheckAccessTokenRes, ICheckAccessTokenRes>(`${ENDPOINTS.checkToken}`);
};

//Sign in to my another company as business admin
export const switchAccount = (id: number) => {
  return xhr.post<ISignInResData, ISignInResData>(`${ENDPOINTS.switchCompany}/${id}`);
};
