// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Interfaces and types
import { IGetMapInfoRes, IGetMapInfoParams } from 'types/mapTypes';

export const getMapInfo = (params: IGetMapInfoParams) => {
  return xhr.get<IGetMapInfoRes, IGetMapInfoRes>(`${ENDPOINTS.map}`, { params });
};
