// Core
import { Suspense } from 'react';
// Packages
import { Switch, Route } from 'react-router-dom';
// Pages
import NotFoundPage from 'pages/NotFoundPage';
// Components
import { ErrorBoundary, Loader } from 'components';
// Routes
import { TSubRoute } from './routes';
// Custom Hooks
import { useRoutePermissions } from 'customHooks';

/**
  @property  routes - sub routes of main route
  @property  fromUrl - path of main router (need for creating pathname in Links to profiles/details pages)
 */

interface IProps {
  routes: TSubRoute[];
  fromUrl?: string;
}

const SubRouter = ({ routes, fromUrl }: IProps) => {
  const allowedRoutes = useRoutePermissions(routes);

  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader isLoading />}>
        <Switch>
          {allowedRoutes.map(({ component: Component, ...restProps }) => (
            <Route
              key={restProps.path}
              {...restProps}
              render={props => <Component fromUrl={fromUrl} {...props} />}
            />
          ))}

          <Route component={NotFoundPage} />
        </Switch>
      </Suspense>
    </ErrorBoundary>
  );
};

export default SubRouter;
