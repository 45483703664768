// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Types
import { TQueryParams } from 'types/appTypes';
import { IGetInvoiceAgingRes } from 'types/invoiceAgingTypes';

export const getInvoiceAging = (params: TQueryParams) => {
  return xhr.get<IGetInvoiceAgingRes, IGetInvoiceAgingRes>(`${ENDPOINTS.invoiceAging}`, {
    params,
  });
};
