// Core
import { CSSProperties, ReactElement, ReactNode, DragEvent } from 'react';
// Packages
import { ProgressProps, Upload as AntUpload } from 'antd';
import {
  RcFile,
  UploadFile,
  UploadListType,
  HttpRequestHeader,
  UploadChangeParam,
  ShowUploadListInterface,
} from 'antd/lib/upload/interface';
import { UploadRequestOption } from 'rc-upload/lib/interface';

export type TCustomRequest = (options: UploadRequestOption) => void;
export type TUploadChangeParam = UploadChangeParam;
export type TUploadFile = UploadFile;
export type TRcFile = RcFile;
export type TItemRender = (
  originNode: ReactElement,
  file: UploadFile,
  fileList: object[],
  actions: { download: () => void; preview: () => void; remove: () => void },
) => ReactNode;

type BeforeUploadValueType = void | boolean | string | Blob | File;

interface IUrlInputProps {
  accept?: string;
  action?: string | ((file: RcFile) => string) | ((file: RcFile) => PromiseLike<string>);
  beforeUpload?: (
    file: RcFile,
    FileList: RcFile[],
  ) => BeforeUploadValueType | Promise<BeforeUploadValueType>;
  children?: ReactNode;
  className?: string;
  customRequest?: TCustomRequest;
  data?: object | ((file: UploadFile) => object);
  defaultFileList?: UploadFile[];
  directory?: boolean;
  disabled?: boolean;
  fileList?: UploadFile[];
  headers?: HttpRequestHeader;
  iconRender?: (file: UploadFile, listType?: UploadListType) => ReactNode;
  isImageUrl?: (file: UploadFile) => boolean;
  itemRender?: TItemRender;
  listType?: UploadListType;
  maxCount?: number;
  method?: 'POST' | 'PUT' | 'PATCH' | 'post' | 'put' | 'patch';
  multiple?: boolean;
  name?: string;
  openFileDialogOnClick?: boolean;
  previewFile?: (file: File | Blob) => PromiseLike<string>;
  progress?: Omit<ProgressProps, 'percent' | 'type'>;
  showUploadList?: boolean | ShowUploadListInterface;
  style?: CSSProperties;
  withCredentials?: boolean;
  id?: string;
  onChange?: (info: UploadChangeParam) => void;
  onDrop?: (event: DragEvent<HTMLDivElement>) => void;
  onDownload?: (file: UploadFile) => void;
  onPreview?: (file: UploadFile) => void;
  onRemove?: (file: UploadFile) => void | boolean | Promise<void | boolean>;
}

/**
 * Upload file by selecting or dragging.
 *
 * @description Uploading is the process of publishing information (web pages, text, pictures, video, etc.) to a remote server via a web page or upload tool.
 * When you need to upload one or more files.
 * When you need to show the process of uploading.
 * When you need to upload files by dragging and dropping.
 *
 * @param accept - File types that can be accepted. See input accept Attribute
 * @param action - Uploading URL
 * @param beforeUpload - Hook function which will be executed before uploading. Uploading will be stopped with false or a rejected Promise returned. When returned value is Upload.LIST_IGNORE, the list of files that have been uploaded will ignore it. Warning：this function is not supported in IE9
 * @param children - Some ReactNode
 * @param className - Component className
 * @param customRequest - Override for the default xhr behavior allowing for additional customization and ability to implement your own XMLHttpRequest
 * @param data - Uploading extra params or function which can return uploading extra params
 * @param defaultFileList - Default list of files that have been uploaded
 * @param directory - Support upload whole directory (caniuse)
 * @param disabled - Disable upload button
 * @param fileList - List of files that have been uploaded (controlled). Here is a common issue #2423 when using it
 * @param headers - Set request headers, valid above IE10
 * @param iconRender - Custom show icon
 * @param isImageUrl - Customize if render <img /> in thumbnail
 * @param itemRender - Custom item of uploadList
 * @param listType - Built-in stylesheets, support for three types: text, picture or picture-card
 * @param maxCount - Limit the number of uploaded files. Will replace current one when maxCount is 1
 * @param method - The http method of upload request
 * @param multiple - Whether to support selected multiple file. IE10+ supported. You can select multiple files with CTRL holding down while multiple is set to be true
 * @param name - The name of uploading file
 * @param openFileDialogOnClick - Click open file dialog
 * @param previewFile - Customize preview file logic
 * @param progress - Custom progress bar
 * @param showUploadList - Whether to show default upload list, could be an object to specify showPreviewIcon, showRemoveIcon, showDownloadIcon, removeIcon and downloadIcon individually
 * @param style - To customize the styles
 * @param withCredentials - The ajax upload with cookie sent
 * @param onChange - A callback function, can be executed when uploading state is changing, see onChange
 * @param onDrop - A callback function executed when files are dragged and dropped into upload area
 * @param onDownload - Click the method to download the file, pass the method to perform the method logic, do not pass the default jump to the new TAB
 * @param onPreview - A callback function, will be executed when file link or preview icon is clicked
 * @param onRemove - A callback function, will be executed when removing file button is clicked, remove event will be prevented when return value is false or a Promise which resolve(false) or reject
 */

const Upload = ({
  accept,
  action,
  beforeUpload,
  children,
  className,
  customRequest,
  data,
  defaultFileList,
  directory = false,
  disabled = false,
  fileList,
  headers,
  iconRender,
  isImageUrl,
  itemRender,
  listType = 'text',
  maxCount,
  method = 'post',
  multiple = false,
  name = 'file',
  openFileDialogOnClick = true,
  previewFile,
  progress,
  showUploadList = true,
  style,
  withCredentials = false,
  id,
  onChange,
  onDrop,
  onDownload,
  onPreview,
  onRemove,
}: IUrlInputProps) => (
  <AntUpload
    accept={accept}
    action={action}
    beforeUpload={beforeUpload}
    className={className}
    customRequest={customRequest}
    data={data}
    defaultFileList={defaultFileList}
    directory={directory}
    disabled={disabled}
    fileList={fileList}
    headers={headers}
    iconRender={iconRender}
    isImageUrl={isImageUrl}
    itemRender={itemRender}
    listType={listType}
    maxCount={maxCount}
    method={method}
    multiple={multiple}
    name={name}
    openFileDialogOnClick={openFileDialogOnClick}
    previewFile={previewFile}
    progress={progress}
    showUploadList={showUploadList}
    style={style}
    withCredentials={withCredentials}
    id={id}
    onChange={onChange}
    onDrop={onDrop}
    onDownload={onDownload}
    onPreview={onPreview}
    onRemove={onRemove}
  >
    {children}
  </AntUpload>
);

const { Dragger, LIST_IGNORE } = AntUpload;

Upload.Dragger = Dragger;
Upload.LIST_IGNORE = LIST_IGNORE;

export default Upload;
