// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { EquipmentExpensesApi } from 'api/endpoints';
// Interfaces and types
import { IErrorRes, TQueryParams } from 'types/appTypes';
import {
  IGetEquipmentExpensesByEquipmentId,
  IGetEquipmentExpensesByEquipmentIdRes,
  IGetEquipmentExpensesRes,
} from 'types/equipmentExpensesTypes';

const getEquipmentExpensesItemsAsync = createAsyncThunk<
  IGetEquipmentExpensesRes,
  TQueryParams,
  { rejectValue: IErrorRes }
>(
  'equipmentExpenses/getEquipmentExpenses',
  async (params: TQueryParams, { rejectWithValue }) => {
    try {
      return await EquipmentExpensesApi.getEquipmentExpensesItems(params);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const getEquipmentExpenesesByEquipmentIdAsync = createAsyncThunk<
  IGetEquipmentExpensesByEquipmentIdRes,
  IGetEquipmentExpensesByEquipmentId,
  { rejectValue: IErrorRes }
>(
  'equipmentExpenses/getEquipmenExpensesByEquipmentId',
  async ({ params, equipmentId }, { rejectWithValue }) => {
    try {
      return await EquipmentExpensesApi.getEquipmentExpensesItemsByEquipmentId(
        equipmentId,
        params,
      );
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

export { getEquipmentExpensesItemsAsync, getEquipmentExpenesesByEquipmentIdAsync };
