// Types
import { IUserPagePermissions, TPermissionKey } from 'customHooks/usePermissions';
import { IPlatformPermissions } from 'types/userTypes';

interface IParams {
  platformPermissions?: IPlatformPermissions;
  neededPermissions: TPermissionKey;
}

const getAccessToPage = ({ platformPermissions, neededPermissions }: IParams) => {
  if (!platformPermissions) return;

  const userPermissionActions = platformPermissions[neededPermissions] || [];

  let userPagePermission: IUserPagePermissions = {
    read: false,
    edit: false,
    delete: false,
    create: false,
  };

  const pagePermissions = (userPermissionActions as string[])?.reduce(
    (acc: IUserPagePermissions, curr: string) => {
      return { ...acc, [curr]: true };
    },
    userPagePermission,
  );

  return pagePermissions;
};

export default getAccessToPage;
