// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import {
  getRecurringInvoicesAsync,
  createRecurringInvoiceAsync,
  getRecurringInvoiceDetailsAsync,
  updateRecurringInvoiceAsync,
  deleteRecurringInvoiceAsync,
} from './recurringInvoiceThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import {
  IGetRecurringInvoiceDetailsRes,
  IGetRecurringInvoicesRes,
} from 'types/recurringInvoiceTypes';
import { IErrorRes } from 'types/appTypes';

interface ISliceRecurringInvoiceState {
  recurringInvoices: IGetRecurringInvoicesRes | null;
  recurringInvoiceDetails: IGetRecurringInvoiceDetailsRes | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: ISliceRecurringInvoiceState = {
  recurringInvoices: null,
  recurringInvoiceDetails: null,
  error: null,
  isLoading: false,
};

const recurringInvoiceSlice = createSlice({
  name: 'recurringInvoice',
  initialState,
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    //* GET RECURRING_INVOICES ASYNC THUNK
    builder.addCase(getRecurringInvoicesAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getRecurringInvoicesAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.recurringInvoices = payload;
    });
    builder.addCase(getRecurringInvoicesAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });
    //* CREATE RECURRING_INVOICE ASYNC THUNK
    builder.addCase(createRecurringInvoiceAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(createRecurringInvoiceAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(createRecurringInvoiceAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* GET RECURRING_INVOICE DETAILS ASYNC THUNK
    builder.addCase(getRecurringInvoiceDetailsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getRecurringInvoiceDetailsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.recurringInvoiceDetails = payload;
    });
    builder.addCase(getRecurringInvoiceDetailsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* UPDATE RECURRING_INVOICE (one) ASYNC THUNK
    builder.addCase(updateRecurringInvoiceAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(updateRecurringInvoiceAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(updateRecurringInvoiceAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* DELETE RECURRING_INVOICE ASYNC THUNK
    builder.addCase(deleteRecurringInvoiceAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(deleteRecurringInvoiceAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteRecurringInvoiceAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export default recurringInvoiceSlice;
