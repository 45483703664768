// Core
import { useState, useEffect } from 'react';
// Packages
import { Upload, Button } from 'components/lib';
import ImgCrop from 'antd-img-crop';
// Assets
import Basket from 'assets/icons/uploaders/Basket';
import UploaderIcon from 'assets/icons/uploaders/UploaderIcon';
// Custom hooks
import { useFetch } from 'customHooks';
// Redux
import { changeLogoImage } from 'redux/auth/authReducer';
import { useAppDispatch } from 'redux/store';
// API
import { CompaniesAPI } from 'api/endpoints';
// Interfaces and types
import {
  ICompanyLogoImgUploadRes,
  ICompanyLogoImgDeleteRes,
  ICompanyLogoImgUploadBody,
} from 'types/companyTypes';
// I18n
import { t } from 'i18n';
// Styles
import styles from './uploader.module.scss';
import './cropp.scss';

const acceptedFormat = 'image/jpeg,image/png,image/jpg';

interface IProps {
  disabled?: boolean;
  logoImage?: string;
}

const Uploader = ({ disabled = false, logoImage }: IProps) => {
  const dispatch = useAppDispatch();

  const [preview, setPreview] = useState('');

  useEffect(() => {
    if (!logoImage) {
      return setPreview('');
    }

    setPreview(logoImage);
  }, [logoImage]);

  // upload company img
  const [
    { response: responseUploadImage, isLoading: isLoadingUploadImage },
    doFetchUploadImage,
  ] = useFetch<
    ICompanyLogoImgUploadRes,
    ICompanyLogoImgUploadBody,
    typeof CompaniesAPI.uploadCompanyImg
  >(CompaniesAPI.uploadCompanyImg);

  const handleUpload = (file: Blob) => {
    const formData = new FormData();
    formData.append('file', file);

    doFetchUploadImage(formData as ICompanyLogoImgUploadBody);
  };

  useEffect(() => {
    if (!responseUploadImage) {
      return;
    }

    setPreview(responseUploadImage?.link);

    dispatch(changeLogoImage(responseUploadImage?.link));
  }, [responseUploadImage, dispatch]);

  // delete company img
  const [{ response: responseDeleteImage }, doFetchDeleteImage] = useFetch<
    ICompanyLogoImgDeleteRes,
    void,
    typeof CompaniesAPI.deleteCompanyImg
  >(CompaniesAPI.deleteCompanyImg);

  const handleDeleteImg = () => doFetchDeleteImage();

  useEffect(() => {
    if (!responseDeleteImage) {
      return;
    }

    setPreview('');

    dispatch(changeLogoImage(''));
  }, [responseDeleteImage, dispatch]);

  // ant uploader logic
  const props = {
    beforeUpload: handleUpload,
    id: 'photoUploader',
    showUploadList: false,
    accept: acceptedFormat,
  };

  const uploadButton = (
    <div className={styles.buttonUploaderContainer}>
      <UploaderIcon />

      <Button disabled={disabled} type="text" className={styles.buttonUploader}>
        <span className={styles.buttonUploaderBoldText}>{t('settingsModal.clickOrDrag')}</span>

        <span>{t('settingsModal.fileToUpload')}</span>
      </Button>
    </div>
  );

  return (
    <>
      {!isLoadingUploadImage && preview && (
        <div className={styles.imageContainer}>
          <img
            src={preview}
            alt={t('settingsModal.companyLogoPreview')}
            className={styles.image}
          />

          <div className={styles.basketContainer}>
            <Button
              type="text"
              icon={<Basket />}
              onClick={handleDeleteImg}
              className={styles.logOutBtn}
            />
          </div>
        </div>
      )}

      {isLoadingUploadImage && (
        <div className={styles.loadingContainer}>{t('settingsModal.uploading')}....</div>
      )}

      <ImgCrop>
        <Upload disabled={disabled} {...props} className={styles.uploader}>
          {uploadButton}
        </Upload>
      </ImgCrop>
    </>
  );
};
export default Uploader;
