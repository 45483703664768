interface IProps {
  width?: string;
  height?: string;
}

const UploaderIcon = ({ width = '1em', height = '1em' }: IProps) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none">
    <path
      d="M0 4.286A4.286 4.286 0 014.286 0h11.428A4.286 4.286 0 0120 4.286v11.428A4.286 4.286 0 0115.714 20H4.286A4.286 4.286 0 010 15.714V4.286zM1.429 10h5c.394 0 .713.319.714.713v.018l.005.086a3.677 3.677 0 00.366 1.364c.18.361.44.701.817.952.373.249.901.438 1.669.438s1.296-.19 1.668-.438c.377-.25.637-.59.818-.952a3.675 3.675 0 00.37-1.45l.001-.018v-.002a.714.714 0 01.714-.711h5V4.286a2.857 2.857 0 00-2.857-2.857H4.286a2.857 2.857 0 00-2.857 2.857V10zm5.714.713v-.002.002z"
      fill="#2E3A59"
    />
  </svg>
);

export default UploaderIcon;
