// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Interfaces and types
import {
  ICreateLeaveBody,
  ICreateLeaveRes,
  IDeleteLeaveRes,
  IGetEmployeeLeaveBody,
  IGetEmployeeLeaveDetailsRes,
  IGetEmployeeLeaveRes,
  IGetLeaveRes,
  ILeaveParams,
  IUpdateLeaveParams,
  IUpdateLeaveRes,
} from 'types/leaveTypes';
import { TQueryParams } from 'types/appTypes';

export const getLeaveRequests = (params: TQueryParams) => {
  return xhr.get<IGetLeaveRes, IGetLeaveRes>(`${ENDPOINTS.leave}`, { params });
};

export const createLeaveRequest = ({ userId, ...body }: ICreateLeaveBody) => {
  return xhr.post<ICreateLeaveRes, ICreateLeaveRes>(
    `${ENDPOINTS.employees}/${userId}${ENDPOINTS.leave}`,
    body,
  );
};

export const getEmployeeLeaveRequests = ({ userId, params }: IGetEmployeeLeaveBody) => {
  return xhr.get<IGetEmployeeLeaveRes, IGetEmployeeLeaveRes>(
    `${ENDPOINTS.employees}/${userId}${ENDPOINTS.leave}`,
    { params },
  );
};

export const getEmployeeLeaveRequestDetails = ({ id, userId }: ILeaveParams) => {
  return xhr.get<IGetEmployeeLeaveDetailsRes, IGetEmployeeLeaveDetailsRes>(
    `${ENDPOINTS.employees}/${userId}${ENDPOINTS.leave}/${id}`,
  );
};

export const updateLeaveRequest = ({ id, userId, body }: IUpdateLeaveParams) => {
  return xhr.patch<IUpdateLeaveRes, IUpdateLeaveRes>(
    `${ENDPOINTS.employees}/${userId}${ENDPOINTS.leave}/${id}`,
    body,
  );
};

export const deleteLeaveRequest = ({ id, userId }: ILeaveParams) => {
  return xhr.delete<IDeleteLeaveRes, IDeleteLeaveRes>(
    `${ENDPOINTS.employees}/${userId}${ENDPOINTS.leave}/${id}`,
  );
};
