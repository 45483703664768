// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { JobGroupsAPI } from 'api/endpoints';
// Interfaces and types
import {
  IGetJobGroupsRes,
  ICreateJobGroupBody,
  ICreateJobGroupRes,
  IGetJobGroupDetailsRes,
  IUpdateJobGroupBodyWithParam,
  IUpdateJobGroupRes,
  IDeleteJobGroupRes,
} from 'types/jobGroupTypes';
import { IErrorRes } from 'types/appTypes';
import { TQueryParams } from 'types/appTypes';

const getJoGroupsAsync = createAsyncThunk<
  IGetJobGroupsRes,
  TQueryParams,
  { rejectValue: IErrorRes }
>('jobGroups/getJobGroups', async (params: TQueryParams, { rejectWithValue }) => {
  try {
    return await JobGroupsAPI.getJobGroups(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const createJobGroupAsync = createAsyncThunk<
  ICreateJobGroupRes,
  ICreateJobGroupBody,
  { rejectValue: IErrorRes }
>('jobGroups/createJobGroup', async (body: ICreateJobGroupBody, { rejectWithValue }) => {
  try {
    return await JobGroupsAPI.createJobGroup(body);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getJobGroupDetailsAsync = createAsyncThunk<
  IGetJobGroupDetailsRes,
  number,
  { rejectValue: IErrorRes }
>('jobGroups/getJobGroupDetails', async (id: number, { rejectWithValue }) => {
  try {
    return await JobGroupsAPI.getJobGroupDetails(id);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const updateJobGroupAsync = createAsyncThunk<
  IUpdateJobGroupRes,
  IUpdateJobGroupBodyWithParam,
  { rejectValue: IErrorRes }
>(
  'jobGroups/updateJobGroup',
  async ({ id, body }: IUpdateJobGroupBodyWithParam, { rejectWithValue }) => {
    try {
      return await JobGroupsAPI.updateJobGroup({ id, body });
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const deleteJobGroupAsync = createAsyncThunk<
  IDeleteJobGroupRes,
  number,
  { rejectValue: IErrorRes }
>('jobGroups/deleteJоbGroup', async (id, { rejectWithValue }) => {
  try {
    return await JobGroupsAPI.deleteJobGroup(id);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

export {
  getJoGroupsAsync,
  createJobGroupAsync,
  getJobGroupDetailsAsync,
  updateJobGroupAsync,
  deleteJobGroupAsync,
};
