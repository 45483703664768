// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { ActionLogsAPI } from 'api/endpoints';
// Interfaces and types
import { IErrorRes, IResMessage, TQueryParams } from 'types/appTypes';
import { IGetActionLogRes } from 'types/actionLogsTypes';

const getActionLogsItemsAsync = createAsyncThunk<
  IGetActionLogRes,
  TQueryParams,
  { rejectValue: IErrorRes }
>('getActionLogs/actionLogs', async (params: TQueryParams, { rejectWithValue }) => {
  try {
    return await ActionLogsAPI.getActionLogs(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const restoreActionAsync = createAsyncThunk<IResMessage, number, { rejectValue: IErrorRes }>(
  'restoreActions/actionLogs',
  async (id: number, { rejectWithValue }) => {
    try {
      return await ActionLogsAPI.restoreActions(id);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

export { getActionLogsItemsAsync, restoreActionAsync };
