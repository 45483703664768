// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Types
import {
  ICreateLocationRatingBody,
  ICreateLocationRatingRes,
  IGetRatingRes,
  IGetAllRatingsRes,
  ICreateRatingResponseBodyWithParam,
  IUpdateRatingResponseBodyWithParams,
  IDeleteRatingResponseParams,
} from 'types/locationRatingTypes';
import { IResMessage, TQueryParams } from 'types/appTypes';

export const createLocationRating = (body: ICreateLocationRatingBody) => {
  return xhr.post<ICreateLocationRatingRes, ICreateLocationRatingRes>(
    `${ENDPOINTS.locationRatings}`,
    body,
  );
};

export const getLocationRating = (id: number) => {
  return xhr.get<IGetRatingRes, IGetRatingRes>(`${ENDPOINTS.locationRatings}/${id}`);
};

export const deleteLocationRating = (id: number) => {
  return xhr.delete<IResMessage, IResMessage>(`${ENDPOINTS.locationRatings}/${id}`);
};

export const getAllRatings = (params: TQueryParams) => {
  return xhr.get<IGetAllRatingsRes, IGetAllRatingsRes>(`${ENDPOINTS.locationRatings}`, {
    params,
  });
};

export const addRatingsResponse = (ratingId: number) => {
  return xhr.post<IGetAllRatingsRes, IGetAllRatingsRes>(
    `${ENDPOINTS.locationRatings}/${ratingId}${ENDPOINTS.response}`,
  );
};

export const createLocationRatingResponse = ({
  ratingId,
  body,
}: ICreateRatingResponseBodyWithParam) => {
  return xhr.post<IResMessage, IResMessage>(
    `${ENDPOINTS.locationRatings}/${ratingId}${ENDPOINTS.response}`,
    body,
  );
};

export const updateLocationRatingResponse = ({
  ratingId,
  responseId,
  body,
}: IUpdateRatingResponseBodyWithParams) => {
  return xhr.put<IResMessage, IResMessage>(
    `${ENDPOINTS.locationRatings}/${ratingId}${ENDPOINTS.response}/${responseId}`,
    body,
  );
};

export const deleteLocationRatingResponse = ({
  ratingId,
  responseId,
}: IDeleteRatingResponseParams) => {
  return xhr.delete<IResMessage, IResMessage>(
    `${ENDPOINTS.locationRatings}/${ratingId}${ENDPOINTS.response}/${responseId}`,
  );
};
