// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import { getEventsAsync } from './calendarThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import { IGetEventsRes } from 'types/calendarTypes';
import { IErrorRes } from 'types/appTypes';

interface ISliceCalendarEvents {
  events: IGetEventsRes | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: ISliceCalendarEvents = {
  events: null,
  error: null,
  isLoading: false,
};

const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {},
  extraReducers: builder => {
    //* GET EVENTS
    builder.addCase(getEventsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getEventsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.events = payload;
    });
    builder.addCase(getEventsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });
    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export default calendarSlice;
