// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { UnInvoicedHoursApi } from 'api/endpoints';
// Interfaces and types
import { IGetUnInvoicedHoursRes, IMarkInvoicedBody } from 'types/unInvoicedHoursTypes';
import { IErrorRes, IResMessage, TQueryParams } from 'types/appTypes';

const getUnInvoicedHoursAsync = createAsyncThunk<
  IGetUnInvoicedHoursRes,
  TQueryParams,
  { rejectValue: IErrorRes }
>('unInvoicedHours/getUnInvoicedHours', async (params: TQueryParams, { rejectWithValue }) => {
  try {
    return await UnInvoicedHoursApi.getUnInvoicedHours(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const markInvoicedAsync = createAsyncThunk<
  IResMessage,
  IMarkInvoicedBody,
  { rejectValue: IErrorRes }
>('unInvoicedHours/markInvoiced', async (data: IMarkInvoicedBody, { rejectWithValue }) => {
  try {
    return await UnInvoicedHoursApi.markedInvoiced(data);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

export { getUnInvoicedHoursAsync, markInvoicedAsync };
