// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Interfaces and types
import {
  IGetDocsRes,
  ICreateDocBody,
  ICreateDocRes,
  IDeleteDocRes,
  IGetEmployeeDocDetailsRes,
  IGetEmployeeDocsRes,
  TUpdateDocBody,
  IUpdateDocRes,
  IGetEmployeeDocsBody,
  IDocParams,
  IGetDocTypes,
} from 'types/docsType';
import { TQueryParams, IResMessage } from 'types/appTypes';

export const getDocs = (params: TQueryParams) => {
  return xhr.get<IGetDocsRes, IGetDocsRes>(`${ENDPOINTS.docs}`, {
    params,
  });
};

export const createDoc = ({ userId, body }: ICreateDocBody) => {
  return xhr.post<ICreateDocRes, ICreateDocRes>(
    `${ENDPOINTS.employees}/${userId}${ENDPOINTS.docs}`,
    body,
  );
};

export const getEmployeeDocs = ({ userId, params }: IGetEmployeeDocsBody) => {
  return xhr.get<IGetEmployeeDocsRes, IGetEmployeeDocsRes>(
    `${ENDPOINTS.employees}/${userId}${ENDPOINTS.docs}`,
    { params },
  );
};

export const getEmployeeDocDetails = ({ id, userId }: IDocParams) => {
  return xhr.get<IGetEmployeeDocDetailsRes, IGetEmployeeDocDetailsRes>(
    `${ENDPOINTS.employees}/${userId}${ENDPOINTS.docs}/${id}`,
  );
};

export const updateDoc = ({ id, userId, body }: TUpdateDocBody) => {
  return xhr.put<IUpdateDocRes, IUpdateDocRes>(
    `${ENDPOINTS.employees}/${userId}${ENDPOINTS.docs}/${id}`,
    body,
  );
};

export const deleteDoc = ({ id, userId }: IDocParams) => {
  return xhr.delete<IDeleteDocRes, IDeleteDocRes>(
    `${ENDPOINTS.employees}/${userId}${ENDPOINTS.docs}/${id}`,
  );
};

export const getDocTypes = () => {
  return xhr.get<IGetDocTypes, IGetDocTypes>(`${ENDPOINTS.docs}${ENDPOINTS.types}`);
};

export const deleteDocType = (id: number) => {
  return xhr.delete<IResMessage, IResMessage>(`${ENDPOINTS.docs}${ENDPOINTS.types}/${id}`);
};
