const MiniLogo = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 400 400"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_7119_40501)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.2916 1.09936e-05H331.709C369.27 1.09936e-05 400.001 30.7266 400.001 68.2915V331.708C400.001 369.269 369.27 400 331.709 400H68.2916C30.7271 400 0 369.269 0 331.708V68.2915C0 30.7266 30.7271 1.09936e-05 68.2916 1.09936e-05ZM133.614 158.45H79.8335V90.2846H225.095C211.959 95.7072 199.895 103.71 189.622 113.988C173.813 129.794 163.371 149.845 159.465 171.5C158.528 170.25 157.504 169.068 156.402 167.967L156.469 167.899C150.604 162.07 142.515 158.45 133.614 158.45V158.45ZM274.795 71.0035L305.657 90.2846H305.887V90.4268L360.211 124.363L305.887 158.305V158.45H305.652L274.795 177.729V158.45H266.39C257.543 158.45 249.469 162.1 243.601 167.967C237.734 173.835 234.084 181.908 234.084 190.756V223.946V284.775V334.453H200.001H165.919V318.859V190.756C165.919 163.092 177.214 137.958 195.402 119.768C213.592 101.579 238.726 90.2846 266.39 90.2846H274.795V71.0035"
        fill="#3794FC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M234.087 260.747V334.454H165.92L236.793 400H331.708C369.273 400 400.002 369.271 400.002 331.707V161.162L360.214 124.366L305.888 158.307V158.45H305.651L274.796 177.729V158.45H266.39C257.545 158.45 249.469 162.101 243.604 167.967C237.734 173.837 234.087 181.908 234.087 190.753V223.947V260.747"
        fill="#2C75C9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M234.087 334.454L234.085 284.776L234.087 223.946V190.753C234.087 181.908 237.734 173.837 243.604 167.967C249.47 162.101 257.546 158.45 266.39 158.45H274.796V177.729L305.652 158.45H305.888V158.307L360.212 124.365L305.888 90.4267V90.2846H305.659L274.796 71.0035V90.2846H266.391C238.727 90.2846 213.594 101.58 195.405 119.768C177.214 137.958 165.92 163.09 165.92 190.756V318.858V334.454H200.004H234.087"
        fill="#FEFEFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M159.466 171.5C163.373 149.845 173.813 129.794 189.624 113.988C199.897 103.712 211.961 95.7086 225.097 90.2846H133.616H79.8359V158.45H133.616C142.517 158.45 150.606 162.07 156.471 167.899L156.403 167.967C157.506 169.068 158.53 170.25 159.466 171.5Z"
        fill="#FEFEFE"
      />
    </g>
    <defs>
      <clipPath id="clip0_7119_40501">
        <rect width="400" height="400" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default MiniLogo;
