const localesList = [
  { label: 'Afrikaans (South Africa)', localeAbb: 'af-ZA', localeDominantAbb: 'af-ZA' },
  { label: 'Albanian (Albania)', localeAbb: 'sq-AL', localeDominantAbb: 'sq-AL' },
  { label: 'Arabic (Algeria)', localeAbb: 'ar-DZ', localeDominantAbb: 'ar-SA' },
  { label: 'Arabic (Bahrain)', localeAbb: 'ar-BH', localeDominantAbb: 'ar-SA' },
  { label: 'Arabic (Egypt)', localeAbb: 'ar-EG', localeDominantAbb: 'ar-SA' },
  { label: 'Arabic (Iraq)', localeAbb: 'ar-IQ', localeDominantAbb: 'ar-SA' },
  { label: 'Arabic (Jordan', localeAbb: 'ar-JO', localeDominantAbb: 'ar-SA' },
  { label: 'Arabic (Kuwait)', localeAbb: 'ar-KW', localeDominantAbb: 'ar-SA' },
  { label: 'Arabic (Lebanon)', localeAbb: 'ar-LB', localeDominantAbb: 'ar-SA' },
  { label: 'Arabic (Libya)', localeAbb: 'ar-LY', localeDominantAbb: 'ar-SA' },
  { label: 'Arabic (Morocco)', localeAbb: 'ar-MA', localeDominantAbb: 'ar-SA' },
  { label: 'Arabic (Oman)', localeAbb: 'ar-OM', localeDominantAbb: 'ar-SA' },
  { label: 'Arabic (Qatar)', localeAbb: 'ar-QA', localeDominantAbb: 'ar-SA' },
  { label: 'Arabic (Saudi Arabia)', localeAbb: 'ar-SA', localeDominantAbb: 'ar-SA' },
  { label: 'Arabic (Syria)', localeAbb: 'ar-SY', localeDominantAbb: 'ar-SA' },
  { label: 'Arabic (Tunisia)', localeAbb: 'ar-TN', localeDominantAbb: 'ar-SA' },
  { label: 'Arabic (United Arab Emirates)', localeAbb: 'ar-AE', localeDominantAbb: 'ar-SA' },
  { label: 'Arabic (Yemen)', localeAbb: 'ar-YE', localeDominantAbb: 'ar-SA' },
  { label: 'Armenian (Armenia)', localeAbb: 'hy-AM', localeDominantAbb: 'hy-AM' },
  { label: 'Azerbaijani (Azerbaijan)', localeAbb: 'az-AZ', localeDominantAbb: 'az-AZ' },
  { label: 'Basque (Spain)', localeAbb: 'eu-ES', localeDominantAbb: 'eu-ES' },
  { label: 'Belarusian (Belarus)', localeAbb: 'be-BY', localeDominantAbb: 'be-BY' },
  { label: 'Bengali (India)', localeAbb: 'bn-IN', localeDominantAbb: 'bn-IN' },
  {
    label: 'Bosnian (Bosnia and Herzegovina)',
    localeAbb: 'bs-BA',
    localeDominantAbb: 'bs-BA',
  },
  { label: 'Bulgarian (Bulgaria)', localeAbb: 'bg-BG', localeDominantAbb: 'bg-BG' },
  { label: 'Catalan (Spain)', localeAbb: 'ca-ES', localeDominantAbb: 'ca-ES' },
  { label: 'Chinese (China)', localeAbb: 'zh-CN', localeDominantAbb: 'zh-CN' },
  { label: 'Chinese (Hong Kong SAR China)', localeAbb: 'zh-HK', localeDominantAbb: 'zh-TW' },
  { label: 'Chinese (Macao SAR China)', localeAbb: 'zh-MO', localeDominantAbb: 'zh-TW' },
  { label: 'Chinese (Singapore)', localeAbb: 'zh-SG', localeDominantAbb: 'zh-CN' },
  { label: 'Chinese (Taiwan)', localeAbb: 'zh-TW', localeDominantAbb: 'zh-TW' },
  { label: 'Croatian (Croatia)', localeAbb: 'hr-HR', localeDominantAbb: 'hr-HR' },
  { label: 'Czech (Czech Republic)', localeAbb: 'cs-CZ', localeDominantAbb: 'cs-CZ' },
  { label: 'Danish (Denmark)', localeAbb: 'da-DK', localeDominantAbb: 'da-DK' },
  { label: 'Dutch (Belgium)', localeAbb: 'nl-BE', localeDominantAbb: 'nl-NL' },
  { label: 'Dutch (Netherlands)', localeAbb: 'nl-NL', localeDominantAbb: 'nl-NL' },
  { label: 'English (Australia)', localeAbb: 'en-AU', localeDominantAbb: 'en-US' },
  { label: 'English (Belize)', localeAbb: 'en-BZ', localeDominantAbb: 'en-US' },
  { label: 'English (Canada)', localeAbb: 'en-CA', localeDominantAbb: 'en-US' },
  { label: 'English (India)', localeAbb: 'en-IN', localeDominantAbb: 'en-US' },
  { label: 'English (Ireland)', localeAbb: 'en-IE', localeDominantAbb: 'en-US' },
  { label: 'English (Jamaica)', localeAbb: 'en-JM', localeDominantAbb: 'en-US' },
  { label: 'English (New Zealand)', localeAbb: 'en-NZ', localeDominantAbb: 'en-US' },
  { label: 'English (Philippines)', localeAbb: 'en-PH', localeDominantAbb: 'en-US' },
  { label: 'English (South Africa)', localeAbb: 'en-ZA', localeDominantAbb: 'en-US' },
  { label: 'English (Trinidad and Tobago)', localeAbb: 'en-TT', localeDominantAbb: 'en-US' },
  { label: 'English (U.S. Virgin Islands)', localeAbb: 'en-VI', localeDominantAbb: 'en-US' },
  { label: 'English (United Kingdom)', localeAbb: 'en-GB', localeDominantAbb: 'en-US' },
  { label: 'English (United States)', localeAbb: 'en-US', localeDominantAbb: 'en-US' },
  { label: 'Estonian (Estonia)', localeAbb: 'et-EE', localeDominantAbb: 'et-EE' },
  { label: 'Faroese (Faroe Islands)', localeAbb: 'fo-FO', localeDominantAbb: 'fo-FO' },
  { label: 'Finnish (Finland)', localeAbb: 'fi-FI', localeDominantAbb: 'fi-FI' },
  { label: 'French (Belgium)', localeAbb: 'fr-BE', localeDominantAbb: 'fr-FR' },
  { label: 'French (Canada)', localeAbb: 'fr-CA', localeDominantAbb: 'fr-FR' },
  { label: 'French (France)', localeAbb: 'fr-FR', localeDominantAbb: 'fr-FR' },
  { label: 'French (Luxembourg)', localeAbb: 'fr-LU', localeDominantAbb: 'fr-FR' },
  { label: 'French (Monaco)', localeAbb: 'fr-MC', localeDominantAbb: 'fr-FR' },
  { label: 'French (Switzerland)', localeAbb: 'fr-CH', localeDominantAbb: 'fr-FR' },
  { label: 'Galician (Spain)', localeAbb: 'gl-ES', localeDominantAbb: 'gl-ES' },
  { label: 'Georgian (Georgia)', localeAbb: 'ka-GE', localeDominantAbb: 'ka-GE' },
  { label: 'German (Austria)', localeAbb: 'de-AT', localeDominantAbb: 'de-DE' },
  { label: 'German (Germany)', localeAbb: 'de-DE', localeDominantAbb: 'de-DE' },
  { label: 'German (Liechtenstein)', localeAbb: 'de-LI', localeDominantAbb: 'de-DE' },
  { label: 'German (Luxembourg)', localeAbb: 'de-LU', localeDominantAbb: 'de-DE' },
  { label: 'German (Switzerland)', localeAbb: 'de-CH', localeDominantAbb: 'de-DE' },
  { label: 'Greek (Greece)', localeAbb: 'el-GR', localeDominantAbb: 'el-GR' },
  { label: 'Gujarati (India)', localeAbb: 'gu-IN', localeDominantAbb: 'gu-IN' },
  { label: 'Hebrew (Israel)', localeAbb: 'he-IL', localeDominantAbb: 'he-IL' },
  { label: 'Hindi (India)', localeAbb: 'hi-IN', localeDominantAbb: 'hi-IN' },
  { label: 'Hungarian (Hungary)', localeAbb: 'hu-HU', localeDominantAbb: 'hu-HU' },
  { label: 'Icelandic (Iceland)', localeAbb: 'is-IS', localeDominantAbb: 'is-IS' },
  { label: 'Indonesian (Indonesia)', localeAbb: 'id-ID', localeDominantAbb: 'id-ID' },
  { label: 'Italian (Italy)', localeAbb: 'it-IT', localeDominantAbb: 'it-IT' },
  { label: 'Italian (Switzerland)', localeAbb: 'it-CH', localeDominantAbb: 'it-IT' },
  { label: 'Japanese (Japan)', localeAbb: 'ja-JP', localeDominantAbb: 'ja-JP' },
  { label: 'Kannada (India)', localeAbb: 'kn-IN', localeDominantAbb: 'kn-IN' },
  { label: 'Kazakh (Kazakhstan)', localeAbb: 'kk-KZ', localeDominantAbb: 'kk-KZ' },
  { label: 'Konkani (India)', localeAbb: 'kok-IN', localeDominantAbb: 'kok-IN' },
  { label: 'Korean (South Korea)', localeAbb: 'ko-KR', localeDominantAbb: 'ko-KR' },
  { label: 'Latvian (Latvia)', localeAbb: 'lv-LV', localeDominantAbb: 'lv-LV' },
  { label: 'Lithuanian (Lithuania)', localeAbb: 'lt-LT', localeDominantAbb: 'lt-LT' },
  { label: 'Macedonian (Macedonia)', localeAbb: 'mk-MK', localeDominantAbb: 'mk-MK' },
  { label: 'Malay (Brunei)', localeAbb: 'ms-BN', localeDominantAbb: 'ms-MY' },
  { label: 'Malay (Malaysia)', localeAbb: 'ms-MY', localeDominantAbb: 'ms-MY' },
  { label: 'Malayalam (India)', localeAbb: 'ml-IN', localeDominantAbb: 'ml-IN' },
  { label: 'Maltese (Malta)', localeAbb: 'mt-MT', localeDominantAbb: 'mt-MT' },
  { label: 'Marathi (India)', localeAbb: 'mr-IN', localeDominantAbb: 'mr-IN' },
  { label: 'Mongolian (Mongolia)', localeAbb: 'mn-MN', localeDominantAbb: 'mn-MN' },
  { label: 'Northern Sami (Norway)', localeAbb: 'se-NO', localeDominantAbb: 'se-NO' },
  { label: 'Norwegian Bokmål (Norway)', localeAbb: 'nb-NO', localeDominantAbb: 'nb-NO' },
  { label: 'Norwegian Nynorsk (Norway)', localeAbb: 'nn-NO', localeDominantAbb: 'nn-NO' },
  { label: 'Persian (Iran)', localeAbb: 'fa-IR', localeDominantAbb: 'fa-IR' },
  { label: 'Polish (Poland)', localeAbb: 'pl-PL', localeDominantAbb: 'pl-PL' },
  { label: 'Portuguese (Brazil)', localeAbb: 'pt-BR', localeDominantAbb: 'pt-BR' },
  { label: 'Portuguese (Portugal)', localeAbb: 'pt-PT', localeDominantAbb: 'pt-BR' },
  { label: 'Punjabi (India)', localeAbb: 'pa-IN', localeDominantAbb: 'pa-IN' },
  { label: 'Romanian (Romania)', localeAbb: 'ro-RO', localeDominantAbb: 'ro-RO' },
  { label: 'Russian (Russia)', localeAbb: 'ru-RU', localeDominantAbb: 'ru-RU' },
  {
    label: 'Serbian (Bosnia and Herzegovina)',
    localeAbb: 'sr-BA',
    localeDominantAbb: 'sr-BA',
  },
  { label: 'Serbian (Serbia And Montenegro)', localeAbb: 'sr-CS', localeDominantAbb: 'sr-BA' },
  { label: 'Slovak (Slovakia)', localeAbb: 'sk-SK', localeDominantAbb: 'sk-SK' },
  { label: 'Slovenian (Slovenia)', localeAbb: 'sl-SI', localeDominantAbb: 'sk-SK' },
  { label: 'Spanish (Argentina)', localeAbb: 'es-AR', localeDominantAbb: 'es-ES' },
  { label: 'Spanish (Bolivia)', localeAbb: 'es-BO', localeDominantAbb: 'es-ES' },
  { label: 'Spanish (Chile)', localeAbb: 'es-CL', localeDominantAbb: 'es-ES' },
  { label: 'Spanish (Colombia)', localeAbb: 'es-CO', localeDominantAbb: 'es-ES' },
  { label: 'Spanish (Costa Rica)', localeAbb: 'es-CR', localeDominantAbb: 'es-ES' },
  { label: 'Spanish (Dominican Republic)', localeAbb: 'es-DO', localeDominantAbb: 'es-ES' },
  { label: 'Spanish (Ecuador)', localeAbb: 'es-EC', localeDominantAbb: 'es-ES' },
  { label: 'Spanish (El Salvador)', localeAbb: 'es-SV', localeDominantAbb: 'es-ES' },
  { label: 'Spanish (Guatemala)', localeAbb: 'es-GT', localeDominantAbb: 'es-ES' },
  { label: 'Spanish (Honduras)', localeAbb: 'es-HN', localeDominantAbb: 'es-ES' },
  { label: 'Spanish (Mexico)', localeAbb: 'es-MX', localeDominantAbb: 'es-ES' },
  { label: 'Spanish (Nicaragua)', localeAbb: 'es-NI', localeDominantAbb: 'es-ES' },
  { label: 'Spanish (Panama)', localeAbb: 'es-PA', localeDominantAbb: 'es-ES' },
  { label: 'Spanish (Paraguay)', localeAbb: 'es-PY', localeDominantAbb: 'es-ES' },
  { label: 'Spanish (Peru)', localeAbb: 'es-PE', localeDominantAbb: 'es-ES' },
  { label: 'Spanish (Puerto Rico)', localeAbb: 'es-PR', localeDominantAbb: 'es-ES' },
  { label: 'Spanish (Spain)', localeAbb: 'es-ES', localeDominantAbb: 'es-ES' },
  { label: 'Spanish (Uruguay)', localeAbb: 'es-UY', localeDominantAbb: 'es-ES' },
  { label: 'Spanish (Venezuela)', localeAbb: 'es-VE', localeDominantAbb: 'es-ES' },
  { label: 'Swahili (Kenya)', localeAbb: 'sw-KE', localeDominantAbb: 'sw-KE' },
  { label: 'Swedish (Finland)', localeAbb: 'sv-FI', localeDominantAbb: 'sv-SE' },
  { label: 'Swedish (Sweden)', localeAbb: 'sv-SE', localeDominantAbb: 'sv-SE' },
  { label: 'Syriac (Syria)', localeAbb: 'syr-SY', localeDominantAbb: 'syr-SY' },
  { label: 'Tamil (India)', localeAbb: 'ta-IN', localeDominantAbb: 'ta-IN' },
  { label: 'Telugu (India)', localeAbb: 'te-IN', localeDominantAbb: 'te-IN' },
  { label: 'Thai (Thailand)', localeAbb: 'th-TH', localeDominantAbb: 'th-TH' },
  { label: 'Tswana (South Africa)', localeAbb: 'tn-ZA', localeDominantAbb: 'tn-ZA' },
  { label: 'Turkish (Turkey)', localeAbb: 'tr-TR', localeDominantAbb: 'tr-TR' },
  { label: 'Ukrainian (Ukraine)', localeAbb: 'uk-UA', localeDominantAbb: 'uk-UA' },
  { label: 'Urdu (Pakistan)', localeAbb: 'ur-PK', localeDominantAbb: 'ur-PK' },
  { label: 'Uzbek (Uzbekistan)', localeAbb: 'uz-UZ', localeDominantAbb: 'uz-UZ' },
  { label: 'Vietnamese (Vietnam)', localeAbb: 'vi-VN', localeDominantAbb: 'vi-VN' },
  { label: 'Welsh (United Kingdom)', localeAbb: 'cy-GB', localeDominantAbb: 'cy-GB' },
  { label: 'Xhosa (South Africa)', localeAbb: 'xh-ZA', localeDominantAbb: 'xh-ZA' },
  { label: 'Zulu (South Africa)', localeAbb: 'zu-ZA', localeDominantAbb: 'zu-ZA' },
];

export default localesList;
