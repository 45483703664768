// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { DocAPI } from 'api/endpoints';
// Interfaces and types
import { IErrorRes, TQueryParams } from 'types/appTypes';
import {
  IGetDocsRes,
  ICreateDocBody,
  ICreateDocRes,
  IDeleteDocRes,
  IGetEmployeeDocDetailsRes,
  IGetEmployeeDocsRes,
  TUpdateDocBody,
  IUpdateDocRes,
  IGetEmployeeDocsBody,
  IDocParams,
} from 'types/docsType';

const getDocsAsync = createAsyncThunk<IGetDocsRes, TQueryParams, { rejectValue: IErrorRes }>(
  'doc/getDocs',
  async (params: TQueryParams, { rejectWithValue }) => {
    try {
      return await DocAPI.getDocs(params);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const createDocAsync = createAsyncThunk<
  ICreateDocRes,
  ICreateDocBody,
  { rejectValue: IErrorRes }
>('doc/createDoc', async (cred: ICreateDocBody, { rejectWithValue }) => {
  try {
    return await DocAPI.createDoc(cred);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getEmployeeDocsAsync = createAsyncThunk<
  IGetEmployeeDocsRes,
  IGetEmployeeDocsBody,
  { rejectValue: IErrorRes }
>('doc/getEmployeeDocs', async (cred: IGetEmployeeDocsBody, { rejectWithValue }) => {
  try {
    return await DocAPI.getEmployeeDocs(cred);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getEmployeeDocDetailsAsync = createAsyncThunk<
  IGetEmployeeDocDetailsRes,
  IDocParams,
  { rejectValue: IErrorRes }
>('doc/getDocDetails', async (params: IDocParams, { rejectWithValue }) => {
  try {
    return await DocAPI.getEmployeeDocDetails(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const updateDocAsync = createAsyncThunk<
  IUpdateDocRes,
  TUpdateDocBody,
  { rejectValue: IErrorRes }
>('doc/updateDoc', async (cred: TUpdateDocBody, { rejectWithValue }) => {
  try {
    return await DocAPI.updateDoc(cred);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const deleteDocAsync = createAsyncThunk<IDeleteDocRes, IDocParams, { rejectValue: IErrorRes }>(
  'doc/deleteDoc',
  async (params: IDocParams, { rejectWithValue }) => {
    try {
      return await DocAPI.deleteDoc(params);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

export {
  getDocsAsync,
  createDocAsync,
  getEmployeeDocDetailsAsync,
  getEmployeeDocsAsync,
  updateDocAsync,
  deleteDocAsync,
};
