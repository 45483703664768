// ROUTES
import routes, { ROUTES } from 'router/routes';

const getPageLabel = (pagePath: string, subPagePath?: string) => {
  return routes
    ?.filter(({ path }) => path === pagePath)[0]
    ?.routes?.filter(({ path: subPath }) => subPath === subPagePath)[0]?.label;
};

const useGetLabelsOfPagesThatShouldHaveActiveStatusFilter = () => {
  const labelsOfPagesThatShouldHaveActiveStatusFilter = [
    getPageLabel(ROUTES.COMPANIES, ROUTES.COMPANIES_LIST),
    getPageLabel(ROUTES.EMPLOYEES, ROUTES.EMPLOYEES_LIST),
    getPageLabel(ROUTES.INVOICES, ROUTES.INVOICES_RECURRING),
    getPageLabel(ROUTES.INVOICES, ROUTES.INVOICES_AGING),
  ];

  return { labelsOfPagesThatShouldHaveActiveStatusFilter };
};

export default useGetLabelsOfPagesThatShouldHaveActiveStatusFilter;
