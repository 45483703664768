// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import { getPaymentsAsync } from './paymentsThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import { IGetPaymentsRes } from 'types/paymentsTypes';
import { IErrorRes } from 'types/appTypes';

interface ISlicePaymentsState {
  payments: IGetPaymentsRes | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: ISlicePaymentsState = {
  payments: null,
  error: null,
  isLoading: false,
};

const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    //* GET PAYMENTS ASYNC THUNK
    builder.addCase(getPaymentsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getPaymentsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.payments = payload;
    });
    builder.addCase(getPaymentsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export default paymentsSlice;
