// API
import { ENDPOINTS } from '../api.config';
// Modules
import xhr from 'modules/xhr';
// Interfaces and types
import { TQueryParams } from 'types/appTypes';
import { IGetActivitiesRes } from 'types/activityTypes';

export const getActivities = (params: TQueryParams) => {
  return xhr.get<IGetActivitiesRes, IGetActivitiesRes>(`${ENDPOINTS.activities}`, {
    params,
  });
};
