// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import {
  getEquipmentItemsAsync,
  getEquipmentDetailsAsync,
  createEquipmentAsync,
  deleteEquipmentAsync,
  updateEquipmentAsync,
} from './equipmentThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import { IGetEquipmentItemsRes, IEquipmentItemDetailsRes } from 'types/equipmentTypes';
import { IErrorRes } from 'types/appTypes';

interface ISliceEquipmentItemsState {
  equipmentItems: IGetEquipmentItemsRes | null;
  equipmentDetails: IEquipmentItemDetailsRes | null;
  error: IErrorRes | null;
  isLoading: boolean;
  shouldTypeFilterUpdate: boolean;
}

const initialState: ISliceEquipmentItemsState = {
  equipmentItems: null,
  equipmentDetails: null,
  error: null,
  isLoading: false,
  shouldTypeFilterUpdate: false,
};

const equipmentSlice = createSlice({
  name: 'equipment',
  initialState,
  reducers: {
    setShouldTypeFilterUpdate: (state, { payload }) => {
      state.shouldTypeFilterUpdate = payload;
    },
    clearErrors: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    //* GET EQUIPMENT ASYNC THUNK
    builder.addCase(getEquipmentItemsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getEquipmentItemsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.equipmentItems = payload;
    });
    builder.addCase(getEquipmentItemsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* GET EQUIPMENT DETAILS ASYNC THUNK
    builder.addCase(getEquipmentDetailsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getEquipmentDetailsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.equipmentDetails = payload;
    });
    builder.addCase(getEquipmentDetailsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CREATE EQUIPMENT ASYNC THUNK
    builder.addCase(createEquipmentAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(createEquipmentAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(createEquipmentAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* UPDATE EQUIPMENT (one) ASYNC THUNK
    builder.addCase(updateEquipmentAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(updateEquipmentAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(updateEquipmentAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* DELETE EQUIPMENT ASYNC THUNK
    builder.addCase(deleteEquipmentAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(deleteEquipmentAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteEquipmentAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export default equipmentSlice;
export const { setShouldTypeFilterUpdate } = equipmentSlice.actions;
