// Core
import { useState, FocusEvent, ChangeEvent } from 'react';
// Custom Hooks
import { useMapWithGeocoder } from 'customHooks';
// Interfaces and types
import { TFormInstance } from 'components/lib/Form';

interface IChangeAddress {
  (param: string): void;
  (param: FocusEvent<HTMLInputElement>): void;
  (param: ChangeEvent<HTMLInputElement>): void;
}
export type IFieldName = 'postalCode' | 'address' | 'city' | 'state' | 'country';

const defineAddressValue = (
  param: string | FocusEvent<HTMLInputElement> | ChangeEvent<HTMLInputElement>,
) => {
  let value = param;

  if (typeof param !== 'string') {
    value = param?.target?.value;
  }

  if (![value as string].length) {
    value = '';
  }

  return value;
};

const useAddressForm = (formInstance: TFormInstance, formField?: string) => {
  const { setFieldsValue, getFieldsValue } = formInstance;

  const [userAddress, setUserAddress] = useState<{ [key: string]: string }>({});

  const { markerPosition, getAddressFromCoord, getCoordFromAddress, countryRestriction } =
    useMapWithGeocoder({ setFieldsValue, setUserAddress });

  // Get all fields from form (arg === true)
  const values = getFieldsValue(true);

  // It is necessary because of different structure and name of location fields on diff pages
  // (name = contactInfo.country or name ="country" )
  const getAddressFromFormField = (val: string, field?: string) => {
    return field ? values?.[field]?.[val] : values?.[val];
  };

  const setCoordinates = ({
    address = getAddressFromFormField('address1', formField) || userAddress?.address1 || '',
    state = getAddressFromFormField('state', formField) || userAddress?.state || '',
    city = getAddressFromFormField('city', formField) || userAddress?.city || '',
    country = getAddressFromFormField('country', formField) || userAddress?.country || '',
    postalCode = getAddressFromFormField('postalCode', formField) ||
      userAddress?.postalCode ||
      '',
    handlerFieldName = null,
  }: any) => {
    if (address && !postalCode && !state && !city) {
      return getCoordFromAddress({ address, formField, handlerFieldName });
    }

    if (address || state || city || country || postalCode) {
      getCoordFromAddress({
        address,
        state,
        city,
        country,
        postalCode,
        formField,
        handlerFieldName,
      });
    }

    setUserAddress(prevState => ({
      ...prevState,
      address1: address,
      state,
      city,
      country,
      postalCode,
    }));
  };

  const changeFirstAddress: IChangeAddress = param => {
    const value = defineAddressValue(param);

    if (value === userAddress.address1) return;

    setFieldsValue(
      formField
        ? {
            [formField]: {
              address1: value,
              state: '',
              city: '',
              postalCode: '',
            },
          }
        : { address1: value, state: '', city: '', postalCode: '' },
    );

    setUserAddress(prevState => ({
      ...prevState,
      address1: value as string,
      state: '',
      city: '',
      postalCode: '',
    }));

    setCoordinates({
      address: value,
      state: '',
      city: '',
      postalCode: '',
      handlerFieldName: 'address',
    });
  };

  const changeState: IChangeAddress = param => {
    const value = defineAddressValue(param);
    if (value === userAddress.state) return;

    setFieldsValue(formField ? { [formField]: { state: value } } : { state: value });
    setUserAddress(prevState => ({
      ...prevState,
      state: value as string,
    }));
    setCoordinates({ state: value, handlerFieldName: 'state' });
  };

  const changeCity: IChangeAddress = param => {
    const value = defineAddressValue(param);
    if (value === userAddress.city) return;

    setFieldsValue(formField ? { [formField]: { city: value } } : { city: value });
    setUserAddress(prevState => ({
      ...prevState,
      city: value as string,
    }));
    setCoordinates({ city: value, handlerFieldName: 'city' });
  };

  const changeCountry: IChangeAddress = param => {
    const value = defineAddressValue(param);
    if (value === userAddress.country) return;

    setFieldsValue(
      formField
        ? {
            [formField]: {
              country: value,
              state: '',
              city: '',
              postalCode: '',
              address1: '',
            },
          }
        : { country: value, state: '', city: '', postalCode: '', address1: '' },
    );

    setUserAddress(prevState => ({
      ...prevState,
      country: value as string,
      state: '',
      city: '',
      postalCode: '',
      address1: '',
    }));

    setCoordinates({
      country: value,
      state: '',
      city: '',
      postalCode: '',
      address: '',
      handlerFieldName: 'country',
    });
  };

  const changePostalCode: IChangeAddress = param => {
    const value = defineAddressValue(param);

    if (value === userAddress.postalCode) return;

    setFieldsValue(formField ? { [formField]: { postalCode: value } } : { postalCode: value });
    setUserAddress(prevState => ({ ...prevState, postalCode: value as string }));
    setCoordinates({ postalCode: value, handlerFieldName: 'postalCode' });
  };

  return {
    changeState,
    changeCity,
    changeCountry,
    changeFirstAddress,
    changePostalCode,
    getAddressFromCoord,
    setCoordinates,
    markerPosition,
    countryRestriction,
  };
};

export default useAddressForm;
