// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import {
  getInvoicesAsync,
  createInvoiceAsync,
  getInvoiceTableLinesAsync,
  getCalculationInvoiceAsync,
  getInvoiceDetailsAsync,
  generateInvoiceAsync,
  sendEmailsAsync,
  updateInvoiceAsync,
  deleteInvoiceAsync,
  getPaymentsAsync,
  updatePaymentsAsync,
  updatePaymentStatusAsync,
  getEmailSentStatusAsync,
} from './invoicesThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import {
  IInvoiceLine,
  IGetInvoiceDetailsRes,
  IGetInvoicesRes,
  IInvoiceCustomComponentTypes,
  IGetPaymentsRes,
  EInvoiceSentStatus,
  EInvoicePaymentStatus,
} from 'types/invoiceTypes';
import { IErrorRes } from 'types/appTypes';

interface ISliceInvoiceState {
  shouldAllLinesExceptCopyBeDeleted: boolean;
  invoices: IGetInvoicesRes | null;
  invoiceId: number | null;
  invoiceDetails: IGetInvoiceDetailsRes | null;
  invoiceTableLines: IInvoiceLine[];
  total?: number;
  hoursSubtotal?: number;
  subtotal?: number;
  adjustmentSubtotal1?: number;
  adjustmentSubtotal2?: number;
  customFirstFieldType: IInvoiceCustomComponentTypes;
  customSecondFieldType: IInvoiceCustomComponentTypes;
  sentStatus: null | EInvoiceSentStatus;
  payments: IGetPaymentsRes | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: ISliceInvoiceState = {
  shouldAllLinesExceptCopyBeDeleted: false,
  invoices: null,
  invoiceDetails: null,
  invoiceId: null,
  customFirstFieldType: IInvoiceCustomComponentTypes.PERCENTAGE,
  customSecondFieldType: IInvoiceCustomComponentTypes.PERCENTAGE,
  invoiceTableLines: [],
  hoursSubtotal: 0,
  total: 0,
  subtotal: 0,
  adjustmentSubtotal1: 0,
  adjustmentSubtotal2: 0,
  sentStatus: null,
  payments: null,
  error: null,
  isLoading: false,
};

const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    setInvoiceId: (state, { payload }) => {
      state.invoiceId = payload;
    },
    changeInvoiceTableLines: (state, { payload }) => {
      state.invoiceTableLines = payload;
    },
    changeCustomFirstFieldType: (state, { payload }) => {
      state.customFirstFieldType = payload;
    },
    changeCustomSecondFieldType: (state, { payload }) => {
      state.customSecondFieldType = payload;
    },
    setShouldAllLinesExceptCopyBeDeletedFlag: (state, { payload }) => {
      state.shouldAllLinesExceptCopyBeDeleted = payload;
    },
    clearTotals: state => {
      state.hoursSubtotal = undefined;
      state.subtotal = undefined;
      state.total = undefined;
      state.adjustmentSubtotal1 = undefined;
      state.adjustmentSubtotal2 = undefined;
    },
    changeAdjustmentAmount1: state => {
      state.total = undefined;
      state.adjustmentSubtotal1 = undefined;
    },
    changeAdjustmentAmount2: state => {
      state.total = undefined;
      state.adjustmentSubtotal2 = undefined;
    },
    changeAdjustmentAmount3: state => {
      state.total = undefined;
    },
    clearModalData: () => {
      return initialState;
    },
    clearErrors: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    //* GET INVOICES ASYNC THUNK
    builder.addCase(getInvoicesAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getInvoicesAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.invoices = payload;
    });
    builder.addCase(getInvoicesAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* GET INVOICE TABLE LINES ASYNC THUNK
    builder.addCase(getInvoiceTableLinesAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getInvoiceTableLinesAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.invoiceTableLines = payload.invoiceLines;
      state.adjustmentSubtotal1 = payload.adjustmentSubtotal1;
      state.adjustmentSubtotal2 = payload.adjustmentSubtotal2;
      state.hoursSubtotal = payload.hoursSubtotal;
      state.subtotal = payload.subtotal;
      state.total = payload.total;
    });
    builder.addCase(getInvoiceTableLinesAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });
    //* CALCULATE INVOICE ASYNC THUNK
    builder.addCase(getCalculationInvoiceAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getCalculationInvoiceAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.invoiceTableLines = payload.invoiceLines;
      state.hoursSubtotal = payload.hoursSubtotal;
      state.subtotal = payload.subtotal;
      state.total = payload.total;
      state.adjustmentSubtotal1 = payload.adjustmentSubtotal1;
      state.adjustmentSubtotal2 = payload.adjustmentSubtotal2;
    });
    builder.addCase(getCalculationInvoiceAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CREATE INVOICE ASYNC THUNK
    builder.addCase(createInvoiceAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(createInvoiceAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.invoiceId = payload.id;
    });
    builder.addCase(createInvoiceAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* GET INVOICES DETAILS ASYNC THUNK
    builder.addCase(getInvoiceDetailsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getInvoiceDetailsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.invoiceDetails = payload;
      state.invoiceId = payload.id;
      state.hoursSubtotal = payload.hoursSubtotal;
      state.subtotal = payload.subtotal;
      state.total = payload.total;
      state.adjustmentSubtotal1 = payload.adjustmentSubtotal1;
      state.adjustmentSubtotal2 = payload.adjustmentSubtotal2;
    });
    builder.addCase(getInvoiceDetailsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* GENERATE INVOICE ASYNC THUNK
    builder.addCase(generateInvoiceAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(generateInvoiceAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.invoiceDetails = payload;
      state.invoiceId = payload.id;
      state.hoursSubtotal = payload.hoursSubtotal;
      state.subtotal = payload.subtotal;
      state.total = payload.total;
      state.adjustmentSubtotal1 = payload.adjustmentSubtotal1;
      state.adjustmentSubtotal2 = payload.adjustmentSubtotal2;
    });
    builder.addCase(generateInvoiceAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* UPDATE INVOICE(one) ASYNC THUNK
    builder.addCase(updateInvoiceAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(updateInvoiceAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(updateInvoiceAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* DELETE INVOICES ASYNC THUNK
    builder.addCase(deleteInvoiceAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(deleteInvoiceAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteInvoiceAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* SEND EMAILS ASYNC THUNK
    builder.addCase(sendEmailsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(sendEmailsAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(sendEmailsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* GET INVOICE PAYMENTS ASYNC THUNK
    builder.addCase(getPaymentsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getPaymentsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.payments = payload;
    });
    builder.addCase(getPaymentsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* UPDATE PAYMENTS ASYNC THUNK
    builder.addCase(updatePaymentsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(updatePaymentsAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(updatePaymentsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* UPDATE PAYMENT STATUS ASYNC THUNK
    builder.addCase(updatePaymentStatusAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(updatePaymentStatusAsync.fulfilled, (state, action) => {
      state.isLoading = false;
      if (state.payments?.paymentStatus) {
        state.payments.paymentStatus = action.meta.arg.body
          .paymentStatus as EInvoicePaymentStatus;
      }
    });
    builder.addCase(updatePaymentStatusAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* UPDATE SENT STATUS ASYNC THUNK
    builder.addCase(getEmailSentStatusAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
      state.sentStatus = null;
    });
    builder.addCase(getEmailSentStatusAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.sentStatus = payload.sentStatus as EInvoiceSentStatus;
    });
    builder.addCase(getEmailSentStatusAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });
    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export const {
  setShouldAllLinesExceptCopyBeDeletedFlag,
  changeInvoiceTableLines,
  changeCustomFirstFieldType,
  changeCustomSecondFieldType,
  setInvoiceId,
  changeAdjustmentAmount1,
  changeAdjustmentAmount2,
  changeAdjustmentAmount3,
  clearTotals,
  clearErrors,
  clearModalData,
} = invoiceSlice.actions;

export default invoiceSlice;
