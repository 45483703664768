// Core
import { CSSProperties, ReactNode } from 'react';
// Packages
import { List as AntList } from 'antd';

interface IListItemProps {
  actions?: Array<ReactNode>;
  extra?: ReactNode;
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
}

/**
 * Simple List Item.
 *
 * @param actions - The actions content of list item. If itemLayout is vertical, shows the content on bottom, otherwise shows content on the far right
 * @param extra - The extra content of list item. If itemLayout is vertical, shows the content on right, otherwise shows content on the far right
 * @param className - Component className
 * @param style - To customize the styles
 * @param children - Some ReactNode
 */

const ListItem = ({ actions, extra, className, style, children }: IListItemProps) => (
  <AntList.Item actions={actions} extra={extra} className={className} style={style}>
    {children}
  </AntList.Item>
);

export default ListItem;
