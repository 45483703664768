// Core
import { CSSProperties, ReactNode } from 'react';
// Packages
import { AiOutlineCalendar } from 'react-icons/ai';
import { Moment } from 'moment';
import { DatePicker as AntDatePicker } from 'antd';
import { PickerLocale } from 'antd/lib/date-picker/generatePicker';
import { DisabledTimes, PanelMode } from 'rc-picker/lib/interface.d';
// Constants
import { DATE_FORMAT } from 'helpers/data/constants';
// Styles
import styles from './dateRangePicker.module.scss';

export type EventValue<DateType = Moment> = DateType | null;
export type RangeValue<DateType = Moment> =
  | [EventValue<DateType>, EventValue<DateType>]
  | null;
type RangeType = 'start' | 'end';
export type RangeInfo = { range: RangeType };

interface IDateRangePickerProps {
  allowClear?: boolean;
  allowEmpty?: [boolean, boolean];
  autoFocus?: boolean;
  bordered?: boolean;
  className?: string;
  dateRender?: (currentDate: Moment, today: Moment, info: RangeInfo) => ReactNode;
  defaultPickerValue?: [Moment, Moment];
  defaultValue?: [Moment, Moment];
  disabled?: [boolean, boolean];
  disabledDate?: (currentDate: Moment) => boolean;
  disabledTime?: (date: EventValue<Moment>, type: RangeType) => DisabledTimes;
  dropdownClassName?: string;
  format?: string | string[];
  getPopupContainer?: (node: HTMLElement) => HTMLElement;
  inputReadOnly?: boolean;
  locale?: PickerLocale;
  mode?: [PanelMode, PanelMode];
  onCalendarChange?: (
    values: RangeValue<Moment>,
    formatString: [string, string],
    info: RangeInfo,
  ) => void;
  onChange?: (values: RangeValue<Moment>, formatString: [string, string]) => void;
  onOpenChange?: (open: boolean) => void;
  onPanelChange?: (values: RangeValue<Moment>, modes: [PanelMode, PanelMode]) => void;
  open?: boolean;
  panelRender?: (originPanel: ReactNode) => ReactNode;
  picker?: 'date' | 'week' | 'month' | 'quarter' | 'year';
  placeholder?: [string, string];
  popupStyle?: CSSProperties;
  ranges?: Record<
    string,
    Exclude<RangeValue<Moment>, null> | (() => Exclude<RangeValue<Moment>, null>)
  >;
  renderExtraFooter?: () => ReactNode;
  separator?: ReactNode;
  size?: 'large' | 'middle' | 'small';
  style?: CSSProperties;
  suffixIcon?: ReactNode;
  showTime?: object | boolean;
  value?: [Moment, Moment];
}

/**
 * To select or input a date.
 *
 * @description By clicking the input box, you can select a date from a popup calendar.
 *
 * @param allowClear - Whether to show clear button
 * @param allowEmpty - Allow start or end input leave empty
 * @param autoFocus - If get focus when component mounted
 * @param bordered - Whether has border style
 * @param className - The picker className
 * @param dateRender - Customize date cell. info argument is added in 4.3.0
 * @param defaultPickerValue - To set default picker date
 * @param defaultValue - To set default date
 * @param disabled - If disable start or end
 * @param disabledDate - Specify the date that cannot be selected
 * @param disabledTime - To specify the time that cannot be selected
 * @param dropdownClassName - To customize the className of the popup calendar
 * @param format - To set the date format, refer to moment.js. When an array is provided, all values are used for parsing and first value is used for formatting
 * @param getPopupContainer - To set the container of the floating layer, while the default is to create a div element in body
 * @param inputReadOnly - Set the readonly attribute of the input tag (avoids virtual keyboard on touch devices)
 * @param locale - Localization configuration
 * @param mode - The picker panel mode( Cannot select year or month anymore? )
 * @param onCalendarChange - Callback function, can be executed when the start time or the end time of the range is changing. info argument is added in 4.4.0
 * @param onChange - Callback function, can be executed when the selected time is changing
 * @param onOpenChange - Callback function, can be executed whether the popup calendar is popped up or closed
 * @param onPanelChange - Callback when picker panel mode is changed
 * @param open - The open state of picker
 * @param panelRender - Customize panel render
 * @param picker - Set picker type
 * @param placeholder - The placeholder of date input
 * @param popupStyle - To customize the style of the popup calendar
 * @param ranges - The preseted ranges for quick selection
 * @param renderExtraFooter - Render extra footer in panel
 * @param separator - Set separator between inputs
 * @param size - To determine the size of the input box, the height of large and small, are 40px and 24px respectively, while default size is 32px
 * @param style - To customize the style of the input box
 * @param suffixIcon - The custom suffix icon
 * @param showTime - Show time in range
 * @param value - To set date
 */

const DateRangePicker = ({
  allowClear = false,
  allowEmpty = [false, false],
  autoFocus = false,
  bordered = true,
  className,
  dateRender,
  defaultPickerValue,
  defaultValue,
  disabled = [false, false],
  disabledDate,
  disabledTime,
  dropdownClassName,
  format = DATE_FORMAT,
  getPopupContainer,
  inputReadOnly = false,
  locale,
  mode,
  onCalendarChange,
  onChange,
  onOpenChange,
  onPanelChange,
  open,
  panelRender,
  picker = 'date',
  placeholder = ['', ''],
  popupStyle = {},
  ranges,
  renderExtraFooter,
  separator,
  size = 'middle',
  style,
  suffixIcon = <AiOutlineCalendar size={16} />,
  showTime = false,
  value,
}: IDateRangePickerProps) => (
  <AntDatePicker.RangePicker
    allowClear={allowClear}
    allowEmpty={allowEmpty}
    autoFocus={autoFocus}
    bordered={bordered}
    className={`${styles.dateRangePicker} ${className} `}
    dateRender={dateRender}
    defaultPickerValue={defaultPickerValue}
    defaultValue={defaultValue}
    disabled={disabled}
    disabledDate={disabledDate}
    disabledTime={disabledTime}
    dropdownClassName={dropdownClassName}
    format={format}
    getPopupContainer={getPopupContainer}
    inputReadOnly={inputReadOnly}
    locale={locale}
    mode={mode}
    onCalendarChange={onCalendarChange}
    onChange={onChange}
    onOpenChange={onOpenChange}
    onPanelChange={onPanelChange}
    open={open}
    panelRender={panelRender}
    picker={picker}
    placeholder={placeholder}
    popupStyle={popupStyle}
    ranges={ranges}
    renderExtraFooter={renderExtraFooter}
    separator={separator}
    size={size}
    style={style}
    suffixIcon={suffixIcon}
    showTime={showTime}
    value={value}
  />
);

export default DateRangePicker;
