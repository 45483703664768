// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Interfaces and types
import {
  IGetContactsRes,
  ICreateContactBodyWithParam,
  ICreateContactRes,
  IUpdateContactBodyWithParam,
  IUpdateContactRes,
  IGetContactParams,
  IGetContactRes,
  IDeleteContactParams,
  IDeleteContactRes,
  IGetContactsByAccountNameParams,
} from 'types/contactTypes';
import { IGetCompaniesRes } from 'types/companyTypes';
import { TQueryParams } from 'types/appTypes';

export const getContacts = (params: TQueryParams) => {
  return xhr.get<IGetContactsRes, IGetContactsRes>(`${ENDPOINTS.contacts}`, {
    params,
  });
};

export const getContactsByAccountName = ({
  accountName,
  params,
}: IGetContactsByAccountNameParams) => {
  return xhr.get<IGetContactsRes, IGetContactsRes>(
    `${ENDPOINTS.companies}/${accountName}${ENDPOINTS.contacts}`,
    { params },
  );
};

export const createContact = ({ accountName, body }: ICreateContactBodyWithParam) => {
  return xhr.post<ICreateContactRes, ICreateContactRes>(
    `${ENDPOINTS.companies}/${accountName}${ENDPOINTS.contacts}`,
    body,
  );
};

export const getContact = ({ accountName, contactId }: IGetContactParams) => {
  return xhr.get<IGetContactRes, IGetContactRes>(
    `${ENDPOINTS.companies}/${accountName}${ENDPOINTS.contacts}/${contactId}`,
  );
};

export const updateContact = ({
  accountName,
  contactId,
  body,
}: IUpdateContactBodyWithParam) => {
  return xhr.put<IUpdateContactRes, IUpdateContactRes>(
    `${ENDPOINTS.companies}/${accountName}${ENDPOINTS.contacts}/${contactId}`,
    body,
  );
};

export const deleteContact = ({ accountName, contactId }: IDeleteContactParams) => {
  return xhr.delete<IDeleteContactRes, IDeleteContactRes>(
    `${ENDPOINTS.companies}/${accountName}${ENDPOINTS.contacts}/${contactId}`,
  );
};

export const getCompaniesForContactsSelect = () => {
  return xhr.get<IGetCompaniesRes, IGetCompaniesRes>(
    `${ENDPOINTS.companies}?columns=id&columns=accountName&columns=name&sortColumn=name&sortOrder=ASC`,
  );
};
