// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { PerformanceLogAPI } from 'api/endpoints';
// Interfaces and types
import {
  ICreatePerfLogsBody,
  ICreatePerfLogsRes,
  IDeletePerfLogsRes,
  IGetEmployeePerfLogDetailsRes,
  IGetEmployeePerfLogsBody,
  IGetEmployeePerfLogsRes,
  IGetPerfLogsRes,
  IPerfLogParams,
  IUpdatePerfLogParams,
  IUpdatePerfLogRes,
} from 'types/performanceLog';
import { IErrorRes, TQueryParams } from 'types/appTypes';

const getPerfLogsAsync = createAsyncThunk<
  IGetPerfLogsRes,
  TQueryParams,
  { rejectValue: IErrorRes }
>('logs/getPerfLogs', async (params: TQueryParams, { rejectWithValue }) => {
  try {
    return await PerformanceLogAPI.getPerfLogs(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const createPerfLogAsync = createAsyncThunk<
  ICreatePerfLogsRes,
  ICreatePerfLogsBody,
  { rejectValue: IErrorRes }
>('logs/createPerfLog', async (cred: ICreatePerfLogsBody, { rejectWithValue }) => {
  try {
    return await PerformanceLogAPI.createPerfLog(cred);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getEmployeePerfLogsAsync = createAsyncThunk<
  IGetEmployeePerfLogsRes,
  IGetEmployeePerfLogsBody,
  { rejectValue: IErrorRes }
>('logs/getEmployeePerfLogs', async (cred: IGetEmployeePerfLogsBody, { rejectWithValue }) => {
  try {
    return await PerformanceLogAPI.getEmployeePerfLogs(cred);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getEmployeePerfLogDetailsAsync = createAsyncThunk<
  IGetEmployeePerfLogDetailsRes,
  IPerfLogParams,
  { rejectValue: IErrorRes }
>('logs/getEmployeePerfLogDetails', async (params: IPerfLogParams, { rejectWithValue }) => {
  try {
    return await PerformanceLogAPI.getEmployeePerfLogDetails(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const updatePerfLogAsync = createAsyncThunk<
  IUpdatePerfLogRes,
  IUpdatePerfLogParams,
  { rejectValue: IErrorRes }
>('logs/updatePerfLog', async (cred: IUpdatePerfLogParams, { rejectWithValue }) => {
  try {
    return await PerformanceLogAPI.updatePerfLog(cred);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const deletePerfLogAsync = createAsyncThunk<
  IDeletePerfLogsRes,
  IPerfLogParams,
  { rejectValue: IErrorRes }
>('logs/deletePerfLog', async (params: IPerfLogParams, { rejectWithValue }) => {
  try {
    return await PerformanceLogAPI.deletePerfLog(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

export {
  getPerfLogsAsync,
  createPerfLogAsync,
  getEmployeePerfLogsAsync,
  getEmployeePerfLogDetailsAsync,
  updatePerfLogAsync,
  deletePerfLogAsync,
};
