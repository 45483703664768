// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { TodoAPI } from 'api/endpoints';
// Interfaces and types
import {
  IGetTodoRes,
  ICreateTodoBody,
  ICreateTodoRes,
  IGetEmployeeTodoBody,
  IGetEmployeeTodoRes,
  IGetTodoDetailsRes,
  IUpdateTodoRes,
  IDeleteTodoRes,
  ITodoParams,
  IUpdateTodoParams,
  IShiftTodoParams,
  IUpdateTasksParams,
} from 'types/todoTypes';
import { IErrorRes, TQueryParams } from 'types/appTypes';

const getTodoAsync = createAsyncThunk<IGetTodoRes, TQueryParams, { rejectValue: IErrorRes }>(
  'todo/getTodo',
  async (params: TQueryParams, { rejectWithValue }) => {
    try {
      return await TodoAPI.getTodo(params);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const createTodoAsync = createAsyncThunk<
  ICreateTodoRes,
  ICreateTodoBody,
  { rejectValue: IErrorRes }
>('todo/createTodo', async (cred: ICreateTodoBody, { rejectWithValue }) => {
  try {
    return await TodoAPI.createTodo(cred);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getEmployeeTodoAsync = createAsyncThunk<
  IGetEmployeeTodoRes,
  IGetEmployeeTodoBody,
  { rejectValue: IErrorRes }
>('todo/getEmployeeTodo', async (cred: IGetEmployeeTodoBody, { rejectWithValue }) => {
  try {
    return await TodoAPI.getEmployeeTodo(cred);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getTodoDetailsAsync = createAsyncThunk<
  IGetTodoDetailsRes,
  number,
  { rejectValue: IErrorRes }
>('todo/getTodoDetails', async (id: number, { rejectWithValue }) => {
  try {
    return await TodoAPI.getTodoDetails(id);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const updateTodoAsync = createAsyncThunk<
  IUpdateTodoRes,
  IUpdateTodoParams,
  { rejectValue: IErrorRes }
>('todo/updateTodo', async (cred: IUpdateTodoParams, { rejectWithValue }) => {
  try {
    return await TodoAPI.updateTodo(cred);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const deleteTodoAsync = createAsyncThunk<
  IDeleteTodoRes,
  ITodoParams,
  { rejectValue: IErrorRes }
>('todo/deleteTodo', async (params: ITodoParams, { rejectWithValue }) => {
  try {
    return await TodoAPI.deleteTodo(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const deleteShiftTodoAsync = createAsyncThunk<
  IDeleteTodoRes,
  IShiftTodoParams,
  { rejectValue: IErrorRes }
>('todo/deleteShiftTodo', async (params: IShiftTodoParams, { rejectWithValue }) => {
  try {
    return await TodoAPI.deleteShiftTodo(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const updateJobTodoTasksStatusAsync = createAsyncThunk<
  IUpdateTodoRes,
  IUpdateTasksParams,
  { rejectValue: IErrorRes }
>('todo/updateJobTodoTasksStatus', async (cred: IUpdateTasksParams, { rejectWithValue }) => {
  try {
    return await TodoAPI.updateTasksStatus(cred);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

export {
  getTodoAsync,
  createTodoAsync,
  getEmployeeTodoAsync,
  getTodoDetailsAsync,
  updateTodoAsync,
  deleteTodoAsync,
  deleteShiftTodoAsync,
  updateJobTodoTasksStatusAsync,
};
