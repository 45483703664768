// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import { getActionLogsItemsAsync, restoreActionAsync } from './ActionLogsThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import { IErrorRes } from 'types/appTypes';
import { IGetActionLogRes } from 'types/actionLogsTypes';

interface ISliceActionLogsState {
  actionLogs: IGetActionLogRes | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: ISliceActionLogsState = {
  actionLogs: null,
  error: null,
  isLoading: false,
};

const ActionLogsSlice = createSlice({
  name: 'actionLogs',
  initialState,
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    //* GET ACTION LOGS ASYNC THUNK
    builder.addCase(getActionLogsItemsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getActionLogsItemsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.actionLogs = payload;
    });
    builder.addCase(getActionLogsItemsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });
    //* RESTORE ACTION LOGS ASYNC THUNK
    builder.addCase(restoreActionAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(restoreActionAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(restoreActionAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export default ActionLogsSlice;
