// Core
import { ReactNode, CSSProperties } from 'react';
// Packages
import { Steps as AntSteps } from 'antd';

export type ProgressDotRender = (
  iconDot: any,
  info: {
    index: number;
    status: 'wait' | 'process' | 'finish' | 'error';
    title: ReactNode;
    description: ReactNode;
  },
) => ReactNode;

interface IStepsProps {
  children?: ReactNode;
  className?: string;
  current?: number;
  direction?: 'horizontal' | 'vertical';
  initial?: number;
  labelPlacement?: 'horizontal' | 'vertical';
  percent?: number;
  progressDot?: boolean | ProgressDotRender;
  responsive?: boolean;
  size?: 'default' | 'small';
  status?: 'wait' | 'process' | 'finish' | 'error';
  style?: CSSProperties;
  type?: 'default' | 'navigation';
  onChange?: (current: number) => void;
}

/**
 * Steps is a navigation bar that guides users through the steps of a task.
 * The whole of the step bar.
 *
 * @description When a given task is complicated or has a certain sequence in the series of subtasks, we can decompose it into several steps to make things easier.
 *
 * @param children - Some ReactNode (Step component)
 * @param className - Component className
 * @param current - To set the current step, counting from 0. You can overwrite this state by using status of Step
 * @param direction - To specify the direction of the step bar, horizontal or vertical
 * @param initial - Set the initial step, counting from 0
 * @param labelPlacement - Place title and description with horizontal or vertical direction
 * @param percent - Progress circle percentage of current step in process status (only works on basic Steps)
 * @param progressDot - Steps with progress dot style, customize the progress dot by setting it to a function. labelPlacement will be vertical
 * @param responsive - Change to vertical direction when screen width smaller than 532px
 * @param size - To specify the size of the step bar, default and small are currently supported
 * @param status - To specify the status of current step, can be set to one of the following values: wait process finish error
 * @param style - To customize the styles
 * @param type - Type of steps, can be set to one of the following values: default, navigation
 * @param onChange - Trigger when Step is changed
 */

const Steps = ({
  children,
  className,
  current = 0,
  direction = 'horizontal',
  initial = 0,
  labelPlacement = 'horizontal',
  percent,
  progressDot = false,
  responsive = true,
  size = 'default',
  status = 'process',
  style,
  type = 'default',
  onChange,
}: IStepsProps) => (
  <AntSteps
    className={className}
    current={current}
    direction={direction}
    initial={initial}
    labelPlacement={labelPlacement}
    percent={percent}
    progressDot={progressDot}
    responsive={responsive}
    size={size}
    status={status}
    type={type}
    onChange={onChange}
    style={style}
  >
    {children}
  </AntSteps>
);

const { Step } = AntSteps;

export { Steps, Step };
