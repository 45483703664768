// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { PaymentsAPI } from 'api/endpoints';
// Interfaces and types
import { IGetPaymentsRes } from 'types/paymentsTypes';
import { TQueryParams, IErrorRes } from 'types/appTypes';

const getPaymentsAsync = createAsyncThunk<
  IGetPaymentsRes,
  TQueryParams,
  { rejectValue: IErrorRes }
>('payments/getPayments', async (params: TQueryParams, { rejectWithValue }) => {
  try {
    return await PaymentsAPI.getPayments(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

export { getPaymentsAsync };
