// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { JobRatesAPI } from 'api/endpoints';
// Interfaces and types
import {
  IGetJobRatesByJobIdParams,
  IGetJobRatesByJobIdRes,
  ICreateJobRateBodyWithParam,
  IGetJobRateDetailsParams,
  IGetJobRateDetailsRes,
  IUpdateJobRateBodyWithParams,
  IUpdateJobRateRes,
  IDeleteJobRateParams,
  IDeleteJobRateRes,
  IGetJobRatesRes,
  ICreateJobRateRes,
} from 'types/jobRatesTypes';
import { IErrorRes } from 'types/appTypes';
import { TQueryParams } from 'types/appTypes';

const getJobRatesAsync = createAsyncThunk<
  IGetJobRatesRes,
  TQueryParams,
  { rejectValue: IErrorRes }
>('jobRates/getJobRates', async (params: TQueryParams, { rejectWithValue }) => {
  try {
    return await JobRatesAPI.getJobRates(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getJobRatesByJobIdAsync = createAsyncThunk<
  IGetJobRatesByJobIdRes,
  IGetJobRatesByJobIdParams,
  { rejectValue: IErrorRes }
>(
  'jobRates/getJobRatesByJobId',
  async ({ jobId, params }: IGetJobRatesByJobIdParams, { rejectWithValue }) => {
    try {
      return await JobRatesAPI.getJobRatesByJobId({ jobId, params });
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const createJobRateAsync = createAsyncThunk<
  ICreateJobRateRes,
  ICreateJobRateBodyWithParam,
  { rejectValue: IErrorRes }
>(
  'jobRates/createJobRate',
  async ({ jobId, body }: ICreateJobRateBodyWithParam, { rejectWithValue }) => {
    try {
      return await JobRatesAPI.createJobRate({ jobId, body });
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const getJobRateDetailsAsync = createAsyncThunk<
  IGetJobRateDetailsRes,
  IGetJobRateDetailsParams,
  { rejectValue: IErrorRes }
>(
  'jobRates/getJobRateDetails',
  async ({ jobId, id }: IGetJobRateDetailsParams, { rejectWithValue }) => {
    try {
      return await JobRatesAPI.getJobRateDetails({ jobId, id });
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const updateJobRateAsync = createAsyncThunk<
  IUpdateJobRateRes,
  IUpdateJobRateBodyWithParams,
  { rejectValue: IErrorRes }
>(
  'jobRates/updateJobRate',
  async ({ jobId, id, body }: IUpdateJobRateBodyWithParams, { rejectWithValue }) => {
    try {
      return await JobRatesAPI.updateJobRate({ jobId, id, body });
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const deleteJobRateAsync = createAsyncThunk<
  IDeleteJobRateRes,
  IDeleteJobRateParams,
  { rejectValue: IErrorRes }
>(
  'jobRates/deleteJоbRate',
  async ({ jobId, id }: IDeleteJobRateParams, { rejectWithValue }) => {
    try {
      return await JobRatesAPI.deleteJobRate({ jobId, id });
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

export {
  getJobRatesAsync,
  getJobRatesByJobIdAsync,
  createJobRateAsync,
  getJobRateDetailsAsync,
  updateJobRateAsync,
  deleteJobRateAsync,
};
