import { IResMessage } from 'types/appTypes';
// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { InvoicesApi } from 'api/endpoints';
// Interfaces and types
import {
  IGetInvoicesRes,
  ICreateInvoiceBody,
  ICreateInvoiceRes,
  IGetInvoiceLinesRes,
  IGetInvoiceLinesBody,
  IGetTotalInvoiceCalculationBody,
  IGetTotalInvoiceCalculationRes,
  IGetInvoiceDetailsRes,
  IUpdateInvoiceRes,
  IUpdateInvoiceBodyWithParam,
  IGetPaymentsParamsWithInvoiceId,
  IGetPaymentsRes,
  ISavePaymentsBodyWithParam,
  IUpdatePaymentStatusParamWithBody,
  ISendEmailBodyWithParam,
  IGetInvoiceDetailsBodyWithParams,
  ISentStatus,
  ISendEmailRes,
} from 'types/invoiceTypes';
import { IErrorRes, TQueryParams } from 'types/appTypes';

const getInvoicesAsync = createAsyncThunk<
  IGetInvoicesRes,
  TQueryParams,
  { rejectValue: IErrorRes }
>('invoices/getInvoices', async (params: TQueryParams, { rejectWithValue }) => {
  try {
    return await InvoicesApi.getInvoices(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getInvoiceTableLinesAsync = createAsyncThunk<
  IGetInvoiceLinesRes,
  IGetInvoiceLinesBody,
  { rejectValue: IErrorRes }
>('invoices/getInvoiceTableLines', async (body: IGetInvoiceLinesBody, { rejectWithValue }) => {
  try {
    return await InvoicesApi.getInvoicesLines(body);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getCalculationInvoiceAsync = createAsyncThunk<
  IGetTotalInvoiceCalculationRes,
  IGetTotalInvoiceCalculationBody,
  { rejectValue: IErrorRes }
>(
  'invoices/calculateInvoice',
  async (body: IGetTotalInvoiceCalculationBody, { rejectWithValue }) => {
    try {
      return await InvoicesApi.calculateInvoiceTotal(body);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const createInvoiceAsync = createAsyncThunk<
  ICreateInvoiceRes,
  ICreateInvoiceBody,
  { rejectValue: IErrorRes }
>('invoices/createInvoice', async (body: ICreateInvoiceBody, { rejectWithValue }) => {
  try {
    return await InvoicesApi.createInvoice(body);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getInvoiceDetailsAsync = createAsyncThunk<
  IGetInvoiceDetailsRes,
  IGetInvoiceDetailsBodyWithParams,
  { rejectValue: IErrorRes }
>(
  'invoices/getInvoiceDetails',
  async ({ id, params }: IGetInvoiceDetailsBodyWithParams, { rejectWithValue }) => {
    try {
      return await InvoicesApi.getInvoiceDetails({ id, params });
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const updateInvoiceAsync = createAsyncThunk<
  IUpdateInvoiceRes,
  IUpdateInvoiceBodyWithParam,
  { rejectValue: IErrorRes }
>(
  'invoices/updateInvoice',
  async ({ body, id }: IUpdateInvoiceBodyWithParam, { rejectWithValue }) => {
    try {
      return await InvoicesApi.updateInvoice({ body, id });
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const deleteInvoiceAsync = createAsyncThunk<IResMessage, number, { rejectValue: IErrorRes }>(
  'invoices/deleteInvoice',
  async (id: number, { rejectWithValue }) => {
    try {
      return await InvoicesApi.deleteInvoice(id);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const generateInvoiceAsync = createAsyncThunk<
  IGetInvoiceDetailsRes,
  number,
  { rejectValue: IErrorRes }
>('invoices/generateInvoice', async (id: number, { rejectWithValue }) => {
  try {
    return await InvoicesApi.generateInvoice(id);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const sendEmailsAsync = createAsyncThunk<
  ISendEmailRes,
  ISendEmailBodyWithParam,
  { rejectValue: IErrorRes }
>('invoices/sendEmails', async (params: ISendEmailBodyWithParam, { rejectWithValue }) => {
  try {
    return await InvoicesApi.sendEmails(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getPaymentsAsync = createAsyncThunk<
  IGetPaymentsRes,
  IGetPaymentsParamsWithInvoiceId,
  { rejectValue: IErrorRes }
>(
  'invoices/getPayments',
  async (params: IGetPaymentsParamsWithInvoiceId, { rejectWithValue }) => {
    try {
      return await InvoicesApi.getPayments(params);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const updatePaymentsAsync = createAsyncThunk<
  IResMessage,
  ISavePaymentsBodyWithParam,
  { rejectValue: IErrorRes }
>('invoices/updatePayments', async (body: ISavePaymentsBodyWithParam, { rejectWithValue }) => {
  try {
    return await InvoicesApi.updatePayments(body);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const updatePaymentStatusAsync = createAsyncThunk<
  IResMessage,
  IUpdatePaymentStatusParamWithBody,
  { rejectValue: IErrorRes }
>(
  'invoices/updatePaymentStatus',
  async (body: IUpdatePaymentStatusParamWithBody, { rejectWithValue }) => {
    try {
      return await InvoicesApi.updatePaymentStatus(body);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const getEmailSentStatusAsync = createAsyncThunk<
  ISentStatus,
  number,
  { rejectValue: IErrorRes }
>('invoices/getSentStatus', async (id: number, { rejectWithValue }) => {
  try {
    return await InvoicesApi.getSentStatus(id);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

export {
  getInvoicesAsync,
  createInvoiceAsync,
  getInvoiceTableLinesAsync,
  getCalculationInvoiceAsync,
  getInvoiceDetailsAsync,
  updateInvoiceAsync,
  deleteInvoiceAsync,
  sendEmailsAsync,
  getPaymentsAsync,
  updatePaymentsAsync,
  updatePaymentStatusAsync,
  getEmailSentStatusAsync,
  generateInvoiceAsync,
};
