interface IProps {
  primaryColor?: string;
}

/**
 * @param primaryColor - The icon color. By default '#2E3A59'.
 */

const MailInbox = ({ primaryColor = '#2E3A59' }: IProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 6.28571C2 3.91878 3.91878 2 6.28571 2H17.7143C20.0812 2 22 3.91878 22 6.28571V17.7143C22 20.0812 20.0812 22 17.7143 22H6.28571C3.91878 22 2 20.0812 2 17.7143V6.28571ZM3.42857 12H8.42857C8.82277 12 9.14239 12.3189 9.14287 12.713L9.14333 12.7306C9.14392 12.7483 9.14522 12.7776 9.14802 12.8169C9.15364 12.8955 9.16522 13.0126 9.18894 13.1549C9.23695 13.4429 9.33163 13.8161 9.51388 14.1806C9.69472 14.5423 9.95478 14.8822 10.3315 15.1334C10.7037 15.3815 11.2321 15.5714 12 15.5714C12.7679 15.5714 13.2963 15.3815 13.6685 15.1334C14.0452 14.8822 14.3053 14.5423 14.4861 14.1806C14.6684 13.8161 14.7631 13.4429 14.8111 13.1549C14.8348 13.0126 14.8464 12.8955 14.852 12.8169C14.8548 12.7776 14.8561 12.7483 14.8567 12.7306L14.8571 12.713L14.8571 12.7115C14.8587 12.3183 15.1779 12 15.5714 12H20.5714V6.28571C20.5714 4.70776 19.2922 3.42857 17.7143 3.42857H6.28571C4.70776 3.42857 3.42857 4.70776 3.42857 6.28571V12ZM9.14287 12.713L9.14285 12.7112L9.14287 12.713Z"
      fill={primaryColor}
    />
  </svg>
);

export default MailInbox;
