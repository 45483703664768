// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { CompaniesAPI } from 'api/endpoints';
// Interfaces and types
import { IErrorRes, TQueryParams } from 'types/appTypes';
import {
  IGetCompaniesRes,
  ICreateCompanyRes,
  ICreateCompanyBody,
  IGetCompanyRes,
  IUpdateCompanyRes,
  IUpdateCompanyBodyWithParam,
  IDeleteCompanyRes,
} from 'types/companyTypes';

const getCompaniesAsync = createAsyncThunk<
  IGetCompaniesRes,
  TQueryParams,
  { rejectValue: IErrorRes }
>('companies/getCompanies', async (params: TQueryParams, { rejectWithValue }) => {
  try {
    return await CompaniesAPI.getCompanies(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const createCompanyAsync = createAsyncThunk<
  ICreateCompanyRes,
  ICreateCompanyBody,
  { rejectValue: IErrorRes }
>('companies/createCompany', async (body: ICreateCompanyBody, { rejectWithValue }) => {
  try {
    return await CompaniesAPI.createCompany(body);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getCompanyAsync = createAsyncThunk<IGetCompanyRes, string, { rejectValue: IErrorRes }>(
  'companies/getCompany',
  async (accountName: string, { rejectWithValue }) => {
    try {
      return await CompaniesAPI.getCompany(accountName);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const updateCompanyAsync = createAsyncThunk<
  IUpdateCompanyRes,
  IUpdateCompanyBodyWithParam,
  { rejectValue: IErrorRes }
>(
  'companies/updateCompany',
  async (cred: IUpdateCompanyBodyWithParam, { rejectWithValue }) => {
    try {
      return await CompaniesAPI.updateCompany(cred);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const deleteCompanyAsync = createAsyncThunk<
  IDeleteCompanyRes,
  string,
  { rejectValue: IErrorRes }
>('companies/deleteCompany', async (accountName: string, { rejectWithValue }) => {
  try {
    return await CompaniesAPI.deleteCompany(accountName);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

export {
  getCompaniesAsync,
  createCompanyAsync,
  getCompanyAsync,
  updateCompanyAsync,
  deleteCompanyAsync,
};
