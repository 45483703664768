// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Interfaces and types
import {
  ICreatePerfLogsBody,
  ICreatePerfLogsRes,
  IDeletePerfLogsRes,
  IGetEmployeePerfLogDetailsRes,
  IGetEmployeePerfLogsBody,
  IGetEmployeePerfLogsRes,
  IGetPerfLogsRes,
  IPerfLogParams,
  IUpdatePerfLogParams,
  IUpdatePerfLogRes,
} from 'types/performanceLog';
import { TQueryParams } from 'types/appTypes';

export const getPerfLogs = (params: TQueryParams) => {
  return xhr.get<IGetPerfLogsRes, IGetPerfLogsRes>(`${ENDPOINTS.perfLogs}`, { params });
};

export const createPerfLog = ({ userId, ...body }: ICreatePerfLogsBody) => {
  return xhr.post<ICreatePerfLogsRes, ICreatePerfLogsRes>(
    `${ENDPOINTS.employees}/${userId}${ENDPOINTS.perfLogs}`,
    body,
  );
};

export const getEmployeePerfLogs = ({ userId, params }: IGetEmployeePerfLogsBody) => {
  return xhr.get<IGetEmployeePerfLogsRes, IGetEmployeePerfLogsRes>(
    `${ENDPOINTS.employees}/${userId}${ENDPOINTS.perfLogs}`,
    { params },
  );
};

export const getEmployeePerfLogDetails = ({ id, userId }: IPerfLogParams) => {
  return xhr.get<IGetEmployeePerfLogDetailsRes, IGetEmployeePerfLogDetailsRes>(
    `${ENDPOINTS.employees}/${userId}${ENDPOINTS.perfLogs}/${id}`,
  );
};

export const updatePerfLog = ({ id, userId, body }: IUpdatePerfLogParams) => {
  return xhr.patch<IUpdatePerfLogRes, IUpdatePerfLogRes>(
    `${ENDPOINTS.employees}/${userId}${ENDPOINTS.perfLogs}/${id}`,
    body,
  );
};

export const deletePerfLog = ({ id, userId }: IPerfLogParams) => {
  return xhr.delete<IDeletePerfLogsRes, IDeletePerfLogsRes>(
    `${ENDPOINTS.employees}/${userId}${ENDPOINTS.perfLogs}/${id}`,
  );
};
