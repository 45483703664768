// Core
import { Suspense } from 'react';
// Packages
import { Switch, Route } from 'react-router-dom';
// Pages
import NotFoundPage from 'pages/NotFoundPage';
// Components
import { ErrorBoundary, Loader } from 'components';
// Custom Hooks
import { useRoutePermissions } from 'customHooks';
// Routes
import routes, { ROUTES } from './routes';
import PublicRoute from 'router/PublicRoute';
import PrivateRoute from 'router/PrivateRoute';
import SignInAsAdmin from 'pages/SignInAsAdmin';

const AppRouter = () => {
  // ** useRoutePermissions:
  // ** If (!user) - returns only public routes;
  // ** If (user) - filtering routes according to Platform Permission (user.platformPermissions), sending from backend;
  // ** (checking if property 'read' is presented  in certain route);

  const allowedRoutes = useRoutePermissions(routes);

  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader isLoading />}>
        <Switch>
          {allowedRoutes.map(route =>
            route.private ? (
              <PrivateRoute key={route.path} {...route} />
            ) : (
              <PublicRoute key={route.path} {...route} />
            ),
          )}

          <Route exact path={ROUTES.SIGN_IN_AS_ADMIN} component={SignInAsAdmin} />

          <Route component={NotFoundPage} />
        </Switch>
      </Suspense>
    </ErrorBoundary>
  );
};

export default AppRouter;
