// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { JobLogsApi } from 'api/endpoints';
// Interfaces and types
import {
  IGetJobLogsRes,
  ICreateJobLogRes,
  ICreateJobLogBodyWithParams,
  IGetJobLogDetailsRes,
  IGetJobLogDetailsParams,
  IUpdateJobLogBodyWithParams,
  IUpdateJobLogRes,
  IDeleteJobLogRes,
  IDeleteJobLogParams,
  IGetJobLogsByJobIdParams,
  IGetJobLogsByJobIdRes,
} from 'types/jobLogsTypes';
import { IErrorRes } from 'types/appTypes';
import { TQueryParams } from 'types/appTypes';

const getJobLogsAsync = createAsyncThunk<
  IGetJobLogsRes,
  TQueryParams,
  { rejectValue: IErrorRes }
>('jobLogs/getJobLogs', async (params: TQueryParams, { rejectWithValue }) => {
  try {
    return await JobLogsApi.getJobLogs(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getJobLogByJobIdAsync = createAsyncThunk<
  IGetJobLogsByJobIdRes,
  IGetJobLogsByJobIdParams,
  { rejectValue: IErrorRes }
>(
  'jobLogs/getJobLogsByJobId',
  async ({ jobId, params }: IGetJobLogsByJobIdParams, { rejectWithValue }) => {
    try {
      return await JobLogsApi.getJobLogsByJobId({ jobId, params });
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const createJobLogAsync = createAsyncThunk<
  ICreateJobLogRes,
  ICreateJobLogBodyWithParams,
  { rejectValue: IErrorRes }
>('jobLogs/createJobLog', async (params: ICreateJobLogBodyWithParams, { rejectWithValue }) => {
  try {
    return await JobLogsApi.createJobLog(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getJobLogDetailsAsync = createAsyncThunk<
  IGetJobLogDetailsRes,
  IGetJobLogDetailsParams,
  { rejectValue: IErrorRes }
>('jobLogs/getJobLogDetails', async (params: IGetJobLogDetailsParams, { rejectWithValue }) => {
  try {
    return await JobLogsApi.getJobLogDetails(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const updateJobLogAsync = createAsyncThunk<
  IUpdateJobLogRes,
  IUpdateJobLogBodyWithParams,
  { rejectValue: IErrorRes }
>(
  'jobLogs/updateJobLog',
  async ({ jobId, id, body }: IUpdateJobLogBodyWithParams, { rejectWithValue }) => {
    try {
      return await JobLogsApi.editJobLogDetails({ jobId, id, body });
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const deleteJobLogAsync = createAsyncThunk<
  IDeleteJobLogRes,
  IDeleteJobLogParams,
  { rejectValue: IErrorRes }
>('jobLogs/deleteJоbLog', async ({ jobId, id }: IDeleteJobLogParams, { rejectWithValue }) => {
  try {
    return await JobLogsApi.deleteJobLog({ jobId, id });
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

export {
  getJobLogsAsync,
  getJobLogByJobIdAsync,
  createJobLogAsync,
  getJobLogDetailsAsync,
  updateJobLogAsync,
  deleteJobLogAsync,
};
