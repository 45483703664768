// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import { getInvoiceSettingsAsync, updateInvoiceSettingsAsync } from './invoiceSettingsThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import { IInvoiceCustomComponentTypes } from 'types/invoiceTypes';
import { IGetInvoiceSettingsRes } from 'types/invoiceSettingsTypes';
import { IErrorRes } from 'types/appTypes';

interface ISliceInvoiceSettingsState {
  invoiceSettings: IGetInvoiceSettingsRes | null;
  customFirstFieldType: IInvoiceCustomComponentTypes;
  customSecondFieldType: IInvoiceCustomComponentTypes;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: ISliceInvoiceSettingsState = {
  invoiceSettings: null,
  customFirstFieldType: IInvoiceCustomComponentTypes.PERCENTAGE,
  customSecondFieldType: IInvoiceCustomComponentTypes.PERCENTAGE,
  error: null,
  isLoading: false,
};

const invoiceSettingsSlice = createSlice({
  name: 'invoiceSettings',
  initialState,
  reducers: {
    changeCustomFirstFieldType: (state, { payload }) => {
      state.customFirstFieldType = payload;
    },
    changeCustomSecondFieldType: (state, { payload }) => {
      state.customSecondFieldType = payload;
    },
  },
  extraReducers: builder => {
    //* GET INVOICE SETTINGS ASYNC THUNK
    builder.addCase(getInvoiceSettingsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getInvoiceSettingsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.invoiceSettings = payload;
    });
    builder.addCase(getInvoiceSettingsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* UPDATE INVOICE SETTINGS ASYNC THUNK
    builder.addCase(updateInvoiceSettingsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(updateInvoiceSettingsAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(updateInvoiceSettingsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export const { changeCustomFirstFieldType, changeCustomSecondFieldType } =
  invoiceSettingsSlice.actions;

export default invoiceSettingsSlice;
