// Core
import { CSSProperties, FocusEventHandler, ReactNode } from 'react';
// Packages
import { AutoComplete as AntAutoComplete } from 'antd';
import usePlacesAutocomplete from 'use-places-autocomplete';
import { SingleType, FilterFunc } from 'rc-select/lib/interface/generator.d';
// Components
import { TValueType, OptionsType } from '../Select';
// Styles
import styles from './autoComplete.module.scss';

const { Option } = AntAutoComplete;

interface IAutoCompleteProps {
  allowClear?: boolean;
  autoFocus?: boolean;
  backfill?: boolean;
  className?: string;
  country?: string;
  children?: ReactNode;
  defaultActiveFirstOption?: boolean;
  defaultOpen?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  dropdownClassName?: string;
  dropdownMatchSelectWidth?: boolean | number;
  filterOption?: boolean | FilterFunc<OptionsType[number]>;
  notFoundContent?: ReactNode;
  open?: boolean;
  options?: OptionsType;
  placeholder?: ReactNode;
  size?: 'large' | 'middle' | 'small';
  style?: CSSProperties;
  type?: '' | 'geocode' | 'address' | 'establishment' | '(regions)' | '(cities)';
  value?: string;
  onBlur?: FocusEventHandler<HTMLElement>;
  onChange?: (value: string) => void;
  onDropdownVisibleChange?: (open: boolean) => void;
  onFocus?: FocusEventHandler<HTMLElement>;
  onSearch?: (value: string) => void;
  onSelect?: (value: SingleType<TValueType>, option: OptionsType[number]) => void;
  onUseAddressForm?: (value: string) => void;
  dataCy?: string;
}

/**
 * Select component to select value from options.
 *
 * @description A dropdown menu for displaying choices - an elegant alternative to the native <select> element.
 * Utilizing Radio is recommended when there are fewer total options (less than 5).
 *
 * @param allowClear - Show clear button
 * @param autoFocus - If get focus when component mounted
 * @param backfill - If backfill selected item the input when using keyboard
 * @param children - Customize input element (for customize input element)
 * @param className - The select className
 * @param defaultActiveFirstOption - Whether active first option by default
 * @param defaultOpen - Initial open state of dropdown
 * @param defaultValue - Initial selected option
 * @param disabled - Whether disabled select
 * @param dropdownClassName - The className of dropdown menu
 * @param dropdownMatchSelectWidth - Determine whether the dropdown menu and the select input are the same width. Default set min-width same as input. Will ignore when value less than select width. false will disable virtual scroll
 * @param filterOption - If true, filter options by input, if function, filter options against it. The function will receive two arguments, inputValue and option, if the function returns true, the option will be included in the filtered set; Otherwise, it will be excluded
 * @param notFoundContent - Specify content to show when no result matches
 * @param open - Controlled open state of dropdown
 * @param options - Select options. Will get better perf than jsx definition
 * @param placeholder - The placeholder of input
 * @param size - Size of Select input
 * @param style - To customize the style
 * @param type - The parameter specifies a type or a type collection
 * @param value - Selected option
 * @param onBlur - Called when leaving the component
 * @param onChange - Called when select an option or input value change, or value of input is changed
 * @param onDropdownVisibleChange - Call when dropdown open
 * @param onFocus - Called when entering the component
 * @param onSearch - Called when searching items
 * @param onSelect - Called when a option is selected. param is option's value and option instance
 * @param dataCy - Cypress
 */

const AutoComplete = ({
  allowClear = false,
  autoFocus,
  backfill,
  country = '',
  className,
  defaultActiveFirstOption,
  defaultOpen,
  defaultValue,
  disabled = false,
  dropdownClassName,
  dropdownMatchSelectWidth,
  filterOption,
  notFoundContent,
  open,
  options,
  placeholder,
  size = 'middle',
  style,
  type = '',
  value,
  onBlur,
  onChange = () => {},
  onSelect = () => {},
  onUseAddressForm = () => {},
  onDropdownVisibleChange,
  onFocus,
  onSearch,
  dataCy,
}: IAutoCompleteProps) => {
  const {
    ready,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
    requestOptions: {
      types: [type],
      componentRestrictions: { country },
    },
  });

  const handleChange = (value: string) => {
    setValue(value);
    onChange(value);
  };

  const handleSelect = (address: TValueType) => {
    setValue(address as string, false);
    onSelect(address as string, { value: address as string });
    onUseAddressForm(address as string);
    clearSuggestions();
  };

  return (
    <AntAutoComplete
      allowClear={allowClear}
      autoFocus={autoFocus}
      backfill={backfill}
      className={`${styles.autoCompleteInput} ${className}`}
      defaultActiveFirstOption={defaultActiveFirstOption}
      defaultOpen={defaultOpen}
      defaultValue={defaultValue}
      disabled={!ready || disabled}
      dropdownClassName={dropdownClassName}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      filterOption={filterOption}
      notFoundContent={notFoundContent}
      open={open}
      options={options}
      placeholder={placeholder}
      value={value}
      onBlur={onBlur}
      onChange={handleChange}
      onDropdownVisibleChange={onDropdownVisibleChange}
      onFocus={onFocus}
      onSearch={onSearch}
      onSelect={handleSelect}
      size={size}
      style={style}
      data-cy={dataCy}
    >
      {status === 'OK' &&
        data.map(({ place_id, description }) => (
          <Option key={place_id} value={description}>
            {description}
          </Option>
        ))}
    </AntAutoComplete>
  );
};

AutoComplete.Option = Option;

export default AutoComplete;
