// Interfaces and types
import { IErrorRes } from 'types/appTypes';
// I18n
import { t } from 'i18n';

type TGetErrorMsg = (errorResData: IErrorRes) => IErrorRes;

const getErrorTranslation: TGetErrorMsg = errorResData => {
  const errorData = { ...errorResData };

  if (typeof errorData.errors === 'object') {
    const validationErrors = Object.entries(errorData.errors);

    for (const [key, value] of validationErrors) {
      if (typeof value === 'object') {
        errorData.errors[key] = value;
      } else {
        errorData.errors[key] = t(`errors.${value}`);
      }
    }
  }

  if (typeof errorData.errors === 'string') {
    errorData.errors = undefined;
  }

  if (errorData.message) {
    errorData.message = t(`errors.${errorData.message}`);
  }

  return { message: errorData.message, errors: errorData.errors };
};

export { getErrorTranslation };
