// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Interfaces and types
import { TQueryParams } from 'types/appTypes';
import { IGetEventsRes } from 'types/calendarTypes';

export const getEvents = (params: TQueryParams) => {
  return xhr.get<IGetEventsRes, IGetEventsRes>(`${ENDPOINTS.events}`, { params });
};
