// Components
import { NotFound } from './components';
import { Layout } from 'components/lib';
// Styles
import styles from './notFound.module.scss';

const NotFoundPage = () => (
  <Layout className={styles.wrapper}>
    <NotFound />
  </Layout>
);

export default NotFoundPage;
