// Core
import { useState } from 'react';
// Packages
import { BsThreeDots } from 'react-icons/bs';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
// Components
import { Button, Popover, Space } from 'components/lib';
// Custom hooks
import { useBreakpoint } from 'customHooks';
// I18n
import { t } from 'i18n';
// Types
import { ETypeOfEditItem } from 'types/todoTypes';
// Styles
import styles from './tableSettingButton.module.scss';

interface IProps {
  isDifferentEditItems?: boolean;
  isEditModeVisible?: boolean;
  deleteText?: string;
  editText?: string;
  customActionText?: string;
  customActionSecondText?: string;
  canDelete?: boolean;
  canEdit?: boolean;
  onEdit?: (typeOfEditItem?: ETypeOfEditItem) => void;
  onDelete?: () => void;
  onCustomAction?: (param?: any) => void;
  onCustomActionSecond?: (param?: any) => void;
}

const TableSettingButton = ({
  isDifferentEditItems = false,
  isEditModeVisible = true,
  deleteText = '',
  editText = '',
  customActionText = '',
  customActionSecondText = '',
  canDelete = true,
  canEdit = true,
  onDelete,
  onEdit,
  onCustomAction,
  onCustomActionSecond,
}: IProps) => {
  const { md } = useBreakpoint();

  const [isVisible, setIsVisible] = useState(false);
  const handleChangeVisiblePopover = () => setIsVisible(prev => !prev);

  const handleDelete = () => {
    onDelete && onDelete();
    handleChangeVisiblePopover();
  };

  const handleEdit = () => {
    onEdit && onEdit();
    handleChangeVisiblePopover();
  };

  const handleDoCustomAction = () => {
    onCustomAction && onCustomAction();
    handleChangeVisiblePopover();
  };

  const handleDoCustomActionSecond = () => {
    onCustomActionSecond && onCustomActionSecond();
    handleChangeVisiblePopover();
  };

  // Only for todo page
  const handleEditTodoResult = () => {
    onEdit && onEdit(ETypeOfEditItem.TODO_RESULT);
    handleChangeVisiblePopover();
  };

  const isEditVisible = onEdit && isEditModeVisible;
  const settingsButtonClass = md ? styles.settingsBtn : styles.settingsBtnMobile;

  return (
    <Popover
      zIndex={100}
      trigger="click"
      placement="topRight"
      visible={isVisible}
      onVisibleChange={handleChangeVisiblePopover}
      content={
        <Space direction="vertical" size={0}>
          {canDelete && onDelete && (
            <Button
              block
              type="text"
              disabled={!canDelete}
              icon={<AiOutlineDelete />}
              onClick={handleDelete}
              className={styles.actionBtn}
            >
              {t(deleteText)}
            </Button>
          )}

          {isEditVisible && (
            <Button
              block
              type="text"
              disabled={!canEdit}
              icon={<AiOutlineEdit />}
              onClick={handleEdit}
              className={styles.actionBtn}
              dataCy="editButton"
            >
              {t(editText)}
            </Button>
          )}

          {onCustomAction && (
            <Button
              block
              type="text"
              disabled={!canEdit}
              icon={<AiOutlineEdit />}
              onClick={handleDoCustomAction}
              className={styles.actionBtn}
            >
              {t(customActionText)}
            </Button>
          )}

          {onCustomActionSecond && (
            <Button
              block
              type="text"
              disabled={!canEdit}
              icon={<AiOutlineEdit />}
              onClick={handleDoCustomActionSecond}
              className={styles.actionBtn}
            >
              {t(customActionSecondText)}
            </Button>
          )}

          {isDifferentEditItems && (
            <Button
              block
              type="text"
              disabled={!canEdit}
              icon={<AiOutlineEdit />}
              onClick={handleEditTodoResult}
              className={styles.actionBtn}
            >
              {t('todo.todoList.editTodoResults')}
            </Button>
          )}
        </Space>
      }
    >
      <Button size="small" className={settingsButtonClass} dataCy="tableMenuButton">
        <BsThreeDots size={16} color="#2E3A59" />
      </Button>
    </Popover>
  );
};

export default TableSettingButton;
