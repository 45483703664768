// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import {
  getJobExpensesAsync,
  createJobExpensesAsync,
  getJobExpensesByJobIdAsync,
  getJobExpensesDetailsAsync,
  updateJobExpensesAsync,
  deleteJobExpensesAsync,
  updateJobExpensesStatusAsync,
} from './jobExpensesThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import {
  IGetJobExpensesRes,
  IGetJobExpensesByJobIdRes,
  IGetJobExpensesDetailsRes,
} from 'types/jobExpensesTypes';
import { IErrorRes } from 'types/appTypes';

interface ISliceJobsExpensesState {
  jobExpenses: IGetJobExpensesRes | IGetJobExpensesByJobIdRes | null;
  jobExpensesDetails: IGetJobExpensesDetailsRes | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: ISliceJobsExpensesState = {
  jobExpenses: null,
  jobExpensesDetails: null,
  error: null,
  isLoading: false,
};

const jobExpensesSlice = createSlice({
  name: 'jobExpenses',
  initialState,
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    //* GET JOB_EXPENSES ASYNC THUNK
    builder.addCase(getJobExpensesAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getJobExpensesAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.jobExpenses = payload;
    });
    builder.addCase(getJobExpensesAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* GET JOB_EXPENSES BY JOB_ID ASYNC THUNK
    builder.addCase(getJobExpensesByJobIdAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getJobExpensesByJobIdAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.jobExpenses = payload;
    });
    builder.addCase(getJobExpensesByJobIdAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CREATE JOB_EXPENSES ASYNC THUNK
    builder.addCase(createJobExpensesAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(createJobExpensesAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(createJobExpensesAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    // //* GET JOB_EXPENSES ASYNC THUNK
    builder.addCase(getJobExpensesDetailsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getJobExpensesDetailsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.jobExpensesDetails = payload;
    });
    builder.addCase(getJobExpensesDetailsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* UPDATE JOB_EXPENSES (one) ASYNC THUNK
    builder.addCase(updateJobExpensesAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(updateJobExpensesAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(updateJobExpensesAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    // //* DELETE JOB_EXPENSES ASYNC THUNK
    builder.addCase(deleteJobExpensesAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(deleteJobExpensesAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteJobExpensesAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* UPDATE JOB_EXPENSES STATUS ASYNC THUNK
    builder.addCase(updateJobExpensesStatusAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(updateJobExpensesStatusAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
    });
    builder.addCase(updateJobExpensesStatusAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export default jobExpensesSlice;
