// Core
import { CSSProperties, ReactNode, MouseEvent } from 'react';
// Packages
import { Anchor as AntAnchor } from 'antd';
import { AnchorContainer } from 'antd/lib/anchor/Anchor';

const { Link } = AntAnchor;

interface IAnchorProps {
  affix?: boolean;
  bounds?: number;
  getContainer?: () => AnchorContainer;
  getCurrentAnchor?: () => string;
  offsetTop?: number;
  showInkInFixed?: boolean;
  targetOffset?: number;
  onChange?: (currentActiveLink: string) => void;
  onClick?: (e: MouseEvent<HTMLElement>, link: { title: ReactNode; href: string }) => void;
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
}

/**
 * Hyperlinks to scroll on one page.For displaying anchor hyperlinks on page and jumping between them.
 *
 * @param affix - Fixed mode of Anchor
 * @param bounds - Bounding distance of anchor area
 * @param getContainer - Scrolling container
 * @param getCurrentAnchor - Customize the anchor highlight
 * @param offsetTop - Pixels to offset from top when calculating position of scroll
 * @param showInkInFixed - Whether show ink-balls when affix={false}
 * @param targetOffset - Anchor scroll offset, default as offsetTop, example
 * @param onChange - Listening for anchor link change
 * @param onClick - Set the handler to handle click event
 * @param className - Component className
 * @param style - To customize the styles
 * @param children - Some ReactNode
 */

const Anchor = ({
  affix = true,
  bounds = 5,
  getContainer,
  getCurrentAnchor,
  offsetTop = 0,
  showInkInFixed = false,
  targetOffset,
  onChange,
  onClick,
  className,
  style,
  children,
}: IAnchorProps) => (
  <AntAnchor
    affix={affix}
    bounds={bounds}
    getContainer={getContainer}
    getCurrentAnchor={getCurrentAnchor}
    offsetTop={offsetTop}
    showInkInFixed={showInkInFixed}
    targetOffset={targetOffset}
    onChange={onChange}
    onClick={onClick}
    className={className}
    style={style}
  >
    {children}
  </AntAnchor>
);

Anchor.Link = Link;

export default Anchor;
