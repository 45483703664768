// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Interfaces and types
import {
  IPayrollOption,
  IGetPayrollParams,
  IGetPayrollTypes,
  IUpdateDefaultPayrollBody,
  TUpdateDefaultPayrollRes,
  IGetPayrollPeriodsParams,
  IGetPayrollPeriods,
  IGetPayrollPeriodsYearsParams,
  IGetPayrollPeriodsYearsRes,
  ICreatePayrollPeriodBody,
  ICreatePayrollPeriodRes,
  IUpdatePayrollPeriodBodyWithParam,
  TUpdatePayrollPeriodRes,
  IGetPayrollPeriodDetailsRes,
  IDeletePayrollPeriodRes,
  TDeleteDefaultPayrollRes,
} from 'types/payrollTypes';
import { TQueryParams } from 'types/appTypes';

// Available on sign-up page (basic types)
export const getBasicPayrollTypes = () => {
  return xhr.get<IPayrollOption[], IPayrollOption[]>(
    `${ENDPOINTS.payrolls}${ENDPOINTS.schedules}`,
  );
};

// Basic types + custom types (default payroll select + employee payroll select)
export const getPayrollTypes = (params?: IGetPayrollParams) => {
  return xhr.get<IGetPayrollTypes, IGetPayrollTypes>(
    `${ENDPOINTS.payrolls}${ENDPOINTS.schedules}${ENDPOINTS.details}`,
    { params },
  );
};

export const updateDefaultPayroll = (payroll: IUpdateDefaultPayrollBody) => {
  return xhr.patch<TUpdateDefaultPayrollRes, TUpdateDefaultPayrollRes>(
    `${ENDPOINTS.payrolls}${ENDPOINTS.schedules}`,
    payroll,
  );
};

export const deleteDefaultPayroll = (id: number) => {
  return xhr.delete<TDeleteDefaultPayrollRes, TDeleteDefaultPayrollRes>(
    `${ENDPOINTS.payrolls}${ENDPOINTS.schedules}/${id}`,
  );
};

export const getPayrollPeriods = (params: TQueryParams | IGetPayrollPeriodsParams) => {
  return xhr.get<IGetPayrollPeriods, IGetPayrollPeriods>(
    `${ENDPOINTS.payrolls}${ENDPOINTS.periods}`,
    { params },
  );
};

export const getPayrollPeriodsYears = (params?: IGetPayrollPeriodsYearsParams) => {
  return xhr.get<IGetPayrollPeriodsYearsRes, IGetPayrollPeriodsYearsRes>(
    `${ENDPOINTS.payrolls}${ENDPOINTS.periods}${ENDPOINTS.years}`,
    { params },
  );
};

export const createPayrollPeriod = (body: ICreatePayrollPeriodBody) => {
  return xhr.post<ICreatePayrollPeriodRes, ICreatePayrollPeriodRes>(
    `${ENDPOINTS.payrolls}${ENDPOINTS.periods}`,
    body,
  );
};

export const getPayrollPeriodDetails = (id: number) => {
  return xhr.get<IGetPayrollPeriodDetailsRes, IGetPayrollPeriodDetailsRes>(
    `${ENDPOINTS.payrolls}${ENDPOINTS.periods}/${id}`,
  );
};

export const updatePayrollPeriod = ({ id, body }: IUpdatePayrollPeriodBodyWithParam) => {
  return xhr.put<TUpdatePayrollPeriodRes, TUpdatePayrollPeriodRes>(
    `${ENDPOINTS.payrolls}${ENDPOINTS.periods}/${id}`,
    body,
  );
};

export const deletePayrollPeriod = (id: number) => {
  return xhr.delete<IDeletePayrollPeriodRes, IDeletePayrollPeriodRes>(
    `${ENDPOINTS.payrolls}${ENDPOINTS.periods}/${id}`,
  );
};
