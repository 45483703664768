interface IProps {
  primaryColor?: string;
  secondaryColor?: string;
}

/**
 * @param primaryColor - The icon color. By default '#65BF73'.
 * @param secondaryColor - The color for the icon inside the logo. By default '#ffffff'.
 */

const StatusDone = ({ primaryColor = '#65BF73', secondaryColor = '#ffffff' }: IProps) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="32" cy="32" r="32" fill={primaryColor} />
    <path
      d="M28.5 37.5858L22.2071 31.2929C21.8166 30.9024 21.1834 30.9024 20.7929 31.2929C20.4024 31.6834 20.4024 32.3166 20.7929 32.7071L27.7929 39.7071C28.1834 40.0976 28.8166 40.0976 29.2071 39.7071L43.2071 25.7071C43.5976 25.3166 43.5976 24.6834 43.2071 24.2929C42.8166 23.9024 42.1834 23.9024 41.7929 24.2929L28.5 37.5858Z"
      fill={secondaryColor}
    />
  </svg>
);

export default StatusDone;
