// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { LocationRatingAPI } from 'api/endpoints';
// Interfaces and types
import {
  IGetAllRatingsRes,
  IUpdateRatingResponseBodyWithParams,
  IDeleteRatingResponseParams,
  ICreateRatingResponseBodyWithParam,
} from 'types/locationRatingTypes';
import { TQueryParams, IErrorRes, IResMessage } from 'types/appTypes';

const getLocationsRatingAsync = createAsyncThunk<
  IGetAllRatingsRes,
  TQueryParams,
  { rejectValue: IErrorRes }
>('locationsRating/getLocationsRating', async (params: TQueryParams, { rejectWithValue }) => {
  try {
    return await LocationRatingAPI.getAllRatings(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const createLocationRatingResponseAsync = createAsyncThunk<
  IResMessage,
  ICreateRatingResponseBodyWithParam,
  { rejectValue: IErrorRes }
>(
  'locationsRating/createLocationsRatingResponse',
  async (data: ICreateRatingResponseBodyWithParam, { rejectWithValue }) => {
    try {
      return await LocationRatingAPI.createLocationRatingResponse(data);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const updateLocationRatingResponseAsync = createAsyncThunk<
  IResMessage,
  IUpdateRatingResponseBodyWithParams,
  { rejectValue: IErrorRes }
>(
  'locationsRating/updateLocationsRatingResponse',
  async (cred: IUpdateRatingResponseBodyWithParams, { rejectWithValue }) => {
    try {
      return await LocationRatingAPI.updateLocationRatingResponse(cred);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const deleteLocationRatingResponseAsync = createAsyncThunk<
  IResMessage,
  IDeleteRatingResponseParams,
  { rejectValue: IErrorRes }
>(
  'locationsRating/deleteLocationsRatingResponse',
  async (params: IDeleteRatingResponseParams, { rejectWithValue }) => {
    try {
      return await LocationRatingAPI.deleteLocationRatingResponse(params);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

export {
  getLocationsRatingAsync,
  createLocationRatingResponseAsync,
  updateLocationRatingResponseAsync,
  deleteLocationRatingResponseAsync,
};
