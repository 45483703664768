// Core
import { useEffect } from 'react';
// Custom Hooks
import { useFetch } from 'customHooks';
// Redux
import { STORE_KEYS, useAppSelector, useAppDispatch } from 'redux/store';
import { changeStateFlagShouldOptionsPayrollScheduleFilterBeUpdated } from 'redux/payrollPeriods/payrollPeriodsReducer';
// API
import { PayrollsApi } from 'api/endpoints';
// Interfaces and types
import { IGetPayrollTypes } from 'types/payrollTypes';

interface IProps {
  isModalVisible?: boolean;
}

const useGetPayrollInfo = ({ isModalVisible = true }: IProps) => {
  const { shouldOptionsPayrollScheduleFilterBeUpdated } = useAppSelector(STORE_KEYS.PAYROLLS);
  const dispatch = useAppDispatch();

  const [{ response: payrollInfo }, doFetchPayrollInfo, doResetPayrollInfo] = useFetch<
    IGetPayrollTypes,
    void,
    typeof PayrollsApi.getPayrollTypes
  >(PayrollsApi.getPayrollTypes);

  useEffect(() => {
    if (isModalVisible || shouldOptionsPayrollScheduleFilterBeUpdated) {
      doFetchPayrollInfo();
    }

    dispatch(changeStateFlagShouldOptionsPayrollScheduleFilterBeUpdated(false));

    return () => {
      doResetPayrollInfo();
    };
  }, [
    isModalVisible,
    doFetchPayrollInfo,
    doResetPayrollInfo,
    dispatch,
    shouldOptionsPayrollScheduleFilterBeUpdated,
  ]);

  return { payrollInfo, doFetchPayrollInfo };
};

export default useGetPayrollInfo;
