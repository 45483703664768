// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Interfaces and types
import {
  IGetRecurringInvoicesRes,
  IGetRecurringInvoiceDetailsRes,
  IUpdateRecurringInvoiceBodyWithParam,
  ICreateRecurringInvoiceRes,
  ICreateRecurringInvoiceBody,
  IUpdateRecurringInvoiceRes,
  IDeleteRecurringInvoiceRes,
} from 'types/recurringInvoiceTypes';
import { TQueryParams } from 'types/appTypes';

export const getRecurringInvoices = (params: TQueryParams) => {
  return xhr.get<IGetRecurringInvoicesRes, IGetRecurringInvoicesRes>(
    `${ENDPOINTS.recurringInvoices}`,
    { params },
  );
};

export const createRecurringInvoiceItem = (body: ICreateRecurringInvoiceBody) => {
  return xhr.post<ICreateRecurringInvoiceRes, ICreateRecurringInvoiceRes>(
    `${ENDPOINTS.recurringInvoices}`,
    body,
  );
};

export const getRecurringInvoiceDetails = (id: number) => {
  return xhr.get<IGetRecurringInvoiceDetailsRes, IGetRecurringInvoiceDetailsRes>(
    `${ENDPOINTS.recurringInvoices}/${id}`,
  );
};

export const updateRecurringInvoiceItem = ({
  id,
  body,
}: IUpdateRecurringInvoiceBodyWithParam) => {
  return xhr.put<IUpdateRecurringInvoiceRes, IUpdateRecurringInvoiceRes>(
    `${ENDPOINTS.recurringInvoices}/${id}`,
    body,
  );
};

export const deleteRecurringInvoice = (id: number) => {
  return xhr.delete<IDeleteRecurringInvoiceRes, IDeleteRecurringInvoiceRes>(
    `${ENDPOINTS.recurringInvoices}/${id}`,
  );
};
