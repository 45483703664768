interface IProps {
  primaryColor?: string;
  secondaryColor?: string;
}

/**
 * @param primaryColor - The icon color. By default '#FF5A30'.
 * @param secondaryColor - The color for the icon inside the logo. By default '#ffffff'.
 */

const StatusFail = ({ primaryColor = '#FF5A30', secondaryColor = '#ffffff' }: IProps) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="32" cy="32" r="32" fill={primaryColor} />
    <path
      d="M40 24L24 40"
      stroke={secondaryColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24 24L40 40"
      stroke={secondaryColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default StatusFail;
