// Core
import { ChangeEvent, CSSProperties, ReactNode } from 'react';
// Packages
import { Input as AntUrlInput } from 'antd';
// Styles
import styles from './urlInput.module.scss';

interface IUrlInputProps {
  allowClear?: boolean;
  bordered?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  id?: string;
  maxLength?: number;
  prefix?: ReactNode;
  size?: 'small' | 'middle' | 'large';
  suffix?: ReactNode;
  value?: string;
  addonBefore?: string;
  addonAfter?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  className?: string;
  style?: CSSProperties;
}

/**
 * A basic widget for getting the user input is a text field.
 * Keyboard and mouse can be used for providing or changing data.
 *
 * @description A user input in a form field is needed. A search input is required.
 * Using pre & post tabs example.
 *
 * @param allowClear - If allow to remove input content with clear icon
 * @param bordered - Whether has border style
 * @param defaultValue - The initial input content
 * @param disabled - 	Whether the input is disabled
 * @param id - The ID for input
 * @param maxLength - The max length
 * @param prefix - The prefix icon for the Input
 * @param size - The size of the input box. Note: in the context of a form, the "large" size is used
 * @param suffix - The suffix icon for the Input
 * @param value - The input content value
 * @param onChange - Callback when user input
 * @param placeholder - Specifies a short hint that describes the expected value of an input field
 * @param className - Component className
 * @param style - To customize the styles
 */

const UrlInput = ({
  allowClear = false,
  bordered = true,
  defaultValue,
  disabled = false,
  id,
  maxLength,
  prefix,
  size = 'middle',
  suffix,
  value,
  onChange,
  placeholder = '',
  className,
  style,
  addonBefore = 'app.timego.com/',
  addonAfter = '',
}: IUrlInputProps) => {
  const classNames = [className, styles.urlInput].join(' ');

  return (
    <AntUrlInput
      addonBefore={addonBefore}
      addonAfter={addonAfter}
      allowClear={allowClear}
      bordered={bordered}
      defaultValue={defaultValue}
      disabled={disabled}
      id={id}
      maxLength={maxLength}
      prefix={prefix}
      size={size}
      suffix={suffix}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className={classNames}
      style={style}
    />
  );
};

export default UrlInput;
