interface IProps {
  primaryColor?: string;
}

/**
 * @param primaryColor - The icon color. By default '#1B96FF'.
 */

const ScheduleFilter = ({ primaryColor = '#1B96FF' }: IProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.75 3.75C1.75 3.33579 2.08579 3 2.5 3H13.5C13.9142 3 14.25 3.33579 14.25 3.75C14.25 4.16421 13.9142 4.5 13.5 4.5H2.5C2.08579 4.5 1.75 4.16421 1.75 3.75ZM3.75 7.75C3.75 7.33579 4.08579 7 4.5 7H11.5C11.9142 7 12.25 7.33579 12.25 7.75C12.25 8.16421 11.9142 8.5 11.5 8.5H4.5C4.08579 8.5 3.75 8.16421 3.75 7.75ZM5.75 11.75C5.75 11.3358 6.08579 11 6.5 11H9.5C9.91421 11 10.25 11.3358 10.25 11.75C10.25 12.1642 9.91421 12.5 9.5 12.5H6.5C6.08579 12.5 5.75 12.1642 5.75 11.75Z"
      fill={primaryColor}
    />
  </svg>
);

export default ScheduleFilter;
