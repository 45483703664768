// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { HolidaysApi } from 'api/endpoints';
// Interfaces and types
import { IErrorRes, TQueryParams } from 'types/appTypes';
import {
  IGetHolidaysRes,
  ICreateHolidayBody,
  ICreateHolidayRes,
  IGetHolidayDetailsRes,
  IUpdateHolidayBodyWithParam,
  TUpdateHolidayRes,
  TDeleteHolidayRes,
} from 'types/holidayTypes';

const getHolidaysAsync = createAsyncThunk<
  IGetHolidaysRes,
  TQueryParams,
  { rejectValue: IErrorRes }
>('holidays/getHolidays', async (params: TQueryParams, { rejectWithValue }) => {
  try {
    return await HolidaysApi.getHolidays(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const createHolidayAsync = createAsyncThunk<
  ICreateHolidayRes,
  ICreateHolidayBody,
  { rejectValue: IErrorRes }
>('holidays/createHoliday', async (body: ICreateHolidayBody, { rejectWithValue }) => {
  try {
    return await HolidaysApi.createHoliday(body);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getHolidayDetailsAsync = createAsyncThunk<
  IGetHolidayDetailsRes,
  number,
  { rejectValue: IErrorRes }
>('holidays/getHolidayDetails', async (id: number, { rejectWithValue }) => {
  try {
    return await HolidaysApi.getHolidayDetails(id);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const updateHolidayAsync = createAsyncThunk<
  TUpdateHolidayRes,
  IUpdateHolidayBodyWithParam,
  { rejectValue: IErrorRes }
>(
  'holidays/updateHoliday',
  async ({ id, body }: IUpdateHolidayBodyWithParam, { rejectWithValue }) => {
    try {
      return await HolidaysApi.updateHoliday({ id, body });
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const deleteHolidayAsync = createAsyncThunk<
  TDeleteHolidayRes,
  number,
  { rejectValue: IErrorRes }
>('holidays/deleteHoliday', async (id: number, { rejectWithValue }) => {
  try {
    return await HolidaysApi.deleteHoliday(id);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

export {
  getHolidaysAsync,
  createHolidayAsync,
  getHolidayDetailsAsync,
  updateHolidayAsync,
  deleteHolidayAsync,
};
