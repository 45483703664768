// Core
import { CSSProperties, ReactNode, SyntheticEvent, MouseEvent } from 'react';
// Packages
import { Image as AntImage } from 'antd';

interface IPreviewType {
  visible?: boolean;
  onVisibleChange?: (value: boolean, prevValue: boolean) => void;
  getContainer?: string | HTMLElement | (() => HTMLElement);
  src?: string;
  mask?: ReactNode;
  maskClassName?: string;
  current?: number;
}

interface IImageProps {
  alt?: string;
  fallback?: string;
  height?: string | number;
  placeholder?: ReactNode;
  preview?: boolean | IPreviewType;
  src?: string;
  width?: string | number;
  onError?: (e: SyntheticEvent<HTMLImageElement, Event>) => void;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  className?: string;
  style?: CSSProperties;
}

/**
 * Previewable image.
 *
 * @description When you need to display pictures. Display when loading a large image or fault tolerant handling when loading fail.
 *
 * @param alt - Image description
 * @param fallback - Load failure fault-tolerant src
 * @param height - Image height
 * @param placeholder - Load placeholder, use default placeholder when set true
 * @param preview - The preview config, disabled when false
 * @param src - Image path
 * @param width - Image width
 * @param onError -Load failed callback
 * @param className - Component className
 * @param style - To customize the styles
 */

const Image = ({
  alt = 'The TimeGO platform Image',
  fallback,
  height,
  placeholder,
  preview = false,
  src,
  width,
  onError,
  onClick,
  className,
  style,
}: IImageProps) => (
  <AntImage
    alt={alt}
    fallback={fallback}
    height={height}
    placeholder={placeholder}
    preview={preview}
    src={src}
    width={width}
    onError={onError}
    onClick={onClick}
    className={className}
    style={style}
  />
);

export default Image;
