// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Interfaces and types
import {
  IGetLocationsRes,
  ICreateLocationBodyWithParam,
  ICreateLocationRes,
  IGetLocationParams,
  IGetLocationRes,
  IUpdateLocationBodyWithParams,
  IUpdateLocationRes,
  IDeleteLocationParams,
  IDeleteLocationRes,
  IGetLocationsByAccountNameParams,
  IGetLocationsByAccountNameForSelect,
  TAvailableLocationsParams,
  IGetAvailableLocationsRes,
  IGetMainLocationRes,
} from 'types/locationTypes';
import { TQueryParams } from 'types/appTypes';

export const getLocations = (params: TQueryParams) => {
  return xhr.get<IGetLocationsRes, IGetLocationsRes>(`${ENDPOINTS.locations}`, { params });
};

export const createLocation = ({ accountName, body }: ICreateLocationBodyWithParam) => {
  return xhr.post<ICreateLocationRes, ICreateLocationRes>(
    `${ENDPOINTS.companies}/${accountName}${ENDPOINTS.locations}`,
    body,
  );
};

export const getLocation = ({ accountName, id }: IGetLocationParams) => {
  return xhr.get<IGetLocationRes, IGetLocationRes>(
    `${ENDPOINTS.companies}/${accountName}${ENDPOINTS.locations}/${id}`,
  );
};

export const updateLocation = ({ accountName, id, body }: IUpdateLocationBodyWithParams) => {
  return xhr.patch<IUpdateLocationRes, IUpdateLocationRes>(
    `${ENDPOINTS.companies}/${accountName}${ENDPOINTS.locations}/${id}`,
    body,
  );
};

export const deleteLocation = ({ accountName, id }: IDeleteLocationParams) => {
  return xhr.delete<IDeleteLocationRes, IDeleteLocationRes>(
    `${ENDPOINTS.companies}/${accountName}${ENDPOINTS.locations}/${id}`,
  );
};

export const getLocationsByAccountName = ({
  accountName,
  params,
}: IGetLocationsByAccountNameParams) => {
  return xhr.get<IGetLocationsRes, IGetLocationsRes>(
    `${ENDPOINTS.companies}/${accountName}${ENDPOINTS.locations}`,
    { params },
  );
};

export const getLocationsByAccountNameForSelect = (accountName: string) => {
  return xhr.get<IGetLocationsByAccountNameForSelect, IGetLocationsByAccountNameForSelect>(
    `${ENDPOINTS.companies}/${accountName}${ENDPOINTS.locations}?columns=id&columns=name`,
  );
};

export const getAvailableLocations = (params: TAvailableLocationsParams) => {
  return xhr.get<IGetAvailableLocationsRes, IGetAvailableLocationsRes>(
    `${ENDPOINTS.locations}`,
    { params },
  );
};

export const getMainCompanyLocation = () => {
  return xhr.get<IGetMainLocationRes, IGetMainLocationRes>(
    `${ENDPOINTS.currentUser}/company${ENDPOINTS.locations}/main`,
  );
};
