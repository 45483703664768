// Core
import { useState } from 'react';
// Packages
import { Link, useLocation } from 'react-router-dom';
import { AiFillSetting } from 'react-icons/ai';
// Components
import { Menu, Button } from 'components/lib';
import { TMenuInfo } from 'components/lib/Menu';
import { Table } from 'components';
// Routes
import { ROUTES } from 'router/routes';
// Custom Hooks
import { useCompanyPath, useSignOut } from 'customHooks';
import { useUserPermissionsDetails, useUserPermissions } from 'customHooks';
// Helpers
import { PERMISSION_KEYS } from 'helpers/data/constants';
import { setQueryString } from 'helpers/queryString';
// Redux
import { useAppSelector, STORE_KEYS } from 'redux/store';
// I18n
import { t } from 'i18n';
// Styles
import styles from './subMenu.module.scss';

interface IProps {
  mode?: 'horizontal' | 'inline' | 'vertical';
  onToggleMenu?: () => void;
  onSetIsSettingsModalVisible: () => void;
}

const SubMenu = ({
  mode = 'horizontal',
  onToggleMenu,
  onSetIsSettingsModalVisible,
}: IProps) => {
  const { pathname } = useLocation();
  const { getUpdatedPath } = useCompanyPath();

  const [currentKey, setCurrentKey] = useState<string>(pathname);
  const { user } = useAppSelector(STORE_KEYS.USER);
  const { current: page } = Table.paginationSettings;
  const defaultQueryString = setQueryString({ page, limit: user?.paginationPreference });
  const signOut = useSignOut();

  const handleClick = ({ key }: TMenuInfo) => {
    setCurrentKey(key as string);

    onToggleMenu && onToggleMenu();
  };

  const handleSignOut = async () => {
    signOut();
  };

  const selectedKey = getUpdatedPath(pathname).split('/').slice(0, 3).join('/');

  // Permissions
  const hasUserPermissionToPaymentPage = useUserPermissionsDetails(
    PERMISSION_KEYS.PAYMENTS,
  ).read;
  const hasUserPermissionToPayrollPage = useUserPermissionsDetails(
    PERMISSION_KEYS.PAYROLL,
  ).read;
  const hasUserPermissionToPreferencesPage = useUserPermissions(PERMISSION_KEYS.PREFERENCES);
  const hasUserPermissionToSettingsModal = useUserPermissionsDetails(
    PERMISSION_KEYS.SETTINGS,
  ).read;
  const hasUserPermissionToFeedback = useUserPermissionsDetails(PERMISSION_KEYS.FEEDBACK).read;
  const hasUserPermissionToIntegrations = useUserPermissionsDetails(
    PERMISSION_KEYS.INTEGRATIONS,
  ).read;
  const hasUserPermissionToActionLogs = useUserPermissionsDetails(
    PERMISSION_KEYS.ACTION_LOGS,
  ).read;
  const hasUserPermissionToImplementationPlan = useUserPermissionsDetails(
    PERMISSION_KEYS.IMPLEMENTATION_PLANS,
  ).read;

  return (
    <Menu
      theme="light"
      mode={mode}
      selectedKeys={[selectedKey]}
      defaultSelectedKeys={[currentKey]}
      onClick={handleClick}
      className={styles.navMenu}
    >
      <Menu.SubMenu
        key="SubMenu"
        icon={
          <Button
            className={styles.subMenuIcon}
            icon={<AiFillSetting size={20} color="#979CAC" />}
            type="text"
            dataCy="settings"
          />
        }
      >
        {hasUserPermissionToPreferencesPage && (
          <Menu.Item className={styles.navMenuItem} key="one">
            <Link to={`${getUpdatedPath(ROUTES.PREFERENCES)}`}>
              {t('mainNav.subNav.preferencesPage')}
            </Link>
          </Menu.Item>
        )}

        {hasUserPermissionToSettingsModal && (
          <Menu.Item
            className={styles.navMenuItem}
            key="two"
            onClick={onSetIsSettingsModalVisible}
            data-cy="accountSettings"
          >
            {t('mainNav.subNav.accountSettings')}
          </Menu.Item>
        )}

        {hasUserPermissionToActionLogs && (
          <Menu.Item className={styles.navMenuItem} key="three">
            <Link to={`${getUpdatedPath(ROUTES.ACTION_LOGS)}${`?${defaultQueryString}`}`}>
              {t('mainNav.subNav.actionLogs')}
            </Link>
          </Menu.Item>
        )}

        {hasUserPermissionToImplementationPlan && (
          <Menu.Item className={styles.navMenuItem} key="four">
            <Link
              to={`${getUpdatedPath(ROUTES.IMPLEMENTATION_PLANS)}${`?${defaultQueryString}`}`}
            >
              {t('mainNav.subNav.implementationPlan')}
            </Link>
          </Menu.Item>
        )}

        {hasUserPermissionToPayrollPage && (
          <Menu.Item className={styles.navMenuItem} key="five">
            <Link to={`${getUpdatedPath(ROUTES.PAYROLL)}${`?${defaultQueryString}`}`}>
              {t('mainNav.subNav.payrollPeriods')}
            </Link>
          </Menu.Item>
        )}

        {hasUserPermissionToPaymentPage && (
          <Menu.Item className={styles.navMenuItem} key="six">
            <Link to={`${getUpdatedPath(ROUTES.PAYMENT)}${`?${defaultQueryString}`}`}>
              {t('mainNav.subNav.paymentPage')}
            </Link>
          </Menu.Item>
        )}

        {hasUserPermissionToIntegrations && (
          <Menu.Item className={styles.navMenuItem} key="seven">
            <Link to={`${getUpdatedPath(ROUTES.INTEGRATIONS)}`}>
              {t('mainNav.subNav.integrations')}
            </Link>
          </Menu.Item>
        )}

        {hasUserPermissionToFeedback && (
          <Menu.Item className={styles.navMenuItem} key="eight">
            <Link to={`${getUpdatedPath(ROUTES.FEEDBACK)}`}>
              {t('mainNav.subNav.reportABag')}
            </Link>
          </Menu.Item>
        )}

        <Menu.Item
          className={styles.navMenuItem}
          key="nine"
          onClick={handleSignOut}
          data-cy="logOutBtn"
        >
          {t('mainNav.subNav.signOut')}
        </Menu.Item>
      </Menu.SubMenu>
    </Menu>
  );
};

export default SubMenu;
