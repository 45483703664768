// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import {
  getLocationsRatingAsync,
  createLocationRatingResponseAsync,
  updateLocationRatingResponseAsync,
  deleteLocationRatingResponseAsync,
} from './locationsRatingsThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import { IErrorRes } from 'types/appTypes';
import {
  IGetAllRatingsRes,
  ILocationRatingResponseWithRateId,
} from 'types/locationRatingTypes';

interface ISliceLocationsRatingsState {
  selectedResponse: ILocationRatingResponseWithRateId | null;
  locationsRatings: IGetAllRatingsRes | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: ISliceLocationsRatingsState = {
  selectedResponse: null,
  locationsRatings: null,
  error: null,
  isLoading: false,
};

const locationsRatings = createSlice({
  name: 'locationsRatings',
  initialState,
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
    setResponse: (state, { payload }) => {
      state.selectedResponse = payload;
    },
  },
  extraReducers: builder => {
    //* GET LOCATIONS RATINGS ASYNC THUNK
    builder.addCase(getLocationsRatingAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getLocationsRatingAsync.fulfilled, (state, { payload }) => {
      const locationRatingsFormatted = {
        ...payload,
        items: payload.items.map(item => ({
          ...item,
          responseId: item?.responses.length > 0 ? item?.responses[0]?.id : null,
          responseContent: item?.responses.length > 0 ? item?.responses[0]?.content : null,
        })),
      };
      state.isLoading = false;
      state.locationsRatings = locationRatingsFormatted;
    });
    builder.addCase(getLocationsRatingAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CREATE LOCATION RATE RESPONSE ASYNC THUNK
    builder.addCase(createLocationRatingResponseAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(createLocationRatingResponseAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(createLocationRatingResponseAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* UPDATE LOCATION RATE RESPONSE ASYNC THUNK
    builder.addCase(updateLocationRatingResponseAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(updateLocationRatingResponseAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(updateLocationRatingResponseAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* DELETE LOCATION RATE RESPONSE ASYNC THUNK
    builder.addCase(deleteLocationRatingResponseAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(deleteLocationRatingResponseAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteLocationRatingResponseAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export default locationsRatings;

export const { setResponse } = locationsRatings.actions;
