// Packages
import axios from 'axios';
// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
import { API_URL } from 'api/api.config';
// Storage
import webStorage from 'modules/storage';
// Interfaces and types
import { IGetUnInvoicedHoursRes, IMarkInvoicedBody } from 'types/unInvoicedHoursTypes';
import { TQueryParams, IResMessage } from 'types/appTypes';
// I18n
import { getCurrentLanguage } from 'i18n';

export const getUnInvoicedHours = (params: TQueryParams) => {
  return xhr.get<IGetUnInvoicedHoursRes, IGetUnInvoicedHoursRes>(
    `${ENDPOINTS.unInvoicedHours}`,
    { params },
  );
};

export const markedInvoiced = (data: IMarkInvoicedBody) => {
  return xhr.patch<IResMessage, IResMessage>(
    `${ENDPOINTS.unInvoicedHours}${ENDPOINTS.markedInvoiced}`,
    data,
  );
};

export const getUnInvoicedHoursReport = () => {
  const storageData = webStorage.getData();
  const language = getCurrentLanguage();

  return axios.get<Blob>(`${API_URL}${ENDPOINTS.unInvoicedHours}/${ENDPOINTS.reports}`, {
    headers: {
      Authorization: `Bearer ${storageData.accessToken}`,
      Accept: 'application/json',
      'Accept-Language': language,
      'Content-Type': 'application/json',
    },
    responseType: 'blob',
  });
};
