// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import { clearReducer } from '../auth/authThunk';
import {
  getEquipmentExpenesesByEquipmentIdAsync,
  getEquipmentExpensesItemsAsync,
} from './equipmentThunk';
// Interfaces and types
import { IErrorRes } from 'types/appTypes';
import { IGetEquipmentExpensesRes } from 'types/equipmentExpensesTypes';

interface ISliceEquipmentExpensesItemsState {
  equipmentExpensesItems: IGetEquipmentExpensesRes | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: ISliceEquipmentExpensesItemsState = {
  equipmentExpensesItems: null,
  error: null,
  isLoading: false,
};

const equipmentExpensesSlice = createSlice({
  name: 'equipmentExpenses',
  initialState,
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    //* GET EQUIPMENT EXPENSES ASYNC THUNK
    builder.addCase(getEquipmentExpensesItemsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getEquipmentExpensesItemsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.equipmentExpensesItems = payload;
    });
    builder.addCase(getEquipmentExpensesItemsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* GET EQUIPMENT EXPENSES BY EQUIPMENT ID ASYNC THUNK
    builder.addCase(getEquipmentExpenesesByEquipmentIdAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(
      getEquipmentExpenesesByEquipmentIdAsync.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.equipmentExpensesItems = payload;
      },
    );
    builder.addCase(getEquipmentExpenesesByEquipmentIdAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export default equipmentExpensesSlice;
