// Core
import { ComponentType } from 'react';
// Packages
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
// Redux
import { useAppSelector, STORE_KEYS } from 'redux/store';
// Custom Hooks
import { useCompanyPath } from 'customHooks';
// Modules
import storage from 'modules/storage';
// Helpers
import defineRedirectRoute from 'helpers/defineRedirectPage';
// Routes
import { TRoute } from './routes';

type TProps = {
  component: ComponentType<RouteComponentProps>;
} & TRoute;

const PublicRoute = ({ component: Component, restricted, ...routeProps }: TProps) => {
  const { getUpdatedPath } = useCompanyPath();

  const { hasScheduleAccess, hasGpsAccess, hasCheckpointsAccess } = storage.getData();
  const { isLoggedIn, userId } = useAppSelector(STORE_KEYS.AUTH);

  const isAuth = isLoggedIn && userId;
  const isRestricted = isAuth && restricted;

  const redirectPage = defineRedirectRoute({
    hasScheduleAccess,
    hasGpsAccess,
    hasCheckpointsAccess,
  });

  const path = getUpdatedPath(redirectPage);

  return (
    <Route
      {...routeProps}
      render={props => (isRestricted ? <Redirect to={path} /> : <Component {...props} />)}
    />
  );
};

export default PublicRoute;
