// Packages
import Timezone, { ITimezoneOption } from 'react-timezone-select';
// Styles
import './timeZoneSelect.styles.scss';

interface ITextInputProps {
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  size?: 'small' | 'middle' | 'large';
  onChange?: (value: string | undefined) => void;
  value?: ITimezoneOption | string;
  dataCy?: string;
}

/**
 * @param className - The select className
 * @param disabled - 	Whether disabled select
 * @param placeholder - Specifies a short hint that describes the expected value of an input field
 * @param size - Size of Select input
 * @param onChange - Called when select an option
 * @param value - Current selected option
 * @param dataCy - Cypress
 */

const TextInput = ({
  className,
  disabled = false,
  placeholder = '',
  size = 'middle',
  onChange = () => {},
  value = '',
  dataCy,
  ...restProps
}: ITextInputProps) => {
  const handleChange = ({ value }: ITimezoneOption) => onChange(value);

  return (
    <Timezone
      className={`timezone-select ${size} ${className}`}
      isDisabled={disabled}
      placeholder={placeholder}
      onChange={handleChange}
      value={value}
      data-cy={dataCy}
      {...restProps}
    />
  );
};

export default TextInput;
