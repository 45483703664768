// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { ContactsAPI } from 'api/endpoints';
// Interfaces and types
import {
  IGetContactsRes,
  ICreateContactRes,
  ICreateContactBodyWithParam,
  IUpdateContactBodyWithParam,
  IUpdateContactRes,
  IGetContactParams,
  IGetContactRes,
  IDeleteContactRes,
  IDeleteContactParams,
  IGetContactsByAccountNameParams,
} from 'types/contactTypes';
import { TQueryParams } from 'types/appTypes';
import { IErrorRes } from 'types/appTypes';

const getContactsAsync = createAsyncThunk<
  IGetContactsRes,
  TQueryParams,
  { rejectValue: IErrorRes }
>('contacts/getContacts', async (params: TQueryParams, { rejectWithValue }) => {
  try {
    return await ContactsAPI.getContacts(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const createContactAsync = createAsyncThunk<
  ICreateContactRes,
  ICreateContactBodyWithParam,
  { rejectValue: IErrorRes }
>('contacts/createContact', async (cred: ICreateContactBodyWithParam, { rejectWithValue }) => {
  try {
    return await ContactsAPI.createContact(cred);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getContactDetailsAsync = createAsyncThunk<
  IGetContactRes,
  IGetContactParams,
  { rejectValue: IErrorRes }
>('contacts/getContact', async (cred: IGetContactParams, { rejectWithValue }) => {
  try {
    return await ContactsAPI.getContact(cred);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const updateContactAsync = createAsyncThunk<
  IUpdateContactRes,
  IUpdateContactBodyWithParam,
  { rejectValue: IErrorRes }
>('contacts/update', async (cred: IUpdateContactBodyWithParam, { rejectWithValue }) => {
  try {
    return await ContactsAPI.updateContact(cred);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const deleteContactAsync = createAsyncThunk<
  IDeleteContactRes,
  IDeleteContactParams,
  { rejectValue: IErrorRes }
>('contact/delete', async (params: IDeleteContactParams, { rejectWithValue }) => {
  try {
    return await ContactsAPI.deleteContact(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getContactsByAccountNameAsync = createAsyncThunk<
  IGetContactsRes,
  IGetContactsByAccountNameParams,
  { rejectValue: IErrorRes }
>(
  'contacts/getContactsByAccountName',
  async (params: IGetContactsByAccountNameParams, { rejectWithValue }) => {
    try {
      return await ContactsAPI.getContactsByAccountName(params);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

export {
  getContactsAsync,
  createContactAsync,
  updateContactAsync,
  getContactDetailsAsync,
  deleteContactAsync,
  getContactsByAccountNameAsync,
};
