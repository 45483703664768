const Dot = () => (
  <svg
    width="25"
    height="16"
    viewBox="0 0 25 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12.75" cy="7.99988" r="2" fill="#1B96FF" />
  </svg>
);

export default Dot;
