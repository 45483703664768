// Packages
import { SerializedStyles } from '@emotion/react';
import BeatLoader from 'react-spinners/BeatLoader';
import ClipLoader from 'react-spinners/ClipLoader';

const SpinnerTypes = {
  beat: BeatLoader,
  clip: ClipLoader,
};

type TSpinnerType = keyof typeof SpinnerTypes;

interface ISpinnerProps {
  color?: string;
  css?: string | SerializedStyles;
  size?: number;
  margin?: number;
  isLoading?: boolean;
  type?: TSpinnerType;
}

/**
 * Simple React spinner
 *
 * @description Each loader has their own default properties. You can overwrite the defaults by passing props into the loaders.
 * Each loader accepts a loading prop as a boolean. The loader will render null if loading is false.
 *
 * @param color - Accepts a color hash in the format of #XXXXXX or #XXX. It also accepts basic colors
 * @param css - It works exactly like the css works with the emotion package
 * @param size - Size of spinner
 * @param margin - Distance between two adjacent spinner elements
 * @param isLoading - Determines when the spinner should work
 */

const Spinner = ({
  color = '#1890FF',
  css = '',
  size = 15,
  margin = 2,
  isLoading = false,
  type = 'beat',
}: ISpinnerProps) => {
  const SpinnerComponent = SpinnerTypes[type];

  return (
    <SpinnerComponent
      css={css}
      size={size}
      color={color}
      margin={margin}
      loading={isLoading}
    />
  );
};

export default Spinner;
