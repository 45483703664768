// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { PayrollAdjustmentApi } from 'api/endpoints';
// Interfaces and types
import { IErrorRes } from 'types/appTypes';
import {
  ICreatePayrollAdjustmentsBody,
  IGetPayrollAdjustmentsParams,
  IGetPayrollAdjustmentsRes,
} from 'types/payrollAdjustmentTypes';

const getPayrollAdjustmentAsync = createAsyncThunk<
  IGetPayrollAdjustmentsRes,
  IGetPayrollAdjustmentsParams,
  { rejectValue: IErrorRes }
>(
  'payrollAdjustments/getPayrollAdjustments',
  async (params: IGetPayrollAdjustmentsParams, { rejectWithValue }) => {
    try {
      return await PayrollAdjustmentApi.getPayrollAdjustments(params);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const savePayrollAdjustmentAsync = createAsyncThunk<
  IGetPayrollAdjustmentsRes,
  ICreatePayrollAdjustmentsBody,
  { rejectValue: IErrorRes }
>(
  'payrollAdjustments/savePayrollAdjustments',
  async (body: ICreatePayrollAdjustmentsBody, { rejectWithValue }) => {
    try {
      return await PayrollAdjustmentApi.savePayrollAdjustments(body);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

export { getPayrollAdjustmentAsync, savePayrollAdjustmentAsync };
