// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { GroupAPI } from 'api/endpoints';
// Interfaces and types
import { IErrorRes, TQueryParams } from 'types/appTypes';
import {
  IGetGroupDetailsRes,
  IUpdateGroupRes,
  IUpdateGroupBody,
  ICreateGroupRes,
  ICreateGroupBody,
  IDeleteGroupRes,
  IGetGroupsRes,
} from 'types/groupTypes';

const getGroupsAsync = createAsyncThunk<
  IGetGroupsRes,
  TQueryParams,
  { rejectValue: IErrorRes }
>('group/getGroups', async (params: TQueryParams, { rejectWithValue }) => {
  try {
    return await GroupAPI.getGroups(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const createGroupAsync = createAsyncThunk<
  ICreateGroupRes,
  ICreateGroupBody,
  { rejectValue: IErrorRes }
>('group/createGroup', async (body: ICreateGroupBody, { rejectWithValue }) => {
  try {
    return await GroupAPI.createGroup(body);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getGroupDetailsAsync = createAsyncThunk<
  IGetGroupDetailsRes,
  number,
  { rejectValue: IErrorRes }
>('group/getGroupDetails', async (id: number, { rejectWithValue }) => {
  try {
    return await GroupAPI.getGroupDetails(id);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const updateGroupAsync = createAsyncThunk<
  IUpdateGroupRes,
  IUpdateGroupBody,
  { rejectValue: IErrorRes }
>('group/updateGroup', async (cred: IUpdateGroupBody, { rejectWithValue }) => {
  try {
    return await GroupAPI.updateGroup(cred);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const deleteGroupAsync = createAsyncThunk<IDeleteGroupRes, number, { rejectValue: IErrorRes }>(
  'group/deleteGroup',
  async (id: number, { rejectWithValue }) => {
    try {
      return await GroupAPI.deleteGroup(id);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

export {
  getGroupsAsync,
  createGroupAsync,
  getGroupDetailsAsync,
  updateGroupAsync,
  deleteGroupAsync,
};
