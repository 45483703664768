// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Interfaces and types
import {
  IGetJobExpensesRes,
  ICreateJobExpensesBodyWithParams,
  ICreateJobExpensesRes,
  IGetJobExpensesByIdParams,
  IGetJobExpensesByJobIdRes,
  IGetJobExpensesDetailsParams,
  IGetJobExpensesDetailsRes,
  IUpdateJobExpensesBodyWithParams,
  IUpdateJobExpensesRes,
  IDeleteJobExpensesRes,
  IDeleteJobExpensesParams,
  IUpdateJobExpensesStatusBodyWithParams,
  IUpdateJobExpensesStatusRes,
  IGeJobExpensesTypes,
} from 'types/jobExpensesTypes';
import { TQueryParams, IResMessage } from 'types/appTypes';

export const getJobExpenses = (params: TQueryParams) => {
  return xhr.get<IGetJobExpensesRes, IGetJobExpensesRes>(`${ENDPOINTS.jobExpenses}`, {
    params,
  });
};

export const getJobExpensesByJobId = ({ jobId, params }: IGetJobExpensesByIdParams) => {
  return xhr.get<IGetJobExpensesByJobIdRes, IGetJobExpensesByJobIdRes>(
    `${ENDPOINTS.jobs}/${jobId}${ENDPOINTS.jobExpenses}`,
    {
      params,
    },
  );
};

export const createJobExpenses = ({ jobId, body }: ICreateJobExpensesBodyWithParams) => {
  return xhr.post<ICreateJobExpensesRes, ICreateJobExpensesRes>(
    `${ENDPOINTS.jobs}/${jobId}${ENDPOINTS.jobExpenses}`,
    body,
  );
};

export const getJobExpenseDetails = ({ jobId, id }: IGetJobExpensesDetailsParams) => {
  return xhr.get<IGetJobExpensesDetailsRes, IGetJobExpensesDetailsRes>(
    `${ENDPOINTS.jobs}/${jobId}${ENDPOINTS.jobExpenses}/${id}`,
  );
};

export const editJobExpensesDetails = ({
  jobId,
  id,
  body,
}: IUpdateJobExpensesBodyWithParams) => {
  return xhr.put<IUpdateJobExpensesRes, IUpdateJobExpensesRes>(
    `${ENDPOINTS.jobs}/${jobId}${ENDPOINTS.jobExpenses}/${id}`,
    body,
  );
};

export const deleteJobExpenses = ({ jobId, id }: IDeleteJobExpensesParams) => {
  return xhr.delete<IDeleteJobExpensesRes, IDeleteJobExpensesRes>(
    `${ENDPOINTS.jobs}/${jobId}${ENDPOINTS.jobExpenses}/${id}`,
  );
};

export const editJobExpensesStatus = ({
  jobId,
  id,
  body,
}: IUpdateJobExpensesStatusBodyWithParams) => {
  return xhr.patch<IUpdateJobExpensesStatusRes, IUpdateJobExpensesStatusRes>(
    `${ENDPOINTS.jobs}/${jobId}${ENDPOINTS.jobExpenses}/${id}${ENDPOINTS.status}`,
    body,
  );
};

export const getJobExpensesTypes = () => {
  return xhr.get<IGeJobExpensesTypes, IGeJobExpensesTypes>(
    `${ENDPOINTS.jobExpenses}${ENDPOINTS.types}`,
  );
};

export const deleteJobExpensesType = (id: number) => {
  return xhr.delete<IResMessage, IResMessage>(
    `${ENDPOINTS.jobExpenses}${ENDPOINTS.types}/${id}`,
  );
};
