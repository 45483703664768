// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import {
  getJobLogsAsync,
  getJobLogByJobIdAsync,
  createJobLogAsync,
  getJobLogDetailsAsync,
  updateJobLogAsync,
  deleteJobLogAsync,
} from './jobLogsThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import {
  IGetJobLogsRes,
  IGetJobLogDetailsRes,
  IGetJobLogsByJobIdRes,
} from 'types/jobLogsTypes';
import { IErrorRes } from 'types/appTypes';

interface ISliceJobLogsState {
  jobLogs: IGetJobLogsRes | IGetJobLogsByJobIdRes | null;
  jobLogDetails: IGetJobLogDetailsRes | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: ISliceJobLogsState = {
  jobLogs: null,
  jobLogDetails: null,
  error: null,
  isLoading: false,
};

const jobLogsSlice = createSlice({
  name: 'jobLogs',
  initialState,
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    //* GET JOB_LOGS ASYNC THUNK
    builder.addCase(getJobLogsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getJobLogsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.jobLogs = payload;
    });
    builder.addCase(getJobLogsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* GET JOB_LOGS BY JOB_ID ASYNC THUNK
    builder.addCase(getJobLogByJobIdAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getJobLogByJobIdAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.jobLogs = payload;
    });
    builder.addCase(getJobLogByJobIdAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CREATE JOB_LOG ASYNC THUNK
    builder.addCase(createJobLogAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(createJobLogAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(createJobLogAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    // //* GET JOB_LOG ASYNC THUNK
    builder.addCase(getJobLogDetailsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getJobLogDetailsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.jobLogDetails = payload;
    });
    builder.addCase(getJobLogDetailsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* UPDATE JOB_LOG (one) ASYNC THUNK
    builder.addCase(updateJobLogAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(updateJobLogAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(updateJobLogAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    // //* DELETE JOB_LOG ASYNC THUNK
    builder.addCase(deleteJobLogAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(deleteJobLogAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteJobLogAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export default jobLogsSlice;
