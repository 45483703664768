// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import { getUnInvoicedHoursAsync, markInvoicedAsync } from './unInvoicedHoursThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import { IErrorRes } from 'types/appTypes';
import { IGetUnInvoicedHoursRes } from 'types/unInvoicedHoursTypes';

interface IState {
  unInvoicedHours: IGetUnInvoicedHoursRes | null;
  unInvoicedHoursSelectedIds: number[];
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: IState = {
  unInvoicedHours: null,
  unInvoicedHoursSelectedIds: [],
  error: null,
  isLoading: false,
};

const unInvoicedHours = createSlice({
  name: 'unInvoicedHours',
  initialState,
  reducers: {
    selectUnInvoicedHours: (state, { payload }) => {
      state.unInvoicedHoursSelectedIds = payload;
    },
  },
  extraReducers: builder => {
    //* GET UN_INVOICED_HOURS_ ASYNC THUNK
    builder.addCase(getUnInvoicedHoursAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getUnInvoicedHoursAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.unInvoicedHours = payload;
    });
    builder.addCase(getUnInvoicedHoursAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* MARK INVOICED ASYNC
    builder.addCase(markInvoicedAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(markInvoicedAsync.fulfilled, state => {
      state.isLoading = false;
      state.unInvoicedHoursSelectedIds = [];
    });
    builder.addCase(markInvoicedAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export const { selectUnInvoicedHours } = unInvoicedHours.actions;

export default unInvoicedHours;
