// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import {
  getContactsAsync,
  getContactDetailsAsync,
  updateContactAsync,
  deleteContactAsync,
  createContactAsync,
  getContactsByAccountNameAsync,
} from './contactsThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import { IGetContactsRes, IGetContactRes } from 'types/contactTypes';
import { IErrorRes } from 'types/appTypes';

interface ISliceContactsState {
  contacts: IGetContactsRes | null;
  contactDetails: IGetContactRes | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: ISliceContactsState = {
  contacts: null,
  contactDetails: null,
  error: null,
  isLoading: false,
};

const contactSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    //* GET CONTACTS ASYNC THUNK
    builder.addCase(getContactsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getContactsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.contacts = payload;
    });
    builder.addCase(getContactsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* GET CONTACTS (BY ACCOUNT NAME) ASYNC THUNK
    builder.addCase(getContactsByAccountNameAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getContactsByAccountNameAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.contacts = payload;
    });
    builder.addCase(getContactsByAccountNameAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CREATE CONTACT ASYNC THUNK
    builder.addCase(createContactAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(createContactAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(createContactAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* GET CONTACT DETAILS ASYNC THUNK
    builder.addCase(getContactDetailsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getContactDetailsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.contactDetails = payload;
    });
    builder.addCase(getContactDetailsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* UPDATE CONTACT (one) ASYNC THUNK
    builder.addCase(updateContactAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(updateContactAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(updateContactAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* DELETE CONTACT ASYNC THUNK
    builder.addCase(deleteContactAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(deleteContactAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteContactAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export default contactSlice;
