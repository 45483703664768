// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import {
  getCompaniesAsync,
  createCompanyAsync,
  getCompanyAsync,
  updateCompanyAsync,
  deleteCompanyAsync,
} from './companiesThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import { IGetCompaniesRes, IGetCompanyRes } from 'types/companyTypes';
import { IErrorRes } from 'types/appTypes';

interface ISliceCompaniesState {
  companies: IGetCompaniesRes | null;
  companyDetails: IGetCompanyRes | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: ISliceCompaniesState = {
  companies: null,
  companyDetails: null,
  error: null,
  isLoading: false,
};

const companySlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    //* GET COMPANIES ASYNC THUNK
    builder.addCase(getCompaniesAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getCompaniesAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.companies = payload;
    });
    builder.addCase(getCompaniesAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CREATE COMPANY ASYNC THUNK
    builder.addCase(createCompanyAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(createCompanyAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(createCompanyAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* GET COMPANY DETAILS ASYNC THUNK
    builder.addCase(getCompanyAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getCompanyAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.companyDetails = payload;
    });
    builder.addCase(getCompanyAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* UPDATE COMPANY ASYNC THUNK
    builder.addCase(updateCompanyAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(updateCompanyAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(updateCompanyAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* DELETE COMPANY ASYNC THUNK
    builder.addCase(deleteCompanyAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(deleteCompanyAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteCompanyAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export default companySlice;
