// Packages
import { Select as AntSelect } from 'antd';
import { LabeledValue } from 'antd/lib/select';
// Assets
import { User, UserGroup } from 'assets/icons/other';
// Styles
import './selectGroup.scss';

type TValueType = string | string[] | number | number[] | LabeledValue | LabeledValue[];

interface ISelectGroupProps {
  disabled?: boolean;
  size?: 'large' | 'middle' | 'small';
  onChange?: (value: TValueType) => void;
  value?: TValueType;
}

/**
 * Select component to select value from two options (group or employee).
 *
 * @description A dropdown menu for displaying choices - an elegant alternative to the native <select> element.
 * Utilizing Radio is recommended when there are fewer total options (less than 5).
 *
 * @param disabled - Whether disabled select
 * @param size -	Size of Select input
 * @param onChange - Called when select an option or input value change
 * @param value -	Current selected option
 */
const selectTypes = [
  { id: 'group', label: <UserGroup />, value: 'group' },
  { id: 'employee', label: <User />, value: 'employee' },
];

const SelectGroup = ({
  disabled = false,
  size = 'middle',
  onChange,
  value,
}: ISelectGroupProps) => (
  <AntSelect
    size={size}
    value={value}
    disabled={disabled}
    defaultValue={selectTypes[0].value}
    onChange={onChange}
    className="groupSelect"
    dropdownClassName="dropdownSelect"
    style={{ backgroundColor: '#fe5c4c' }}
  >
    {selectTypes.map(({ id, label, value }) => (
      <AntSelect.Option key={id} value={value}>
        {label}
      </AntSelect.Option>
    ))}
  </AntSelect>
);

export default SelectGroup;
