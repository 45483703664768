// Components
import { TRuleObject } from 'components/lib/Form/FormItem';
// I18n
import { t } from 'i18n';

const rules = {
  required: {
    required: true,
    message: t('inputRules.required'),
  } as TRuleObject,
  basicValidation: {
    max: 200,
    required: false,
    message: t('inputRules.basicValidation'),
  } as TRuleObject,
  basicValidationRequired: {
    min: 1,
    max: 200,
    required: true,
    message: t('inputRules.basicValidationRequired'),
  } as TRuleObject,
  name: {
    min: 1,
    max: 50,
    type: 'string',
    required: true,
    message: t('inputRules.name'),
  } as TRuleObject,
  firstName: {
    min: 1,
    max: 50,
    type: 'string',
    required: true,
    message: t('inputRules.firstName'),
  } as TRuleObject,
  lastName: {
    min: 1,
    max: 50,
    type: 'string',
    required: true,
    message: t('inputRules.lastName'),
  } as TRuleObject,
  middleName: {
    min: 1,
    max: 50,
    type: 'string',
    required: false,
    message: t('inputRules.middleName'),
  } as TRuleObject,
  shortName: {
    min: 1,
    max: 20,
    type: 'string',
    required: true,
    message: t('inputRules.shortName'),
  } as TRuleObject,
  username: {
    min: 1,
    max: 35,
    type: 'string',
    required: true,
    pattern: /^\S*$/,
    message: t('inputRules.username'),
  } as TRuleObject,
  employeeId: {
    max: 10,
    type: 'string',
    required: false,
    message: t('inputRules.employeeId'),
  } as TRuleObject,
  email: {
    type: 'email',
    required: true,
    message: t('inputRules.email'),
  } as TRuleObject,
  password: {
    min: 6,
    max: 50,
    type: 'string',
    required: true,
    message: t('inputRules.password'),
  } as TRuleObject,
  passwordPattern: {
    min: 8,
    max: 50,
    type: 'string',
    required: true,
    message: t('inputRules.passwordPattern'),
    pattern: /(?=.*\d)(?=.*[A-Z])(?=.*[-+_>(){}#[<%/!@#$%^&*.,=.:>~?*|])/,
  } as TRuleObject,
  timezone: {
    required: true,
    message: t('inputRules.timezone'),
  } as TRuleObject,
  phoneNumber: {
    min: 5,
    max: 14,
    type: 'string',
    required: false,
    message: t('inputRules.phoneNumber'),
  } as TRuleObject,
  businessName: {
    min: 1,
    max: 100,
    type: 'string',
    required: true,
    message: t('inputRules.businessName'),
  } as TRuleObject,
  webAddress: {
    min: 2,
    max: 20,
    type: 'string',
    pattern: /^[a-zA-Zа0-9]+$/,
    required: true,
    message: t('inputRules.webAddress'),
  } as TRuleObject,
  industryId: {
    required: true,
    message: t('inputRules.industryId'),
  } as TRuleObject,
  country: {
    required: true,
    message: t('inputRules.country'),
  } as TRuleObject,
  city: {
    required: true,
    message: t('inputRules.city'),
  } as TRuleObject,
  numberOfEmployees: {
    required: true,
    message: t('inputRules.numberOfEmployees'),
  } as TRuleObject,
  payrollStartDay: {
    required: true,
    message: t('inputRules.payrollStartDay'),
  } as TRuleObject,
  payrollTrackOvertime: {
    required: true,
    message: t('inputRules.payrollTrackOvertime'),
  } as TRuleObject,
  payrollGenerationFrequency: {
    required: true,
    message: t('inputRules.payrollGenerationFrequency'),
  } as TRuleObject,
  fax: {
    min: 0,
    max: 50,
    type: 'string',
    required: false,
    message: t('inputRules.fax'),
  } as TRuleObject,
  notes: {
    min: 0,
    max: 5000,
    required: false,
    message: t('inputRules.notes'),
  } as TRuleObject,
  // Company settings
  overTimePerWeek: {
    required: true,
    message: t('inputRules.overTimePerWeek'),
  } as TRuleObject,
  overTimePerDay: {
    required: true,
    message: t('inputRules.overTimePerDay'),
  } as TRuleObject,
  // Companies
  usernameCompanies: {
    min: 2,
    max: 35,
    type: 'string',
    required: true,
    message: t('inputRules.usernameCompanies'),
  } as TRuleObject,
  passwordCompanies: {
    min: 6,
    max: 50,
    type: 'string',
    required: true,
    message: t('inputRules.passwordCompanies'),
  } as TRuleObject,
  phoneNumberCompanies: {
    min: 5,
    max: 14,
    type: 'string',
    required: false,
    message: t('inputRules.phoneNumberCompanies'),
  } as TRuleObject,
  locationNameCompanies: {
    min: 1,
    max: 200,
    required: true,
    message: t('inputRules.locationNameCompanies'),
  },
  countryCompanies: {
    min: 1,
    max: 200,
    required: true,
    message: t('inputRules.countryCompanies'),
  } as TRuleObject,
  cityCompanies: {
    min: 1,
    max: 200,
    required: true,
    message: t('inputRules.cityCompanies'),
  } as TRuleObject,
  // CONTACTS
  positionContacts: {
    min: 1,
    max: 200,
    type: 'string',
    required: true,
    message: t('inputRules.positionContacts'),
  } as TRuleObject,
  emailContacts: {
    min: 0,
    max: 50,
    type: 'email',
    message: t('inputRules.emailContacts'),
  } as TRuleObject,
  companyName: {
    min: 1,
    max: 100,
    type: 'string',
    required: true,
    message: t('inputRules.companyName'),
  } as TRuleObject,
  employee: {
    required: true,
    message: t('inputRules.employee'),
  } as TRuleObject,
  // RATES
  startDate: {
    required: true,
    message: t('inputRules.startDate'),
  } as TRuleObject,
  endDate: {
    required: true,
    message: t('inputRules.endDate'),
  } as TRuleObject,
  // PERFORMANCE LOG
  note: {
    required: true,
    max: 5000,
    message: t('inputRules.note'),
  } as TRuleObject,
  // LEAVE REQUESTS
  reason: {
    required: true,
    max: 5000,
    message: t('inputRules.reason'),
  } as TRuleObject,
  // FORMS
  assignedTo: {
    required: false,
    type: 'array',
    message: t('inputRules.assignedTo'),
    max: 500,
  } as TRuleObject,
  select: {
    type: 'array',
    message: t('inputRules.select'),
  } as TRuleObject,
  emailMultiple: {
    type: 'string',
    message: t('inputRules.emailMulti'),
    max: 700,
  },
  date: {
    type: 'object',
    required: false,
    message: t('inputRules.date'),
    whitespace: true,
  },
  equipmentNumber: {
    type: 'string',
    message: t('inputRules.equipmentNumber'),
    max: 100,
  },
  invoiceNumber: {
    type: 'string',
    required: true,
    min: 1,
    max: 200,
    pattern: /^[ a-zA-Zа-яА-Я-0-9]+$/,
    message: t('inputRules.invoiceNumber'),
  },
  earningsRates: {
    type: 'string',
    required: true,
    message: t('inputRules.earningsRates'),
  },
  calendarType: {
    type: 'string',
    required: true,
    message: t('inputRules.calendarType'),
  },
  currency: {
    type: 'string',
    required: true,
    min: 1,
    max: 20,
    message: t('inputRules.currency'),
  },
  partnerCode: {
    min: 5,
    max: 100,
    required: false,
    pattern: /^\S*$/,
    message: t('inputRules.partnerCode'),
  } as TRuleObject,
};
export default rules;
