// Modules
import axios from 'axios';
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
import { API_URL } from 'api/api.config';
// Storage
import webStorage from 'modules/storage';
// Interfaces and types
import { IGetReportParams, TGetReportRes } from 'types/reportTypes';
import { IGetTimeSpentOutsideGeofenceReportRes } from 'types/timeSpentOutsideGeofenceReportTypes';
import { ISignInResData } from 'types/authTypes';
import { TQueryParams } from 'types/appTypes';
// I18n
import { getCurrentLanguage } from 'i18n';

export const getReport = (params: IGetReportParams) => {
  const storageData = webStorage.getData();
  const language = getCurrentLanguage();

  return axios.get<TGetReportRes>(`${API_URL}${ENDPOINTS.reports}`, {
    headers: {
      Authorization: `Bearer ${storageData.accessToken}`,
      Accept: 'application/json',
      'Accept-Language': language,
      'Content-Type': 'application/json',
    },
    responseType: 'blob',
    params,
  });
};

export const getQuickBooksReport = (params: IGetReportParams) => {
  const storageData = webStorage.getData();
  return axios.get<TGetReportRes>(`${API_URL}/integrations/timeSheet-IIF-QBD`, {
    headers: {
      Authorization: `Bearer ${storageData.accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    responseType: 'blob',
    params,
  });
};

export const getUsageReport = () => {
  const storageData = webStorage.getData();
  const language = getCurrentLanguage();

  return axios.get<TGetReportRes>(`${API_URL}${ENDPOINTS.reports}`, {
    headers: {
      Authorization: `Bearer ${storageData.accessToken}`,
      Accept: 'application/json',
      'Accept-Language': language,
      'Content-Type': 'application/json',
    },
    responseType: 'blob',
  });
};

export const refreshToken = () => {
  const storageData = webStorage.getData();
  return axios.patch<ISignInResData>(`${API_URL}${ENDPOINTS.refreshToken}`, {
    refreshToken: storageData?.refreshToken,
    headers: {
      Authorization: `Bearer ${storageData.accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export const getTimeOutsideGeofenceReport = (params: TQueryParams) => {
  return xhr.get<IGetTimeSpentOutsideGeofenceReportRes, IGetTimeSpentOutsideGeofenceReportRes>(
    `${ENDPOINTS.reports}${ENDPOINTS.timeOutsideGeofence}`,
    { params },
  );
};
