// Core
import { CSSProperties, ReactNode, MouseEvent } from 'react';
// Packages
import cn from 'classnames';
import { Checkbox as AntCheckbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
// Styles
import styles from './checkbox.module.scss';

export type TCheckboxChangeEvent = CheckboxChangeEvent;

interface ICheckboxProps {
  autoFocus?: boolean;
  dataCy?: string;
  checked?: boolean;
  defaultChecked?: boolean;
  disabled?: boolean;
  indeterminate?: boolean;
  name?: string;
  value?: any;
  onChange?: (e: CheckboxChangeEvent) => void;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  className?: string;
  style?: CSSProperties;
  size?: 'small' | 'middle' | 'large';
  children?: ReactNode;
}

/**
 * Checkbox component.
 *
 * @description Used for selecting multiple values from several options.
 * If you use only one checkbox, it is the same as using Switch to toggle
 * between two states. The difference is that Switch will trigger the state
 * change directly, but Checkbox just marks the state as changed and this
 * needs to be submitted.
 *
 * @param autoFocus - If get focus when component mounted
 * @param dataCy - prop for testing purpose;
 * @param checked - Specifies whether the checkbox is selected
 * @param defaultChecked - Specifies the initial state: whether or not the checkbox is selected
 * @param disabled - If disable checkbox
 * @param indeterminate -	The indeterminate checked state of checkbox
 * @param name - The name of checkbox
 * @param value - The value of checkbox
 * @param onChange - The callback function that is triggered when the state changes
 * @param className - Component className
 * @param style - To customize the styles
 * @param children - Some ReactNode
 */

const Checkbox = ({
  autoFocus = false,
  checked,
  dataCy,
  defaultChecked,
  disabled = false,
  indeterminate = false,
  name,
  value,
  onChange,
  onClick,
  className,
  style,
  size = 'middle',
  children,
}: ICheckboxProps) => {
  const classNames = cn(styles.checkbox, className, {
    [styles.large]: size === 'large',
    [styles.middle]: size === 'middle',
    [styles.small]: size === 'small',
  });

  return (
    <AntCheckbox
      autoFocus={autoFocus}
      data-cy={dataCy}
      value={value}
      checked={checked}
      defaultChecked={defaultChecked}
      disabled={disabled}
      indeterminate={indeterminate}
      name={name}
      onChange={onChange}
      onClick={onClick}
      className={classNames}
      style={style}
    >
      {children}
    </AntCheckbox>
  );
};

export default Checkbox;
