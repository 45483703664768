// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import {
  getLeavesAsync,
  createLeavesAsync,
  getEmployeeLeavesAsync,
  getEmployeeLeaveDetailsAsync,
  updateLeaveAsync,
  deleteLeaveAsync,
} from './leaveThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import { IErrorRes } from 'types/appTypes';
import {
  IGetLeaveRes,
  IGetEmployeeLeaveRes,
  IGetEmployeeLeaveDetailsRes,
} from 'types/leaveTypes';
import { TSliceReducer, TActionType } from 'redux/store';

type TAction<T extends TActionType> =
  | typeof getLeavesAsync[T]
  | typeof createLeavesAsync[T]
  | typeof getEmployeeLeavesAsync[T]
  | typeof getEmployeeLeaveDetailsAsync[T]
  | typeof updateLeaveAsync[T]
  | typeof deleteLeaveAsync[T];

const handleLoadingReducer: TSliceReducer<IState, TAction<'pending'>> = state => {
  state.isLoading = true;
  state.error = null;
};

const handleEmptyFulfilledReducer: TSliceReducer<IState, TAction<'fulfilled'>> = state => {
  state.isLoading = false;
};

const handleErrorReducer: TSliceReducer<IState, TAction<'rejected'>> = (state, action) => {
  state.isLoading = false;
  state.error = action.payload || null;
};

interface IState {
  leaves: IGetLeaveRes | null;
  employeeLeaves: IGetEmployeeLeaveRes | null;
  leaveDetails: IGetEmployeeLeaveDetailsRes | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: IState = {
  leaves: null,
  employeeLeaves: null,
  leaveDetails: null,
  error: null,
  isLoading: false,
};

const leaveSlice = createSlice({
  name: 'leave',
  initialState,
  reducers: {},
  extraReducers: builder => {
    //* GET RATES ASYNC THUNK
    builder.addCase(getLeavesAsync.pending, handleLoadingReducer);
    builder.addCase(getLeavesAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.leaves = payload;
    });
    builder.addCase(getLeavesAsync.rejected, handleErrorReducer);

    //* CREATE RATES ASYNC THUNK
    builder.addCase(createLeavesAsync.pending, handleLoadingReducer);
    builder.addCase(createLeavesAsync.fulfilled, handleEmptyFulfilledReducer);
    builder.addCase(createLeavesAsync.rejected, handleErrorReducer);

    //* GET EMPLOYEE RATES ASYNC THUNK
    builder.addCase(getEmployeeLeavesAsync.pending, handleLoadingReducer);
    builder.addCase(getEmployeeLeavesAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.employeeLeaves = payload;
    });
    builder.addCase(getEmployeeLeavesAsync.rejected, handleErrorReducer);

    //* GET EMPLOYEE RATES DETAILS ASYNC THUNK
    builder.addCase(getEmployeeLeaveDetailsAsync.pending, handleLoadingReducer);
    builder.addCase(getEmployeeLeaveDetailsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.leaveDetails = payload;
    });
    builder.addCase(getEmployeeLeaveDetailsAsync.rejected, handleErrorReducer);

    //* UPDATE RATES ASYNC THUNK
    builder.addCase(updateLeaveAsync.pending, handleLoadingReducer);
    builder.addCase(updateLeaveAsync.fulfilled, handleEmptyFulfilledReducer);
    builder.addCase(updateLeaveAsync.rejected, handleErrorReducer);

    //* DELETE RATES ASYNC THUNK
    builder.addCase(deleteLeaveAsync.pending, handleLoadingReducer);
    builder.addCase(deleteLeaveAsync.fulfilled, state => {
      state.isLoading = false;
      state.leaveDetails = null;
    });
    builder.addCase(deleteLeaveAsync.rejected, handleErrorReducer);

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export default leaveSlice;
