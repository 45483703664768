// Core
import { Dispatch, SetStateAction } from 'react';
// Packages
import { Drawer } from 'antd';
// Components
import { Navigation } from 'components';
// I18n
import { t } from 'i18n';
// Styles
import styles from './authSideBarDesktop.module.scss';

interface IProps {
  isMenuVisible: boolean;
  setIsMenuVisible: Dispatch<SetStateAction<boolean>>;
}

const AuthSideBarDesktop = ({ isMenuVisible, setIsMenuVisible }: IProps) => {
  return (
    <>
      <Drawer
        width="420px"
        placement="left"
        title={t('header.menu')}
        visible={isMenuVisible}
        onClose={() => setIsMenuVisible(prevState => !prevState)}
        className={styles.sidebarMenu}
      >
        <Navigation
          isMenuVisible={isMenuVisible}
          mode="vertical"
          onToggleMenu={() => setIsMenuVisible(prevState => !prevState)}
        />
      </Drawer>
    </>
  );
};

export default AuthSideBarDesktop;
