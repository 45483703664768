// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import {
  getGroupsAsync,
  createGroupAsync,
  getGroupDetailsAsync,
  updateGroupAsync,
  deleteGroupAsync,
} from './groupThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import { IErrorRes } from 'types/appTypes';
import { TSliceReducer, TActionType } from 'redux/store';
import { IGetGroupsRes, IGetGroupDetailsRes } from 'types/groupTypes';

type TAction<T extends TActionType> =
  | typeof getGroupsAsync[T]
  | typeof createGroupAsync[T]
  | typeof getGroupDetailsAsync[T]
  | typeof updateGroupAsync[T]
  | typeof deleteGroupAsync[T];

const handleLoadingReducer: TSliceReducer<IState, TAction<'pending'>> = state => {
  state.isLoading = true;
  state.error = null;
};

const handleEmptyFulfilledReducer: TSliceReducer<IState, TAction<'fulfilled'>> = state => {
  state.isLoading = false;
};

const handleErrorReducer: TSliceReducer<IState, TAction<'rejected'>> = (state, action) => {
  state.isLoading = false;
  state.error = action.payload || null;
};

interface IState {
  groups: IGetGroupsRes | null;
  groupDetails: IGetGroupDetailsRes | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: IState = {
  groups: null,
  groupDetails: null,
  error: null,
  isLoading: false,
};

const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {},
  extraReducers: builder => {
    //* GET GROUPS ASYNC THUNK
    builder.addCase(getGroupsAsync.pending, handleLoadingReducer);
    builder.addCase(getGroupsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.groups = payload;
    });
    builder.addCase(getGroupsAsync.rejected, handleErrorReducer);

    //* CREATE GROUP ASYNC THUNK
    builder.addCase(createGroupAsync.pending, handleLoadingReducer);
    builder.addCase(createGroupAsync.fulfilled, handleEmptyFulfilledReducer);
    builder.addCase(createGroupAsync.rejected, handleErrorReducer);

    //* GET GROUP DETAILS ASYNC THUNK
    builder.addCase(getGroupDetailsAsync.pending, handleLoadingReducer);
    builder.addCase(getGroupDetailsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.groupDetails = payload;
    });
    builder.addCase(getGroupDetailsAsync.rejected, handleErrorReducer);

    //* UPDATE GROUP ASYNC THUNK
    builder.addCase(updateGroupAsync.pending, handleLoadingReducer);
    builder.addCase(updateGroupAsync.fulfilled, handleEmptyFulfilledReducer);
    builder.addCase(updateGroupAsync.rejected, handleErrorReducer);

    //* DELETE GROUP ASYNC THUNK
    builder.addCase(deleteGroupAsync.pending, handleLoadingReducer);
    builder.addCase(deleteGroupAsync.fulfilled, state => {
      state.isLoading = false;
      state.groupDetails = null;
    });
    builder.addCase(deleteGroupAsync.rejected, handleErrorReducer);

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export default groupsSlice;
