// Components
import { Spinner } from 'components/lib';
// Styles
import styles from './loader.module.scss';

interface IProps {
  isLoading?: boolean;
}

const css = `
  display: block;
  margin: calc(100vh * 0.4) auto;
`;

const Loader = ({ isLoading = false }: IProps) => (
  <div className={styles.loaderWrap}>
    <Spinner size={30} isLoading={isLoading} css={css} />
  </div>
);

export default Loader;
