// Redux
import { useAppDispatch } from 'redux/store';
import { signOutAsync, clearReducer } from 'redux/auth/authThunk';
// Modules
import storage from 'modules/storage';

const useSignOut = () => {
  const dispatch = useAppDispatch();

  const signOut = async () => {
    const refreshToken = storage.getData()?.refreshToken;
    const resultAction = await dispatch(signOutAsync({ refreshToken }));

    if (signOutAsync.fulfilled.match(resultAction)) {
      dispatch(clearReducer());
    }
  };

  return signOut;
};

export default useSignOut;
