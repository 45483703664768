// Core
import { useState, Dispatch, SetStateAction } from 'react';
// Helpers
import { GOOGLE_ADDRESS_PARTS } from 'helpers/data/constants';
// Types
import { IFieldName } from './useAddressForm';

export type TAddress = {
  address?: string;
  state?: string;
  city?: string;
  country?: string;
  postalCode?: string;
  formField?: string;
  handlerFieldName?: IFieldName | null;
};

type TGetCoordFromAddress = ({ address, city, postalCode, state, country }: TAddress) => void;
type TMarker = { lat: number; lng: number } | null;
type TGetAddressFromCoord = (
  { latLng }: google.maps.MapMouseEvent,
  formField?: string,
) => void;

type TProps = {
  setFieldsValue: (
    value: { [x: string]: Record<string, string> } | Record<string, string>,
  ) => void;
  setUserAddress: Dispatch<SetStateAction<Record<string, string>>>;
};

type TUseMapWithGeocoder = (fn: TProps) => {
  markerPosition: TMarker;
  getAddressFromCoord: TGetAddressFromCoord;
  getCoordFromAddress: TGetCoordFromAddress;
  countryRestriction: string | undefined;
};

const useMapWithGeocoder: TUseMapWithGeocoder = ({
  setFieldsValue,
  setUserAddress,
}: TProps) => {
  const [markerPosition, setMarkerPosition] = useState<TMarker>(null);
  const [countryRestriction, setCountryRestriction] = useState<string | undefined>(undefined);

  const geocoder = new google.maps.Geocoder();

  // Invoke after setting a point at the map
  const getAddressFromCoord: TGetAddressFromCoord = ({ latLng }, formField) => {
    const location = { lat: latLng?.lat() as number, lng: latLng?.lng() as number };

    geocoder.geocode({ location }, (res, status) => {
      if (!res || status !== 'OK') return;
      const addressData = res[0].address_components;

      let address = '';

      addressData.forEach(item => {
        if (item.types.includes(GOOGLE_ADDRESS_PARTS.STREET_NUMBER)) {
          address += item.short_name;
        }

        if (item.types.includes(GOOGLE_ADDRESS_PARTS.ROUTE)) {
          address += ` ${item.long_name}`;
        }

        if (item.types.includes(GOOGLE_ADDRESS_PARTS.CITY)) {
          setFieldsValue(
            formField ? { [formField]: { city: item.long_name } } : { city: item.long_name },
          );
          setUserAddress((userAddress: Record<string, string>) => ({
            ...userAddress,
            city: item.long_name,
          }));
        }

        if (item.types.includes(GOOGLE_ADDRESS_PARTS.COUNTRY)) {
          setFieldsValue(
            formField
              ? { [formField]: { country: item.long_name } }
              : { country: item.long_name },
          );
          setUserAddress((userAddress: Record<string, string>) => ({
            ...userAddress,
            country: item.long_name,
          }));

          setCountryRestriction(item.short_name);
        }

        if (item.types.includes(GOOGLE_ADDRESS_PARTS.STATE)) {
          setFieldsValue(
            formField ? { [formField]: { state: item.long_name } } : { state: item.long_name },
          );
          setUserAddress((userAddress: Record<string, string>) => ({
            ...userAddress,
            state: item.long_name,
          }));
        }

        if (item.types.includes(GOOGLE_ADDRESS_PARTS.POSTAL_CODE)) {
          setFieldsValue(
            formField
              ? { [formField]: { postalCode: item.short_name } }
              : { postalCode: item.short_name },
          );
          setUserAddress((userAddress: Record<string, string>) => ({
            ...userAddress,
            postalCode: item.short_name,
          }));
        }
      });

      setFieldsValue(
        formField
          ? { [formField]: { address1: address.trim() } }
          : { address1: address.trim() },
      );
      setUserAddress((userAddress: Record<string, string>) => ({
        ...userAddress,
        address1: address,
      }));

      setMarkerPosition(location);
    });
  };

  // Invoke after changing any input address (country, city, etc...)
  const getCoordFromAddress: TGetCoordFromAddress = ({
    address,
    state,
    city,
    country,
    postalCode,
    formField,
    handlerFieldName,
  }) => {
    if (address && !city && !state && !postalCode) {
      return geocoder.geocode({ address: `${address}}` }, (res, status) => {
        if (!res || status !== 'OK') return;

        setMarkerPosition({
          lat: res[0].geometry.location.lat(),
          lng: res[0].geometry.location.lng(),
        });

        const addressData = res[0].address_components;
        addressData.forEach(item => {
          if (item.types.includes(GOOGLE_ADDRESS_PARTS.COUNTRY)) {
            const value = formField
              ? { [formField]: { country: item.long_name } }
              : { country: item.long_name };
            setFieldsValue(value);
            setUserAddress((userAddress: Record<string, string>) => ({
              ...userAddress,
              country: item.long_name,
            }));

            setCountryRestriction(item.short_name);
          }

          if (item.types.includes(GOOGLE_ADDRESS_PARTS.CITY)) {
            const value = formField
              ? { [formField]: { city: item.long_name } }
              : { city: item.long_name };
            setFieldsValue(value);
            setUserAddress((userAddress: Record<string, string>) => ({
              ...userAddress,
              city: item.long_name,
            }));
          }

          if (item.types.includes(GOOGLE_ADDRESS_PARTS.STATE)) {
            const value = formField
              ? { [formField]: { state: item.long_name } }
              : { state: item.long_name };
            setFieldsValue(value);
            setUserAddress((userAddress: Record<string, string>) => ({
              ...userAddress,
              state: item.long_name,
            }));
          }

          if (item.types.includes(GOOGLE_ADDRESS_PARTS.POSTAL_CODE)) {
            const value = formField
              ? { [formField]: { postalCode: item.short_name } }
              : { postalCode: item.short_name };
            setFieldsValue(value);
            setUserAddress((userAddress: Record<string, string>) => ({
              ...userAddress,
              postalCode: item.short_name,
            }));
          }

          if (item.types.includes(GOOGLE_ADDRESS_PARTS.ROUTE)) {
            let streetAddress = item.long_name;
            addressData.forEach(subItem => {
              if (subItem.types.includes(GOOGLE_ADDRESS_PARTS.STREET_NUMBER)) {
                streetAddress = `${subItem.long_name} ${item.long_name}`;
              }
              const value = formField
                ? {
                    [formField]: { address1: streetAddress },
                  }
                : { address1: streetAddress };
              setFieldsValue(value);
              setUserAddress((userAddress: Record<string, string>) => ({
                ...userAddress,
                address1: streetAddress,
              }));
            });
          }
        });
      });
    }

    if (country && handlerFieldName === 'country') {
      geocoder.geocode({ address: `${country}}` }, (res, status) => {
        if (!res || status !== 'OK') return;

        const addressData = res[0].address_components;
        addressData.forEach(item => {
          if (item.types.includes(GOOGLE_ADDRESS_PARTS.COUNTRY)) {
            const value = formField
              ? { [formField]: { country: item.long_name } }
              : { country: item.long_name };
            setFieldsValue(value);
            setUserAddress((userAddress: Record<string, string>) => ({
              ...userAddress,
              country: item.long_name,
            }));

            setCountryRestriction(item.short_name);
          }
        });
      });
    }

    if (state && handlerFieldName === 'state') {
      geocoder.geocode({ address: `${state}}` }, (res, status) => {
        if (!res || status !== 'OK') return;

        const addressData = res[0].address_components;
        addressData.forEach(item => {
          if (item.types.includes(GOOGLE_ADDRESS_PARTS.STATE)) {
            const value = formField
              ? { [formField]: { state: item.long_name } }
              : { state: item.long_name };
            setFieldsValue(value);
            setUserAddress((userAddress: Record<string, string>) => ({
              ...userAddress,
              state: item.long_name,
            }));
          }
        });
      });
    }

    if (city && handlerFieldName === 'city') {
      geocoder.geocode({ address: `${city}}` }, (res, status) => {
        if (!res || status !== 'OK') return;

        const addressData = res[0].address_components;
        addressData.forEach(item => {
          if (item.types.includes(GOOGLE_ADDRESS_PARTS.CITY)) {
            const value = formField
              ? { [formField]: { city: item.long_name } }
              : { city: item.long_name };
            setFieldsValue(value);
            setUserAddress((userAddress: Record<string, string>) => ({
              ...userAddress,
              city: item.long_name,
            }));
          }
        });
      });
    }

    if (address || city || state || country || postalCode) {
      geocoder.geocode(
        { address: `${address}, ${city}, ${state}, ${country} ${postalCode}` },
        (res, status) => {
          if (!res || status !== 'OK') return;

          setMarkerPosition({
            lat: res[0].geometry.location.lat(),
            lng: res[0].geometry.location.lng(),
          });
        },
      );
    }
  };

  return {
    markerPosition,
    getAddressFromCoord,
    getCoordFromAddress,
    countryRestriction,
  };
};

export default useMapWithGeocoder;
