// Core
import { useEffect } from 'react';
// Components
import Uploader from '../Uploader';
import { ANCHOR_IDS } from '../SideBar';
import {
  FormItem,
  Card,
  GridRow,
  GridCol,
  TextInput,
  Select,
  UrlInput,
  EmailInput,
  Switch,
} from 'components/lib';
// Custom hooks
import { useFetch } from 'customHooks';
// API
import { IndustriesAPI } from 'api/endpoints';
import { TGetIndustriesRes } from 'types/industryTypes';
// Helpers
import getInputRules from 'helpers/getRules';
import mfaOptions from 'helpers/data/companySettingsData/mfaOptions.json';
import { ROLES } from 'helpers/data/constants';
// Interfaces and types
import { IErrorRes } from 'types/appTypes';
import { TQueryParams } from 'types/appTypes';
import { TSearchOption } from 'components/lib/Select';
import { TRole } from 'types/employeeTypes';
// I18n
import { t } from 'i18n';
import localesList from 'i18n/localesList/localesList';
// Styles
import styles from './mainSettings.module.scss';

interface IProps {
  hasUserPermissions: boolean;
  logoImage?: string;
  role?: TRole;
  errorInfo?: IErrorRes | null;
}

interface ILocaleItem {
  label: string;
  localeAbb: string;
  localeDominantAbb: string;
}

const getNumberFormatsSelectExample = ({
  localeAbb,
  localeDominantAbb,
}: {
  localeAbb: string;
  localeDominantAbb: string;
}) => {
  return new Intl.NumberFormat([localeAbb, localeDominantAbb, 'en-US'], {
    minimumFractionDigits: 2,
  }).format(55555555.55);
};

const MainSettings = ({ hasUserPermissions, logoImage, role, errorInfo }: IProps) => {
  const [{ response: industries }, doFetchIndustries, doResetResponseIndustries] = useFetch<
    TGetIndustriesRes,
    TQueryParams,
    typeof IndustriesAPI.getIndustries
  >(IndustriesAPI.getIndustries);

  useEffect(() => {
    const abortController = new AbortController();

    doFetchIndustries({});
    return () => {
      abortController.abort();
      doResetResponseIndustries();
    };
  }, [doFetchIndustries, doResetResponseIndustries]);

  const handleSearchLocale = (input: string, option: TSearchOption) => {
    return (option?.children as string).toLowerCase().includes(input.toLowerCase());
  };

  const isAdmin = role === ROLES.business_admin || ROLES.admin;
  return (
    <Card
      id={ANCHOR_IDS.MAIN_SETTINGS.slice(1)}
      title={t('settingsModal.mainSettings')}
      className="infoCard"
    >
      <GridRow gutter={32}>
        <GridCol span={12}>
          <GridRow>
            <label className={styles.label} htmlFor="photoUploader">
              {t('settingsModal.companyImage')}
            </label>

            <GridCol span={24} className={styles.uploaderContainer}>
              <Uploader disabled={!hasUserPermissions} logoImage={logoImage} />
            </GridCol>
          </GridRow>
        </GridCol>

        <GridCol span={12}>
          <GridRow justify="end" gutter={32}>
            <GridCol span={24}>
              <FormItem
                required
                name={['main', 'name']}
                label={t('settingsModal.name')}
                rules={getInputRules('businessName')}
                validateStatus={errorInfo?.errors?.['main.name'] ? 'error' : undefined}
                help={errorInfo?.errors?.['main.name']}
              >
                <TextInput disabled={!hasUserPermissions} size="large" />
              </FormItem>
            </GridCol>

            <GridCol span={24}>
              <FormItem
                required
                name={['main', 'industryId']}
                label={t('settingsModal.industry')}
                rules={getInputRules('industryId')}
                validateStatus={errorInfo?.errors?.['main.industryId'] ? 'error' : undefined}
                help={errorInfo?.errors?.['main.industryId']}
              >
                <Select size="large" disabled={!hasUserPermissions || !industries}>
                  {industries?.map(({ id, name }) => (
                    <Select.Option key={id} value={id}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
            </GridCol>
          </GridRow>
        </GridCol>
      </GridRow>

      <GridRow gutter={32}>
        <GridCol span={12}>
          <FormItem
            required
            name={['main', 'accountName']}
            label={t('settingsModal.webAddress')}
            rules={getInputRules('webAddress')}
            validateStatus={errorInfo?.errors?.['main.accountName'] ? 'error' : undefined}
            help={errorInfo?.errors?.['main.accountName']}
          >
            <UrlInput
              disabled={!hasUserPermissions}
              size="large"
              placeholder={t('settingsModal.webAddress')}
            />
          </FormItem>
        </GridCol>

        <GridCol span={12}>
          <FormItem
            name={['main', 'newEmployeeDefaultPassword']}
            label={t('settingsModal.newEmployeeDefaultPassword')}
            rules={getInputRules('passwordPattern')}
            validateStatus={
              errorInfo?.errors?.['main.newEmployeeDefaultPassword'] ? 'error' : undefined
            }
            help={errorInfo?.errors?.['main.newEmployeeDefaultPassword']}
            className={styles.formItem}
          >
            <TextInput disabled={!hasUserPermissions} size="large" />
          </FormItem>
        </GridCol>

        <GridCol span={24}>
          <FormItem
            required
            name={['main', 'emailNotificationsRecipients']}
            label={t('settingsModal.emailForNotifications')}
            rules={getInputRules('emailMultiple')}
            help={errorInfo?.errors?.['main.emailNotificationsRecipients']}
            validateStatus={
              errorInfo?.errors?.['main.emailNotificationsRecipients'] ? 'error' : undefined
            }
          >
            <EmailInput
              disabled={!hasUserPermissions}
              multiple
              size="large"
              placeholder={t('settingsModal.emailForNotifications')}
            />
          </FormItem>
        </GridCol>

        <GridCol span={24}>
          <FormItem
            required
            name={['main', 'numberLocale']}
            label={t('settingsModal.numberLocale')}
            rules={getInputRules('required')}
            validateStatus={errorInfo?.errors?.['main.numberLocale'] ? 'error' : undefined}
            help={errorInfo?.errors?.['main.numberLocale']}
            initialValue={'en-US'}
          >
            <Select
              showArrow
              showSearch
              size="large"
              disabled={!hasUserPermissions}
              filterOption={handleSearchLocale}
            >
              {(localesList as ILocaleItem[])?.map(
                ({ label, localeAbb, localeDominantAbb }) => (
                  <Select.Option key={localeAbb} value={localeAbb}>
                    {`${label}${' '}
                    ${t('settingsModal.example')}:${' '}
                    ${getNumberFormatsSelectExample({ localeAbb, localeDominantAbb })}`}
                  </Select.Option>
                ),
              )}
            </Select>
          </FormItem>
        </GridCol>

        <GridCol span={24}>
          <FormItem
            name={['main', 'isJobVisibleToAll']}
            label={t('settingsModal.isJobVisibleToAll')}
            valuePropName="checked"
            initialValue={true}
            validateStatus={
              errorInfo?.errors?.['main.isJobVisibleToAll'] ? 'error' : undefined
            }
            help={errorInfo?.errors?.['main.isJobVisibleToAll']}
            className={styles.switchItem}
          >
            <Switch disabled={!hasUserPermissions} className={styles.switch} />
          </FormItem>
        </GridCol>

        <GridCol span={24}>
          <FormItem
            name={['main', 'isEmployeeGroupRequired']}
            label={t('settingsModal.isEmployeeGroupRequired')}
            valuePropName="checked"
            initialValue={false}
            validateStatus={
              errorInfo?.errors?.['main.isEmployeeGroupRequired'] ? 'error' : undefined
            }
            help={errorInfo?.errors?.['main.isEmployeeGroupRequired']}
            className={styles.switchItem}
          >
            <Switch disabled={!hasUserPermissions} className={styles.switch} />
          </FormItem>
        </GridCol>

        <GridCol span={24}>
          <FormItem
            required
            name={['main', 'twoFactorMethod']}
            label={t('settingsModal.mfa')}
            validateStatus={errorInfo?.errors?.['main.twoFactorMethod'] ? 'error' : undefined}
            help={errorInfo?.errors?.['main.twoFactorMethod']}
          >
            <Select size="large" disabled={!isAdmin}>
              {mfaOptions?.map(({ label, value }) => (
                <Select.Option key={label} value={value}>
                  {t(`settingsModal.${label}`)}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
        </GridCol>
      </GridRow>
    </Card>
  );
};

export default MainSettings;
