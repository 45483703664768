// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import { getTimeOutsideGeofenceReport } from './outsideGeofenceReportThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import { IErrorRes } from 'types/appTypes';
import { IGetTimeSpentOutsideGeofenceReportRes } from 'types/timeSpentOutsideGeofenceReportTypes';

interface ISliceTimeOutsideGeofenceState {
  timeOutsideGeofence: IGetTimeSpentOutsideGeofenceReportRes | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: ISliceTimeOutsideGeofenceState = {
  timeOutsideGeofence: null,
  error: null,
  isLoading: false,
};

const timeOutsideGeofenceReport = createSlice({
  name: 'timeOutsideGeofenceReport',
  initialState,
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    //* GET TIME OUTSIDE GEOFENCE ASYNC THUNK
    builder.addCase(getTimeOutsideGeofenceReport.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getTimeOutsideGeofenceReport.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.timeOutsideGeofence = payload;
    });
    builder.addCase(getTimeOutsideGeofenceReport.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export default timeOutsideGeofenceReport;
