// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import {
  getDocsAsync,
  createDocAsync,
  getEmployeeDocDetailsAsync,
  getEmployeeDocsAsync,
  updateDocAsync,
  deleteDocAsync,
} from './docThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import { IErrorRes } from 'types/appTypes';
import { TSliceReducer, TActionType } from 'redux/store';
import { IGetDocsRes, IGetEmployeeDocsRes, IGetEmployeeDocDetailsRes } from 'types/docsType';

type TAction<T extends TActionType> =
  | typeof getDocsAsync[T]
  | typeof createDocAsync[T]
  | typeof getEmployeeDocDetailsAsync[T]
  | typeof getEmployeeDocsAsync[T]
  | typeof updateDocAsync[T]
  | typeof deleteDocAsync[T];

const handleLoadingReducer: TSliceReducer<IState, TAction<'pending'>> = state => {
  state.isLoading = true;
  state.error = null;
};

const handleEmptyFulfilledReducer: TSliceReducer<IState, TAction<'fulfilled'>> = state => {
  state.isLoading = false;
};

const handleErrorReducer: TSliceReducer<IState, TAction<'rejected'>> = (state, action) => {
  state.isLoading = false;
  state.error = action.payload || null;
};

interface IState {
  docs: IGetDocsRes | null;
  employeeDocs: IGetEmployeeDocsRes | null;
  docDetails: IGetEmployeeDocDetailsRes | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: IState = {
  docs: null,
  employeeDocs: null,
  docDetails: null,
  error: null,
  isLoading: false,
};

const docsSlice = createSlice({
  name: 'docs',
  initialState,
  reducers: {},
  extraReducers: builder => {
    //* GET DOCS ASYNC THUNK
    builder.addCase(getDocsAsync.pending, handleLoadingReducer);
    builder.addCase(getDocsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.docs = payload;
    });
    builder.addCase(getDocsAsync.rejected, handleErrorReducer);

    //* CREATE DOC ASYNC THUNK
    builder.addCase(createDocAsync.pending, handleLoadingReducer);
    builder.addCase(createDocAsync.fulfilled, handleEmptyFulfilledReducer);
    builder.addCase(createDocAsync.rejected, handleErrorReducer);

    //* GET DOC DETAILS ASYNC THUNK
    builder.addCase(getEmployeeDocDetailsAsync.pending, handleLoadingReducer);
    builder.addCase(getEmployeeDocDetailsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.docDetails = payload;
    });
    builder.addCase(getEmployeeDocDetailsAsync.rejected, handleErrorReducer);

    //* GET EMPLOYEE DOCS ASYNC THUNK
    builder.addCase(getEmployeeDocsAsync.pending, handleLoadingReducer);
    builder.addCase(getEmployeeDocsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.employeeDocs = payload;
    });
    builder.addCase(getEmployeeDocsAsync.rejected, handleErrorReducer);

    //* UPDATE DOC ASYNC THUNK
    builder.addCase(updateDocAsync.pending, handleLoadingReducer);
    builder.addCase(updateDocAsync.fulfilled, handleEmptyFulfilledReducer);
    builder.addCase(updateDocAsync.rejected, handleErrorReducer);

    //* DELETE DOC ASYNC THUNK
    builder.addCase(deleteDocAsync.pending, handleLoadingReducer);
    builder.addCase(deleteDocAsync.fulfilled, state => {
      state.isLoading = false;
      state.docDetails = null;
    });
    builder.addCase(deleteDocAsync.rejected, handleErrorReducer);

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export default docsSlice;
