// Core
import { CSSProperties, ReactNode } from 'react';
// Packages
import { Statistic } from 'antd';
import cx from 'classnames';
// Types
import { countdownValueType } from 'antd/lib/statistic/utils';
// Styles
import './countDown.module.scss';

const { Countdown } = Statistic;

interface ICountDown {
  format?: string;
  prefix?: ReactNode;
  suffix?: ReactNode;
  title?: ReactNode;
  value?: number;
  valueStyle?: CSSProperties;
  className?: string;
  onFinish?: () => void;
  onChange?: (value?: countdownValueType) => void;
}

/**
 * @param format - Format as moment
 * @param prefix - The prefix node of value
 * @param suffix - The suffix node of value
 * @param title - Display title
 * @param value - Set target countdown time
 * @param valueStyle - 	Set value css style
 * @param className - 	Component className
 * @param onFinish - Trigger when time's up
 * @param onChange - Trigger when time's changing
 */

function CountDown({
  format,
  prefix,
  suffix,
  title,
  value,
  valueStyle,
  className,
  onFinish,
  onChange,
}: ICountDown) {
  return (
    <Countdown
      format={format}
      prefix={prefix}
      suffix={suffix}
      title={title}
      value={value}
      className={cx('countDown', className)}
      valueStyle={valueStyle}
      onFinish={onFinish}
      onChange={onChange}
    />
  );
}

export default CountDown;
