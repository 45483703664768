//@ts-noCheck
// Interfaces and types
import { IGetUserRes } from 'types/userTypes';

const getFullNameInitials = (user: IGetUserRes | null) => {
  if (!user) {
    return 'XX';
  }

  if (!user.firstName && user.username) {
    return user.username.charAt(0);
  }

  const { firstName, lastName } = user;

  return `${firstName?.charAt(0).toUpperCase()}${lastName?.charAt(0).toUpperCase()}`;
};

export default getFullNameInitials;
