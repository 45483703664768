// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import {
  getJobRatesAsync,
  getJobRatesByJobIdAsync,
  createJobRateAsync,
  getJobRateDetailsAsync,
  updateJobRateAsync,
  deleteJobRateAsync,
} from './jobRatesThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import {
  IGetJobRatesRes,
  IGetJobRatesByJobIdRes,
  IGetJobRateDetailsRes,
} from 'types/jobRatesTypes';
import { IErrorRes } from 'types/appTypes';

interface ISliceJobsRateState {
  jobRates: IGetJobRatesRes | IGetJobRatesByJobIdRes | null;
  jobRateDetails: IGetJobRateDetailsRes | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: ISliceJobsRateState = {
  jobRates: null,
  jobRateDetails: null,
  error: null,
  isLoading: false,
};

const jobRatesSlice = createSlice({
  name: 'jobRates',
  initialState,
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    //* GET JOB_RATES ASYNC THUNK
    builder.addCase(getJobRatesAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getJobRatesAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.jobRates = payload;
    });
    builder.addCase(getJobRatesAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* GET JOB_RATES BY JOB ID ASYNC THUNK
    builder.addCase(getJobRatesByJobIdAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getJobRatesByJobIdAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.jobRates = payload;
    });
    builder.addCase(getJobRatesByJobIdAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CREATE JOB_RATE ASYNC THUNK
    builder.addCase(createJobRateAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(createJobRateAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(createJobRateAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    // //* GET JOB_RATE DETAILS ASYNC THUNK
    builder.addCase(getJobRateDetailsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getJobRateDetailsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.jobRateDetails = payload;
    });
    builder.addCase(getJobRateDetailsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* UPDATE JOB_RATE (one) ASYNC THUNK
    builder.addCase(updateJobRateAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(updateJobRateAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(updateJobRateAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* DELETE JOB_RATE ASYNC THUNK
    builder.addCase(deleteJobRateAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(deleteJobRateAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteJobRateAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export default jobRatesSlice;
