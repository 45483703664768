// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Types
import { TQueryParams, IResMessage } from 'types/appTypes';
import {
  IGetEquipmentLogItem,
  IEquipmentLogCreateBody,
  IEquipmentLogCreateRes,
  IEquipmentLogUpdateBody,
  IEquipmentLogUpdateRes,
  IGetEquipmentLogsRes,
} from 'types/equipmentLogsTypes';

export const getEquipmentLogsItems = (params: TQueryParams) => {
  return xhr.get<IGetEquipmentLogsRes, IGetEquipmentLogsRes>(`${ENDPOINTS.equipmentLogs}`, {
    params,
  });
};

export const getEquipmentLogs = (equipmentId: number, params: TQueryParams) => {
  return xhr.get<IGetEquipmentLogsRes, IGetEquipmentLogsRes>(
    `${ENDPOINTS.equipment}/${equipmentId}${ENDPOINTS.equipmentLogs}`,
    {
      params,
    },
  );
};

export const getEquipmentLog = (equipmentId: number, id: number) => {
  return xhr.get<IGetEquipmentLogItem, IGetEquipmentLogItem>(
    `${ENDPOINTS.equipment}/${equipmentId}${ENDPOINTS.equipmentLogs}/${id}`,
  );
};

export const createEquipmentLog = ({ equipmentId, body }: IEquipmentLogCreateBody) => {
  return xhr.post<IEquipmentLogCreateRes, IEquipmentLogCreateRes>(
    `${ENDPOINTS.equipment}/${equipmentId}${ENDPOINTS.equipmentLogs}`,
    body,
  );
};

export const updateEquipmentLog = ({ id, body, equipmentId }: IEquipmentLogUpdateBody) => {
  return xhr.put<IEquipmentLogUpdateRes, IEquipmentLogUpdateRes>(
    `${ENDPOINTS.equipment}/${equipmentId}${ENDPOINTS.equipmentLogs}/${id}`,
    body,
  );
};

export const deleteEquipmentLog = (equipmentId: number, id: number) => {
  return xhr.delete<IResMessage, IResMessage>(
    `${ENDPOINTS.equipment}/${equipmentId}${ENDPOINTS.equipmentLogs}/${id}`,
  );
};
