// Core
import { ComponentType, useEffect } from 'react';
// Packages
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
// Redux
import { useAppSelector, STORE_KEYS, useAppDispatch } from 'redux/store';
import { saveVisitedPageLabelAsync } from 'redux/nav/navThunk';
// Routes
import { TRoute, ROUTES } from './routes';

type TProps = {
  component: ComponentType<RouteComponentProps>;
} & TRoute;

const PrivateRoute = ({ component: Component, routes, ...routeProps }: TProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (routeProps.label && routeProps.label !== 'helpPage')
      dispatch(
        saveVisitedPageLabelAsync({
          label: routeProps.label,
        }),
      );
  }, [routeProps.label, dispatch]);

  const { isLoggedIn, userId } = useAppSelector(STORE_KEYS.AUTH);

  const isAuth = isLoggedIn && userId;

  return (
    <Route
      {...routeProps}
      render={props =>
        isAuth ? <Component routes={routes} {...props} /> : <Redirect to={ROUTES.INTRO} />
      }
    />
  );
};

export default PrivateRoute;
