interface IProps {
  primaryColor?: string;
}

/**
 * @param primaryColor - The icon color. By default '#ffffff'.
 */

const User = ({ primaryColor = '#ffffff' }: IProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 11.3333C12.9379 11.3333 13.8547 11.0596 14.6345 10.5468C15.4143 10.0341 16.0221 9.30523 16.381 8.45251C16.7399 7.59979 16.8338 6.66148 16.6508 5.75624C16.4679 4.851 16.0162 4.01948 15.3531 3.36683C14.6899 2.71419 13.845 2.26973 12.9251 2.08967C12.0053 1.90961 11.0518 2.00202 10.1853 2.35523C9.31886 2.70844 8.57827 3.30657 8.05722 4.074C7.53617 4.84143 7.25806 5.74368 7.25806 6.66666C7.2595 7.9039 7.75955 9.09006 8.64853 9.96492C9.5375 10.8398 10.7428 11.3319 12 11.3333ZM12 3.33333C12.6699 3.33333 13.3248 3.52883 13.8818 3.8951C14.4388 4.26137 14.8729 4.78196 15.1293 5.39105C15.3856 6.00013 15.4527 6.67035 15.322 7.31696C15.1913 7.96356 14.8687 8.5575 14.395 9.02367C13.9213 9.48985 13.3178 9.80731 12.6608 9.93593C12.0038 10.0645 11.3227 9.99854 10.7038 9.74625C10.0849 9.49396 9.55591 9.06671 9.18373 8.51855C8.81155 7.97039 8.6129 7.32593 8.6129 6.66666C8.61398 5.78293 8.97118 4.9357 9.60615 4.31081C10.2411 3.68592 11.102 3.33439 12 3.33333Z"
      fill={primaryColor}
    />
    <path
      d="M13.5603 12.6667H10.4397C8.99754 12.6685 7.61497 13.233 6.59522 14.2366C5.57548 15.2402 5.00179 16.6008 5 18.02V21.3333C5 21.5101 5.07137 21.6797 5.19841 21.8047C5.32545 21.9298 5.49776 22 5.67742 22H18.3226C18.5022 22 18.6745 21.9298 18.8016 21.8047C18.9286 21.6797 19 21.5101 19 21.3333V18.02C18.9982 16.6008 18.4245 15.2402 17.4048 14.2366C16.385 13.233 15.0025 12.6685 13.5603 12.6667ZM17.6452 20.6667H6.35484V18.02C6.35609 16.9542 6.78686 15.9324 7.55265 15.1788C8.31843 14.4252 9.3567 14.0013 10.4397 14H13.5603C14.6433 14.0013 15.6816 14.4252 16.4474 15.1788C17.2131 15.9324 17.6439 16.9542 17.6452 18.02V20.6667Z"
      fill={primaryColor}
    />
  </svg>
);

export default User;
