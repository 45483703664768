// Routes
import { ROUTES } from 'router/routes';

export interface IParams {
  hasScheduleAccess: boolean;
  hasGpsAccess: boolean;
  hasCheckpointsAccess?: boolean;
}

// **Determine redirect after user sign-in/sign-up/reload page
// For business admins and managers (with permission to Schedule page) Schedule page is main and first
// For managers without permission to Schedule page - Gps page is main and first, because all managers have permissions to GPS page
// For business company GPS page is main (if it has not permission to GPS page - redirect to Checkpoints page  -> if no permission -> redirect to Form page)

const defineRedirectRoute = ({
  hasScheduleAccess = false,
  hasGpsAccess = false,
  hasCheckpointsAccess = false,
}: IParams) => {
  if (hasScheduleAccess) {
    return ROUTES.SCHEDULE;
  }

  if (hasGpsAccess) {
    return ROUTES.GPS;
  }

  if (hasCheckpointsAccess) {
    return ROUTES.CHECKPOINTS;
  }
  return ROUTES.FORMS;
};

export default defineRedirectRoute;
