// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Interfaces and types
import {
  IGetLocationsDocsRes,
  ICreateLocationDocRes,
  ICreateLocationDocBodyWithParam,
  IGetLocationDocDetailsParams,
  IGetLocationDocDetailsRes,
  IUpdateLocationDocDetailsParams,
  IDeleteLocationDocParams,
  IGetLocationsDocsByLocationParams,
  IGetLocationsDocsByLocationRes,
} from 'types/locationDocsTypes';
import { TQueryParams, IResMessage } from 'types/appTypes';

export const getLocationsDocs = (params: TQueryParams) => {
  return xhr.get<IGetLocationsDocsRes, IGetLocationsDocsRes>(`${ENDPOINTS.locationDocs}`, {
    params,
  });
};

export const getLocationsDocsByLocation = ({
  params,
  locationId,
}: IGetLocationsDocsByLocationParams) => {
  return xhr.get<IGetLocationsDocsByLocationRes, IGetLocationsDocsByLocationRes>(
    `${ENDPOINTS.locations}/${locationId}${ENDPOINTS.locationDocs}`,
    {
      params,
    },
  );
};

export const createLocationDoc = ({ locationId, body }: ICreateLocationDocBodyWithParam) => {
  return xhr.post<ICreateLocationDocRes, ICreateLocationDocRes>(
    `${ENDPOINTS.locations}/${locationId}${ENDPOINTS.locationDocs}`,
    body,
  );
};

export const getLocationDocDetails = ({ locationId, id }: IGetLocationDocDetailsParams) => {
  return xhr.get<IGetLocationDocDetailsRes, IGetLocationDocDetailsRes>(
    `${ENDPOINTS.locations}/${locationId}${ENDPOINTS.locationDocs}/${id}`,
  );
};

export const updateLocationDoc = ({
  locationId,
  id,
  body,
}: IUpdateLocationDocDetailsParams) => {
  return xhr.put<IResMessage, IResMessage>(
    `${ENDPOINTS.locations}/${locationId}${ENDPOINTS.locationDocs}/${id}`,
    body,
  );
};

export const deleteLocationDoc = ({ locationId, id }: IDeleteLocationDocParams) => {
  return xhr.delete<IResMessage, IResMessage>(
    `${ENDPOINTS.locations}/${locationId}${ENDPOINTS.locationDocs}/${id}`,
  );
};
