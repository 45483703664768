// Core
import { CSSProperties, MouseEvent, ReactNode } from 'react';
// Packages
import { Button as AntButton } from 'antd';
// Styles
import './button.styles.scss';

interface IButtonProps {
  block?: boolean;
  danger?: boolean;
  dataCy?: string;
  disabled?: boolean;
  form?: string;
  ghost?: boolean;
  href?: string;
  htmlType?: 'submit' | 'button' | 'reset';
  icon?: ReactNode;
  id?: string;
  loading?: boolean | { delay: number };
  shape?: 'circle' | 'round';
  size?: 'small' | 'middle' | 'large';
  target?: string;
  type?: 'default' | 'primary' | 'link' | 'text' | 'dashed';
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
}

/**
 * To trigger an operation.
 *
 * @description A button means an operation (or a series of operations).
 * Clicking a button will trigger corresponding business logic.
 *
 * @param block - Option to fit button width to its parent width
 * @param danger -Set the danger status of button
 * @param disabled - Disabled state of button
 * @param dataCy - Param for testing purpose;
 * @param form - Form ID
 * @param ghost - Make background transparent and invert text and border colors
 * @param href - Redirect url of link button
 * @param htmlType - Set the original html type of button ('submit' | 'button' | 'reset')
 * @param icon - Set the icon component of button
 * @param loading - Set the loading status of button
 * @param shape - Can be set button shape
 * @param size - Set the size of button
 * @param target - Same as target attribute of a, works when href is specified
 * @param type - Can be set to 'default' | 'primary' | 'link' | 'text' | 'dashed'
 * @param onClick - Set the handler to handle "click" event
 * @param className - Can be set to 'goBackBtn' | string
 * @param style - To customize the styles
 * @param children - Some ReactNode
 */

const Button = ({
  block = false,
  danger = false,
  dataCy = '',
  disabled = false,
  form,
  ghost = false,
  href,
  htmlType = 'button',
  icon,
  id,
  loading = false,
  shape,
  size = 'middle',
  target,
  type = 'default',
  onClick,
  className,
  style,
  children,
}: IButtonProps) => (
  <AntButton
    block={block}
    danger={danger}
    data-cy={dataCy}
    disabled={disabled}
    form={form}
    ghost={ghost}
    href={href}
    htmlType={htmlType}
    icon={icon}
    id={id}
    loading={loading}
    shape={shape}
    size={size}
    target={target}
    type={type}
    onClick={onClick}
    className={className}
    style={style}
  >
    {children}
  </AntButton>
);

export default Button;
