// Components
import { TRuleArray, TRuleObject } from 'components/lib/Form/FormItem';
// Data
import inputRules from './data/inputRules';

type TRuleName = keyof typeof inputRules;
type TGetInputRules = (name: TRuleName, options?: TRuleObject) => TRuleArray;

const getInputRules: TGetInputRules = (name, options = {}) => {
  const rule = inputRules[name];

  return [{ ...rule, ...options }] as TRuleArray;
};

export default getInputRules;
