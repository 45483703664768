// Packages
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
// Types
import type {} from '@mui/x-date-pickers/themeAugmentation';
// Styles
import { styled } from '@mui/material/styles';

export const CustomInput = styled(TextField)(({ error }) => ({
  backgroundColor: '#F5F5F7',
  border: error ? '1px solid red' : '1px solid #c3c6cf;',
  borderRadius: '3px',
  width: '100%',
  '& .MuiFormLabel-root ': {
    display: 'none',
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    fontSize: 16,
    padding: '8px 10px',
    fontWeight: '600',
    fontFamily:
      " -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none',
      '& legend': {
        borderBottom: 'none',
      },
    },
  },
  '& .MuiSvgIcon-root': {
    color: 'rgba(0, 0, 0, 0.25);',
  },
}));

export const CustomLabel = styled(InputLabel)(() => ({
  marginBottom: '0.5rem',
  color: '#2e3a59',
  fontWeight: '600 !important',
  fontSize: '1.2rem !important',
  fontFamily:
    " -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
  display: 'flex',
  flexDirection: 'row-reverse',
  justifyContent: 'flex-start',
  alignItems: 'start',
}));

export const customErrorStyles = { color: 'red', fontSize: '1.4rem' };
