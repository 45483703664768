// Core
import { useState, useEffect, useRef } from 'react';
// Packages
import io, { Socket } from 'socket.io-client';
// Redux
import { useAppDispatch } from 'redux/store';
import { updateShiftOnUpdatingStatusWebSocket } from 'redux/schedule/scheduleReducer';
// CustomHooks
import useNotifyEmployees from 'pages/SchedulePage/components/controls/ShiftControls/useNotifyEmployees';
import { useSignOut } from 'customHooks';
// Modules
import webStorage from 'modules/storage';
// API
import { API_URL, ENDPOINTS } from 'api/api.config';
import { AuthAPI } from 'api/endpoints';
// Helpers
import { ROLES } from 'helpers/data/constants';
import showNotification from 'helpers/showNotification';
// Interfaces ans types
import { IGetUserRes } from 'types/userTypes';
import {
  ShiftStatus,
  IGetShiftItemWithStatusWebSocket,
  IGetShiftItemUpdateStatusWebSocket,
} from 'types/scheduleTypes';
// I18n
import { t } from 'i18n';

interface ServerToClientEvents {
  noArg: () => void;
  basicEmit: (a: number, b: string, c: Buffer) => void;
  withAck: (d: string, callback: (e: number) => void) => void;
  changeShiftsStatus: (shiftData: IGetShiftItemWithStatusWebSocket) => void;
  exception: (err: { message: string }) => void;
  connect_failed: (err: { message: string }) => void;
}

interface IProps {
  user: IGetUserRes | null;
  isAuth?: boolean;
}

let attemptsToConnect = 0;

const useScheduleWebSocket = ({ user, isAuth }: IProps) => {
  const dispatch = useAppDispatch();
  const socketRef = useRef<Socket<ServerToClientEvents, ServerToClientEvents> | null>(null);

  const [isAddedSocketToRef, setIsAddedSocketToRef] = useState(false);
  const [isNeedUpdateNotifyEmployeeList, setIsNeedUpdateNotifyEmployeeList] = useState(false);
  const [isNeedToLogOut, setNeedToLogout] = useState(false);
  const [shifts, setShifts] = useState<IGetShiftItemUpdateStatusWebSocket[] | null>(null);

  const { getShiftsFormNotify } = useNotifyEmployees();
  const signOut = useSignOut();

  useEffect(() => {
    if (!isNeedToLogOut) return;

    setNeedToLogout(false);
    signOut();
  }, [isNeedToLogOut, signOut]);

  useEffect(() => {
    if (isNeedUpdateNotifyEmployeeList) {
      getShiftsFormNotify();
    }

    setIsNeedUpdateNotifyEmployeeList(false);
  }, [isNeedUpdateNotifyEmployeeList, getShiftsFormNotify]);

  useEffect(() => {
    if (shifts) {
      dispatch(updateShiftOnUpdatingStatusWebSocket(shifts));
    }
  }, [shifts, dispatch]);

  useEffect(() => {
    const isSocketAvailable =
      isAuth &&
      (user?.role === ROLES.business_admin ||
        user?.role === ROLES.manager ||
        user?.role === ROLES.admin);

    if (socketRef.current === null && isSocketAvailable) {
      socketRef.current = io(`${API_URL}${ENDPOINTS.scheduleWeb}`, {
        reconnectionDelayMax: 20000,
        reconnectionDelay: 2000,
        withCredentials: true,
        auth: {
          token: webStorage.getData()?.accessToken,
        },
      });
      setIsAddedSocketToRef(true);
    }

    if (socketRef.current && !isSocketAvailable) {
      setIsAddedSocketToRef(false);
      socketRef.current.disconnect();
      socketRef.current.removeAllListeners();
      socketRef.current = null;
    }
  }, [isAuth, user?.role]);

  useEffect(() => {
    if (isAddedSocketToRef && socketRef?.current) {
      // CONNECTING
      socketRef.current.on('connect', () => {
        console.log('connected Schedule Page webSocket');
        attemptsToConnect = 0;
      });

      socketRef.current.on('connect_error', (err: { message: string }) => {
        console.log('socket message connect_error:>> ', err.message);

        if (socketRef?.current && webStorage.getData()?.accessToken) {
          socketRef.current?.connect();
        }
      });

      socketRef.current.io.on('reconnect_failed', function () {
        console.log('reconnect_failed');

        showNotification(
          'error',
          t('schedule.webSocket.errorWithOnlineUpdating'),
          '',
          'topRight',
          5000,
        );
      });

      // EVENTS
      socketRef.current.on(
        'changeShiftsStatus',
        async (shiftData: IGetShiftItemWithStatusWebSocket) => {
          try {
            if (shiftData?.status === ShiftStatus.confirmed) {
              showNotification(
                'success',
                `${shiftData?.shifts[0]?.employeeName} ${t(
                  'schedule.webSocket.hasAcceptedShifts',
                )}`,
              );
            }

            if (shiftData?.status === ShiftStatus.declined) {
              showNotification(
                'error',
                `${shiftData?.shifts[0]?.employeeName} ${t(
                  'schedule.webSocket.hasDeclinedShifts',
                )}`,
              );

              setIsNeedUpdateNotifyEmployeeList(true);
            }
            setShifts(shiftData.shifts);
          } catch (err: any) {
            console.log(
              'socket error message on event "changeShiftsStatus":>> ',
              err?.message,
            );
            showNotification(
              'error',
              t('schedule.webSocket.errorWithOnlineUpdating'),
              '',
              'topRight',
              5000,
            );
          }
        },
      );

      socketRef.current.on('exception', (err: { message: string }) => {
        console.log('socket exception":>> ', err.message);
        let errorMessage = t('schedule.webSocket.errorWithOnlineUpdating');
        if (err.message !== 'INVALID_TOKEN') {
          showNotification('error', errorMessage);
        }
      });

      // DISCONNECT
      socketRef.current.on('disconnect', reason => {
        console.log('disconnected', reason);
        if (attemptsToConnect === 10) {
          let errorMessage = t('schedule.webSocket.errorWithOnlineUpdating');
          return showNotification('error', errorMessage, '', 'topRight', 5000);
        }

        if (reason === 'io server disconnect' && socketRef.current && attemptsToConnect < 10) {
          attemptsToConnect += 1;
          AuthAPI.checkIfAccessTokenIsValid()
            .then(() => {
              (socketRef!.current!.auth as any)!.token = webStorage.getData()?.accessToken;
              socketRef.current?.connect();
            })
            .catch(async () => {
              console.log('Token was updated successfully!');
            });
        }
      });

      // FOR TESTING PURPOSE
      // socketRef.current.io.on('reconnect', attempt => {
      //   console.log('reconnect', attempt);
      // });

      // socketRef.current.io.on('reconnect_attempt', attempt => {
      //   console.log('reconnect_attempt', attempt);
      // });
    }
  }, [isAddedSocketToRef, setNeedToLogout]);
};

export default useScheduleWebSocket;
