// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import {
  getJobsAsync,
  getJobsByAccountNameAsync,
  getJobsByLocationAsync,
  createJobAsync,
  getJobDetailsAsync,
  updateJobAsync,
  deleteJobAsync,
} from './jobsThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import {
  IGetJobsRes,
  IGetJobRes,
  IGetJobsByAccountNameRes,
  IGetJobsByLocationRes,
} from 'types/jobsTypes';
import { IErrorRes } from 'types/appTypes';

interface ISliceJobsState {
  jobs: IGetJobsRes | IGetJobsByAccountNameRes | IGetJobsByLocationRes | null;
  jobDetails: IGetJobRes | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: ISliceJobsState = {
  jobs: null,
  jobDetails: null,
  error: null,
  isLoading: false,
};

const jobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    //* GET JOBS ASYNC THUNK
    builder.addCase(getJobsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getJobsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.jobs = payload;
    });
    builder.addCase(getJobsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* GET JOBS BY ACCOUNT_NAME ASYNC THUNK
    builder.addCase(getJobsByAccountNameAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getJobsByAccountNameAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.jobs = payload;
    });
    builder.addCase(getJobsByAccountNameAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* GET JOBS BY LOCATION ASYNC THUNK
    builder.addCase(getJobsByLocationAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getJobsByLocationAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.jobs = payload;
    });
    builder.addCase(getJobsByLocationAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CREATE JOB ASYNC THUNK
    builder.addCase(createJobAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(createJobAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(createJobAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* GET JOB DETAILS ASYNC THUNK
    builder.addCase(getJobDetailsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getJobDetailsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.jobDetails = payload;
    });
    builder.addCase(getJobDetailsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* UPDATE JOB (one) ASYNC THUNK
    builder.addCase(updateJobAsync.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateJobAsync.fulfilled, state => {
      state.isLoading = false;
      if (state.error) {
        state.error = null;
      }
    });
    builder.addCase(updateJobAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* DELETE JOB ASYNC THUNK
    builder.addCase(deleteJobAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(deleteJobAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteJobAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export default jobsSlice;
