// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Interfaces and types
import { TGetIndustriesRes } from 'types/industryTypes';
import { TQueryParams } from 'types/appTypes';

export const getIndustries = (params: TQueryParams) => {
  return xhr.get<TGetIndustriesRes, TGetIndustriesRes>(`${ENDPOINTS.industries}`, {
    params,
  });
};
