// I18n
import { t } from 'i18n';
// Styles
import styles from './tableFooter.module.scss';

interface IProps {
  count?: number;
}

const TableFooter = ({ count }: IProps) => {
  if (!count) return null;

  return (
    <div className={styles.footer}>
      <div>
        {t('table.count')}
        {count}
      </div>
    </div>
  );
};

export default TableFooter;
