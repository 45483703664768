// Packages
import i18n, { TFunction } from 'i18next';
// Modules
import webStorage from 'modules/storage';
// I18N
import en from './dictionaries/en.json';
import es from './dictionaries/es.json';
import vi from './dictionaries/vi.json';
import enUsOptions from './locales/en.json';
import esEsOptions from './locales/es.json';
import viVnOptions from './locales/vi.json';
// Locale
import enUS from 'antd/lib/locale/en_US';
import esES from 'antd/lib/locale/es_ES';
import viVn from 'antd/lib/locale/vi_VN';
import { Locale } from 'antd/lib/locale-provider';

enum LANG {
  EN = 'en',
  ES = 'es',
  VI = 'vi',
}

export const languages = {
  [LANG.EN]: [LANG.EN],
  [LANG.ES]: [LANG.ES],
  [LANG.VI]: [LANG.VI],
} as const;

const locales = {
  [LANG.EN]: enUS,
  [LANG.ES]: esES,
  [LANG.VI]: viVn,
} as const;

const localeOptions = {
  [LANG.EN]: enUsOptions,
  [LANG.ES]: esEsOptions,
  [LANG.VI]: viVnOptions,
};

export type TLangKeys = keyof typeof languages;
export type TLocaleKeys = keyof typeof locales;

export type TLang = { lng: TLangKeys };

i18n.init({
  resources: {
    [LANG.EN]: { translation: en },
    [LANG.ES]: { translation: es },
    [LANG.VI]: { translation: vi },
    // Add another translations here
    // and to /i18next-parser.config.js
  },
  lng: webStorage.getData<TLang>('timeGoLang')?.lng || languages.en,
  fallbackLng: languages.en,
  interpolation: {
    escapeValue: false,
  },
});

// USAGE:
// import { t } from '../i18n'
// const translated = t('Some en text');
export const langs = Object.keys(languages) as TLangKeys[];

export const t: TFunction = (key, options) => i18n.t(key, options);

export const changeLanguage = (lng: TLangKeys): void => {
  webStorage.setData<TLang>({ lng }, 'timeGoLang');
  window.location.reload();
};

export const getCurrentLanguage = (): TLangKeys => {
  return webStorage.getData<TLang>('timeGoLang')?.lng || languages.en;
};

export const getCurrentLocale = (): Locale => {
  const currentLanguage = getCurrentLanguage();

  return locales[currentLanguage] || locales.en;
};

export const getLocaleOptions = (startDay: number) => {
  const currentLanguage = getCurrentLanguage();

  const options = localeOptions[currentLanguage] || localeOptions.en;

  return { ...options, week: { dow: startDay } };
};

export default i18n;
