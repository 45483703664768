// Core
import { ReactNode } from 'react';
// Components
import { MainContent } from 'components/lib';
// Custom Hooks
import { useBreakpoint } from 'customHooks';
import useScheduleWebSocket from 'customHooks/useScheduleWebSocket';
// Interface and types
import { IGetUserRes } from 'types/userTypes';
// Styles
import styles from './content.module.scss';

interface IProps {
  children: ReactNode;
  isAuth?: boolean;
  user: IGetUserRes | null;
}

const Content = ({ children, isAuth, user }: IProps) => {
  const { xl } = useBreakpoint();
  useScheduleWebSocket({ user, isAuth });

  const containerAuthStyle = `${xl ? styles.contentContainer : styles.wrap}`;
  const container = `${styles.container}`;

  return (
    <MainContent className={isAuth ? containerAuthStyle : container}>{children}</MainContent>
  );
};

export default Content;
