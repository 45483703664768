// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Interfaces and types
import { TQueryParams } from 'types/appTypes';
import {
  IGetHolidaysRes,
  ICreateHolidayBody,
  ICreateHolidayRes,
  IGetHolidayDetailsRes,
  IUpdateHolidayBodyWithParam,
  TUpdateHolidayRes,
  TDeleteHolidayRes,
  IGetHolidaysYearsRes,
} from 'types/holidayTypes';

export const getHolidays = (params: TQueryParams) => {
  return xhr.get<IGetHolidaysRes, IGetHolidaysRes>(`${ENDPOINTS.holidays}`, {
    params: params,
  });
};

export const createHoliday = (body: ICreateHolidayBody) => {
  return xhr.post<ICreateHolidayRes, ICreateHolidayRes>(`${ENDPOINTS.holidays}`, body);
};

export const getHolidayDetails = (id: number) => {
  return xhr.get<IGetHolidayDetailsRes, IGetHolidayDetailsRes>(`${ENDPOINTS.holidays}/${id}`);
};

export const updateHoliday = ({ id, body }: IUpdateHolidayBodyWithParam) => {
  return xhr.put<TUpdateHolidayRes, TUpdateHolidayRes>(`${ENDPOINTS.holidays}/${id}`, body);
};

export const deleteHoliday = (id: number) => {
  return xhr.delete<TDeleteHolidayRes, TDeleteHolidayRes>(`${ENDPOINTS.holidays}/${id}`);
};

export const getHolidaysYears = () => {
  return xhr.get<IGetHolidaysYearsRes, IGetHolidaysYearsRes>(
    `${ENDPOINTS.holidays}${ENDPOINTS.years}`,
  );
};
