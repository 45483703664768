// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import {
  getImplementationPlansAsync,
  createImplementationPlanAsync,
  getImplementationPlanDetailsAsync,
  updateImplementationPlanAsync,
  deleteImplementationPlanAsync,
  modifyOrderAsync,
} from './implementationPlansThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import {
  IGetImplementationPlansRes,
  IGetImplementationPlanDetails,
} from 'types/implementationPlansTypes';
import { IErrorRes } from 'types/appTypes';

interface ISliceJobsExpensesState {
  implementationPlans: IGetImplementationPlansRes | null;
  implementationPlanDetails: IGetImplementationPlanDetails | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: ISliceJobsExpensesState = {
  implementationPlans: null,
  implementationPlanDetails: null,
  error: null,
  isLoading: false,
};

const implementationPlansSlice = createSlice({
  name: 'implementationPlans',
  initialState,
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
    changeImplementationPlansPosition: (state, { payload }) => {
      state.implementationPlans = {
        count: state?.implementationPlans?.count,
        items: payload,
      } as IGetImplementationPlansRes;
    },
  },
  extraReducers: builder => {
    // //* GET IMPLEMENTATION PLANS ASYNC THUNK
    builder.addCase(getImplementationPlansAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getImplementationPlansAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.implementationPlans = payload;
    });
    builder.addCase(getImplementationPlansAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CREATE IMPLEMENTATION_PLAN ASYNC THUNK
    builder.addCase(createImplementationPlanAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(createImplementationPlanAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(createImplementationPlanAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    // * GET IMPLEMENTATION PLAN ASYNC THUNK
    builder.addCase(getImplementationPlanDetailsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getImplementationPlanDetailsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.implementationPlanDetails = payload;
    });
    builder.addCase(getImplementationPlanDetailsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    // //* UPDATE IMPLEMENTATION PLAN  (one) ASYNC THUNK
    builder.addCase(updateImplementationPlanAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(updateImplementationPlanAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(updateImplementationPlanAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    // //* MODIFY ORDER IMPLEMENTATION PLAN  (one) ASYNC THUNK
    builder.addCase(modifyOrderAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(modifyOrderAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(modifyOrderAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    // * DELETE IMPLEMENTATION PLAN ASYNC THUNK
    builder.addCase(deleteImplementationPlanAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(deleteImplementationPlanAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteImplementationPlanAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export const { changeImplementationPlansPosition } = implementationPlansSlice.actions;

export default implementationPlansSlice;
