// Core
import { useCallback, useState } from 'react';
// Packages
import moment from 'moment';
// Redux
import { getShiftsForNotifyAsync } from 'redux/schedule/scheduleThunk';
import { useAppDispatch } from 'redux/store';
// Helpers
import { DATE_FORMAT_STANDARD } from 'helpers/data/constants';
// Interfaces and types
import { TGetShiftsRes } from 'types/scheduleTypes';

// Get shifts for notify employees
const useNotifyEmployees = () => {
  const dispatch = useAppDispatch();

  const [isShiftsLoading, setIsShiftsLoading] = useState(false);
  const [shiftsForNotify, setShiftsForNotify] = useState<TGetShiftsRes | null>(null);

  const dateFrom = moment().format(DATE_FORMAT_STANDARD);

  const getShiftsFormNotify = useCallback(async () => {
    setIsShiftsLoading(true);

    try {
      const data = await dispatch(
        getShiftsForNotifyAsync({ dateFrom, isNotNotified: 'true' }),
      );

      if (getShiftsForNotifyAsync.fulfilled.match(data)) {
        setShiftsForNotify(
          data.payload.filter(({ employeeStatus }) => employeeStatus === 'active'),
        );
      }
    } finally {
      return setIsShiftsLoading(false);
    }
  }, [dateFrom, dispatch]);

  return { getShiftsFormNotify, isShiftsLoading, shiftsForNotify };
};

export default useNotifyEmployees;
