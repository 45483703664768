// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import {
  verifyRatesAsync,
  getTimegoHourssAsync,
  getSynclogsAsync,
} from 'redux/integration/integrationThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import {
  IGetEmployeeDetailsRes,
  IGetTimegoHourseDifferenceRes,
  SyncLogsRes,
} from 'types/integrationTypes';
import { IErrorRes } from 'types/appTypes';

interface ISliceTimegoHoursState {
  TimegoHours: IGetEmployeeDetailsRes | null;
  TimesheetHours: IGetEmployeeDetailsRes | null;
  TimegoHoursDifference: IGetTimegoHourseDifferenceRes | null;
  synclogs: SyncLogsRes | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: ISliceTimegoHoursState = {
  TimegoHours: null,
  TimesheetHours: null,
  synclogs: null,
  TimegoHoursDifference: null,
  error: null,
  isLoading: false,
};

const TimegoHoursSlice = createSlice({
  name: 'timegoHours',
  initialState,
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
  },

  extraReducers: builder => {
    //* GET TIMEGOHOURS ASYNC THUNK
    builder.addCase(getTimegoHourssAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getTimegoHourssAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.TimegoHours = payload;
    });
    builder.addCase(getTimegoHourssAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* POST VERIFY RATES ASYNC THUNK
    builder.addCase(verifyRatesAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(verifyRatesAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.TimegoHoursDifference = payload;
    });
    builder.addCase(verifyRatesAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    // INTEGRATIONREDUCER.TS
    //* GET SYNC LOGS ASYNC THUNK
    builder.addCase(getSynclogsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getSynclogsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.synclogs = payload;
    });
    builder.addCase(getSynclogsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });
    builder.addCase(clearReducer, () => initialState);
  },
});

export default TimegoHoursSlice;
