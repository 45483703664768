interface IProps {
  primaryColor?: string;
}

/**
 * @param primaryColor - The icon color. By default '#ffffff'.
 */

const DeleteIcon = ({ primaryColor = '#ffffff' }: IProps) => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.66797 1.66667H9.16797C9.39809 1.66667 9.58463 1.85321 9.58463 2.08333C9.58463 2.31345 9.39809 2.5 9.16797 2.5H8.70756L8.08015 8.14673C7.96292 9.2018 7.07112 10 6.00956 10H3.99303C2.93147 10 2.03967 9.2018 1.92244 8.14673L1.29503 2.5H0.834635C0.604517 2.5 0.417969 2.31345 0.417969 2.08333C0.417969 1.85321 0.604517 1.66667 0.834635 1.66667H3.33464C3.33464 0.746192 4.08083 0 5.0013 0C5.92178 0 6.66797 0.746192 6.66797 1.66667ZM5.0013 0.833333C4.54106 0.833333 4.16797 1.20643 4.16797 1.66667H5.83463C5.83463 1.20643 5.46154 0.833333 5.0013 0.833333ZM3.7513 4.16667L3.7513 7.5C3.7513 7.73012 3.93785 7.91667 4.16797 7.91667C4.39809 7.91667 4.58463 7.73012 4.58463 7.5L4.58463 4.16667C4.58463 3.93655 4.39809 3.75 4.16797 3.75C3.93785 3.75 3.7513 3.93655 3.7513 4.16667ZM5.83463 3.75C5.60452 3.75 5.41797 3.93655 5.41797 4.16667V7.5C5.41797 7.73012 5.60452 7.91667 5.83463 7.91667C6.06475 7.91667 6.2513 7.73012 6.2513 7.5V4.16667C6.2513 3.93655 6.06475 3.75 5.83463 3.75Z"
      fill={primaryColor}
    />
  </svg>
);

export default DeleteIcon;
