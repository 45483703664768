// Core
import React, { ReactNode, MouseEvent, CSSProperties } from 'react';
// Packages
import { Typography as AntTypography } from 'antd';
// Styles
import './title.styles.scss';

interface IEllipsisProps {
  rows?: number;
  expandable?: boolean;
  suffix?: string;
  symbol?: ReactNode;
  tooltip?: boolean | ReactNode;
  onExpand?: (e?: MouseEvent<HTMLElement>) => void;
  onEllipsis?: (ellipsis: boolean) => void;
}
interface ITitleProps {
  children?: ReactNode;
  code?: boolean;
  copyable?: boolean;
  className?: string;
  deleted?: boolean;
  disabled?: boolean;
  editable?: boolean;
  ellipsis?: boolean | IEllipsisProps;
  level?: 1 | 5 | 2 | 3 | 4;
  mark?: boolean;
  onClick?: (e?: MouseEvent<HTMLDivElement>) => void;
  style?: CSSProperties;
  italic?: boolean;
  type?: 'secondary' | 'success' | 'warning' | 'danger';
  underline?: boolean;
}

/**
 * Display title in different level.
 * Basic text writing, including headings, body text, lists, and more.
 *
 * @description When need to display a title or paragraph contents in Articles/Blogs/Notes.
 * When you need copyable/editable/ellipsis texts.
 *
 * @param children - Some ReactNode
 * @param code - Code style
 * @param copyable - Whether to be copyable, customize it via setting an object
 * @param className - Component className
 * @param deleted - Deleted line style
 * @param disabled - Disabled content
 * @param editable - 	If editable. Can control edit state when is object
 * @param ellipsis - Display ellipsis when text overflows, can configure rows and expandable by using object
 * @param level - Set content importance. Match with h1, h2, h3, h4, h5
 * @param mark - Marked style
 * @param onClick - Set the handler to handle click event
 * @param style - To customize the styles
 * @param italic - Italic style
 * @param type - Content type
 * @param underline - Underlined style
 */

const Title = ({
  children,
  code = false,
  copyable = false,
  className,
  deleted = false,
  disabled = false,
  editable = false,
  ellipsis = false,
  level = 1,
  mark = false,
  onClick,
  style,
  italic = false,
  type,
  underline = false,
}: ITitleProps) => (
  <AntTypography.Title
    code={code}
    copyable={copyable}
    className={className}
    delete={deleted}
    disabled={disabled}
    editable={editable}
    ellipsis={ellipsis}
    level={level}
    mark={mark}
    onClick={onClick}
    style={style}
    italic={italic}
    type={type}
    underline={underline}
  >
    {children}
  </AntTypography.Title>
);

export default Title;

// Type 'boolean | IEllipsisProps' is not assignable to type 'boolean | EllipsisConfig | undefined'.
//   Type 'IEllipsisProps' is not assignable to type 'boolean | EllipsisConfig | undefined'.
//     Type 'IEllipsisProps' is not assignable to type 'EllipsisConfig'.
//       Types of property 'onExpand' are incompatible.
//         Type '((event: MouseEventHandler<HTMLElement>) => void) | undefined' is not assignable to type 'MouseEventHandler<HTMLElement> | undefined'.
//           Type '(event: MouseEventHandler<HTMLElement>) => void' is not assignable to type 'MouseEventHandler<HTMLElement>'.
//             Types of parameters 'event' and 'event' are incompatible.
//               Type 'MouseEvent<HTMLElement, MouseEvent>' is not assignable to type 'MouseEventHandler<HTMLElement>'.
//                 Type 'MouseEvent<HTMLElement, MouseEvent>' provides no match for the signature '(event: MouseEvent<HTMLElement, MouseEvent>): void'.
// Type 'boolean | IEllipsisProps' is not assignable to type 'boolean | EllipsisConfig | undefined'.
//   Type 'IEllipsisProps' is not assignable to type 'boolean | EllipsisConfig | undefined'.
//     Type 'IEllipsisProps' is not assignable to type 'EllipsisConfig'.
//       Types of property 'onExpand' are incompatible.
//         Type 'MouseEvent<HTMLElement, MouseEvent<Element, MouseEvent>> | undefined' is not assignable to type 'MouseEventHandler<HTMLElement> | undefined'.
//           Type 'MouseEvent<HTMLElement, MouseEvent<Element, MouseEvent>>' is not assignable to type 'MouseEventHandler<HTMLElement>'.
//             Type 'MouseEvent<HTMLElement, MouseEvent<Element, MouseEvent>>' provides no match for the signature '(event: MouseEvent<HTMLElement, MouseEvent>): void'.
