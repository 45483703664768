// Packages
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
// Modules
import { API_URL } from 'api/api.config';
import webStorage from 'modules/storage';
import sessionStorageApp from 'modules/storage/sessionStorage';
import errorHandler from 'modules/error';
// Helpers
import showNotification from 'helpers/showNotification';
// Interfaces and types
import { ISignInResData } from 'types/authTypes';
// I18n
import { getCurrentLanguage, t } from 'i18n';

//* A boolean indicator that evaluates if a request has already been sent
let isAlreadyFetchingAccessToken = false;

//* Array of original failed requests with a new valid access token
type TSubscriber = (accessToken: string) => void;

let subscribers: TSubscriber[] = [];

//* On response (error) helper - use to add a new access token for the original requests
const onAccessTokenFetched = (accessToken: string) => {
  subscribers = subscribers.filter(callback => callback(accessToken));
};

//* On response (error) helper - use to add a callback with an original request which was failed
const addSubscriber = (callback: TSubscriber) => {
  subscribers.push(callback);
};

//* On request (success) handler
const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const storageData = webStorage.getData();
  const sessionStorageData = sessionStorageApp.getData();
  const language = getCurrentLanguage();

  if (storageData?.accessToken) {
    config!.headers!.Authorization = `Bearer ${storageData.accessToken}`;
  }

  // two factor auth
  if (!storageData?.accessToken && sessionStorageData?.accessToken) {
    config!.headers!.Authorization = `Bearer ${sessionStorageData.accessToken}`;
  }

  config.headers['Accept-Language'] = language;

  return config;
};

//* On request (error) handler
const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  console.error(`[request error] [${JSON.stringify(error)}]`);

  return Promise.reject(error);
};

//* On response (success) handler
const onResponse = (response: AxiosResponse): AxiosResponse['data'] => {
  return response.data;
};

//* On response (error) handler
const onResponseError = (axiosInstance: AxiosInstance) => async (error: AxiosError) => {
  await errorHandler.observe(error);

  const originalRequest = error.config;

  if (!isAlreadyFetchingAccessToken) {
    isAlreadyFetchingAccessToken = true;

    const storageData = webStorage.getData();

    axios
      .patch<ISignInResData>(`${API_URL}/auth/tokens`, {
        refreshToken: storageData?.refreshToken,
      })
      .then(({ data }) => {
        isAlreadyFetchingAccessToken = false;

        const {
          accountName,
          scheduleJobsIdsFilter,
          scheduleEmployeesIdsFilter,
          scheduleTypeFilter,
        } = webStorage.getData();

        const { tokens, role, ...restData } = data;

        webStorage.setData({
          ...data.tokens,
          ...restData,
          accountName,
          scheduleJobsIdsFilter,
          scheduleEmployeesIdsFilter,
          scheduleTypeFilter,
        });

        onAccessTokenFetched(data.tokens.accessToken);
      })
      .catch(error => {
        console.log('Error from interceptor after failing to get refresh token', error);
        showNotification('error', t('mfa.invalidToken'));

        setTimeout(() => {
          webStorage.unsetData();
          webStorage.unsetData('timeGoUse12Hours');
          window.location.reload();
          window.location.replace('/');
        }, 2000);
      });
  }

  const retryOriginalRequest = new Promise(resolve => {
    addSubscriber((accessToken: string) => {
      originalRequest!.headers!.Authorization = 'Bearer ' + accessToken;

      resolve(axiosInstance(originalRequest));
    });
  });

  return retryOriginalRequest;
};

//* Setup interceptors to the axios instance
const setupInterceptorsTo = (axiosInstance: AxiosInstance): AxiosInstance => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError(axiosInstance));

  return axiosInstance;
};

export default setupInterceptorsTo;
