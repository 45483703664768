// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Interfaces and types
import {
  IGetJobLogsRes,
  ICreateJobLogBodyWithParams,
  ICreateJobLogRes,
  IGetJobLogDetailsRes,
  IGetJobLogDetailsParams,
  IUpdateJobLogBodyWithParams,
  IUpdateJobLogRes,
  IGetJobLogsByJobIdParams,
  IDeleteJobLogParams,
  IDeleteJobLogRes,
  IGetJobLogsByJobIdRes,
} from 'types/jobLogsTypes';
import { TQueryParams } from 'types/appTypes';

export const getJobLogs = (params: TQueryParams) => {
  return xhr.get<IGetJobLogsRes, IGetJobLogsRes>(`${ENDPOINTS.jobLogs}`, {
    params,
  });
};

export const getJobLogsByJobId = ({ jobId, params }: IGetJobLogsByJobIdParams) => {
  return xhr.get<IGetJobLogsByJobIdRes, IGetJobLogsByJobIdRes>(
    `${ENDPOINTS.jobs}/${jobId}${ENDPOINTS.jobLogs}`,
    {
      params,
    },
  );
};

export const createJobLog = ({ jobId, body }: ICreateJobLogBodyWithParams) => {
  return xhr.post<ICreateJobLogRes, ICreateJobLogRes>(
    `${ENDPOINTS.jobs}/${jobId}${ENDPOINTS.jobLogs}`,
    body,
  );
};

export const getJobLogDetails = ({ jobId, id }: IGetJobLogDetailsParams) => {
  return xhr.get<IGetJobLogDetailsRes, IGetJobLogDetailsRes>(
    `${ENDPOINTS.jobs}/${jobId}${ENDPOINTS.jobLogs}/${id}`,
  );
};

export const editJobLogDetails = ({ jobId, id, body }: IUpdateJobLogBodyWithParams) => {
  return xhr.put<IUpdateJobLogRes, IUpdateJobLogRes>(
    `${ENDPOINTS.jobs}/${jobId}${ENDPOINTS.jobLogs}/${id}`,
    body,
  );
};

export const deleteJobLog = ({ jobId, id }: IDeleteJobLogParams) => {
  return xhr.delete<IDeleteJobLogRes, IDeleteJobLogRes>(
    `${ENDPOINTS.jobs}/${jobId}${ENDPOINTS.jobLogs}/${id}`,
  );
};
