// Modules
import xhr from 'modules/xhr';
import axios from 'axios';
// API
import { API_URL, ENDPOINTS } from '../api.config';
// Storage
import webStorage from 'modules/storage';
// Interfaces and types
import { IGetReportParams, TGetReportRes } from 'types/reportTypes';
import {
  IGetPayrollAdjustmentsParams,
  IGetPayrollAdjustmentsRes,
  ICreatePayrollAdjustmentsBody,
} from 'types/payrollAdjustmentTypes';
// Intl
import { getCurrentLanguage } from 'i18n';

export const getReport = (params: IGetReportParams) => {
  const storageData = webStorage.getData();
  const language = getCurrentLanguage();

  return axios.get<TGetReportRes>(
    `${API_URL}${ENDPOINTS.reports}${ENDPOINTS.payrollAdjustment}`,
    {
      headers: {
        Authorization: `Bearer ${storageData.accessToken}`,
        Accept: 'application/json',
        'Accept-Language': language,
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
      params,
    },
  );
};

export const getPayrollAdjustments = (params: IGetPayrollAdjustmentsParams) => {
  return xhr.get<IGetPayrollAdjustmentsRes, IGetPayrollAdjustmentsRes>(
    `${ENDPOINTS.payrollAdjustment}`,
    {
      params,
    },
  );
};

export const savePayrollAdjustments = (body: ICreatePayrollAdjustmentsBody) => {
  return xhr.post<IGetPayrollAdjustmentsRes, IGetPayrollAdjustmentsRes>(
    `${ENDPOINTS.payrollAdjustment}`,
    body,
  );
};
