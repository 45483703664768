// Core
import { CSSProperties, ReactNode } from 'react';
// Packages
import { Layout as AntLayout } from 'antd';

interface IFooterProps {
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
}

/**
 * Handling the overall layout of a page.
 *
 * @param className - Container className
 * @param style - To customize the styles
 * @param children - Some ReactNode
 */

const Footer = ({ className, style, children }: IFooterProps) => (
  <AntLayout.Footer className={className} style={style}>
    {children}
  </AntLayout.Footer>
);

export default Footer;
