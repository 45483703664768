interface IProps {
  primaryColor?: string;
}

/**
 * @param primaryColor - The icon color. By default '#ffffff'.
 */

const UserGroup = ({ primaryColor = '#ffffff' }: IProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 9.80269C14.2487 9.80269 16.0781 8.05256 16.0781 5.90135C16.0781 3.75013 14.2487 2 12 2C9.75129 2 7.92186 3.75013 7.92186 5.90135C7.92186 8.05256 9.75129 9.80269 12 9.80269ZM12 3.34529C13.4733 3.34529 14.6718 4.49193 14.6718 5.90135C14.6718 7.31076 13.4733 8.4574 12 8.4574C10.5267 8.4574 9.32811 7.31076 9.32811 5.90135C9.32811 4.49193 10.5267 3.34529 12 3.34529Z"
      fill={primaryColor}
    />
    <path
      d="M20.25 9.80269C21.6715 9.80269 22.8281 8.69628 22.8281 7.33632C22.8281 5.97637 21.6715 4.86996 20.25 4.86996C18.8284 4.86996 17.6718 5.97637 17.6718 7.33632C17.6718 8.69628 18.8284 9.80269 20.25 9.80269ZM20.25 6.21525C20.8961 6.21525 21.4218 6.71816 21.4218 7.33632C21.4218 7.95448 20.8961 8.4574 20.25 8.4574C19.6038 8.4574 19.0781 7.95448 19.0781 7.33632C19.0781 6.71816 19.6038 6.21525 20.25 6.21525Z"
      fill={primaryColor}
    />
    <path
      d="M20.8171 11.3274H19.7297C18.4481 11.3274 17.3419 12.0517 16.8378 13.092C15.7999 12.0082 14.3092 11.3274 12.6552 11.3274H11.3448C9.69083 11.3274 8.20006 12.0082 7.16221 13.092C6.65807 12.0517 5.55182 11.3274 4.2703 11.3274H3.18281C1.42781 11.3274 0 12.685 0 14.3538V19.2511C0 19.9756 0.618468 20.565 1.37868 20.565H5.67838C5.75047 21.3677 6.45603 22 7.31352 22H16.6865C17.5439 22 18.2495 21.3677 18.3216 20.565H22.5722C23.3595 20.565 24 19.9547 24 19.2045V14.3538C24 12.685 22.5721 11.3274 20.8171 11.3274ZM1.40625 14.3538C1.40625 13.4268 2.20321 12.6726 3.18281 12.6726H4.2703C5.2499 12.6726 6.04686 13.4268 6.04686 14.3538V14.8144C5.57404 15.9922 5.67186 16.698 5.67186 19.2197H1.40625V14.3538ZM16.9218 20.4296C16.9218 20.5537 16.8163 20.6547 16.6865 20.6547H7.31347C7.18367 20.6547 7.07811 20.5537 7.07811 20.4296V16.7543C7.07811 14.5037 8.99211 12.6726 11.3447 12.6726H12.6552C15.0078 12.6726 16.9218 14.5037 16.9218 16.7543V20.4296ZM22.5937 19.2045C22.5937 19.2277 22.8558 19.2197 18.3281 19.2197C18.3281 16.6793 18.4252 15.9902 17.9531 14.8144V14.3538C17.9531 13.4268 18.7501 12.6726 19.7297 12.6726H20.8171C21.7967 12.6726 22.5937 13.4268 22.5937 14.3538V19.2045Z"
      fill={primaryColor}
    />
    <path
      d="M3.74999 9.80269C5.17157 9.80269 6.32811 8.69628 6.32811 7.33632C6.32811 5.97637 5.17157 4.86996 3.74999 4.86996C2.32842 4.86996 1.17187 5.97637 1.17187 7.33632C1.17187 8.69628 2.32842 9.80269 3.74999 9.80269ZM3.74999 6.21525C4.39616 6.21525 4.92187 6.71816 4.92187 7.33632C4.92187 7.95448 4.39616 8.4574 3.74999 8.4574C3.10382 8.4574 2.57812 7.95448 2.57812 7.33632C2.57812 6.71816 3.10382 6.21525 3.74999 6.21525Z"
      fill={primaryColor}
    />
  </svg>
);

export default UserGroup;
