// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Interfaces and types
import { IGetPaymentsRes, IGetBilledUsersRes } from 'types/paymentsTypes';
import { TQueryParams } from 'types/appTypes';

export const getPayments = (params: TQueryParams) => {
  return xhr.get<IGetPaymentsRes, IGetPaymentsRes>(`${ENDPOINTS.payments}`, { params });
};

export const getBilledUsers = (id: number) => {
  return xhr.get<IGetBilledUsersRes, IGetBilledUsersRes>(
    `${ENDPOINTS.payments}/${id}${ENDPOINTS.billedUsers}`,
  );
};
