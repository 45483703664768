// Packages
import axios from 'axios';
// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
import { API_URL } from 'api/api.config';
// Storage
import webStorage from 'modules/storage';
// Interfaces and types
import {
  IGetInvoiceSettingsRes,
  IUpdateInvoiceSettingsBody,
  IUpdateInvoiceSettingsRes,
  IGetInvoiceNumber,
} from 'types/invoiceSettingsTypes';
import {
  IGetInvoicesRes,
  ICreateInvoiceBody,
  ICreateInvoiceRes,
  IGetInvoiceLinesRes,
  IGetInvoiceLinesBody,
  IGetTotalHoursRes,
  IGetTotalInvoiceCalculationBody,
  IGetTotalInvoiceCalculationRes,
  IGetInvoiceDetailsBodyWithParams,
  IGetInvoiceDetailsRes,
  IUpdateInvoiceBodyWithParam,
  IUpdateInvoiceRes,
  IGetPaymentsParamsWithInvoiceId,
  IGetPaymentsRes,
  ISavePaymentsBodyWithParam,
  IUpdatePaymentStatusParamWithBody,
  ISendEmailBodyWithParam,
  ISentStatus,
  ISendEmailRes,
  IInvoiceEmailRead,
} from 'types/invoiceTypes';
import { TQueryParams, IResMessage } from 'types/appTypes';
// I18n
import { getCurrentLanguage } from 'i18n';

// INVOICES SETTINGS
export const getInvoiceSettings = () => {
  return xhr.get<IGetInvoiceSettingsRes, IGetInvoiceSettingsRes>(
    `${ENDPOINTS.invoiceSettings}`,
  );
};

export const getInvoiceNumber = (params: TQueryParams) => {
  return xhr.get<IGetInvoiceNumber, IGetInvoiceNumber>(`${ENDPOINTS.invoiceNumber}`, {
    params,
  });
};

export const updateInvoiceSettings = (body: IUpdateInvoiceSettingsBody) => {
  return xhr.put<IUpdateInvoiceSettingsRes, IUpdateInvoiceSettingsRes>(
    `${ENDPOINTS.invoiceSettings}`,
    body,
  );
};

// INVOICES
export const getInvoices = (params: TQueryParams) => {
  return xhr.get<IGetInvoicesRes, IGetInvoicesRes>(`${ENDPOINTS.invoices}`, {
    params,
  });
};

export const createInvoice = (body: ICreateInvoiceBody) => {
  return xhr.post<ICreateInvoiceRes, ICreateInvoiceRes>(`${ENDPOINTS.invoices}`, body);
};

export const getInvoiceDetails = ({ id, params }: IGetInvoiceDetailsBodyWithParams) => {
  return xhr.get<IGetInvoiceDetailsRes, IGetInvoiceDetailsRes>(`${ENDPOINTS.invoices}/${id}`, {
    params,
  });
};

export const updateInvoice = ({ body, id }: IUpdateInvoiceBodyWithParam) => {
  return xhr.put<IUpdateInvoiceRes, IUpdateInvoiceRes>(`${ENDPOINTS.invoices}/${id}`, body);
};

export const deleteInvoice = (id: number) => {
  return xhr.delete<IResMessage, IResMessage>(`${ENDPOINTS.invoices}/${id}`);
};

export const getInvoicesLines = (body: IGetInvoiceLinesBody) => {
  return xhr.post<IGetInvoiceLinesRes, IGetInvoiceLinesRes>(`${ENDPOINTS.invoiceLines}`, body);
};

export const getTotalHours = (params: TQueryParams) => {
  return xhr.get<IGetTotalHoursRes, IGetTotalHoursRes>(`${ENDPOINTS.invoicesHours}`, {
    params,
  });
};

export const calculateInvoiceTotal = (body: IGetTotalInvoiceCalculationBody) => {
  return xhr.post<IGetTotalInvoiceCalculationRes, IGetTotalInvoiceCalculationRes>(
    `${ENDPOINTS.invoiceCalculate}`,
    body,
  );
};

export const generateInvoice = (id: number) => {
  return xhr.get<IGetInvoiceDetailsRes, IGetInvoiceDetailsRes>(
    `${ENDPOINTS.recurringInvoices}/${id}${ENDPOINTS.generate}`,
  );
};

// PAYMENTS
export const getPayments = ({ invoiceId, params }: IGetPaymentsParamsWithInvoiceId) => {
  return xhr.get<IGetPaymentsRes, IGetPaymentsRes>(
    `${ENDPOINTS.invoices}/${invoiceId}${ENDPOINTS.payments}`,
    {
      params,
    },
  );
};

export const updatePayments = ({ invoiceId, body }: ISavePaymentsBodyWithParam) => {
  return xhr.patch<IResMessage, IResMessage>(
    `${ENDPOINTS.invoices}/${invoiceId}${ENDPOINTS.payments}`,
    body,
  );
};

export const updatePaymentStatus = ({
  invoiceId,
  body,
}: IUpdatePaymentStatusParamWithBody) => {
  return xhr.put<IResMessage, IResMessage>(
    `${ENDPOINTS.invoices}/${invoiceId}${ENDPOINTS.payments}${ENDPOINTS.status}`,
    body,
  );
};

// Invoice PDF
export const getInvoicePdf = (id: number) => {
  const storageData = webStorage.getData();
  const language = getCurrentLanguage();

  return axios.get<Blob>(`${API_URL}${ENDPOINTS.invoices}/${id}${ENDPOINTS.pdf}`, {
    headers: {
      Authorization: `Bearer ${storageData.accessToken}`,
      Accept: 'application/json',
      'Accept-Language': language,
      'Content-Type': 'application/json',
    },
    responseType: 'blob',
  });
};

// Invoice PDF statement
export const getInvoiceStatementPdf = (id: number) => {
  const storageData = webStorage.getData();
  const language = getCurrentLanguage();

  return axios.get<Blob>(`${API_URL}${ENDPOINTS.invoices}/${id}${ENDPOINTS.statement}`, {
    headers: {
      Authorization: `Bearer ${storageData.accessToken}`,
      Accept: 'application/json',
      'Accept-Language': language,
      'Content-Type': 'application/json',
    },
    responseType: 'blob',
  });
};

// Invoice Excel report
export const getInvoiceExcelReport = (params: TQueryParams) => {
  const storageData = webStorage.getData();
  const language = getCurrentLanguage();

  return axios.get<Blob>(`${API_URL}${ENDPOINTS.invoices}/${ENDPOINTS.reports}`, {
    headers: {
      Authorization: `Bearer ${storageData.accessToken}`,
      Accept: 'application/json',
      'Accept-Language': language,
      'Content-Type': 'application/json',
    },
    responseType: 'blob',
    params,
  });
};

// EMAIL
export const sendEmails = ({ invoiceId, body }: ISendEmailBodyWithParam) => {
  return xhr.post<ISendEmailRes, ISendEmailRes>(
    `${ENDPOINTS.invoices}${ENDPOINTS.emails}/${invoiceId}`,
    body,
  );
};

export const getSentStatus = (invoiceId: number) => {
  return xhr.get<ISentStatus, ISentStatus>(
    `${ENDPOINTS.invoices}/${invoiceId}${ENDPOINTS.sentStatus}`,
  );
};

export const getEmailsHistory = (id: number) => {
  return xhr.get<IInvoiceEmailRead[], IInvoiceEmailRead[]>(
    `${ENDPOINTS.invoices}/${id}${ENDPOINTS.emailsHistory}`,
  );
};
