// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { JobsAPI } from 'api/endpoints';
// Interfaces and types
import {
  IGetJobsRes,
  IGetJobsByAccountNameRes,
  IGetJobsByAccountNameParams,
  IGetJobsByLocationRes,
  IGetJobsByLocationParams,
  ICreateJobRes,
  ICreateJobBody,
  IGetJobRes,
  IUpdateJobBodyWithParam,
  IUpdateJobRes,
  IDeleteJobRes,
} from 'types/jobsTypes';
import { IErrorRes, TQueryParams } from 'types/appTypes';

const getJobsAsync = createAsyncThunk<IGetJobsRes, TQueryParams, { rejectValue: IErrorRes }>(
  'jobs/getJobs',
  async (params: TQueryParams, { rejectWithValue }) => {
    try {
      return await JobsAPI.getJobs(params);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const getJobsByAccountNameAsync = createAsyncThunk<
  IGetJobsByAccountNameRes,
  IGetJobsByAccountNameParams,
  { rejectValue: IErrorRes }
>(
  'jobs/getJobsByAccountName',
  async (params: IGetJobsByAccountNameParams, { rejectWithValue }) => {
    try {
      return await JobsAPI.getJobsByAccountName(params);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const getJobsByLocationAsync = createAsyncThunk<
  IGetJobsByLocationRes,
  IGetJobsByLocationParams,
  { rejectValue: IErrorRes }
>('jobs/getJobsByLocation', async (params: IGetJobsByLocationParams, { rejectWithValue }) => {
  try {
    return await JobsAPI.getJobsByLocation(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const createJobAsync = createAsyncThunk<
  ICreateJobRes,
  ICreateJobBody,
  { rejectValue: IErrorRes }
>('jobs/createJob', async (body: ICreateJobBody, { rejectWithValue }) => {
  try {
    return await JobsAPI.createJob(body);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getJobDetailsAsync = createAsyncThunk<IGetJobRes, number, { rejectValue: IErrorRes }>(
  'jobs/getJob',
  async (id: number, { rejectWithValue }) => {
    try {
      return await JobsAPI.getJob(id);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const updateJobAsync = createAsyncThunk<
  IUpdateJobRes,
  IUpdateJobBodyWithParam,
  { rejectValue: IErrorRes }
>('jobs/updateJob', async (bodyWithParam: IUpdateJobBodyWithParam, { rejectWithValue }) => {
  try {
    return await JobsAPI.updateJob(bodyWithParam);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const deleteJobAsync = createAsyncThunk<IDeleteJobRes, number, { rejectValue: IErrorRes }>(
  'jobs/deleteJob',
  async (id: number, { rejectWithValue }) => {
    try {
      return await JobsAPI.deleteJob(id);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

export {
  getJobsAsync,
  getJobsByAccountNameAsync,
  getJobsByLocationAsync,
  createJobAsync,
  getJobDetailsAsync,
  updateJobAsync,
  deleteJobAsync,
};
