// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Interfaces and types
import {
  IIntegrationRes,
  ICreateIntegrationBody,
  IIntegrationParams,
  IGetEmployeeDetailsRes,
  IGetTimegoHourseDifferenceRes,
  ISyncEmployeeRateBodyWithParam,
  IUpdateEmployeeRateBodyWithParam,
  SyncLogsRes,
  ISyncLogsParams,
  IQboAuthParams,
  IQboAuthRes,
  ISyncEmployeeVerifyRatesBody,
  IIntegrationXeroParams,
  ISyncParams,
  ICreateConfigurationBody,
  ISyncEmployeePayrollBodyWithParam,
} from 'types/integrationTypes';

// Function to retrieve integration data
export const getIntegration = (timeGoUniqueId: number) => {
  return xhr.get<IIntegrationRes, IIntegrationRes>(
    `${ENDPOINTS.getIntegration}?timeGoUniqueId=${timeGoUniqueId}`,
  );
};

// Function to retrieve configuration data
export const getConfiguration = (timeGoUniqueId: number) => {
  return xhr.get<IIntegrationRes, IIntegrationRes>(
    `${ENDPOINTS.getConfiguration}?timeGoUniqueId=${timeGoUniqueId}`,
  );
};
// Function to retrieve EarningRates and PayRunCalenders  data
export const getEarningRatesAndPayRunCalenders = (timeGoUniqueId: number) => {
  return xhr.get<IIntegrationRes, IIntegrationRes>(
    `${ENDPOINTS.getEarningRatesAndPayRunCalenders}?timeGoUniqueId=${timeGoUniqueId}`,
  );
};
// Function to sync EarningRates and PayCalenders data
export const syncEarningRatesAndPayCalenders = ({ timeGoUniqueId, type }: ISyncParams) => {
  return xhr.get<IIntegrationRes, IIntegrationRes>(
    type === 0
      ? `${ENDPOINTS.syncEarningRatesAndPayCalenders}?timeGoUniqueId=${timeGoUniqueId}`
      : `${ENDPOINTS.syncEarningRatesAndPayCalenders}?timeGoUniqueId=${timeGoUniqueId}&type=${type}`,
  );
};
// Function to retrieve QuickBooks Online authorization URL
export const getIntegrationAuthURL = ({ timeGoUniqueId, platform }: IQboAuthParams) => {
  return xhr.get<IQboAuthRes, IQboAuthRes>(
    `${ENDPOINTS.getAuthURLIntegration}?timeGoUniqueId=${timeGoUniqueId}&platform=${platform}`,
  );
};

// Function to create a new integration
export const createIntegration = (body: Partial<ICreateIntegrationBody>) => {
  return xhr.post<IIntegrationRes, IIntegrationRes>(`${ENDPOINTS.createIntegration}`, body);
};
// Function to create new configuration
export const createConfiguration = ({ body, timeGoUniqueId }: ICreateConfigurationBody) => {
  return xhr.post<IIntegrationRes, IIntegrationRes>(
    `${ENDPOINTS.createConfiguration}?timeGoUniqueId=${timeGoUniqueId}`,
    body,
  );
};

// Function to create a new xero integration
export const createXeroIntegration = (body: Partial<ICreateIntegrationBody>) => {
  return xhr.post<IIntegrationRes, IIntegrationRes>(
    `${ENDPOINTS.createXeroIntegration}`,
    body,
  );
};

// Function to delete a checkpoint or integration
export const deleteCheckpoint = (timeGoUniqueId: number) => {
  return xhr.delete<IIntegrationRes, IIntegrationRes>(
    `${ENDPOINTS.deleteIntegration}?timeGoUniqueId=${timeGoUniqueId}`,
  );
};
// Function to delete a checkpoint or integration
export const deleteXeroCheckpoint = (timeGoUniqueId: number) => {
  return xhr.delete<IIntegrationRes, IIntegrationRes>(
    `${ENDPOINTS.deleteXeroIntegration}?timeGoUniqueId=${timeGoUniqueId}`,
  );
};

// Function to retrieve Timego hours data
export const getTimegoHours = ({ dateFrom, dateTo }: IIntegrationParams) => {
  return xhr.get<IGetEmployeeDetailsRes, IGetEmployeeDetailsRes>(
    `${ENDPOINTS.getReports}?dateTo=${dateTo}&dateFrom=${dateFrom}&includeRates=true`,
  );
};
// Function to retrieve Timesheet hours data
export const getTimesheetHours = ({ dateFrom, dateTo }: IIntegrationXeroParams) => {
  return xhr.get<IGetEmployeeDetailsRes, IGetEmployeeDetailsRes>(
    `${ENDPOINTS.getReports}?dateFrom=${dateFrom}&dateTo=${dateTo}&includeRates=true`,
  );
};

// Function to verify rates for integration
export const verifyRates = ({ timeGoUniqueId, body }: ISyncEmployeeVerifyRatesBody) => {
  return xhr.post<IGetTimegoHourseDifferenceRes, IGetTimegoHourseDifferenceRes>(
    `${ENDPOINTS.verifyRates}?timeGoUniqueId=${timeGoUniqueId}`,
    body,
  );
};

// Function to sync Timego rates data
export const syncTimegoRatesData = ({
  timeGoUniqueId,
  toDate,
  body,
}: ISyncEmployeeRateBodyWithParam) => {
  return xhr.post<IIntegrationRes, IIntegrationRes>(
    `${ENDPOINTS.syncTimeActivity}?timeGoUniqueId=${timeGoUniqueId}&fromDate=${toDate}`,
    body,
  );
};
// Function to sync Timego rates data
export const syncTimegoPayrollData = ({
  timeGoUniqueId,
  body,
}: ISyncEmployeePayrollBodyWithParam) => {
  return xhr.post<IIntegrationRes, IIntegrationRes>(
    `${ENDPOINTS.syncPayrollXero}?timeGoUniqueId=${timeGoUniqueId}`,
    body,
  );
};

// Function to update Timego rates data
export const updateTimegoRatesData = ({
  timeGoUniqueId,
  body,
}: IUpdateEmployeeRateBodyWithParam) => {
  return xhr.post<IIntegrationRes, IIntegrationRes>(
    `${ENDPOINTS.updateRates}?timeGoUniqueId=${timeGoUniqueId}`,
    body,
  );
};

// Function to retrieve sync logs for integration
export const getSyncLogs = ({ timeGoUniqueId, params }: ISyncLogsParams) => {
  return xhr.get<SyncLogsRes, SyncLogsRes>(
    `${ENDPOINTS.getSyncLogs}?timeGoUniqueId=${timeGoUniqueId}`,
    { params },
  );
};
