// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { EquipmentLogsApi } from 'api/endpoints';
// Interfaces and types
import { IErrorRes, TQueryParams, IResMessage } from 'types/appTypes';
import {
  IDeleteEquipmentLogByEquipmentId,
  IGetEquipmentLogItem,
  IEquipmentLogCreateRes,
  IEquipmentLogUpdateBody,
  IEquipmentLogUpdateRes,
  IGetEquipmentLogByEquipmentId,
  IGetEquipmentLogsByEquipmentId,
  IGetEquipmentLogsRes,
  IEquipmentLogCreateBody,
} from 'types/equipmentLogsTypes';

const getEquipmentLogItemsAsync = createAsyncThunk<
  IGetEquipmentLogsRes,
  TQueryParams,
  { rejectValue: IErrorRes }
>('equipmentLogs/getEquipmentLogs', async (params: TQueryParams, { rejectWithValue }) => {
  try {
    return await EquipmentLogsApi.getEquipmentLogsItems(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getEquipmentLogItemsByEquipmentIdAsync = createAsyncThunk<
  IGetEquipmentLogsRes,
  IGetEquipmentLogsByEquipmentId,
  { rejectValue: IErrorRes }
>(
  'equipmentLogs/getEquipmentLogsByEquipmentId',
  async ({ equipmentId, params }, { rejectWithValue }) => {
    try {
      return await EquipmentLogsApi.getEquipmentLogs(equipmentId, params);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const getEquipmentLogDetailsAsync = createAsyncThunk<
  IGetEquipmentLogItem,
  IGetEquipmentLogByEquipmentId,
  { rejectValue: IErrorRes }
>('equipmentLogs/getEquipmentLogDetails', async ({ equipmentId, id }, { rejectWithValue }) => {
  try {
    return await EquipmentLogsApi.getEquipmentLog(equipmentId, id);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const createEquipmentLogAsync = createAsyncThunk<
  IEquipmentLogCreateRes,
  IEquipmentLogCreateBody,
  { rejectValue: IErrorRes }
>(
  'equipmentLogs/createEquipmentLog',
  async (cred: IEquipmentLogCreateBody, { rejectWithValue }) => {
    try {
      return await EquipmentLogsApi.createEquipmentLog(cred);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const updateEquipmentLogAsync = createAsyncThunk<
  IEquipmentLogUpdateRes,
  IEquipmentLogUpdateBody,
  { rejectValue: IErrorRes }
>(
  'equipmentLogs/updateEquipmentLog',
  async (cred: IEquipmentLogUpdateBody, { rejectWithValue }) => {
    try {
      return await EquipmentLogsApi.updateEquipmentLog(cred);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const deleteEquipmentLogAsync = createAsyncThunk<
  IResMessage,
  IDeleteEquipmentLogByEquipmentId,
  { rejectValue: IErrorRes }
>('equipmentLogs/deleteEquipmentLog', async ({ equipmentId, id }, { rejectWithValue }) => {
  try {
    return await EquipmentLogsApi.deleteEquipmentLog(equipmentId, id);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

export {
  getEquipmentLogItemsAsync,
  getEquipmentLogItemsByEquipmentIdAsync,
  getEquipmentLogDetailsAsync,
  createEquipmentLogAsync,
  updateEquipmentLogAsync,
  deleteEquipmentLogAsync,
};
