// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { ImplementationPlansAPI } from 'api/endpoints';
// Interfaces and types
import {
  IGetImplementationPlansRes,
  ICreateImplementationPlanBody,
  ICreateImplementationPlanRes,
  IGetImplementationPlanDetails,
  IUpdateImplementationPlanBodyWithParams,
  IModifyOrder,
} from 'types/implementationPlansTypes';
import { IErrorRes, IResMessage, TQueryParams } from 'types/appTypes';

const getImplementationPlansAsync = createAsyncThunk<
  IGetImplementationPlansRes,
  TQueryParams,
  { rejectValue: IErrorRes }
>('implementationPlans/getPlans', async (params: TQueryParams, { rejectWithValue }) => {
  try {
    return await ImplementationPlansAPI.getImplementationPlansItems(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const createImplementationPlanAsync = createAsyncThunk<
  ICreateImplementationPlanRes,
  ICreateImplementationPlanBody,
  { rejectValue: IErrorRes }
>(
  'implementationPlans/createPlan',
  async (body: ICreateImplementationPlanBody, { rejectWithValue }) => {
    try {
      return await ImplementationPlansAPI.createImplementationPlan(body);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const getImplementationPlanDetailsAsync = createAsyncThunk<
  IGetImplementationPlanDetails,
  number,
  { rejectValue: IErrorRes }
>('implementationPlans/getImplementationPlan', async (id: number, { rejectWithValue }) => {
  try {
    return await ImplementationPlansAPI.getImplementationPlanDetails(id);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const updateImplementationPlanAsync = createAsyncThunk<
  IResMessage,
  IUpdateImplementationPlanBodyWithParams,
  { rejectValue: IErrorRes }
>(
  'implementationPlans/updateImplementationPlan',
  async ({ id, body }: IUpdateImplementationPlanBodyWithParams, { rejectWithValue }) => {
    try {
      return await ImplementationPlansAPI.updateImplementationPlan({ id, body });
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const deleteImplementationPlanAsync = createAsyncThunk<
  IResMessage,
  number,
  { rejectValue: IErrorRes }
>('implementationPlans/deleteImplementationPlan', async (id: number, { rejectWithValue }) => {
  try {
    return await ImplementationPlansAPI.deleteImplementationPlan(id);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const modifyOrderAsync = createAsyncThunk<
  IResMessage,
  IModifyOrder,
  { rejectValue: IErrorRes }
>(
  'implementationPlans/modifyImplementationPlanRowsOrder',
  async ({ data }: IModifyOrder, { rejectWithValue }) => {
    try {
      return await ImplementationPlansAPI.modifyRowOrder({ data });
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

export {
  getImplementationPlansAsync,
  createImplementationPlanAsync,
  getImplementationPlanDetailsAsync,
  updateImplementationPlanAsync,
  deleteImplementationPlanAsync,
  modifyOrderAsync,
};
