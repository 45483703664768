// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { PayrollsApi } from 'api/endpoints';
// Interfaces and types
import {
  IGetPayrollPeriods,
  ICreatePayrollPeriodBody,
  ICreatePayrollPeriodRes,
  IUpdatePayrollPeriodBodyWithParam,
  TUpdatePayrollPeriodRes,
  IGetPayrollPeriodDetailsRes,
  IDeletePayrollPeriodRes,
} from 'types/payrollTypes';
import { IErrorRes, TQueryParams } from 'types/appTypes';

const getPayrollPeriodsAsync = createAsyncThunk<
  IGetPayrollPeriods,
  TQueryParams,
  { rejectValue: IErrorRes }
>('payrolls/getPayrollPeriods', async (params: TQueryParams, { rejectWithValue }) => {
  try {
    return await PayrollsApi.getPayrollPeriods(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const createPayrollPeriodAsync = createAsyncThunk<
  ICreatePayrollPeriodRes,
  ICreatePayrollPeriodBody,
  { rejectValue: IErrorRes }
>(
  'payrolls/createPayrollPeriod',
  async (body: ICreatePayrollPeriodBody, { rejectWithValue }) => {
    try {
      return await PayrollsApi.createPayrollPeriod(body);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const getPayrollPeriodDetailsAsync = createAsyncThunk<
  IGetPayrollPeriodDetailsRes,
  number,
  { rejectValue: IErrorRes }
>('payroll/getPayrollPeriodDetails', async (id: number, { rejectWithValue }) => {
  try {
    return await PayrollsApi.getPayrollPeriodDetails(id);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const updatePayrollPeriodAsync = createAsyncThunk<
  TUpdatePayrollPeriodRes,
  IUpdatePayrollPeriodBodyWithParam,
  { rejectValue: IErrorRes }
>(
  'payroll/updatePayrollPeriod',
  async ({ id, body }: IUpdatePayrollPeriodBodyWithParam, { rejectWithValue }) => {
    try {
      return await PayrollsApi.updatePayrollPeriod({ id, body });
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const deletePayrollPeriodAsync = createAsyncThunk<
  IDeletePayrollPeriodRes,
  number,
  { rejectValue: IErrorRes }
>('payroll/deletePayrollPeriod', async (id: number, { rejectWithValue }) => {
  try {
    return await PayrollsApi.deletePayrollPeriod(id);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

export {
  getPayrollPeriodsAsync,
  createPayrollPeriodAsync,
  getPayrollPeriodDetailsAsync,
  updatePayrollPeriodAsync,
  deletePayrollPeriodAsync,
};
