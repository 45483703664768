// Core
import { CSSProperties } from 'react';
// Packages
import PhoneInput from 'react-phone-number-input';
// Styles
import './phoneInput.styles.scss';

interface ITextInputProps {
  className?: string;
  defaultCountry?: string;
  disabled?: boolean;
  placeholder?: string;
  size?: 'small' | 'middle' | 'large';
  style?: CSSProperties;
  onChange?: (value: string) => void;
  value?: string;
  dataCy?: string;
}

/**
 * @param className - The picker className
 * @param defaultCountry - The default country name
 * @param disabled - 	Whether the input is disabled
 * @param placeholder - Specifies a short hint that describes the expected value of an input field
 * @param size - The size of the input box.
 * @param style - To customize the styles
 * @param onChange - Called when select an option
 * @param value - The input content value
 * @param dataCy - Cypress
 */

const TextInput = ({
  className,
  defaultCountry = 'US',
  disabled = false,
  placeholder = '',
  size = 'middle',
  style,
  onChange = () => {},
  value = '',
  dataCy,
}: ITextInputProps) => (
  <PhoneInput
    international
    limitMaxLength
    className={`${className} ${size}`}
    defaultCountry={defaultCountry}
    disabled={disabled}
    placeholder={placeholder}
    style={style}
    onChange={onChange}
    value={value}
    data-cy={dataCy}
  />
);

export default TextInput;
