// Core
import { Component, ErrorInfo, ReactNode } from 'react';
// Packages
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
// Components
import { GridCol, GridRow, Image, Paragraph, Text, Divider, Button } from 'components/lib';
// Assets
import notFoundImg from 'assets/images/404.png';
// I18n
import { t } from 'i18n';
// Styles
import styles from './errorBoundary.module.scss';

interface IProps extends RouteComponentProps {
  children: ReactNode;
}

interface IState {
  isReloading: boolean;
  hasUiError: boolean;
  hasNotFoundError: boolean;
}

class ErrorBoundary extends Component<IProps, IState> {
  public state: IState = {
    isReloading: false,
    hasUiError: false,
    hasNotFoundError: false,
  };

  public static getDerivedStateFromError(error: Error): IState {
    // Update state so the next render will show the fallback UI.

    return { hasUiError: true, isReloading: false, hasNotFoundError: false };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);

    this.setState({ hasUiError: true });
  }

  handleReloadPage = () => {
    window.location.reload();

    this.setState({ isReloading: true });
  };

  public render() {
    const { children } = this.props;
    const { hasUiError, hasNotFoundError, isReloading } = this.state;

    // Fallback for UI errors
    if (hasUiError) {
      return (
        <div className={styles.wrapper}>
          <h1 className={styles.title}>{t('errorBoundary.title')}</h1>

          <Button
            size="large"
            disabled={isReloading}
            onClick={this.handleReloadPage}
            className={styles.goBack}
          >
            {t('errorBoundary.goBack')}
          </Button>
        </div>
      );
    }

    // Fallback for 404 errors if the resource was not found
    if (hasNotFoundError) {
      <GridRow align="middle" justify="center" className={styles.contentWrap}>
        <GridCol span={10} style={{ textAlign: 'center' }}>
          <Image src={notFoundImg} alt="404" className={styles.content} />

          <Paragraph strong className={styles.title}>
            {t('notFoundPage.title')}
          </Paragraph>

          <Text className={styles.text}>{t('notFoundPage.text')}</Text>

          <Divider />

          <Button type="primary">
            <Link to="/">{t('notFoundPage.linkBtn')}</Link>
          </Button>
        </GridCol>
      </GridRow>;
    }

    return children;
  }
}

export default withRouter(ErrorBoundary);
