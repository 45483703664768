// Packages
import { Link } from 'react-router-dom';
// Components
import { GridCol, GridRow, Space } from 'components/lib';
// Routes
import { ROUTES } from 'router/routes';
// Assets
import { Dot } from 'assets/icons/footer';
// Helpers
import { useBreakpoint } from 'customHooks';
// I18n
import { t } from 'i18n';
// Styles
import styles from './support.module.scss';

const Support = () => {
  const { xs } = useBreakpoint();

  return (
    <GridRow justify={xs ? 'start' : 'center'}>
      <GridCol>
        <Space
          split={xs ? '' : <Dot />}
          size={xs ? 9 : 'large'}
          direction={xs ? 'vertical' : 'horizontal'}
          className={styles.linkWrap}
        >
          <Link to={ROUTES.TERMS}>{t('footer.termsOfService')}</Link>

          <Link to={ROUTES.PRIVACY}>{t('footer.privacyPolicy')}</Link>

          <Link to={ROUTES.CONTACTS}>{t('footer.contactUs')}</Link>
        </Space>
      </GridCol>
    </GridRow>
  );
};

export default Support;
