// Packages
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Moment } from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// Components
import { CustomInput, CustomLabel, customErrorStyles } from './TimePickerStyledComponent';
// Types from '
import type {} from '@mui/x-date-pickers/themeAugmentation';

type Props = {
  ampm?: boolean;
  ampmInClock?: boolean;
  dataCy?: string;
  desktopModeMediaQuery?: string;
  disabled?: boolean;
  disableIgnoringDatePartForTimeValidation?: boolean;
  disableMaskedInput?: boolean;
  disableOpenPicker?: boolean;
  inputFormat?: string;
  inputRef?: () => {} | { current?: object };
  label?: string;
  locale?: string;
  maxTime?: any;
  minTime?: any;
  minutesStep?: number;
  openTo?: 'hours' | 'minutes' | 'seconds';
  required?: boolean;
  readOnly?: boolean;
  value?: Moment | null;
  views?: Array<'hours' | 'minutes' | 'seconds'>;
  closeOnSelect?: boolean;
  className?: string;
  showToolbar?: boolean;
  onChange: (value: Moment | null, keyboardInputValue?: string) => void;
};

/**
 * To select or input time.
 *
 * @description By clicking the clock icon, you can select a time from a popup panel.

 * @param onChange - Callback fired when the value (the selected date) changes @DateIOType.
 * @param acceptRegex - Regular expression to detect "accepted" symbols. 
 * @param ampm -	12h/24h view for hour selection clock.
 * @param ampmInClock - Display ampm controls under the clock (instead of in the toolbar)
 * @param className - className applied to the root component.
 * @param closeOnSelect - If true the popup or dialog will immediately close after submitting full date. `true` for Desktop, `false` for Mobile (based on the chosen wrapper and `desktopModeMediaQuery` prop). 
 * @param desktopModeMediaQuery CSS media query when Mobile mode will be changed to Desktop.
 * @param disabled -If true, the picker and text field are disabled.
 * @param disableIgnoringDatePartForTimeValidation - 	Do not ignore date part when validating min/max time.
 * @param disableMaskedInput - Disable mask on the keyboard, this should be used rarely. Consider passing proper mask for your format.
 * @param disableOpenPicker - Do not render open picker button (renders only text field with validation).
 * @param inputFormat -	Format string.
 * @param inputRef - Pass a ref to the input element.
 * @param mask -Custom mask.
 * @param maxTime - Max time acceptable time. For input validation date part of passed object will be ignored if disableIgnoringDatePartForTimeValidation not specified.
 * @param minTime - Min time acceptable time. For input validation date part of passed object will be ignored if disableIgnoringDatePartForTimeValidation not specified.
 * @param minutesStep - Step over minutes.
 * @param onAccept - Callback fired when date is accepted;
 * @param onClose -  Callback fired when the popup requests to be closed. Use in controlled mode (see open).
 * @param onOpen - 	Callback fired when the popup requests to be opened. Use in controlled mode (see open).
 * @param open - Control the popup or dialog open state.
 * @param openTo - First view to show. Must be a valid option from views list
 * @param readOnly - 	Make picker read only.
 * @param showToolbar - If true, show the toolbar even in desktop mode.
 * @param views - Array of views to show
 *  * ADDITIONAL! You can also add additional props from https://mui.com/x/api/date-pickers/time-picker/ 
 */

const TimePickerMui = ({
  ampm = false,
  ampmInClock = false,
  desktopModeMediaQuery,
  dataCy,
  disabled = false,
  disableIgnoringDatePartForTimeValidation = false,
  disableMaskedInput = false,
  disableOpenPicker = false,
  inputRef,
  label = '',
  locale = 'en',
  maxTime,
  minTime,
  minutesStep = 1,
  openTo = 'hours',
  required = false,
  readOnly = false,
  showToolbar = false,
  value = null,
  views = ['hours', 'minutes'],
  className,
  onChange,
}: Props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
      <TimePicker
        renderInput={(params: any) => (
          <>
            <div style={{ display: 'flex' }}>
              {required && <sub style={customErrorStyles}>*</sub>}
              &nbsp;<CustomLabel htmlFor={label}>{label}</CustomLabel>
            </div>

            <CustomInput
              {...params}
              id={label}
              required={required}
              variant="outlined"
              placeholder={label}
              inputProps={{
                ...params.inputProps,
                placeholder: ampm ? 'hh:mm a' : 'HH:mm',
                'data-cy': dataCy,
              }}
            />
          </>
        )}
        ampm={ampm}
        ampmInClock={ampmInClock}
        inputRef={inputRef}
        desktopModeMediaQuery={desktopModeMediaQuery}
        disabled={disabled}
        disableIgnoringDatePartForTimeValidation={disableIgnoringDatePartForTimeValidation}
        disableMaskedInput={disableMaskedInput}
        disableOpenPicker={disableOpenPicker}
        label={label}
        maxTime={maxTime}
        minTime={minTime}
        minutesStep={minutesStep}
        openTo={openTo}
        value={value}
        views={views}
        readOnly={readOnly}
        inputFormat={ampm ? 'hh:mm a' : 'HH:mm'}
        showToolbar={showToolbar}
        className={className}
        onChange={onChange}
      />
    </LocalizationProvider>
  );
};

export default TimePickerMui;
