// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
// Modules
import storage from 'modules/storage';
// API
import { UserAPI } from 'api/endpoints';
// Interfaces and types
import { IGetUserRes, IUpdateUserBody, IUpdateUserRes } from 'types/userTypes';
import { IErrorRes, IResMessage } from 'types/appTypes';

const getCurrentUserAsync = createAsyncThunk<IGetUserRes, void, { rejectValue: IErrorRes }>(
  'user/getCurrentUser',
  async (_, { rejectWithValue, getState }) => {
    try {
      const data = await UserAPI.getCurrentUser();
      const webStorage = storage.getData();
      storage.setData({ ...webStorage, hasSubscriptionAccess: data.hasSubscriptionAccess });

      const state = getState() as any;

      if (state?.user?.isSwitchingAccount) {
        moment.updateLocale('en', { week: { dow: Number(data.payroll.startDay) } });
      }

      return data;
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const updateCurrentUserAsync = createAsyncThunk<
  IUpdateUserRes,
  IUpdateUserBody,
  { rejectValue: IErrorRes }
>('user/updateCurrentUser', async (body: IUpdateUserBody, { rejectWithValue }) => {
  try {
    return await UserAPI.updateCurrentUser(body);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const resendVerificationEmailAsync = createAsyncThunk<
  IResMessage,
  void,
  { rejectValue: IErrorRes }
>('user/resendVerificationEmail', async (_, { rejectWithValue }) => {
  try {
    return await UserAPI.resendVerificationEmail();
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

export { getCurrentUserAsync, updateCurrentUserAsync, resendVerificationEmailAsync };
