// API
import { ENDPOINTS } from '../api.config';
// Modules
import xhr from 'modules/xhr';
// Interfaces and types
import { TQueryParams, IResMessage } from 'types/appTypes';
import { IGetActionLogRes } from 'types/actionLogsTypes';

export const getActionLogs = (params: TQueryParams) => {
  return xhr.get<IGetActionLogRes, IGetActionLogRes>(`${ENDPOINTS.actionLogs}`, {
    params,
  });
};

export const restoreActions = (id: number) => {
  return xhr.post<IResMessage, IResMessage>(
    `${ENDPOINTS.actionLogs}/${id}${ENDPOINTS.revert}`,
  );
};
