import { Moment } from 'moment';
// Types
import {
  EInvoiceGenerationFrequency,
  IJobCreateInvoice,
  IJobGetInvoice,
} from './recurringInvoiceTypes';
import { IResMessage } from 'types/appTypes';

export enum EInvoicePaymentStatus {
  NotPaid = 'notPaid',
  PartiallyPaid = 'partiallyPaid',
  FullyPaid = 'fullyPaid',
}

export enum EInvoiceSentStatus {
  NotSent = 'notSent',
  Sent = 'sent',
  Cancelled = 'cancelled',
}

export enum EInvoiceHoursType {
  ActualHoursWorked = 'actualHoursWorked',
  ScheduledHours = 'scheduledHours',
}

export enum EInvoiceBillingHoursType {
  UnBilled = 'unbilled',
  AllHours = 'allHours',
}

export interface IInvoiceLine {
  id: number | string;
  cni: boolean;
  date: string | null;
  description: string;
  rate?: number;
  hours?: number;
  total?: number;
  index: number;
  jobId: number | null;
  jobName: number | null;
  shiftId: number | null;
  isJobTitle: boolean;
}

export interface IInvoice {
  id: number;
  name: string;
  currency: string;
  fromText: string;
  billToText: string;
  invoiceNumber: 0;
  invoiceDate: string;
  dueDate: string;
  customFieldName1: string;
  customFieldValue1: string;
  customFieldName2: string;
  customFieldValue2: string;
  dateFrom: string;
  dateTo: string;
  note: string;
  privateNote: string;
  companyLocationJobs: IJobCreateInvoice[];
  invoiceHoursType: EInvoiceHoursType;
  showTotalsByRate: boolean;
  adjustmentFieldName1: string;
  adjustmentPercentage1: number;
  adjustmentAmount1: number;
  adjustmentFieldName2: string;
  adjustmentPercentage2: number;
  adjustmentAmount2: number;
  adjustmentFieldName3: string;
  adjustmentAmount3: number;
  sentStatus: EInvoicePaymentStatus;
  invoiceLines: IInvoiceLine[];
  totalScheduledHours: number;
  totalActualHoursWorked: number;
  hoursSubtotal: number;
  subtotal: number;
  total: number;
  adjustmentSubtotal1: number;
  adjustmentSubtotal2: number;
  invoicedAmount: number;
  totalPaid: number;
  outstanding: number;
  invoiceBillingHoursType: EInvoiceBillingHoursType;
  emailSubject?: string;
  emailRecipients?: string[];
  emailReplyTo?: string[];
  emailBody?: string;
  recurringInvoiceFrequency?: EInvoiceGenerationFrequency;
  recurringInvoiceName?: string;
  recurringInvoiceNextDate?: string;
  copyInvoiceFromId: number | null;
  attachStatementToEmail: boolean;
}

//** */ ENDPOINT "/invoices/lines" METHOD "GET"
export interface IGetInvoiceLinesBody {
  invoiceHoursType: IInvoice['invoiceHoursType'];
  showTotalsByRate: IInvoice['showTotalsByRate'];
  invoiceLines: Required<IInvoice['invoiceLines']>;
  jobIds: number[];
  dateFrom: string;
  dateTo: string;
  adjustmentPercentage1: IInvoice['adjustmentPercentage1'];
  adjustmentAmount1: IInvoice['adjustmentAmount1'];
  adjustmentPercentage2: IInvoice['adjustmentPercentage2'];
  adjustmentAmount2: IInvoice['adjustmentAmount2'];
  adjustmentAmount3: IInvoice['adjustmentAmount3'];
}

export interface IGetInvoiceLinesRes {
  hoursSubtotal: IInvoice['hoursSubtotal'];
  invoiceLines: IInvoiceLine[];
  adjustmentSubtotal1: number;
  adjustmentSubtotal2: number;
  subtotal: IInvoice['subtotal'];
  total: IInvoice['subtotal'];
}

export interface IInvoiceTotals {
  invoicedAmount: number;
  outstanding: number;
  totalPaid: number;
}

//** */ ENDPOINT "/invoices" METHOD "GET"
export interface IInvoiceItem {
  id: IInvoice['id'];
  invoiceNumber: IInvoice['id'];
  invoiceDate: Date;
  name: IInvoice['name'];
  invoicedAmount: IInvoice['invoicedAmount'];
  totalPaid: IInvoice['totalPaid'];
  outstanding: IInvoice['outstanding'];
  sentStatus: EInvoiceSentStatus;
}

export interface IGetInvoicesRes {
  count: number;
  items: IInvoiceItem[];
  totals: IInvoiceTotals;
}

//** */ ENDPOINT "/invoices" METHOD "POST"
export interface ICreateInvoiceBody {
  name: IInvoice['name'];
  currency: IInvoice['currency'];
  fromText: IInvoice['fromText'];
  billToText: IInvoice['billToText'];
  invoiceNumber: IInvoice['invoiceNumber'];
  invoiceDate: IInvoice['invoiceDate'];
  dueDate: IInvoice['dueDate'];
  customFieldName1: IInvoice['customFieldName1'];
  customFieldValue1: IInvoice['customFieldValue1'];
  customFieldName2: IInvoice['customFieldName2'];
  customFieldValue2: IInvoice['customFieldValue2'];
  dateFrom: IInvoice['dateFrom'];
  dateTo: IInvoice['dateTo'];
  note: IInvoice['note'];
  privateNote: IInvoice['privateNote'];
  companyLocationJobs: IInvoice['companyLocationJobs'];
  invoiceHoursType: IInvoice['invoiceHoursType'];
  showTotalsByRate: IInvoice['showTotalsByRate'];
  adjustmentFieldName1: IInvoice['adjustmentFieldName1'];
  adjustmentPercentage1: IInvoice['adjustmentPercentage1'];
  adjustmentAmount1: IInvoice['adjustmentAmount1'];
  adjustmentFieldName2: IInvoice['adjustmentFieldName2'];
  adjustmentPercentage2: IInvoice['adjustmentPercentage2'];
  adjustmentAmount2: IInvoice['adjustmentAmount2'];
  adjustmentFieldName3: IInvoice['adjustmentFieldName3'];
  adjustmentAmount3: IInvoice['adjustmentAmount3'];
  sentStatus: IInvoice['sentStatus'];
  recurringInvoiceId: number | null;
  invoiceTable: {
    invoiceLines: Required<IInvoice['invoiceLines']>;
    adjustmentPercentage1: IInvoice['adjustmentPercentage1'];
    adjustmentAmount1: IInvoice['adjustmentAmount1'];
    adjustmentPercentage2: IInvoice['adjustmentPercentage2'];
    adjustmentAmount2: IInvoice['adjustmentAmount2'];
    adjustmentAmount3: IInvoice['adjustmentAmount3'];
  };
  invoiceBillingHoursType: IInvoice['invoiceBillingHoursType'];
  // only received when generating invoice from recurring invoice
  recurringInvoiceFrequency?: IInvoice['recurringInvoiceFrequency'];
  recurringInvoiceName?: IInvoice['recurringInvoiceName'];
  recurringInvoiceNextDate?: IInvoice['recurringInvoiceNextDate'];
  copyInvoiceFromId: IInvoice['copyInvoiceFromId'];
}

//** */ ENDPOINT "/invoices" METHOD "PUT"
export interface IUpdateInvoiceBody extends ICreateInvoiceBody {
  emailSubject: IInvoice['emailSubject'];
  emailRecipients: IInvoice['emailRecipients'];
  emailReplyTo: IInvoice['emailReplyTo'];
  emailBody: IInvoice['emailBody'];
  attachStatementToEmail: IInvoice['attachStatementToEmail'];
}

export interface ICreateInvoiceForm
  extends Omit<
    ICreateInvoiceBody,
    | 'invoiceDate'
    | 'dueDate'
    | 'dateFrom'
    | 'dateTo'
    | 'invoiceTable'
    | 'invoiceLines'
    | 'adjustmentPercentage1'
    | 'adjustmentAmount1'
    | 'adjustmentPercentage2'
    | 'adjustmentAmount2'
    | 'adjustmentAmount3'
    | 'recurringInvoiceId'
  > {
  invoiceDate?: Moment;
  dueDate?: Moment;
  paymentTableDate: [Moment, Moment];
  invoiceLines: Required<IInvoice['invoiceLines'][]>;
  adjustmentPercentage1?: IInvoice['adjustmentPercentage1'];
  adjustmentAmount1?: IInvoice['adjustmentAmount1'];
  adjustmentPercentage2?: IInvoice['adjustmentPercentage2'];
  adjustmentAmount2?: IInvoice['adjustmentAmount2'];
  adjustmentAmount3?: IInvoice['adjustmentAmount3'];
  // only received when updating invoice
  emailSubject?: IInvoice['emailSubject'];
  emailRecipients?: IInvoice['emailRecipients'];
  emailReplyTo?: IInvoice['emailReplyTo'];
  emailBody?: IInvoice['emailBody'];
  attachStatementToEmail?: IInvoice['attachStatementToEmail'];
}

export interface ICreateInvoiceRes {
  id: number;
}

//** */ ENDPOINT /invoices/shifts-hours" METHOD "GET"
export interface IGetTotalHoursRes {
  totalActualHoursWorked: IInvoice['totalActualHoursWorked'];
  totalScheduledHours: IInvoice['totalScheduledHours'];
}

//** */ ENDPOINT /invoices/calculate" METHOD "GET"
export interface IGetTotalInvoiceCalculationBody {
  invoiceLines: IInvoiceLine[];
  adjustmentPercentage1?: IInvoice['adjustmentPercentage1'];
  adjustmentAmount1?: IInvoice['adjustmentAmount1'];
  adjustmentPercentage2?: IInvoice['adjustmentPercentage2'];
  adjustmentAmount2?: IInvoice['adjustmentAmount2'];
  adjustmentAmount3?: IInvoice['adjustmentAmount3'];
}

export interface IGetTotalInvoiceCalculationRes {
  invoiceLines: IInvoiceLine[];
  hoursSubtotal: IInvoice['hoursSubtotal'];
  subtotal: IInvoice['subtotal'];
  total: IInvoice['total'];
  adjustmentSubtotal1?: IInvoice['adjustmentSubtotal1'];
  adjustmentSubtotal2?: IInvoice['adjustmentSubtotal2'];
}

//** */ ENDPOINT "/invoices/{id}" METHOD "GET"

export enum EInvoiceView {
  Edit = 'edit',
  Copy = 'copy',
}

export interface IGetInvoiceDetailsParams {
  type: EInvoiceView;
}

export interface IGetInvoiceDetailsBodyWithParams {
  id: number;
  params: IGetInvoiceDetailsParams;
}

export enum EReadStatus {
  READ = 'read',
  UNREAD = 'unread',
}

export interface IOpenedEmail {
  readAt: Date | null;
  readBy: string;
  sentAt: Date;
  sentBy: string;
  status: EReadStatus;
}

export interface IGetInvoiceDetailsRes {
  id: IInvoice['id'];
  name: IInvoice['name'];
  currency: IInvoice['currency'];
  fromText: IInvoice['fromText'];
  billToText: IInvoice['billToText'];
  invoiceNumber: IInvoice['invoiceNumber'];
  invoiceDate: IInvoice['invoiceDate'];
  dueDate: IInvoice['dueDate'];
  customFieldName1: IInvoice['customFieldName1'];
  customFieldValue1: IInvoice['customFieldValue1'];
  customFieldName2: IInvoice['customFieldName2'];
  customFieldValue2: IInvoice['customFieldValue2'];
  dateFrom: IInvoice['dateFrom'];
  dateTo: IInvoice['dateTo'];
  note: IInvoice['note'];
  privateNote: IInvoice['privateNote'];
  totalActualHoursWorked: IInvoice['totalActualHoursWorked'];
  totalScheduledHours: IInvoice['totalScheduledHours'];
  hoursSubtotal: IInvoice['hoursSubtotal'];
  subtotal: IInvoice['subtotal'];
  adjustmentFieldName1: IInvoice['adjustmentFieldName1'];
  adjustmentPercentage1: IInvoice['adjustmentPercentage1'];
  adjustmentAmount1: IInvoice['adjustmentAmount1'];
  adjustmentSubtotal1: IInvoice['adjustmentSubtotal1'];
  adjustmentFieldName2: IInvoice['adjustmentFieldName2'];
  adjustmentPercentage2: IInvoice['adjustmentPercentage2'];
  adjustmentAmount2: IInvoice['adjustmentAmount2'];
  adjustmentSubtotal2: IInvoice['adjustmentSubtotal2'];
  adjustmentFieldName3: IInvoice['adjustmentFieldName3'];
  adjustmentAmount3: IInvoice['adjustmentAmount3'];
  total: IInvoice['total'];
  sentStatus: EInvoiceSentStatus | null;
  companyLocationJobs: IJobGetInvoice[];
  invoiceLines: Required<IInvoice['invoiceLines'][]>;
  emailSubject: string;
  emailRecipients: string[];
  emailReplyTo: string[];
  emailBody: string;
  emailLastSentBy: string;
  emailLastSentAt: Date;
  emailLastOpened: IOpenedEmail[];
  attachStatementToEmail: IInvoice['attachStatementToEmail'];
  invoiceBillingHoursType: IInvoice['invoiceBillingHoursType'];
  // only received when generating invoice from recurring invoice
  recurringInvoiceFrequency?: IInvoice['recurringInvoiceFrequency'];
  recurringInvoiceName?: IInvoice['recurringInvoiceName'];
  recurringInvoiceNextDate?: IInvoice['recurringInvoiceNextDate'];
}

//** */ ENDPOINT "/invoices/{id}" METHOD "PUT"
export interface IUpdateInvoiceBodyWithParam {
  body: ICreateInvoiceBody;
  id: IInvoice['id'];
}

export type IUpdateInvoiceRes = IResMessage;

export enum IInvoiceCustomComponentTypes {
  PERCENTAGE = 'percentage',
  AMOUNT = 'amount',
}

//** */ ENDPOINT /invoices/{invoiceId}/payments METHOD "GET"
export interface IPaymentsTableItem {
  id: number | string; // string id while creating in front end before saving to the data base
  checkNumber?: string;
  amount: number;
  paidOn: string;
  depositedOn: string;
}

export interface IGetPaymentsRes {
  invoiceBalance: number;
  paymentStatus: string;
  payments: IPaymentsTableItem[];
}

export interface IGetPaymentsParams {
  sortColumn: string;
  sortOrder: 'ASC' | 'DESC';
}

export interface IGetPaymentsParamsWithInvoiceId {
  invoiceId: number;
  params?: IGetPaymentsParams;
}

//** */ ENDPOINT /invoices/{invoiceId}/payments METHOD "POST"
export interface ISavePaymentsItem {
  id: number;
  amount: number;
  paidOn: string;
  depositedOn: string;
  checkNumber: string | null;
}

export interface ISavePaymentsBody {
  invoicePayments: ISavePaymentsItem[];
}

export interface ISavePaymentsBodyWithParam {
  invoiceId: number;
  body: ISavePaymentsBody;
}

//** */ ENDPOINT /invoices/{invoiceId}/payments/status METHOD "PUT"
export interface IPaymentStatus {
  paymentStatus: EInvoicePaymentStatus;
}

export interface IUpdatePaymentStatusParamWithBody {
  invoiceId: number;
  body: IPaymentStatus;
}

//** */ ENDPOINT /invoices/{emails}/{id} METHOD "POST"
export interface ISendEmail {
  emailSubject: string;
  emailRecipients: string[];
  emailReplyTo: string[];
  emailBody: string;
  attachStatementToEmail: IInvoice['attachStatementToEmail'];
}

export interface ISendEmailBodyWithParam {
  body: ISendEmail;
  invoiceId: number;
}

export interface ISendEmailRes {
  emailLastSentAt: Date;
  emailLastSentBy: string;
}

//** */ ENDPOINT /invoices/{invoiceId}/sent-status METHOD "GET"
export interface ISentStatus {
  sentStatus: string;
}

export interface IInvoiceEmailRead {
  id: number;
  invoiceId: number;
  readBy: string;
  readAt: Date | null;
  sentBy: string;
  sentAt: Date;
  status: EReadStatus;
}
