// Core
import { ReactNode, CSSProperties } from 'react';
// Packages
import { Result as AntResult } from 'antd';
import { ResultStatusType } from 'antd/lib/result';

export type TResultStatus = ResultStatusType;

interface IResultProps {
  className?: string;
  children?: ReactNode;
  extra?: ReactNode;
  icon?: ReactNode;
  status?: ResultStatusType;
  style?: CSSProperties;
  subTitle?: ReactNode;
  title?: ReactNode;
}

/**
 * Used to feed back the results of a series of operational tasks.
 *
 * @description Use when important operations need to inform the user to process the results and the feedback is more complicated.
 *
 * @param className - Component className
 * @param children - Some ReactNode
 * @param extra - Operating area
 * @param icon - Custom back icon
 * @param status - Result status, decide icons and colors
 * @param subTitle - The subTitle
 * @param title - The title
 * @param style - To customize the styles
 */

const Result = ({
  className,
  children,
  extra,
  icon,
  status = 'info',
  subTitle,
  title,
  style,
}: IResultProps) => (
  <AntResult
    className={className}
    extra={extra}
    icon={icon}
    status={status}
    subTitle={subTitle}
    title={title}
    style={style}
  >
    {children}
  </AntResult>
);

export default Result;
