// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import {
  getTodoAsync,
  createTodoAsync,
  getEmployeeTodoAsync,
  getTodoDetailsAsync,
  updateTodoAsync,
  deleteTodoAsync,
  deleteShiftTodoAsync,
  updateJobTodoTasksStatusAsync,
} from './todoThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import { IErrorRes } from 'types/appTypes';
import { TSliceReducer, TActionType } from 'redux/store';
import { IGetTodoRes, IGetEmployeeTodoRes, IGetTodoDetailsRes } from 'types/todoTypes';

type TAction<T extends TActionType> =
  | typeof getTodoAsync[T]
  | typeof createTodoAsync[T]
  | typeof getEmployeeTodoAsync[T]
  | typeof getTodoDetailsAsync[T]
  | typeof updateTodoAsync[T]
  | typeof deleteTodoAsync[T]
  | typeof deleteShiftTodoAsync[T]
  | typeof updateJobTodoTasksStatusAsync[T];

const handleLoadingReducer: TSliceReducer<IState, TAction<'pending'>> = state => {
  state.isLoading = true;
  state.error = null;
};

const handleEmptyFulfilledReducer: TSliceReducer<IState, TAction<'fulfilled'>> = state => {
  state.isLoading = false;
};

const handleErrorReducer: TSliceReducer<IState, TAction<'rejected'>> = (state, action) => {
  state.isLoading = false;
  state.error = action.payload || null;
};

interface IState {
  todo: IGetTodoRes | null;
  employeeTodo: IGetEmployeeTodoRes | null;
  todoDetails: IGetTodoDetailsRes | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: IState = {
  todo: null,
  todoDetails: null,
  employeeTodo: null,
  error: null,
  isLoading: false,
};

const todoSlice = createSlice({
  name: 'todo',
  initialState,
  reducers: {},
  extraReducers: builder => {
    //* GET TODO ASYNC THUNK
    builder.addCase(getTodoAsync.pending, handleLoadingReducer);
    builder.addCase(getTodoAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.todo = payload;
    });
    builder.addCase(getTodoAsync.rejected, handleErrorReducer);

    //* CREATE TODO ASYNC THUNK
    builder.addCase(createTodoAsync.pending, handleLoadingReducer);
    builder.addCase(createTodoAsync.fulfilled, handleEmptyFulfilledReducer);
    builder.addCase(createTodoAsync.rejected, handleErrorReducer);

    //* GET EMPLOYEE TODO ASYNC THUNK
    builder.addCase(getEmployeeTodoAsync.pending, handleLoadingReducer);
    builder.addCase(getEmployeeTodoAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.employeeTodo = payload;
    });
    builder.addCase(getEmployeeTodoAsync.rejected, handleErrorReducer);

    //* GET EMPLOYEE TODO DETAILS ASYNC THUNK
    builder.addCase(getTodoDetailsAsync.pending, handleLoadingReducer);
    builder.addCase(getTodoDetailsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.todoDetails = payload;
    });
    builder.addCase(getTodoDetailsAsync.rejected, handleErrorReducer);

    //* UPDATE TODO ASYNC THUNK
    builder.addCase(updateTodoAsync.pending, handleLoadingReducer);
    builder.addCase(updateTodoAsync.fulfilled, handleEmptyFulfilledReducer);
    builder.addCase(updateTodoAsync.rejected, handleErrorReducer);

    //* UPDATE JOB TODO STATUS ASYNC THUNK
    builder.addCase(updateJobTodoTasksStatusAsync.pending, handleLoadingReducer);
    builder.addCase(updateJobTodoTasksStatusAsync.fulfilled, handleEmptyFulfilledReducer);
    builder.addCase(updateJobTodoTasksStatusAsync.rejected, handleErrorReducer);

    //* DELETE EMPLOYEE TODO ASYNC THUNK
    builder.addCase(deleteTodoAsync.pending, handleLoadingReducer);
    builder.addCase(deleteTodoAsync.fulfilled, state => {
      state.isLoading = false;
      state.todoDetails = null;
    });
    builder.addCase(deleteTodoAsync.rejected, handleErrorReducer);

    //* DELETE SHIFT TODO ASYNC THUNK
    builder.addCase(deleteShiftTodoAsync.pending, handleLoadingReducer);
    builder.addCase(deleteShiftTodoAsync.fulfilled, state => {
      state.isLoading = false;
      state.todoDetails = null;
    });
    builder.addCase(deleteShiftTodoAsync.rejected, handleErrorReducer);

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export default todoSlice;
