// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Interfaces and types
import {
  ITodoParams,
  IGetTodoRes,
  ICreateTodoBody,
  ICreateTodoRes,
  IGetEmployeeTodoBody,
  IGetEmployeeTodoRes,
  IGetTodoDetailsRes,
  IUpdateTodoRes,
  IDeleteTodoRes,
  IUpdateTodoParams,
  IShiftTodoParams,
  IUpdateTasksParams,
} from 'types/todoTypes';
import { TQueryParams } from 'types/appTypes';

export const getTodo = (params: TQueryParams) => {
  return xhr.get<IGetTodoRes, IGetTodoRes>(`${ENDPOINTS.todo}`, { params });
};

export const createTodo = (body: ICreateTodoBody) => {
  return xhr.post<ICreateTodoRes, ICreateTodoRes>(`${ENDPOINTS.todo}`, body);
};

export const getEmployeeTodo = ({ userId, params }: IGetEmployeeTodoBody) => {
  return xhr.get<IGetEmployeeTodoRes, IGetEmployeeTodoRes>(
    `${ENDPOINTS.employees}/${userId}${ENDPOINTS.todo}`,
    { params },
  );
};

export const getTodoDetails = (id: number) => {
  return xhr.get<IGetTodoDetailsRes, IGetTodoDetailsRes>(`${ENDPOINTS.todo}/${id}`);
};

export const updateTodo = ({ id, userId, body }: IUpdateTodoParams) => {
  return xhr.patch<IUpdateTodoRes, IUpdateTodoRes>(
    `${ENDPOINTS.employees}/${userId}${ENDPOINTS.todo}/${id}`,
    body,
  );
};

export const deleteTodo = ({ id, userId }: ITodoParams) => {
  return xhr.delete<IDeleteTodoRes, IDeleteTodoRes>(
    `${ENDPOINTS.employees}/${userId}${ENDPOINTS.todo}/${id}`,
  );
};

export const deleteShiftTodo = ({ id, shiftId }: IShiftTodoParams) => {
  return xhr.delete<IDeleteTodoRes, IDeleteTodoRes>(
    `${ENDPOINTS.schedule}/${shiftId}${ENDPOINTS.todo}/${id}`,
  );
};

export const updateTasksStatus = ({ body, todoId }: IUpdateTasksParams) => {
  return xhr.patch<IUpdateTodoRes, IUpdateTodoRes>(
    `${ENDPOINTS.todo}/${todoId}${ENDPOINTS.tasks}`,
    body,
  );
};
