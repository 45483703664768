// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Types
import { ICreateItemMessage, IResMessage } from 'types/appTypes';
import { IFeedbackCreateBody, ICreateFeedbackFileRes } from 'types/feedbackTypes';

export const createFeedback = (body: IFeedbackCreateBody) => {
  return xhr.post<ICreateItemMessage, ICreateItemMessage>(`${ENDPOINTS.feedback}`, body);
};

export const addFeedbackFile = (file: FormData) => {
  return xhr.post<ICreateFeedbackFileRes, ICreateFeedbackFileRes>(
    `${ENDPOINTS.feedback}${ENDPOINTS.files}`,
    file,
  );
};

export const deleteFeedbackFile = (id: string) => {
  return xhr.delete<IResMessage, IResMessage>(`${ENDPOINTS.feedback}${ENDPOINTS.files}/${id}`);
};
