// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Interfaces and types
import {
  IGetGroupDetailsRes,
  IUpdateGroupRes,
  IUpdateGroupBody,
  ICreateGroupRes,
  ICreateGroupBody,
  IDeleteGroupRes,
  IGetGroupsRes,
  IGetEmployeeAvailableGroupsRes,
  IGetRestrictedGroupsFlagRes,
} from 'types/groupTypes';
import { TQueryParams } from 'types/appTypes';

export const getGroups = (params: TQueryParams) => {
  return xhr.get<IGetGroupsRes, IGetGroupsRes>(`${ENDPOINTS.groups}`, { params });
};

export const checkIfRestrictedEmployeeGroups = () => {
  return xhr.get<IGetRestrictedGroupsFlagRes, IGetRestrictedGroupsFlagRes>(
    `${ENDPOINTS.groups}/${ENDPOINTS.isRestricted}`,
  );
};

export const createGroup = (body: ICreateGroupBody) => {
  return xhr.post<ICreateGroupRes, ICreateGroupRes>(`${ENDPOINTS.groups}`, body);
};

export const getGroupAvailableEmployees = () => {
  return xhr.get<IGetEmployeeAvailableGroupsRes, IGetEmployeeAvailableGroupsRes>(
    `${ENDPOINTS.groups}?columns=id&columns=name`,
  );
};

export const getGroupDetails = (id: number) => {
  return xhr.get<IGetGroupDetailsRes, IGetGroupDetailsRes>(`${ENDPOINTS.groups}/${id}`);
};

export const updateGroup = ({ id, body }: IUpdateGroupBody) => {
  return xhr.put<IUpdateGroupRes, IUpdateGroupRes>(`${ENDPOINTS.groups}/${id}`, body);
};

export const deleteGroup = (id: number) => {
  return xhr.delete<IDeleteGroupRes, IDeleteGroupRes>(`${ENDPOINTS.groups}/${id}`);
};
