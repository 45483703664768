// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Interfaces and types
import {
  ICreateRatesBody,
  ICreateRatesRes,
  IDeleteRatesRes,
  IGetEmployeeRatesDetailsRes,
  IGetEmployeeRatesRes,
  IGetEmployeeRatesBody,
  IGetRatesRes,
  IRatesParams,
  IUpdateRatesParams,
  IUpdateRatesRes,
} from 'types/rateTypes';
import { TQueryParams } from 'types/appTypes';

export const getRates = (params: TQueryParams) => {
  return xhr.get<IGetRatesRes, IGetRatesRes>(`${ENDPOINTS.rates}`, { params });
};

export const createRates = ({ userId, ...body }: ICreateRatesBody) => {
  return xhr.post<ICreateRatesRes, ICreateRatesRes>(
    `${ENDPOINTS.employees}/${userId}${ENDPOINTS.rates}`,
    body,
  );
};

export const getEmployeeRates = ({ userId, params }: IGetEmployeeRatesBody) => {
  return xhr.get<IGetEmployeeRatesRes, IGetEmployeeRatesRes>(
    `${ENDPOINTS.employees}/${userId}${ENDPOINTS.rates}`,
    { params },
  );
};

export const getEmployeeRatesDetails = ({ id, userId }: IRatesParams) => {
  return xhr.get<IGetEmployeeRatesDetailsRes, IGetEmployeeRatesDetailsRes>(
    `${ENDPOINTS.employees}/${userId}${ENDPOINTS.rates}/${id}`,
  );
};

export const updateRates = ({ id, userId, body }: IUpdateRatesParams) => {
  return xhr.patch<IUpdateRatesRes, IUpdateRatesRes>(
    `${ENDPOINTS.employees}/${userId}${ENDPOINTS.rates}/${id}`,
    body,
  );
};

export const deleteRates = ({ id, userId }: IRatesParams) => {
  return xhr.delete<IDeleteRatesRes, IDeleteRatesRes>(
    `${ENDPOINTS.employees}/${userId}${ENDPOINTS.rates}/${id}`,
  );
};
