// Core
import { useState, useEffect } from 'react';
// Packages
// import { AiOutlineRight } from 'react-icons/ai';
import {
  Link,
  // useHistory,
  // useLocation
} from 'react-router-dom';
// Components
import {
  GridRow,
  GridCol,
  // Button, Text,
  MainHeader,
  Select,
} from 'components/lib';
// Redux
// import { clearErrors } from 'redux/auth/authReducer';
// import { useAppDispatch, useAppSelector, STORE_KEYS } from 'redux/store';
// Routes
import { ROUTES } from 'router/routes';
// Modules
import storage from 'modules/storage';
// Assets
import { FullLogo } from 'assets/icons/logo';
// Interfaces and types
import { TValueType } from 'components/lib/Select';
// I18n
import {
  changeLanguage,
  getCurrentLanguage,
  langs,
  languages,
  TLang,
  //  t,
  TLangKeys,
} from 'i18n';
// Styles
import styles from './authHeader.module.scss';

interface IProps {
  path: string;
  title: string;
}
//TODO temporary hide Sign up page

const AuthHeader = ({ path, title }: IProps) => {
  // const history = useHistory();
  // const location = useLocation();
  // const goToSignIn = path === ROUTES.SIGN_IN;
  // const goToIntro = path === ROUTES.INTRO;

  // const dispatch = useAppDispatch();
  // const { error } = useAppSelector(STORE_KEYS.AUTH);

  const [currentLanguage, setCurrentLanguage] = useState<string>(getCurrentLanguage());

  const handleChangeLanguage = (lng: TValueType) => changeLanguage(lng as TLangKeys);

  useEffect(() => {
    let language = storage.getData<TLang>('timeGoLang')?.lng;
    const browserLanguage = navigator.language.split('-')[0];

    if (!language || !(language in languages)) {
      let appLanguage = 'en';

      if (browserLanguage in languages) {
        appLanguage = browserLanguage;
      }

      setCurrentLanguage(appLanguage as string);
      return changeLanguage(appLanguage as TLangKeys);
    }

    setCurrentLanguage(language);
  }, []);

  // const handleGoTo = () => {
  //   if (error) {
  //     dispatch(clearErrors());
  //   }

  //   history.push(path);
  // };
  // const titleText = goToIntro ? '' : title;

  // let linkText = t('signIn.goToSignUp');
  // //TODO
  // let linkText = '';

  // if (goToSignIn) {
  //   linkText = t('signUp.goToSignIn');
  // }

  // if (goToIntro) {
  //   linkText = t('signIn.goBack');
  // }
  return (
    <MainHeader className={styles.authHeader}>
      <GridRow align="middle">
        <GridCol xs={10} sm={8} md={10} lg={15} xl={17}>
          <Link to={ROUTES.INTRO} className={styles.authHeaderLogo}>
            <FullLogo />
          </Link>
        </GridCol>
        <GridCol xs={8} sm={3} md={4} lg={2} xl={2} className={styles.languageSelect}>
          <Select color="secondary" value={currentLanguage} onChange={handleChangeLanguage}>
            {langs.map(lang => (
              <Select.Option key={lang} value={lang}>
                {lang}
              </Select.Option>
            ))}
          </Select>
        </GridCol>
        {/* <GridCol xs={0} sm={8} md={6} lg={4} xl={3} className={styles.authHeaderTitle}>
          <Text type="secondary" fontSize="small">
            {titleText}
          </Text>
        </GridCol> */}
        {/* {location.key && (
          <GridCol xs={6} sm={5} md={4} lg={3} xl={2} className={styles.authHeaderBtnWrap}>
            <Button onClick={handleGoTo} type="link" className={styles.authHeaderBtn}>
              {linkText}
              <AiOutlineRight />
            </Button>
          </GridCol>
        )} */}
      </GridRow>
    </MainHeader>
  );
};

export default AuthHeader;
