// Core
import { useEffect, useState } from 'react';
// Packages
import { Link } from 'react-router-dom';
// Components
import { Anchor, Button, Space } from 'components/lib';
// Routes
import { ROUTES } from 'router/routes';
// Assets
import { Dot } from 'assets/icons/footer';
// Helpers
import { useBreakpoint } from 'customHooks';
// I18n
import { t } from 'i18n';
// Styles
import styles from './sideBar.module.scss';

export enum ANCHOR_IDS {
  MAIN_SETTINGS = '#main-settings',
  PAYROLL_OVERTIME = '#payroll-overtime',
  SWITCH_ACCOUNT = '#switch-account',
}

interface IProps {
  hasActionPermissions: boolean;
  onCancel: () => void;
}

const SideBar = ({ hasActionPermissions, onCancel }: IProps) => {
  const { xs } = useBreakpoint();

  const [targetOffset, setTargetOffset] = useState<number | undefined>(undefined);

  useEffect(() => {
    setTargetOffset(window.innerHeight / 3);
  }, []);

  const handleCloseModal = () => {
    onCancel();
  };

  return (
    <Space direction="vertical" size={40}>
      <Anchor
        targetOffset={targetOffset}
        getContainer={() => document.getElementById('scroll-layout') as HTMLElement}
        className="sideBarAnchor"
      >
        <Anchor.Link href={ANCHOR_IDS.MAIN_SETTINGS} title={t('settingsModal.mainSettings')} />

        <Anchor.Link
          href={ANCHOR_IDS.PAYROLL_OVERTIME}
          title={t('settingsModal.payrollOvertime')}
        />

        <Anchor.Link
          href={ANCHOR_IDS.SWITCH_ACCOUNT}
          title={t('settingsModal.switchAccount')}
        />
      </Anchor>

      {hasActionPermissions && (
        <Space size="middle">
          <Button type="default" onClick={onCancel} className={styles.formButton}>
            {t('settingsModal.cancelBtn')}
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            className={styles.formButton}
            dataCy="saveSettingsBtn"
          >
            {t('settingsModal.saveBtn')}
          </Button>
        </Space>
      )}

      <Space
        split={xs ? '' : <Dot />}
        size={xs ? 9 : 'large'}
        direction={xs ? 'vertical' : 'horizontal'}
        className={styles.linkWrap}
      >
        <Link onClick={handleCloseModal} to={ROUTES.TERMS}>
          {t('footer.termsOfService')}
        </Link>

        <Link onClick={handleCloseModal} to={ROUTES.PRIVACY}>
          {t('footer.privacyPolicy')}
        </Link>
      </Space>

      <Space
        split={xs ? '' : <Dot />}
        size={xs ? 9 : 'large'}
        direction={xs ? 'vertical' : 'horizontal'}
        className={styles.linkWrap}
      >
        <Link onClick={handleCloseModal} to={ROUTES.CONTACTS}>
          {t('footer.contactUs')}
        </Link>
      </Space>
    </Space>
  );
};

export default SideBar;
