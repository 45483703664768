// Core
import { ReactNode, CSSProperties } from 'react';
// Packages
import { Empty as AntEmpty } from 'antd';

const noDataImg = {
  default: AntEmpty.PRESENTED_IMAGE_DEFAULT,
  simple: AntEmpty.PRESENTED_IMAGE_SIMPLE,
};

interface IEmptyProps {
  className?: string;
  description?: ReactNode;
  noDataType?: 'default' | 'simple';
  image?: ReactNode;
  imageStyle?: CSSProperties;
  style?: CSSProperties;
}

/**
 * Empty state placeholder.
 *
 * @description When there is no data provided, display for friendly tips.
 * User tutorial to create something in fresh new situation.
 *
 * @param className - Component className
 * @param description - Customize description
 * @param noDataType - Custom prop which defines an image of empty data.
 * @param image - Customize image. Will treat as image url when string provided
 * @param imageStyle - The style of image
 * @param style - To customize the styles
 */

const Empty = ({
  className,
  description = '',
  noDataType = 'default',
  image,
  imageStyle,
  style,
}: IEmptyProps) => (
  <AntEmpty
    className={className}
    description={description}
    image={image ?? noDataImg[noDataType]}
    imageStyle={imageStyle}
    style={style}
  />
);

export default Empty;
