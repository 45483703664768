// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// Redux
import { addCurrentPageToNav } from './navReducer';
// API
import { UserAPI } from 'api/endpoints';
// Interfaces and types
import { IErrorRes, IResMessage } from 'types/appTypes';
import { INavHistory } from 'types/userTypes';

const getMostVisitedPageLabelsAsync = createAsyncThunk<
  INavHistory[],
  void,
  { rejectValue: IErrorRes }
>('nav/getMostVisitedPageLabels', async (_: void, { rejectWithValue }) => {
  try {
    const data = await UserAPI.getLabelsOfMostVisitedPages();

    return data;
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const saveVisitedPageLabelAsync = createAsyncThunk<
  IResMessage,
  INavHistory,
  { rejectValue: IErrorRes }
>('nav/saveVisitedPageLabel', async (pageData: INavHistory, { dispatch, rejectWithValue }) => {
  try {
    dispatch(addCurrentPageToNav(pageData));

    const data = await UserAPI.saveVisitedPageLabel(pageData);
    await dispatch(getMostVisitedPageLabelsAsync());

    return data;
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

export { saveVisitedPageLabelAsync, getMostVisitedPageLabelsAsync };
