// Core
import { ChangeEvent, CSSProperties, ReactNode } from 'react';
// Packages
import { Input as AntPasswordInput } from 'antd';
// Styles
import styles from './passwordInput.module.scss';

interface IPasswordInputProps {
  addonAfter?: ReactNode;
  addonBefore?: ReactNode;
  allowClear?: boolean;
  autoComplete?: string;
  bordered?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  id?: string;
  maxLength?: number;
  prefix?: ReactNode;
  size?: 'small' | 'middle' | 'large';
  suffix?: ReactNode;
  value?: string;
  iconRender?: (visible: boolean) => ReactNode;
  visibilityToggle?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  className?: string;
  style?: CSSProperties;
  dataCy?: string;
}

/**
 * A basic widget for getting the user input is a password field.
 * Keyboard and mouse can be used for providing or changing data.
 *
 * @description A user input in a form field is needed. A search input is required.
 *
 * @param addonAfter - The label text displayed after (on the right side of) the input field
 * @param addonBefore - The label text displayed before (on the left side of) the input field
 * @param allowClear - If allow to remove input content with clear icon
 * @param autoComplete - browser autocomplite
 * @param bordered - Whether has border style
 * @param defaultValue - The initial input content
 * @param disabled - 	Whether the input is disabled
 * @param id - The ID for input
 * @param maxLength - The max length
 * @param prefix - The prefix icon for the Input
 * @param size - The size of the input box. Note: in the context of a form, the "large" size is used
 * @param suffix - The suffix icon for the Input
 * @param value - The input content value
 * @param iconRender - Custom toggle button
 * @param visibilityToggle - Whether show toggle button
 * @param onChange - Callback when user input
 * @param placeholder - Specifies a short hint that describes the expected value of an input field
 * @param className - Component className
 * @param style - To customize the styles
 * @param dataCy - testing
 */

const PasswordInput = ({
  addonAfter,
  addonBefore,
  allowClear = false,
  autoComplete = 'new-password',
  bordered = true,
  defaultValue,
  disabled = false,
  id,
  maxLength,
  prefix,
  size = 'middle',
  suffix,
  value,
  iconRender,
  visibilityToggle = true,
  onChange,
  placeholder = '',
  className,
  style,
  dataCy,
}: IPasswordInputProps) => {
  const classNames = [className, styles.formInputHasAffix].join(' ');

  return (
    <AntPasswordInput.Password
      addonAfter={addonAfter}
      addonBefore={addonBefore}
      allowClear={allowClear}
      bordered={bordered}
      defaultValue={defaultValue}
      disabled={disabled}
      id={id}
      autoComplete={autoComplete}
      maxLength={maxLength}
      prefix={prefix}
      size={size}
      suffix={suffix}
      type="password"
      value={value}
      iconRender={iconRender}
      visibilityToggle={visibilityToggle}
      onChange={onChange}
      placeholder={placeholder}
      className={classNames}
      style={style}
      data-cy={dataCy}
    />
  );
};

export default PasswordInput;
