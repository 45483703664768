// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Types
import { TQueryParams, IResMessage } from 'types/appTypes';
import {
  IGetImplementationPlansRes,
  ICreateImplementationPlanBody,
  ICreateImplementationPlanRes,
  IGetImplementationPlanDetails,
  IUpdateImplementationPlanBodyWithParams,
  IModifyOrder,
} from 'types/implementationPlansTypes';

export const getImplementationPlansItems = (params: TQueryParams) => {
  return xhr.get<IGetImplementationPlansRes, IGetImplementationPlansRes>(
    `${ENDPOINTS.implementationPlans}`,
    {
      params,
    },
  );
};

export const getImplementationPlanDetails = (id: number) => {
  return xhr.get<IGetImplementationPlanDetails, IGetImplementationPlanDetails>(
    `${ENDPOINTS.implementationPlans}/${id}`,
  );
};

export const createImplementationPlan = (body: ICreateImplementationPlanBody) => {
  return xhr.post<ICreateImplementationPlanRes, ICreateImplementationPlanRes>(
    `${ENDPOINTS.implementationPlans}`,
    body,
  );
};

export const updateImplementationPlan = ({
  id,
  body,
}: IUpdateImplementationPlanBodyWithParams) => {
  return xhr.put<IResMessage, IResMessage>(`${ENDPOINTS.implementationPlans}/${id}`, body);
};

export const deleteImplementationPlan = (id: number) => {
  return xhr.delete<IResMessage, IResMessage>(`${ENDPOINTS.implementationPlans}/${id}`);
};

export const modifyRowOrder = (body: IModifyOrder) => {
  return xhr.put<IResMessage, IResMessage>(
    `${ENDPOINTS.implementationPlans}${ENDPOINTS.modifyOrder}`,
    body,
  );
};
