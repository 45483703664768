// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { LeaveAPI } from 'api/endpoints';
// Interfaces and types
import {
  ICreateLeaveBody,
  ICreateLeaveRes,
  IDeleteLeaveRes,
  IGetEmployeeLeaveBody,
  IGetEmployeeLeaveDetailsRes,
  IGetEmployeeLeaveRes,
  IGetLeaveRes,
  ILeaveParams,
  IUpdateLeaveParams,
  IUpdateLeaveRes,
} from 'types/leaveTypes';
import { IErrorRes, TQueryParams } from 'types/appTypes';

const getLeavesAsync = createAsyncThunk<
  IGetLeaveRes,
  TQueryParams,
  { rejectValue: IErrorRes }
>('leave/getLeaves', async (params: TQueryParams, { rejectWithValue }) => {
  try {
    return await LeaveAPI.getLeaveRequests(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const createLeavesAsync = createAsyncThunk<
  ICreateLeaveRes,
  ICreateLeaveBody,
  { rejectValue: IErrorRes }
>('leave/createLeaves', async (body: ICreateLeaveBody, { rejectWithValue }) => {
  try {
    return await LeaveAPI.createLeaveRequest(body);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getEmployeeLeavesAsync = createAsyncThunk<
  IGetEmployeeLeaveRes,
  IGetEmployeeLeaveBody,
  { rejectValue: IErrorRes }
>('leave/getEmployeeLeaves', async (cred: IGetEmployeeLeaveBody, { rejectWithValue }) => {
  try {
    return await LeaveAPI.getEmployeeLeaveRequests(cred);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getEmployeeLeaveDetailsAsync = createAsyncThunk<
  IGetEmployeeLeaveDetailsRes,
  ILeaveParams,
  { rejectValue: IErrorRes }
>('leave/getEmployeeLeaveDetails', async (params: ILeaveParams, { rejectWithValue }) => {
  try {
    return await LeaveAPI.getEmployeeLeaveRequestDetails(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const updateLeaveAsync = createAsyncThunk<
  IUpdateLeaveRes,
  IUpdateLeaveParams,
  { rejectValue: IErrorRes }
>('leave/updateLeave', async (cred: IUpdateLeaveParams, { rejectWithValue }) => {
  try {
    return await LeaveAPI.updateLeaveRequest(cred);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const deleteLeaveAsync = createAsyncThunk<
  IDeleteLeaveRes,
  ILeaveParams,
  { rejectValue: IErrorRes }
>('leave/deleteRates', async (params: ILeaveParams, { rejectWithValue }) => {
  try {
    return await LeaveAPI.deleteLeaveRequest(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

export {
  getLeavesAsync,
  createLeavesAsync,
  getEmployeeLeavesAsync,
  getEmployeeLeaveDetailsAsync,
  updateLeaveAsync,
  deleteLeaveAsync,
};
