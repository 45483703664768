// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import {
  getRatesAsync,
  createRatesAsync,
  getEmployeeRatesAsync,
  getEmployeeRatesDetailsAsync,
  updateRatesAsync,
  deleteRatesAsync,
} from './rateThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import { IErrorRes } from 'types/appTypes';
import { TSliceReducer, TActionType } from 'redux/store';
import { IGetRatesRes, IGetEmployeeRatesRes, IRates } from 'types/rateTypes';

type TAction<T extends TActionType> =
  | typeof getRatesAsync[T]
  | typeof createRatesAsync[T]
  | typeof getEmployeeRatesAsync[T]
  | typeof getEmployeeRatesDetailsAsync[T]
  | typeof updateRatesAsync[T]
  | typeof deleteRatesAsync[T];

const handleLoadingReducer: TSliceReducer<IState, TAction<'pending'>> = state => {
  state.isLoading = true;
  state.error = null;
};

const handleEmptyFulfilledReducer: TSliceReducer<IState, TAction<'fulfilled'>> = state => {
  state.isLoading = false;
};

const handleErrorReducer: TSliceReducer<IState, TAction<'rejected'>> = (state, action) => {
  state.isLoading = false;
  state.error = action.payload || null;
};

interface IState {
  rates: IGetRatesRes | null;
  employeeRates: IGetEmployeeRatesRes | null;
  ratesDetails: IRates | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: IState = {
  rates: null,
  ratesDetails: null,
  employeeRates: null,
  error: null,
  isLoading: false,
};

const ratesSlice = createSlice({
  name: 'rates',
  initialState,
  reducers: {},
  extraReducers: builder => {
    //* GET RATES ASYNC THUNK
    builder.addCase(getRatesAsync.pending, handleLoadingReducer);
    builder.addCase(getRatesAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.rates = payload;
    });
    builder.addCase(getRatesAsync.rejected, handleErrorReducer);

    //* CREATE RATES ASYNC THUNK
    builder.addCase(createRatesAsync.pending, handleLoadingReducer);
    builder.addCase(createRatesAsync.fulfilled, handleEmptyFulfilledReducer);
    builder.addCase(createRatesAsync.rejected, handleErrorReducer);

    //* GET EMPLOYEE RATES ASYNC THUNK
    builder.addCase(getEmployeeRatesAsync.pending, handleLoadingReducer);
    builder.addCase(getEmployeeRatesAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.employeeRates = payload;
    });
    builder.addCase(getEmployeeRatesAsync.rejected, handleErrorReducer);

    //* GET EMPLOYEE RATES DETAILS ASYNC THUNK
    builder.addCase(getEmployeeRatesDetailsAsync.pending, handleLoadingReducer);
    builder.addCase(getEmployeeRatesDetailsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.ratesDetails = payload;
    });
    builder.addCase(getEmployeeRatesDetailsAsync.rejected, handleErrorReducer);

    //* UPDATE RATES ASYNC THUNK
    builder.addCase(updateRatesAsync.pending, handleLoadingReducer);
    builder.addCase(updateRatesAsync.fulfilled, handleEmptyFulfilledReducer);
    builder.addCase(updateRatesAsync.rejected, handleErrorReducer);

    //* DELETE RATES ASYNC THUNK
    builder.addCase(deleteRatesAsync.pending, handleLoadingReducer);
    builder.addCase(deleteRatesAsync.fulfilled, state => {
      state.isLoading = false;
      state.ratesDetails = null;
    });
    builder.addCase(deleteRatesAsync.rejected, handleErrorReducer);

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export default ratesSlice;
